<template>
  <div class="placeholder-input flex items-center gap-3">
    <fw-icon-calendar class="h-5 w-5 text-gray-400" />
    {{ placeholder[language] }}
  </div>
</template>

<script>
export default {
  name: 'PlaceholderTextInput',
  props: {
    placeholder: {
      type: Object,
      default: () => {
        return {
          pt: 'Insira uma data',
          en: 'Enter a date'
        }
      }
    },
    language: {
      type: String,
      default: 'pt'
    }
  }
}
</script>
