<template>
  <BlockUserDetails :user="user" :loading="loading">
    <fw-panel title="Grupos" :loading="savingData" after-loading-checked boxed="xs" custom-class="bg-gray-100">
      <template #toolbar>
        <div class="flex gap-1 items-center">
          <div v-if="canEdit" class="flex gap-5">
            <fw-button
              :type="!editMode ? 'link' : 'link-light'"
              :disabled="savingData"
              @click.native="toggleEditMode()"
            >
              {{ editMode ? 'Fechar edicão' : 'Editar' }}
            </fw-button>
            <fw-button
              v-if="editMode"
              type="primary"
              :disabled="savingData"
              :loading="savingData"
              @click.native="save()"
              >Guardar</fw-button
            >
          </div>
        </div>
      </template>

      <template v-if="canEdit && editMode">
        <multiselect
          v-model="selectedGroups"
          :options="groups"
          :show-labels="false"
          :clear-on-select="false"
          :searchable="true"
          :multiple="true"
          placeholder="Selecione os módulos"
          track-by="key"
          label="title"
          :loading="loadingGroups"
          :internal-search="true"
        ></multiselect>
      </template>

      <template v-else>
        <div
          v-if="user.groups?.length"
          class="text-sm text-gray-500 font-normal flex gap-2 items-center flex-wrap pt-2"
        >
          <b-tooltip
            v-for="group in user.groups"
            :key="group.key"
            type="is-light"
            :label="group.title"
            position="is-bottom"
          >
            <fw-tag type="light-border-box" size="xs">
              <span class="truncate max-w-[20ch]">{{ group.title }}</span>
            </fw-tag>
          </b-tooltip>
        </div>
        <fw-panel-info v-else clean empty>
          Sem grupos
        </fw-panel-info>
      </template>
    </fw-panel>
    <!-- TODO: pretify me -->
    <fw-panel v-if="enrollment.enrolled_date" title="Módulos" :loading="loading" boxed="xs" custom-class="bg-gray-100">
      <template>
        <div class="w-20 mr-3">
          <ProgressIcon
            :progress="progress"
            show-percentage
            percentage-pos="right"
            :color="progress == 100 ? 'bg-gradient-to-r from-teal-300 to-teal-400' : 'bg-gray-500 bg-opacity-80'"
          />
        </div>

        <div v-for="unit in units" :key="unit.key">
          <div class="flex justify-between items-center">
            <fw-heading size="h3">{{ unit.title }}</fw-heading>
            <ProgressIcon
              :progress="unit.progress"
              show-percentage
              percentage-pos="right"
              :color="unit.progress == 100 ? 'bg-gradient-to-r from-teal-300 to-teal-400' : 'bg-gray-500 bg-opacity-80'"
            />
          </div>

          <div v-for="topic in unit.topics" :key="topic.key" class="flex justify-between items-center">
            <fw-label>{{ topic.title }}</fw-label>
            <div class="inline-flex">
              <fw-icon-check v-if="topic.seen_date" class="w-5 h-5 text-primary mr-1" />
              {{ topic.seen_date ? 'Concluído' : ' Por concluir' }}
            </div>
          </div>
        </div>
      </template>
    </fw-panel>
    <div v-else>
      <fw-tag type="xlight"> Não inscrito </fw-tag>
    </div>
  </BlockUserDetails>
</template>

<script>
import BlockUserDetails from '@/fw-modules/fw-core-vue/id/components/blocks/BlockUserDetails'
import Multiselect from 'vue-multiselect'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import ServiceCourses from '@/fw-modules/fw-core-vue/courses/services/ServiceCourses'
import ProgressIcon from '@/fw-modules/fw-core-vue/ui/components/ProgressIcon'

export default {
  components: {
    BlockUserDetails,
    Multiselect,
    ProgressIcon
  },
  props: {
    user: {
      type: Object,
      default: () => {}
    },
    savingData: {
      type: Boolean,
      default: false
    },
    canEdit: {
      type: Boolean,
      default: true
    },
    courseKey: {
      type: String
    },
    editionKey: {
      type: String
    },
    classKey: {
      type: String
    }
  },
  data() {
    return {
      loading: true,
      selectedGroups: [],
      loadingGroups: false,
      groups: [],
      units: [],
      progress: 0,
      enrollment: {},
      editMode: false
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    loggedUser() {
      return this.$store.getters.getUser
    }
  },

  mounted() {
    if (this.user.groups?.length) {
      this.selectedGroups = [...this.user.groups]
    }
    this.getGroups()
    this.getClassStudent()
  },

  methods: {
    save() {
      this.$emit('update', {
        userKey: this.user.key,
        groupKeys: this.selectedGroups?.map(item => item.key)
      })
      this.$emit('close')
    },

    async getGroups() {
      this.loadingGroups = true

      await utils.tryAndCatch(this, async () => {
        const response = await ServiceCourses.getClassGroups(this.courseKey, this.editionKey, this.classKey)
        console.log('getClassGroups :>> ', response)
        this.groups = response.groups
      })

      this.loadingGroups = false
    },

    toggleEditMode() {
      this.editMode = !this.editMode
    },

    async getClassStudent() {
      this.loading = true

      await utils.tryAndCatch(this, async () => {
        const response = await ServiceCourses.getClassStudent(
          this.courseKey,
          this.editionKey,
          this.classKey,
          this.user.key
        )
        console.log('getClassStudent :>> ', response)
        this.units = response.units
        this.progress = response.progress
        this.enrollment = response.enrollment
      })

      this.loading = false
    }
  }
}
</script>
