<template>
  <div class="placeholder-input">
    {{ placeholder[language] }}
  </div>
</template>

<script>
export default {
  name: 'PlaceholderTextInput',
  props: {
    placeholder: {
      type: Object,
      default: () => {
        return {
          pt: 'Insira aqui o seu e-mail',
          en: 'Enter here your email'
        }
      }
    },
    language: {
      type: String,
      default: 'pt'
    }
  }
}
</script>
