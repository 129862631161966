<template>
  <div class="max-w-screen-lg mx-auto px-5  flex flex-col h-full rounded-md">
    <div class="mb-1 ">
      <div class="flex gap-3 items-center mb-2">
        <div>
          <div class="font-bold text-2xl">
            Respostas
          </div>
          <div class="font-semibold text-gray-500 opacity-70 text-sm -mt-1">
            {{ instance !== null ? instance.title[language] : 'Exame sem título' }}
          </div>
        </div>
        <div class="flex-1 flex gap-3 justify-end  items-center">
          <b-dropdown v-if="false" aria-role="list" :position="'is-bottom-left'" :disabled="correctanswers === null">
            <template #trigger="{ active }"></template>

            <b-dropdown-item aria-role="listitem" @click.native="downloadAnswers()"
              >Resumo de cada participante</b-dropdown-item
            >
            <b-dropdown-item aria-role="listitem" @click.native="downloadAnswers('extended')"
              >Todas as respostas dos participantes</b-dropdown-item
            >
          </b-dropdown>
          <fw-button
            :type="instance && instance.answersFile ? 'primary' : 'disabled'"
            class="flex-shrink-0"
            @click.native="downloadZip()"
            >{{ txtDownloadButton }}</fw-button
          >
        </div>
      </div>
      <div class="relative my-3">
        <fw-icon-search class="text-gray-400 absolute top-3 left-3 h-5 w-5"></fw-icon-search>
        <input
          v-model="searchUserQuery"
          class="w-full border border-gray-100 shadow px-2 py-2 pl-10 mb-2 outline-none active:ring-2 focus:ring-2 ring-primary ring-opacity-30 rounded-md"
          type="text"
          placeholder="Pesquisar..."
        />
      </div>
    </div>
    <div class="flex-1 flex flex-col bg-white rounded shadow h-full">
      <div class="flex bg-gray-50 text-gray-500 font-semibold px-3 text-sm py-2 gap-3 border-b border-gray-100 pr-5">
        <div class="flex-1">
          Nome do participante
        </div>
        <div class="flex-shrink-0 w-32 text-right">
          Nº de respostas
        </div>
        <div class="flex-shrink-0 w-36 text-right">
          Respostas certas
        </div>
        <div class="flex-shrink-0 w-24 text-right">
          Pontuação
        </div>
        <div v-if="false" class="flex-shrink-0 w-32 text-right"></div>
      </div>
      <div class="flex-1  overflow-y-auto">
        <RecycleScroller
          v-if="filteredUsers.length && !loading"
          v-slot="{ item, index }"
          :items="filteredUsers"
          :item-size="54"
          :buffer="50"
          key-field="key"
        >
          <Person
            :key="item.key"
            class="border-b border-gray-100 pl-1 pr-0"
            no-style
            :person="item"
            :selectable="false"
            :clickable="true"
            @clicked="openUserExam(item.key)"
          >
            <template #options>
              <div class="flex gap-3 justify-end">
                <div class="flex-shrink-0 w-32 text-right">
                  {{ item.summary.answers ? item.summary.answers : '--' }}
                </div>
                <div class="flex-shrink-0 w-36 text-right">
                  {{ item.summary.correctAnswers ? item.summary.correctAnswers : '--' }}
                </div>
                <div class="flex-shrink-0 w-24 text-right">
                  {{ item.summary.scaleScore ? item.summary.scaleScore : '--' }}
                </div>
                <fw-button v-if="false" type="light" class="flex gap-2" @click.native="openUserExam(item.key)">
                  Abrir exame
                  <svg
                    class="fill-current h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M10 6v2H5v11h11v-5h2v6a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h6zm11-3v9l-3.794-3.793-5.999 6-1.414-1.414 5.999-6L12 3h9z"
                    />
                  </svg>
                </fw-button>
              </div>
            </template>
          </Person>
        </RecycleScroller>
        <div v-else-if="filteredUsers.length === 0 && !loading" class="py-16 text-center text-gray-400">
          {{ searchUserQuery.length > 0 ? 'Sem resultados' : 'Ainda não temos nenhuma resposta registada' }}
        </div>
        <div v-else class="p-5 flex flex-col gap-5">
          <div class="animate-pulse h-6 rounded bg-gray-200 w-1/2"></div>
          <div class="animate-pulse h-6 rounded bg-gray-200 w-2/6"></div>
          <div class="animate-pulse h-6 rounded bg-gray-200 w-4/6"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Person from '@/fw-modules/fw-core-vue/ui/components/cards/PersonBase'
import { RecycleScroller } from 'vue-virtual-scroller'
import FormServices from '@/fw-modules/fw-core-vue/exams/services/ServiceExams'
//import ServiceSettings from '../../id/services/ServiceSettings'
export default {
  name: 'PanelUserAnswers',
  components: { Person, RecycleScroller },
  props: {
    instance: {
      type: Object
    }
  },
  data: function() {
    return {
      loading: true,
      //instance: null,
      answers: null,
      correctanswers: null,
      searchUserQuery: '',
      usersWithAnswers: [],
      selectedForm: null,
      selectedFormIndex: 0,
      formsData: {},
      pages: {},
      questions: [],
      language: 'pt'
    }
  },
  computed: {
    txtDownloadButton() {
      if (this.instance == null) {
        return ''
      }
      return 'Descarregar (' + (this.instance.forms && this.instance.forms.length > 1 ? '.zip' : '.csv') + ')'
    },
    filteredUsers() {
      if (this.selectedForm !== null && this.answers !== null) {
        //aply search filter
        if (this.searchUserQuery.length > 0) {
          return this.answers.forms[this.selectedForm]
            .map(o => {
              let data = o.user
              data['summary'] = o.summary
              return data
            })
            .filter(user => user.user.full_name.startsWith(this.searchUserQuery))
        }
        return this.answers.forms[this.selectedForm].map(o => {
          let data = o.user
          data['summary'] = o.summary
          return data
        })
      } else {
        return []
      }
    },
    instanceKey() {
      return this.instanceData && this.instanceData.key ? this.instanceData.key : null
    }
  },

  mounted() {
    this.start()
  },

  methods: {
    downloadZip() {
      if (this.instance && this.instance.answersFile) {
        let url = this.instance.answersFile.url_format
        url = url.replace('{TOKEN}', this.instance.answersFile.token)
        url = url.replace('{KEY}', this.instance.answersFile.key)
        url = url.replace('{FILENAME}', this.instance.answersFile.filename)
        window.open(url, '_blank')
      }
    },
    downloadAnswers(mode = 'summary') {
      if (this.instance !== null && this.answers !== null) {
        let correctOptions = this.correctanswers
        let rows = []
        if (mode === 'summary') {
          rows.push(['Nome', 'Número', 'e-mail', 'FormID', 'Num respostas dadas', 'Num respostas certas'])
          for (let formKey of Object.keys(this.answers.forms)) {
            //Por cada form
            for (let i = 0; i < this.answers.forms[formKey].length; i++) {
              //Por cada resposta
              let user = this.answers.forms[formKey][i].user
              let answers = this.answers.forms[formKey][i].answers
              let answered = 0,
                correct = 0
              for (let answerPageKey of Object.keys(answers)) {
                for (let inputKey of Object.keys(answers[answerPageKey])) {
                  let selectedOptions = answers[answerPageKey][inputKey]
                  if (selectedOptions.length > 0) {
                    answered++
                  }
                  if (correctOptions !== null) {
                    let correct_options = correctOptions[answerPageKey + '_' + inputKey].correct
                    //if (correctOptionsCache[answerPageKey] && correctOptionsCache[answerPageKey][inputKey]) {
                    //use cache
                    //  correct_options = correctOptionsCache[answerPageKey][inputKey]
                    //} else {
                    //create cache
                    //correct_options =
                    //}

                    for (let j = 0; j < correct_options.length; j++) {
                      let keycorrect = correct_options[j].key
                      let isRight = correct_options[j].score > 0
                      if (selectedOptions.includes(keycorrect) && isRight) {
                        correct++
                      }
                    }
                  } else {
                    correct = '--'
                  }
                }
              }
              //ignore my student number
              if (user.number !== 2010126227) {
                rows.push([user.full_name, user.number, user.email, formKey, answered, correct])
              }
              //console.log(user, answered, correct)
            }
          }
        } else if (mode === 'extended') {
          //mostra respostas dada por cada pessoa a cada pergunta
          let header = ['Nome', 'Número', 'e-mail', 'FormID']

          //loop pelas perguntas para criar header

          //preparing data
          for (let formKey of Object.keys(this.pages)) {
            for (let pageKey of Object.keys(this.pages[formKey])) {
              let schema = this.pages[formKey][pageKey].schema
              for (let s = 0; s < schema.length; s++) {
                for (let c = 0; c < schema[s].content.length; c++) {
                  let question = {
                    label: schema[s].content[c].label ? schema[s].content[c].label['pt'] : null,
                    instructions: schema[s].content[c].instructions['pt'],
                    options: [],
                    key: schema[s].content[c].key,
                    pageKey: pageKey,
                    type: schema[s].content[c].type
                  }

                  if (question.label !== null) {
                    header.push(question.label)
                  } else {
                    header.push(question.instructions)
                  }

                  for (let o = 0; o < schema[s].content[c].options.length; o++) {
                    question['options'].push({
                      text: schema[s].content[c].options[o]['text']['pt'],
                      num_option: o,
                      key: schema[s].content[c].options[o].key
                    })
                  }
                  this.questions.push(question)
                }
              }
            }
          }

          header.push('Num respostas dadas')
          header.push('Num respostas certas')
          rows.push(header)

          for (let formKey of Object.keys(this.answers.forms)) {
            //Por cada form
            for (let i = 0; i < this.answers.forms[formKey].length; i++) {
              //Por cada resposta
              let user = this.answers.forms[formKey][i].user
              let answers = this.answers.forms[formKey][i].answers
              let answered = 0,
                correct = 0
              let userRow = [user.full_name, user.number, user.email, formKey]
              for (let answerPageKey of Object.keys(answers)) {
                for (let inputKey of Object.keys(answers[answerPageKey])) {
                  let selectedOptions = answers[answerPageKey][inputKey]
                  console.log(selectedOptions, Array.isArray(selectedOptions))
                  if (selectedOptions.length > 0) {
                    answered++
                  }
                  if (this.correctOptions !== null) {
                    let correct_options = correctOptions[answerPageKey + '_' + inputKey].correct
                    //if (correctOptionsCache[answerPageKey] && correctOptionsCache[answerPageKey][inputKey]) {
                    //use cache
                    //  correct_options = correctOptionsCache[answerPageKey][inputKey]
                    //} else {
                    //create cache
                    //correct_options =
                    //}

                    for (let j = 0; j < correct_options.length; j++) {
                      let keycorrect = correct_options[j].key
                      let isRight = correct_options[j].score > 0
                      if (selectedOptions.includes(keycorrect) && isRight) {
                        correct++
                      }
                    }
                  } else {
                    correct = '--'
                  }
                }
              }

              //loop questions to see what the user answer
              for (let q = 0; q < this.questions.length; q++) {
                let selectedOptions = answers[this.questions[q].pageKey][this.questions[q].key]
                if (this.questions[q].type === 'multiple_choice') {
                  let selected = this.questions[q].options
                    .filter(opt => selectedOptions.includes(opt.key))
                    .map(opt => String.fromCharCode(97 + opt.num_option).toUpperCase())
                  userRow.push(selected.join(', '))
                } else {
                  userRow.push(selectedOptions)
                }
              }

              userRow.push(answered)
              userRow.push(correct)
              //ignore my student number
              if (user.number !== 2010126227) {
                rows.push(userRow)
              }
              //console.log(user, answered, correct)
            }
          }
        }

        let csvContent = 'data:text/csv;charset=utf-8,' + rows.map(e => e.join(',')).join('\n')

        let encodedUri = encodeURI(csvContent)
        let link = document.createElement('a')
        link.setAttribute('href', encodedUri)
        link.setAttribute(
          'download',
          mode === 'summary'
            ? 'Resumo Respostas Certas (ExamID_' + this.instance.key + ').csv'
            : 'Respostas (ExamID_' + this.instance.key + ').csv'
        )
        link.style.display = 'hidden'
        document.body.appendChild(link) // Required for FF

        link.click() // This will download the data file named "my_data.csv".
      }
    },
    getNumberAnswersUser(i) {
      if (this.selectedForm !== null && this.answers !== null) {
        let sum = 0
        for (let formKey of Object.keys(this.answers.forms[this.selectedForm][i].answers)) {
          sum += Object.keys(this.answers.forms[this.selectedForm][i].answers[formKey]).length
        }
        return sum
      } else {
        return 0
      }
    },
    async start() {
      this.loading = true
      if (this.instanceData) {
        this.instance = this.instanceData
      } else {
        try {
          this.instance = await FormServices.getInstance(this.instanceKey ? this.instanceKey : this.instanceId)
        } catch (e) {
          console.error(e)
        }
      }
      this.selectedForm = this.instance.forms[0].key
      //let show = false
      /*if (this.instance !== null && show) {
          this.selectedForm = this.instance.forms[0].key*/
      //check if user have answers
      /*try {
            let correct_answers = await ServiceSettings.getSetting('correction_form_' + this.selectedForm)
            this.correctanswers = JSON.parse(correct_answers.value)
          } catch (e) {
            console.error('Correct answers not found', e)
          }*/
      /*   try {
            this.formsData[this.selectedForm] = await FormServices.getForm(this.selectedForm)
            //load page
            for (let p = 0; p < this.formsData[this.selectedForm].pages.length; p++) {
              let pageKey = this.formsData[this.selectedForm].pages[p].key
              if (!this.pages[this.selectedForm]) {
                this.pages[this.selectedForm] = {}
              }
              this.pages[this.selectedForm][pageKey] = await FormServices.getPage(pageKey)
            }
          } catch (e) {
            console.error(e)
          }
        } else {*/
      try {
        this.answers = await FormServices.getAnswers(
          this.instanceKey ? this.instanceKey : this.instanceId ? this.instanceId : this.instanceData.key
        )
      } catch (e) {
        console.error(e)
      }
      this.loading = false
    },
    //},
    changeForm(i) {
      if (this.instance.forms[i]) {
        this.selectedFormIndex = i
        this.selectedForm = this.instance.forms[i].key
      }
    },

    clickBackground() {},
    goBack() {
      if (this.instanceId || this.instanceData) {
        this.$emit('close')
      } else {
        this.$router.back()
      }
    },
    openUserExam(key) {
      console.log('open exam', key)
      window.open('/builder/exam/' + this.instanceKey + '/answers/' + key, '_self')
      //this.$router.push('/builder/exam/' + this.instanceKey + '/answers/' + key)
    }
  }
}
</script>

<style scoped></style>
