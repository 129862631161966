<template>
  <div>
    <TextHeadingDual :main-text="'Dashboard'" class="mb-2"></TextHeadingDual>
    <PanelExamCover
      :form="form"
      :mode="instance.mode"
      :instance="instance"
      :instance-key="instance.key"
      :first-loading="false"
      :language="language"
      :status="instance.status"
      :is-dashboard="true"
      :override-exam-name="instance.title && instance.title[language] ? instance.title[language] : 'Sem título'"
      :users="users"
      :show-participant-message="false"
    />
    <fw-panel title="Acesso ao exame" boxed="sm" class="my-5">
      <div class="flex gap-10">
        <div class="flex flex-col gap-1">
          <div><fw-label marginless>Acesso</fw-label></div>
          <div class="flex gap-2">
            <div v-if="instance.mode == 'remote'" class="text-gray-500 py-1.5">Remoto</div>
            <div v-else>
              <span>Presencial</span>
              <span class="text-primary ml-2 text-sm">(exclusivo <b class="font-medium">UC DigitalPad</b>)</span>
            </div>
          </div>
        </div>
        <div v-if="instance.mode != 'remote'" class="flex flex-col gap-1">
          <div><fw-label marginless>Código de acesso</fw-label></div>
          <div class="flex gap-2">
            <div v-if="instance.small_key" class="flex items-center gap-2">
              <span>{{ instance.small_key }}</span>
              <fw-button type="link" paddingless size="xs" @click.native="generateAccessCode()">
                <fw-icon-reverse class="h-4 w-4 mr-1" /> Gerar novo
              </fw-button>
            </div>
            <div v-else>
              <fw-button type="link" paddingless @click.native="generateAccessCode()">Gerar novo</fw-button>
            </div>
          </div>
        </div>
        <div v-if="instance.mode == 'remote'" class="flex flex-col gap-1 flex-1">
          <div><fw-label marginless>Endereço de acesso</fw-label></div>
          <div class="flex gap-1 items-center">
            <div>
              <b-dropdown aria-role="list" position="is-bottom-left">
                <fw-button-dropdown slot="trigger" aria-role="listitem" type="link" label="App">
                  {{ platform == 'ucstudent' ? 'UC Student' : 'UC Teacher' }}
                </fw-button-dropdown>
                <b-dropdown-item has-link paddingless aria-role="listitem">
                  <a @click="platform = 'ucstudent'">UC Student</a>
                </b-dropdown-item>
                <b-dropdown-item has-link paddingless aria-role="listitem">
                  <a @click="platform = 'ucteacher'">UC Teacher</a>
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <div class="flex gap-2 flex-1 items-center">
              <div>
                <v-clamp :lines="1">{{ examLink }}</v-clamp>
              </div>
              <div>
                <b-tooltip label="Copiar link do exame" type="is-light">
                  <fw-button :type="'link'" @click.native="toClipboardExamLink">
                    <fw-icon-check v-if="copied" class="h-5 w-5"></fw-icon-check>
                    <fw-icon-copy v-else class="h-5 w-5"></fw-icon-copy>
                  </fw-button>
                </b-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </fw-panel>

    <fw-panel title="Calendarização" boxed="sm" class="my-5">
      <div class="flex gap-10">
        <div v-if="instance.mode == 'remote'" class="flex flex-col gap-1">
          <div><fw-label marginless>Estado</fw-label></div>
          <div class="flex gap-2">
            <fw-icon-calendar class="h-5 w-5 flex-shrink-0 text-gray-500 mt-0.5"></fw-icon-calendar>
            <div v-if="instance.startDate == null" class="text-gray-500">Não agendado.</div>
            <div v-else>Agendado</div>
          </div>
        </div>
        <div class="flex flex-col gap-1">
          <div><fw-label marginless>Início previsto</fw-label></div>
          <div>
            <div v-if="instance.startDate != null">
              {{ instance.startDate | formatDateTime }}
            </div>
            <div v-else class="text-gray-500">Sem data de início definida.</div>
          </div>
        </div>
        <div class="flex flex-col gap-1">
          <div><fw-label marginless>Local</fw-label></div>
          <div>
            <div v-if="instance.location !== null && instance.location !== ''">
              {{ instance.location }}
            </div>
            <div v-else class="text-gray-500">Sem local definido.</div>
          </div>
        </div>
      </div>
    </fw-panel>

    <fw-panel title="Composição" class="my-5" boxed="sm">
      <div class="flex flex-col gap-5">
        <div v-for="(frm, f) in instance.forms" :key="'form_' + f" class="flex gap-4 items-center">
          <div class="rounded-xl bg-gray-300 text-gray-600 uppercase w-9 h-9 leading-9 text-center font-bold">
            {{ frm.letter }}
          </div>
          <div class="flex-1 text-gray-600 font-semibold capitalize">Versão {{ frm.letter }}</div>
          <div class="flex gap-5">
            <div class="flex flex-col items-center gap-0.5">
              <fw-label marginless>Secções</fw-label>
              <div>{{ frm.pages[0].sections.length }}</div>
            </div>
            <div class="flex flex-col items-center gap-0.5">
              <fw-label marginless>Questões</fw-label>
              <div>{{ countAllQuestions(frm) }}</div>
            </div>
            <div v-if="false" class="flex flex-col items-center gap-0.5">
              <fw-label marginless>Idiomas</fw-label>
              <div>
                <fw-tag
                  v-for="(lang, l) in frm.availableLanguages"
                  :key="'form_language_' + f + '_' + l"
                  size="xs"
                  type="border-light"
                  class="uppercase"
                >
                  {{ lang }}
                </fw-tag>
              </div>
            </div>
          </div>
        </div>
      </div>
    </fw-panel>

    <fw-panel title="Responsável" class="my-5" boxed="sm">
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        <Person v-if="creator != null" :person="creator" paddingless></Person>
        <Person v-if="managers.length > 0" :person="managers[0]" paddingless></Person>
      </div>
    </fw-panel>
  </div>
</template>
<script>
import TextHeadingDual from '../text/TextHeadingDual.vue'
import PanelExamCover from './PanelExamCover.vue'
import Person from '@/fw-modules/fw-core-vue/ui/components/cards/PersonBase'
import FwLabel from '../../../ui/components/text/FwLabel.vue'
import FormServices from '@/fw-modules/fw-core-vue/exams/services/ServiceExams'
export default {
  name: 'ExamDashboard',
  components: {
    PanelExamCover,
    TextHeadingDual,
    Person,
    FwLabel
  },
  props: {
    instance: {
      type: Object,
      required: true
    },
    users: {
      type: Object,
      required: true
    },
    language: {
      type: String,
      required: true
    }
  },
  data: function() {
    return {
      form: null,
      platform: 'ucstudent',
      copied: false
    }
  },
  computed: {
    examLink() {
      if (this.platform == 'ucstudent') {
        return process.env.VUE_APP_IS_PRODUCTION
          ? `https://ucstudent.uc.pt/ex/${this.instance.key}`
          : `https://ucstudent.dev.ucframework.pt/ex/${this.instance.key}`
      } else {
        return process.env.VUE_APP_IS_PRODUCTION
          ? `https://ucteacher.uc.pt/ex/${this.instance.key}`
          : `https://ucteacher.dev.ucframework.pt/ex/${this.instance.key}`
      }
    },
    creator() {
      return this.users[this.instance.user_key] ?? null
    },
    managers() {
      return Object.keys(this.instance.managers).map(manager => this.users[manager])
    }
  },
  methods: {
    toClipboardExamLink() {
      this.$copyText(this.examLink).then(() => {
        this.copied = true

        if (this.clipboard_timer !== null) {
          clearTimeout(this.clipboard_timer)
        } else {
          this.$buefy.toast.open({
            duration: 3000,
            message: `Link copiado para a área de transferência.`,
            position: 'is-top'
          })
        }

        this.clipboard_timer = setTimeout(() => {
          this.copied = false
          this.clipboard_timer = null
        }, 3000)
      })
    },
    async generateAccessCode() {
      try {
        let code = await FormServices.generateAccessCode(this.instance.key)
        this.instance.small_key = code.small_key
      } catch (e) {
        console.log(e)
      }
    },
    countAllQuestions(form) {
      let count = 0

      form.pages.forEach(page => {
        page.sections.forEach(section => {
          count += section.questionCount
        })
      })

      return count
    }
  }
}
</script>
