<template>
  <div class="placeholder-input relative">
    {{ placeholder[language] }}
    <fw-icon-chevron-down class="absolute right-2.5 top-1/2 h-5 w-5 transform -translate-y-1/2 mt-1.5" />
    <fw-icon-chevron-up class="absolute right-2.5 top-1/2 h-5 w-5 transform -translate-y-1/2 -mt-1.5" />
  </div>
</template>

<script>
export default {
  name: 'PlaceholderSelect',
  props: {
    placeholder: {
      type: Object,
      default: () => {
        return {
          pt: 'Escolha uma opção',
          en: 'Choose an option'
        }
      }
    },
    language: {
      type: String,
      default: 'pt'
    }
  }
}
</script>
