import ViewManageCourses from './views/manage/ViewManageCourses'
import ViewManageCourse from './views/manage/ViewManageCourse'
import ViewManageCourseEdition from './views/manage/ViewManageCourseEdition'
import ViewManageCourseEditionModule from './views/manage/ViewManageCourseEditionModule'

export default [
  {
    path: '/manage/courses',
    name: 'courses-manage',
    component: ViewManageCourses,
    meta: { requiresRole: 'teacher' }
  },
  {
    path: '/manage/course/:id/:view?',
    name: 'course-manage',
    component: ViewManageCourse,
    meta: { requiresRole: 'teacher' }
  },
  {
    path: '/manage/course/:id/edition/:editionId/modules/:moduleId?',
    name: 'course-manage-edition-module',
    component: ViewManageCourseEditionModule,
    meta: { requiresRole: 'teacher', view: 'modules' }
  },
  {
    path: '/manage/course/:id/edition/:editionId/:view?',
    name: 'course-manage-edition',
    component: ViewManageCourseEdition,
    meta: { requiresRole: 'teacher' }
  },
  {
    path: '/manage/course/:id/edition/:editionId/classes/:classKey/:subview?',
    name: 'course-manage-edition-class',
    component: ViewManageCourseEdition,
    meta: { requiresRole: 'teacher', view: 'classes' }
  },
  {
    path: '/manage/course/:id/edition/:editionId/modules/:moduleId/people',
    name: 'course-manage-edition-module-people',
    component: ViewManageCourseEditionModule,
    meta: { requiresRole: 'teacher' }
  },
  {
    path: '/manage/course/:id/edition/:editionId/modules/:moduleId/foruns',
    name: 'course-manage-edition-module-foruns',
    component: ViewManageCourseEditionModule,
    meta: { requiresRole: 'teacher' }
  },
  {
    path: '/manage/course/:id/edition/:editionId/modules/:moduleId/:topicId',
    name: 'course-manage-edition-module-topic',
    component: ViewManageCourseEditionModule,
    meta: { requiresRole: 'teacher', view: 'modules' }
  },
  {
    path: '/manage/course/:id/edition/:editionId/modules/:moduleId/:topicId/agenda',
    name: 'course-manage-edition-module-topic-agenda',
    component: ViewManageCourseEditionModule,
    meta: { requiresRole: 'teacher' }
  },
  {
    path: '/manage/course/:id/edition/:editionId/modules/:moduleId/:topicId/foruns',
    name: 'course-manage-edition-module-topic-foruns',
    component: ViewManageCourseEditionModule,
    meta: { requiresRole: 'teacher' }
  },
  {
    path: '/manage/course/:id/edition/:editionId/:view/collections',
    name: 'course-manage-edition-post-collections',
    component: ViewManageCourseEdition,
    meta: { requiresRole: 'teacher' }
  },
  {
    path: '/manage/course/:id/edition/:editionId/:view/collection/:collectionId/:postId?',
    name: 'course-manage-edition-post-collection',
    component: ViewManageCourseEdition,
    meta: { requiresRole: 'teacher' }
  }
]
