<template>
  <div>
    <TextHeadingDual :main-text="'Editor'" :secondary-text="currentVersionText">
      <template v-slot:options>
        <b-dropdown
          v-if="form != null && form.options.availableLanguages.length > 1"
          aria-role="list"
          position="is-bottom-left"
        >
          <template #trigger="{ active }">
            <b-button :label="currentLanguage" type="is-light" />
          </template>
          <b-dropdown-item aria-role="listitem" @click="language = 'pt'">Português</b-dropdown-item>
          <b-dropdown-item aria-role="listitem" @click="language = 'en'">Inglês</b-dropdown-item>
        </b-dropdown>
        <fw-button v-if="editable || canIgnoreQuestions || canUpdateOptions" type="link" @click.native="editForm()">
          Editar versão
        </fw-button>
        <fw-menu-more v-if="deletable">
          <b-dropdown-item aria-role="listitem" @click="deleteForm()">Eliminar versão</b-dropdown-item>
        </fw-menu-more>
      </template>
    </TextHeadingDual>

    <section v-if="!loading && form != null && pages.length > 0" id="exam-start" class="pb-20 pt-5">
      <div class="flex gap-8 flex-col">
        <div>
          <fw-label>Instruções</fw-label>
          <div v-if="!isInstructionsFieldEmpty" v-html="form.instructions[language]"></div>
          <div v-else class="text-sm text-gray-500">Não foram definidas instruções.</div>
        </div>

        <div
          v-if="pages.length > 0 && pages[0].schema.length > 0 && pages[0].schema[0].content.length > 0"
          class="w-full flex-1"
        >
          <div
            class="p-2 pb-7 text-center text-gray-400 border-t border-dashed border-gray-300"
            style="font-size: 0.6rem; line-height: 1rem"
          >
            INÍCIO DO EXAME
          </div>
          <div v-for="(page, p) in pages" :key="'page_' + p">
            <fw-form
              :id="page.key"
              :exam="true"
              :font-size-ratio="fontSize"
              :language="language"
              :form="page.schema"
              :errors="{}"
              :data="pagesState[page.key]"
              :editable="true"
              :disable-context-menu="false"
              :key-name="'key'"
              :view="'slides'"
              :input-gap="'gap-6'"
              :section-gap="'gap-12'"
              :show-saving-indicators="false"
              :text-area-save-button="true"
              :random-options="false"
              @download="downloadFile"
            ></fw-form>
          </div>

          <div
            class="p-2 mt-8 pb-7 text-center text-gray-400 border-t border-dashed border-gray-300"
            style="font-size: 0.6rem; line-height: 1rem"
          >
            FIM DO EXAME
          </div>
        </div>
        <div v-else class="bg-white rounded-xl py-20 text-center text-gray-400 w-full flex flex-col gap-5 items-center">
          Exame sem conteúdo
          <fw-button type="primary" @click.native="editForm()">Editar</fw-button>
        </div>
      </div>
    </section>
    <div v-else-if="loading" class="flex justify-center items-center h-64 animate-pulse text-lg text-gray-500">
      A carregar...
    </div>
  </div>
</template>
<script>
import FormServices from '@/fw-modules/fw-core-vue/exams/services/ServiceExams'
import TextHeadingDual from '../text/TextHeadingDual.vue'
import FwForm from '@/fw-modules/fw-core-vue/ui/components/form/Exam'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  name: 'ExamViewer',
  components: {
    TextHeadingDual,
    FwForm
  },
  props: {
    formOptions: {
      type: Object,
      default: () => {
        return {}
      }
    },
    instance: {
      type: Object,
      default: null
    },
    permissions: {
      type: Object,
      default: () => {
        return {}
      }
    },
    formId: {
      type: String,
      default: null
    }
  },
  data: function() {
    return {
      form: null,
      pages: [],
      language: 'pt',
      fontSize: 1,
      pagesState: {},
      loading: false
    }
  },
  computed: {
    currentLanguage() {
      return this.language == 'en' ? 'Inglês' : 'Português'
    },
    editable() {
      return this.form && this.form.permissions.edit ? this.form.permissions.edit : false
    },
    deletable() {
      return this.form && this.form.permissions.delete && this.permissions.delete ? this.form.permissions.delete : false
    },
    canIgnoreQuestions() {
      return this.permissions != null && this.permissions.canIgnoreQuestions
    },
    canUpdateOptions() {
      return this.permissions != null && this.permissions.canUpdateOptions
    },
    /*formIndex() {
      let i = 0
      for (let form of this.instance.forms) {
        if (form.key === this.formID) {
          return i
        }
        i++
      }
      return i
    },*/
    currentVersionText() {
      let version = this.instance.forms.find(v => v.key === this.formId)
      return version ? 'Versão ' + version.letter : '--' //+ String.fromCharCode(64 + this.formIndex)
    },
    isInstructionsFieldEmpty() {
      if (this.form && this.form.instructions && this.form.instructions[this.language]) {
        // Basic clean up... we should improve this.
        var cleanInstructions = utils.stripHTML(this.form.instructions[this.language])
        return !cleanInstructions.length > 0
      }
      return true
    }
  },
  watch: {
    formId: function(val) {
      if (this.form == null || val !== this.form.key) this.loadForm()
    }
  },
  created() {
    this.loadForm()
  },
  methods: {
    deleteForm() {
      this.$buefy.dialog.confirm({
        title: 'Eliminar versão',
        message: 'Tem a certeza que deseja eliminar esta versão?',
        confirmText: 'Eliminar',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          await FormServices.deleteForm(this.formId)
          this.$emit('delete-form')
          this.$buefy.toast.open({
            message: 'Versão eliminada com sucesso',
            type: 'is-success'
          })
          this.$router.push({ name: 'exams-manage-editor', params: { id: this.instance.key } })
        }
      })
    },
    downloadFile() {},
    editForm() {
      this.$emit('edit-form', this.formId)
    },
    shuffle(array) {
      for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1))
        var temp = array[i]
        array[i] = array[j]
        array[j] = temp
      }
    },
    async loadFormPage(pageKey) {
      console.log('loadFormPage', pageKey)
      let pageResponse = await FormServices.getPage(pageKey)
      if (typeof pageResponse.title[this.language] === 'undefined') {
        pageResponse.title[this.language] = ''
      }

      console.log(pageResponse)

      for (let s = 0; s < pageResponse.schema.length; s++) {
        if (typeof pageResponse.schema[s].title[this.language] === 'undefined') {
          pageResponse.schema[s].title[this.language] = ''
        }

        //remove boxes
        pageResponse.schema[s].boxed = false

        //Shuffle content
        if (
          this.formOptions.randomSections &&
          pageResponse.schema[s].content &&
          pageResponse.schema[s].content.length > 0
        ) {
          //pageResponse.schema[s].content =
          this.shuffle(pageResponse.schema[s].content)
        }

        for (let i = 0; i < pageResponse.schema[s].content.length; i++) {
          //score
          if (typeof pageResponse.schema[s].content[i].score === 'undefined') {
            pageResponse.schema[s].content[i].score = null
          }
          //instructions
          if (
            typeof pageResponse.schema[s].content[i].instructions === 'undefined' ||
            typeof pageResponse.schema[s].content[i].instructions[this.language] === 'undefined'
          ) {
            if (typeof pageResponse.schema[s].content[i].instructions === 'undefined') {
              pageResponse.schema[s].content[i].instructions = {}
            }
            pageResponse.schema[s].content[i].instructions[this.language] = ''
          }
          //label
          if (
            typeof pageResponse.schema[s].content[i].label === 'undefined' ||
            typeof pageResponse.schema[s].content[i].label[this.language] === 'undefined'
          ) {
            if (typeof pageResponse.schema[s].content[i].label === 'undefined') {
              pageResponse.schema[s].content[i].label = {}
            }
            pageResponse.schema[s].content[i].label[this.language] = ''
          }
          //message
          if (
            pageResponse.schema[s].content[i].message &&
            typeof pageResponse.schema[s].content[i].message[this.language] === 'undefined'
          ) {
            if (typeof pageResponse.schema[s].content[i].message === 'undefined') {
              pageResponse.schema[s].content[i].message = {}
            }
            pageResponse.schema[s].content[i].message[this.language] = ''
          }
          //text
          if (
            pageResponse.schema[s].content[i].text &&
            typeof pageResponse.schema[s].content[i].text[this.language] === 'undefined'
          ) {
            if (typeof pageResponse.schema[s].content[i].text === 'undefined') {
              pageResponse.schema[s].content[i].text = {}
            }
            pageResponse.schema[s].content[i].text[this.language] = ''
          }
          //placeholder
          if (
            typeof pageResponse.schema[s].content[i].placeholder === 'undefined' ||
            typeof pageResponse.schema[s].content[i].placeholder[this.language] === 'undefined'
          ) {
            if (typeof pageResponse.schema[s].content[i].placeholder === 'undefined') {
              pageResponse.schema[s].content[i].placeholder = {}
            }

            if (pageResponse.schema[s].content[i].type === 'text_area') {
              pageResponse.schema[s].content[i]['placeholder'][this.language] =
                this.language === 'pt' ? 'Escreva aqui a sua resposta' : 'Write here your answer'
            }

            pageResponse.schema[s].content[i].placeholder[this.language] = ''
          }

          //check options
          if (pageResponse.schema[s].content[i].options && pageResponse.schema[s].content[i].options.length > 0) {
            for (let o = 0; o < pageResponse.schema[s].content[i].options.length; o++) {
              let optiontext = pageResponse.schema[s].content[i].options[o].text
              /*let optionscore = 0
              if (
                this.scores &&
                this.scores[pageResponse.key + '_' + pageResponse.schema[s].content[i].key] &&
                this.scores[pageResponse.key + '_' + pageResponse.schema[s].content[i].key].correct &&
                this.scores[pageResponse.key + '_' + pageResponse.schema[s].content[i].key].correct[o] &&
                this.scores[pageResponse.key + '_' + pageResponse.schema[s].content[i].key].correct[o].score
              ) {
                optionscore = Number(
                  this.scores[pageResponse.key + '_' + pageResponse.schema[s].content[i].key].correct[o].score
                )
              }*/

              //? this.scores[pageResponse.key + '_' + pageResponse.schema[s].content[i].key].score
              //: 0

              /*try {
                optiontext = JSON.parse(optiontext)
                console.log('é JSON', optiontext)
              } catch (e) {
                //no json detected
                console.log('NAO é JSON', optiontext)
              }*/

              if (typeof optiontext !== 'string' && typeof optiontext[this.language] === 'undefined') {
                optiontext[this.language] = ''
              }
              pageResponse.schema[s].content[i].options[o].text = optiontext
              //pageResponse.schema[s].content[i].options[o].score = optionscore
            }
          }
        }
      }

      return pageResponse
    },
    async loadForm() {
      let response = await FormServices.getForm(this.formId)
      this.form = response
      let promises = []
      //response.pages.forEach(page => promises.push(FormServices.getPage(page.key)))
      response.pages.forEach(page => promises.push(this.loadFormPage(page.key)))

      this.pages = []
      Promise.all(promises).then(result => {
        this.pages = result
      })
    }
  }
}
</script>
