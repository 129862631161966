<template>
  <div class="placeholder-input min-h-24" style="z-index: 1">
    {{ placeholder[language] }}
    <div v-if="max > 0" class="mt-1 font-semibold text-xs">Reposta limitada a {{ input.options.max }} caracteres</div>
  </div>
</template>

<script>
export default {
  name: 'PlaceholderTextInput',
  props: {
    placeholder: {
      type: Object,
      default: () => {
        return {
          pt: 'Insira aqui o texto',
          en: 'Enter here the text'
        }
      }
    },
    max: {
      type: Number,
      default: 0
    },
    language: {
      type: String,
      default: 'pt'
    }
  }
}
</script>
