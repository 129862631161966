<template>
  <fw-panel title="Novo grupo">
    <form @submit.prevent="next()">
      <div class="flex flex-col">
        <div>
          <fw-label>Nome</fw-label>
          <TextInput
            v-model="title"
            :minlength="2"
            :maxlength="150"
            :class="{
              error: $v.title.$error
            }"
            autofocus
          >
          </TextInput>

          <fw-tip v-if="$v.title.$error" error>
            <span v-if="!$v.title.min">Insira no mínimo 2 caracteres</span>
            <span v-if="!$v.title.max">Insira no máximo 150 caracteres</span>
            <span v-else>Insira o nome do grupo</span>
          </fw-tip>
        </div>
      </div>
      <div class="flex flex-shrink-0 pt-5 gap-3">
        <div class="flex-1"></div>
        <fw-button type="link-muted" @click.native="cancel()">
          Cancelar
        </fw-button>
        <fw-button :type="'primary'" class="w-32" role="submit">
          Criar
        </fw-button>
      </div>
    </form>
  </fw-panel>
</template>

<script>
import TextInput from '@/fw-modules/fw-core-vue/ui/components/form/TextInput'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
export default {
  name: 'ModalNewCourse',

  components: {
    TextInput
  },

  data() {
    return {
      title: ''
    }
  },

  validations: {
    title: {
      required,
      min: minLength(2),
      max: maxLength(150)
    }
  },

  methods: {
    next() {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.$emit('save', {
        title: this.title
      })
    },

    cancel() {
      this.$emit('cancel')
    }
  }
}
</script>
