<template>
  <PanelNotifications
    :get-notifications-api-function="getNotificationsApi"
    :get-notification-api-function="getNotificationApi"
    :show-export-toolbar="false"
  />
</template>

<script>
import PanelNotifications from '@/fw-modules/fw-core-vue/ui/components/panels/PanelNotifications'
import ServiceCourses from '@/fw-modules/fw-core-vue/courses/services/ServiceCourses'

export default {
  components: {
    PanelNotifications
  },

  props: {
    course: {
      type: Object,
      required: true
    },
    edition: {
      type: Object
    }
  },
  computed: {
    courseKey() {
      return this.course.key
    },
    editionKey() {
      return this.edition?.key
    }
  },
  methods: {
    async getNotificationApi(key) {
      return await ServiceCourses.getNotification(this.courseKey, this.editionKey, key)
    },
    async getNotificationsApi(params) {
      return await ServiceCourses.getNotifications(this.courseKey, this.editionKey, params)
    }
  }
}
</script>
