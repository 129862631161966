<template>
  <PanelActivity :get-activity-api-function="getActivityApi" />
</template>

<script>
import PanelActivity from '@/fw-modules/fw-core-vue/ui/components/panels/PanelActivity'
import ServiceCourses from '@/fw-modules/fw-core-vue/courses/services/ServiceCourses'
export default {
  components: {
    PanelActivity
  },

  props: {
    course: {
      type: Object,
      required: true
    },
    edition: {
      type: Object
    }
  },
  computed: {
    courseKey() {
      return this.course.key
    },
    editionKey() {
      return this.edition?.key
    }
  },
  methods: {
    async getActivityApi(params) {
      return await ServiceCourses.getActivity(this.courseKey, this.editionKey, params)
    }
  }
}
</script>
