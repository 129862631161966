<template>
  <form
    class="fw-form flex flex-col"
    :style="{ fontSize: computedFontSize }"
    :class="[sectionGap, { text15x: fontSizeRatio > 1.25 && fontSizeRatio < 1.75, text20x: fontSizeRatio >= 1.75 }]"
    @submit.prevent="sendForm"
    @keyup.enter="sendForm"
  >
    <div v-if="!paginate">
      <FormSection
        v-for="(section, s) in form"
        :id="id"
        :key="'form_section_' + s"
        :section="section"
        :has-section="hasSection"
        :editable="editable"
        :data="tmp_data"
        :errors="errorsLocal || errors"
        :debug="debug"
        :language="language"
        :languages-options="languagesOptions"
        :can-preview-files="canPreviewFiles"
        :can-edit-files="canEditFiles"
        @logs="$emit('logs', $event)"
        @download="$emit('download', $event)"
        @preview="$emit('preview', $event)"
        @changed="dataChanged"
        @atomic-changed="$emit('atomic-changed', $event)"
        @save-manual="$emit('save-manual', $event)"
        @saved="$emit('saved', $event)"
      />
    </div>
    <FormSection
      v-else
      :id="id"
      :key="'form_section_' + currentPage"
      :section="form[currentPage]"
      :has-section="hasSection"
      :editable="editable"
      :data="tmp_data"
      :errors="errorsLocal || errors"
      :debug="debug"
      :language="language"
      :languages-options="languagesOptions"
      :can-preview-files="canPreviewFiles"
      :can-edit-files="canEditFiles"
      @logs="$emit('logs', $event)"
      @download="$emit('download', $event)"
      @preview="$emit('preview', $event)"
      @changed="dataChanged"
      @atomic-changed="$emit('atomic-changed', $event)"
      @save-manual="$emit('save-manual', $event)"
      @saved="$emit('saved', $event)"
    />
    <div
      v-if="showSendButton || showSaveButton || paginate"
      class="bg-gray-50 sticky bottom-0 border-t border-gray-100 flex gap-3 px-5 py-4"
    >
      <div class="flex-1 flex items-center">
        <fw-icon-error-warning v-if="numberErrors > 0" class="text-red-500 mr-2 w-5 h-5"></fw-icon-error-warning>
        <span v-if="numberErrors > 0" class="text-red-500 font-semibold">
          Tem {{ numberErrors }} erro(s) no formulário.
        </span>
      </div>
      <fw-button
        v-if="paginate && currentPage > 0"
        icon="chevron-left"
        size="md"
        type="xlight"
        @click.native="prevPage"
      >
        Anterior
      </fw-button>
      <fw-button
        v-if="paginate && currentPage < form.length - 1"
        icon-right="chevron-right"
        type="xlight"
        size="md"
        @click.native="nextPage"
      >
        Seguinte
      </fw-button>
      <fw-button
        v-if="showSaveButton"
        :type="!showSendButton ? 'primary' : 'xlight'"
        :loading="savingLocal || saving"
        @click.native="save()"
      >
        Guardar
      </fw-button>
      <fw-button v-if="showSendButton" type="primary" :loading="sendingLocal || sending" @click.native="submit()">
        Enviar
      </fw-button>
    </div>

    <fw-panel-info v-if="debug" label="Data (raw)">
      <json-viewer :value="{ form, tmp_data }"></json-viewer>
    </fw-panel-info>
  </form>
</template>

<script>
import FormSection from './FormSection.vue'
import ServiceForms from '../../../forms/services/ServiceForms'
import utils from '@/fw-modules/fw-core-vue/utilities/utils.js'
import Vue from 'vue'
export default {
  name: 'FwForm',
  components: {
    FormSection
  },
  props: {
    canEditFiles: {
      type: Boolean,
      default: true
    },
    canPreviewFiles: {
      type: Boolean,
      default: true
    },
    formKey: {
      type: String
    },
    local: {
      type: Boolean,
      default: false
    },
    saving: {
      type: Boolean,
      default: false
    },
    sending: {
      type: Boolean,
      default: false
    },
    showSendButton: {
      type: Boolean,
      default: false
    },
    showSaveButton: {
      type: Boolean,
      default: false
    },
    hasSection: {
      type: Boolean,
      default: true
    },
    fontSizeRatio: {
      type: Number,
      default: 1
    },
    data: {
      type: Object,
      default: function() {
        return {}
      }
    },
    keyName: {
      type: String,
      default: 'key'
    },
    form: {
      type: Array,
      default: function() {
        return []
      }
    },
    editable: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    bucketId: {
      type: String,
      default: null
    },
    folderId: {
      type: String,
      default: null
    },
    disableContextMenu: {
      type: Boolean,
      default: false
    },
    language: {
      type: String,
      default: 'pt'
    },
    languagesOptions: {
      type: Array,
      default: function() {
        return ['pt', 'en']
      }
    },
    id: {
      type: String,
      default: 'fw_form'
    },
    sectionGap: {
      //tailwind gap property
      type: String,
      default: 'gap-7'
    },
    dirtyInputs: {
      type: Set,
      default: function() {
        return null
      }
    },
    debug: {
      type: Boolean,
      default: false
    },
    paginate: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },

  data() {
    return {
      currentPage: 0,
      errorsLocal: null,
      tmp_data: {},
      savedTimeout: null,
      sendingLocal: false,
      savingLocal: false
    }
  },

  computed: {
    numberErrors() {
      let errors = this.errorsLocal || this.errors
      return Object.keys(errors || {}).length
    },
    showImageFullScreen() {
      return this.activeFullscreenImage !== null
    },
    computedFontSize() {
      return this.fontSizeRatio * 100 + '%'
    },
    hasLogListener() {
      return this.$listeners && this.$listeners.logs
    },
    newFileContext() {
      return { bucket: this.bucketId, folder: this.folderId }
    },
    mandatoryString: function() {
      return 'Obrigatório'
    },
    optionalString: function() {
      return 'Opcional'
    },
    user() {
      return this.$store.getters.getUser
    }
  },

  watch: {
    // whenever question changes, this function will run
    successRequest(newValue) {
      if (newValue) {
        this.saved = new Set([...this.dirty, ...this.failed])
        this.failed.clear()
        this.dirty.clear()
        //after 2 seconds, clear saved
        if (this.savedTimeout == null) {
          this.savedTimeout = setTimeout(() => {
            this.saved.clear()
            this.savedTimeout = null
          }, 2000)
        }
      }
    },
    failedRequest(newValue) {
      if (newValue) {
        this.failed = new Set(this.dirty)
        this.dirty.clear()
      }
    }
  },

  created() {
    //TODO: integrate real data from prop here
    let tmp_data = {}
    for (let i = 0; i < this.form.length; i++) {
      let section = this.form[i]
      for (let j = 0; j < section.content.length; j++) {
        let input = section.content[j]

        if (input.type === 'group') {
          for (let k = 0; k < input.content.length; k++) {
            let subinput = input.content[k]
            if (this.data[subinput[this.keyName]]) {
              tmp_data[subinput[this.keyName]] = this.data[subinput[this.keyName]]
            } else if (subinput.type === 'files') {
              tmp_data[subinput[this.keyName]] = this.data[subinput[this.keyName]]
                ? this.data[subinput[this.keyName]]
                : []
            } else {
              tmp_data[subinput[this.keyName]] = this.data[subinput[this.keyName]]
                ? this.data[subinput[this.keyName]]
                : ''
            }
          }
        } else {
          if (input[this.keyName]) {
            if (this.data[input.key]) {
              //verify if its a date, datetime or identity_doc field
              if ((input.type === 'date' || input.type === 'datetime') && this.data[input.key] != null) {
                //convert to date object
                tmp_data[input.key] = new Date(this.data[input.key])
              } else if (
                input.type === 'identity_doc' &&
                this.data[input.key] &&
                this.data[input.key].expire_date != null
              ) {
                tmp_data[input.key] = this.data[input.key]
                tmp_data[input.key].expire_date = new Date(this.data[input.key].expire_date)
              } else if (input.type === 'custom_objects' && !Array.isArray(this.data[input.key])) {
                tmp_data[input.key] = []
              } else {
                tmp_data[input.key] = this.data[input.key]
              }
            } else if (
              input.type === 'files' ||
              input.type === 'upload_file' ||
              input.type === 'person_input' ||
              input.type === 'meeting_input' ||
              input.type === 'multiple_choice' ||
              //input.type === 'select' ||
              input.type === 'custom_objects'
            ) {
              tmp_data[input[this.keyName]] = []
            } else if (input.type === 'select') {
              tmp_data[input[this.keyName]] = null
            } else if (input.type === 'image_direct' || input.type === 'text_content') {
              //IGNORE!
            } else if (input.type === 'identity_doc') {
              tmp_data[input[this.keyName]] = {
                type: 'cc',
                value: '',
                country: 'PT',
                emission_date: null,
                expire_date: null
              }
            } else if (input.type === 'date' || input.type === 'datetime') {
              tmp_data[input[this.keyName]] = null
            } else if (input.type === 'country_input') {
              tmp_data[input[this.keyName]] = 'PT' //set Portugal as default
            } else if (input.type === 'vat') {
              tmp_data[input[this.keyName]] = {
                country: 'PT',
                value: ''
              }
            } else if (input.type === 'address') {
              tmp_data[input[this.keyName]] = {
                address: '',
                postal_code: '',
                locality: '',
                country: 'PT',
                number: '',
                state: ''
              }
            } else if (input.type === 'phone_number') {
              tmp_data[input[this.keyName]] = {
                country: 'PT',
                value: ''
              }
            } else if (input.type === 'multilanguage_text_input' || input.type === 'multilanguage_textarea_input') {
              tmp_data[input[this.keyName]] = {}
              for (let k = 0; k < this.languagesOptions.length; k++) {
                tmp_data[input[this.keyName]][this.languagesOptions[k]] = this.data[input[this.keyName]]
                  ? this.data[input[this.keyName]][this.languagesOptions[k]]
                    ? this.data[input[this.keyName]][this.languagesOptions[k]]
                    : ''
                  : ''
              }
            } else if (input.type === 'switch') {
              tmp_data[input[this.keyName]] = this.data[input[this.keyName]] == true ? true : false
            } else if (input.type === 'bool') {
              tmp_data[input[this.keyName]] =
                this.data[input[this.keyName]] == true || this.data[input[this.keyName]] == false
                  ? this.data[input[this.keyName]]
                  : null
            } else {
              tmp_data[input[this.keyName]] = ''
            }
          } else {
            throw Error('input with no id!')
          }
        }
      }
    }
    this.tmp_data = tmp_data
  },

  methods: {
    //PUBLIC METHODS
    openPage(pageNumber) {
      if (pageNumber < 0 || pageNumber >= this.form.length) return
      this.currentPage = pageNumber
      //scroll up with smooth movement
      this.$nextTick(() => {
        let form = document.querySelector('.fw-form')
        form.scrollIntoView({ behavior: 'smooth', block: 'start' })
      })
    },
    async submit() {
      if (this.local) {
        this.$emit('submit', this.tmp_data)
      } else {
        try {
          await this.saveAnswers(false)
          await this.saveAnswers(true, true)
        } catch (e) {
          console.error('Error submitting form', e)
          this.$buefy.dialog.alert({
            message: this.$t('errorSubmittingForm'),
            type: 'is-danger',
            hasIcon: false
          })
        }
      }
    },
    async save() {
      if (this.local) {
        this.$emit('save', this.tmp_data)
      } else {
        await this.saveAnswers(false)
        await this.saveAnswers(true)
      }
    },
    check() {
      this.saveAnswers(true)
    },
    //END PUBLIC METHODS
    dataChanged(data) {
      //merge new data with this.tmp_data
      this.tmp_data = Object.assign(this.tmp_data, data)
      this.$emit('changed', this.tmp_data)
      console.log('FwForm dataChanged', data, this.tmp_data)
    },
    sendForm() {
      this.$emit('send', this.tmp_data)
    },
    prevPage() {
      this.openPage(this.currentPage - 1)
    },
    nextPage() {
      //check data first
      this.openPage(this.currentPage + 1)
    },
    async loadAnswers() {
      if (this.formKey) {
        try {
          let result = await ServiceForms.loadAnswers(this.formKey, this.tmp_data)
          console.log('loadAnswers', result)
          if (result) {
            this.tmp_data = result.tmp_data
          }
        } catch (e) {
          this.$buefy.dialog.alert({
            message: this.$t('errorLoadingAnswers'),
            type: 'is-danger',
            hasIcon: false
          })
          console.error('Error loading answers', e)
        }
      }
    },
    async saveAnswers(check = false, send = false) {
      this.$emit('errors', null)
      this.errorsLocal = null
      if (send) {
        this.sendingLocal = true
      } else {
        this.savingLocal = true
      }
      let convertedData = JSON.parse(JSON.stringify(this.tmp_data))
      //fix dates, loop through all fields and convert dates to string
      let sections = this.form
      for (let i = 0; i < sections.length; i++) {
        let section = sections[i]
        for (let j = 0; j < section.content.length; j++) {
          let input = section.content[j]
          if (input.type === 'date' && convertedData[input.key] && convertedData[input.key] !== null) {
            console.log('date', convertedData[input.key])
            //convert date to YYYY-MM-DD format
            convertedData[input.key] = convertedData[input.key].split('T')[0]
          } else if (input.type === 'identity_doc' && convertedData[input[this.keyName]].expire_date) {
            convertedData[input[this.keyName]].expire_date = convertedData[input[this.keyName]].expire_date.split(
              'T'
            )[0]
          } else if (input.type == 'custom_objects') {
            let schema = input.options.schema
            let entries = convertedData[input.key]
            for (let k = 0; k < entries.length; k++) {
              let data = entries[k]
              for (let j = 0; j < schema.length; j++) {
                let subinput = schema[j]
                if (subinput.type === 'date' && data[subinput.key] && data[subinput.key] !== null) {
                  console.log('subinput date', data[subinput.key])
                  //convert date to YYYY-MM-DD format
                  data[subinput.key] = data[subinput.key].split('T')[0]
                } else if (subinput.type === 'identity_doc' && data[subinput.key].expire_date) {
                  data[subinput.key].expire_date = data[subinput.key].expire_date.split('T')[0]
                }
              }
            }
          }
        }
      }
      try {
        let result = await ServiceForms.saveAnswer(this.formKey, convertedData, check)
        console.log('saveForm', result)
        this.$emit('valid', true)
        this.$emit('saved', result)
      } catch (e) {
        //Analyse errors
        if (e.response.data && e.response.data['__errors__'] && e.response.data['__errors__'].length > 0) {
          let errors = e.response.data['__errors__']
          this.errorsLocal = {}
          for (let i = 0; i < errors.length; i++) {
            let error = errors[i]
            if (error.field) {
              //get the input key
              let fieldParts = error.field.split('.')
              let section = Number(fieldParts[0])
              let input = Number(fieldParts[1])
              let input_key = this.form[section].content[input].key
              console.log('found input_key', input_key)
              let message = utils.generateErrorMessage(error, this.language)
              Vue.set(this.errorsLocal, input_key, message)
            }
          }

          this.$nextTick(() => {
            //scroll to first error
            let firstError = document.querySelector('.error-form')
            if (firstError) {
              firstError.scrollIntoView({ behavior: 'smooth', block: 'center' })
            }
          })
          this.$emit('errors', this.errorsLocal)
          this.$emit('valid', false)
          if (check) {
            this.$buefy.dialog.alert({
              message: this.$t('hasFormErrors'),
              type: 'is-danger',
              hasIcon: false
            })
          }
        }
      } finally {
        this.savingLocal = false
        this.sendingLocal = false
      }
    },
    registerListener(element, event, action) {
      this.listeners.push({
        element: element,
        event: event,
        action: action
      })
      if (element === 'window') {
        window.addEventListener(event, action)
      } else if (element === 'document') {
        document.addEventListener(event, action)
      } else {
        throw 'Not implemented listener element'
      }
    }
    //triggered by uploader
    /* async addFiles(id, files) {
      let fileKeys = []
      for (const file of files) {
        if (file.response.data) {
          const data = { files: [file.response.data.file.key] }
          if (file.context.folder) data.folder_key = file.context.folder

          const response = await ServiceBuckets.addItemsToBucket(file.context.bucket, data)
          console.log('response', response)
          if (file.context.bucket == this.bucketId && file.context.folder == this.folderId && response.new_items) {
            //this.addItems(response.new_items)
            //this.showFullDragZoneUploader = false
            //tmp_data[piece[this.keyName]]
            fileKeys = fileKeys.concat(response.new_items.map(file => file.key))
            let newdata = this.tmp_data[id].concat(response.new_items)
            this.tmp_data[id] = newdata
          }
        }
      }
      this.emitDataChanged()
      if (this.hasLogListener) {
        this.sendLogs({
          time: new Date(),
          action: 'upload',
          inputID: id,
          elements: fileKeys,
        })
      }
    }, */
  }
}
</script>

<style>
.custom_object_list .dndrop-container.vertical > .dndrop-draggable-wrapper {
  overflow: visible;
}
.drag-handle-new-form {
  @apply h-6 w-5 shadow-sm bg-white rounded border border-gray-200 items-center flex justify-center text-center absolute -left-2.5;
  cursor: grab;
}

.my-remove-tip {
  box-sizing: border-box;
  position: absolute;
  border: 1px solid #fff;
  border-radius: 3px;
  height: 20px;
  width: 40px;
  color: #fff;
  background: #444;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  line-height: 18px;
  overflow: visible;
}

.my-remove-tip::after {
  content: '';
  position: absolute;
  left: 16px;
  bottom: -4px;
  border-color: #444 transparent transparent;
  border-width: 4px 4px 0;
  border-style: solid;
  height: 0;
  width: 0;
}

.video-js:hover .vjs-big-play-button {
  height: 2.5em !important;
  width: 2.5em !important;
  line-height: 2.5em !important;
  border-radius: 2em !important;
  @apply bg-primary border-primary shadow-lg scale-110;
}
.video-js {
  cursor: pointer;
  @apply overflow-hidden rounded-2xl;
}
.video-js .vjs-big-play-button {
  height: 2.5em !important;
  width: 2.5em !important;
  line-height: 2.5em !important;
  border-radius: 2em !important;
  @apply bg-primary border-primary scale-90;
}

.fw-form .file-uploads {
  @apply w-full rounded;
}
.fw-select .select {
  @apply rounded w-full;
}
.fw-select .select select {
  @apply w-full;
}
.fw-select .select:after {
  color: #999;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ' ';
  transform: rotate(0deg);
  top: 60%;
}

.fw-select .select:not(.is-multiple):not(.is-loading)::after {
  color: #999;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ' ';
  transform: rotate(0deg);
  top: 60%;
}

.fw-select:hover .select:not(.is-multiple):not(.is-loading)::after {
  color: #999;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ' ';
  transform: rotate(0deg);
}

.fw-select:hover .select:after {
  color: #999;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ' ';
  transform: rotate(0deg);
}
.fw-select:hover .select select {
  @apply border border-gray-200;
}
.fw-select .select select {
  @apply border border-gray-200;
}
.fw-select .select:not(.is-multiple) {
  height: 2.5rem;
}
.fw-select .select select {
  height: 2.5rem;
  padding-bottom: 0px;
  padding-top: 0px;
}

.fw-select .select select:focus {
  border-color: transparent;
  outline: solid 2px rgba(3, 164, 121, 0.7);
  outline-offset: 2px;
  box-shadow: none;
}

.multiselect {
  @apply rounded shadow border border-gray-200;
}

.multiselect .multiselect__select:before {
  color: #999;
  border-color: #999 transparent transparent;
  border-radius: 2px;
}

.fw-form button {
  outline: none;
}

.fw-form .textarea:focus,
.fw-form .input:focus,
.fw-form .taginput .taginput-container.is-focusable:focus,
.fw-form .datepicker .dropdown .input:focus,
.fw-form .datepicker .dropdown-trigger .input[readonly]:focus,
.fw-form select:focus,
.fw-form button:focus:not(.multiplechoice_option) {
  border-color: transparent;
  outline: solid 2px rgba(3, 164, 121, 0.7) !important;
  outline-offset: 2px !important;
  box-shadow: none;
}

.fw-form .error .textarea,
.fw-form .error .input,
.fw-form .error .datepicker .dropdown .input,
.fw-form .error .datepicker .dropdown-trigger .input[readonly],
.fw-form .error select,
.fw-form .error .multiselect {
  border-color: #f15f1fff !important;
  border-width: 1px !important;
  border-style: solid !important;
}

.input-r .input {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.dropdown-trigger .control.has-icons-left .icon {
  top: 1px;
  height: 2.5em;
  width: 2.5em;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.dropdown-trigger .control.has-icons-left .input {
  padding-left: 2.5em;
}

.fw-form .datepicker .dropdown .input,
.fw-form .datepicker .dropdown-trigger .input[readonly] {
  background-color: #f5f5f5;
}

.fw-form .textarea,
.fw-form .input,
.fw-form .taginput .taginput-container.is-focusable {
  min-height: 35px;
  background-color: #f5f5f5;
}

.fw-form .datepicker .dropdown .input::placeholder,
.fw-form .datepicker .dropdown-trigger .input[readonly]::placeholder {
  color: rgba(108, 105, 105, 0.8);
}

.fw-form .textarea::placeholder,
.fw-form .input::placeholder,
.fw-form .taginput .taginput-container.is-focusable::placeholder {
  color: rgba(108, 105, 105, 0.8);
}

.fw-form .b-numberinput .control .button {
  height: 38px;
  width: 38px;
}

.fw-form input::placeholder {
  color: rgba(108, 105, 105, 0.8);
}

.fw-form input::-ms-input-placeholder {
  color: rgba(108, 105, 105, 0.8);
}
.fw-form .select:not(.is-multiple) {
  height: 2.75rem;
}
.fw-form .select select {
  height: 2.75rem;
}
.fw-form .datepicker-header .pagination-previous {
  width: 3rem;
  height: 2.75rem;
}
.fw-form .datepicker-header .pagination-next {
  width: 3rem;
  height: 2.75rem;
}
.fw-form .bg-saved {
  background-color: rgba(47, 199, 149, 0.25);
}

.fw-form.text15x .text-2xl {
  @apply text-3xl;
}
.fw-form.text15x .text-lg {
  @apply text-xl;
}
.fw-form.text15x .text-sm {
  @apply text-base;
}
.fw-form.text20x .text-2xl {
  @apply text-4xl;
}
.fw-form.text20x .text-lg {
  @apply text-2xl;
}
.fw-form.text20x .text-sm {
  @apply text-lg;
}
</style>

<i18n>
  {
    "en": {
      "errorSubmittingForm": "Error submitting form",
      "hasFormErrors": "There are errors in the form. Please check the fields in red.",
      "deleteFileConfirm": "Are you sure you want to delete this file?",
      "writeText": "Write here",
      "noSectionDocuments": "No documents in this section yet.",
      "id.type.label" : "Document type",
      "id.number": "Document number",
      "id.validDate.label": "Expire date",
      "phoneCountry": "Country",
      "phone": "Phone number",
      "id.type.placeholder": "Type of document",
      "id.type.cc": "Portuguese Citizen Card",
      "id.type.passport": "Passport",
      "errorLoadingAnswers": "Error loading answers",
      "vat": "VAT Number",
      "date_placeholder": "Select a date",
      "datetime_placeholder": "Select a date and time",
      "address": {
        "placeholder": "Enter your address",
        "street": "Street",
        "number": "Number",
        "state": "State",
        "locality": "Locality",
        "postal_code": "Postal code",
        "country": "Country"
      }
    },
    "pt": {
      "errorSubmittingForm": "Erro ao submeter formulário",
      "hasFormErrors": "Existem erros no formulário. Por favor verifique os campos a vermelho.",
      "deleteFileConfirm": "Tem certeza que deseja apagar este ficheiro?",
      "writeText": "Escreva aqui",
      "noSectionDocuments": "Nenhum documento nesta secção ainda.",
      "id.type.label" : "Tipo de documento",
      "id.number": "Número do documento",
      "id.validDate.label": "Data de validade",
      "phoneCountry": "País",
      "phone": "Número de telefone",
      "id.type.placeholder": "Tipo de documento",
      "id.type.cc": "Cartão de Cidadão Português",
      "id.type.passport": "Passaporte",
      "errorLoadingAnswers": "Erro ao carregar respostas",
      "vat": "Número de Contribuinte",
      "date_placeholder": "Selecione uma data",
      "datetime_placeholder": "Selecione uma data e hora",
      "address": {
        "placeholder": "Insira o seu endereço",
        "street": "Rua",
        "number": "Número",
        "state": "Estado",
        "locality": "Localidade",
        "postal_code": "Código Postal",
        "country": "País"
      }
    }
  }
  </i18n>
