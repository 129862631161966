var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"flex gap-2 relative w-full text-left group rounded-lg items-start bg-white group-hover:bg-gray-100 hover:bg-gray-100",class:{
    'text-primary bg-white shadow': _vm.data.key == _vm.selected,
    'bg-gray-300 bg-opacity-20 shadow-sm': !_vm.shortSidebar && _vm.data.key != _vm.selected,
    'p-3': !_vm.shortSidebar,
    'border border-gray-200 bg-gray-100': _vm.shortSidebar && _vm.data.key != _vm.selected,
    'border border-primary/50': _vm.shortSidebar && _vm.data.key == _vm.selected
  }},[(_vm.data.index >= 0)?_c('div',{staticClass:"rounded text-center text-xs font-bold",class:{
      'text-primary': _vm.data.key == _vm.selected,
      'text-gray-500 bg-gray-100': !_vm.shortSidebar && _vm.data.key != _vm.selected,
      'bg-primary/10': _vm.data.key == _vm.selected,
      'ml-7': _vm.draggable,
      'h-7 w-7 leading-7': !_vm.shortSidebar,
      'h-9 w-10 leading-9': _vm.shortSidebar
    },on:{"click":function($event){return _vm.$emit('open')}}},[_vm._v(" M"+_vm._s(_vm.data.index > 99 ? 99 : _vm.data.index + 1)+" ")]):_c('div',{staticClass:"font-bold text-sm pl-7 py-1 text-gray-700",on:{"click":function($event){return _vm.$emit('open')}}},[_c('fw-icon-book-mark-solid',{staticClass:"w-5 h-5"})],1),(!_vm.shortSidebar)?_c('div',{staticClass:"flex-1 pt-0.5",on:{"click":function($event){return _vm.$emit('open')}}},[_c('v-clamp',{staticClass:"font-medium",attrs:{"autoresize":"","max-lines":2}},[_vm._v(_vm._s(_vm.data.title.replace('.', ''))+" ")])],1):_vm._e(),(!_vm.shortSidebar)?_c('b-tooltip',{attrs:{"label":"Número de temas","position":"is-left","type":"is-light"}},[_c('div',{staticClass:"flex items-center text-xs font-medium gap-1.5 pt-1 w-9 overflow-hidden overflow-ellipsis",class:{
        'text-primary': _vm.data.stats?.total_topics,
        'text-gray-400': !_vm.data.stats?.total_topics
      }},[_c('fw-icon-list-view',{staticClass:"h-5 w-5"}),_vm._v(" "+_vm._s(_vm.data.stats?.total_topics ?? 0)+" ")],1)]):_vm._e(),(!_vm.shortSidebar)?_c('b-tooltip',{attrs:{"label":"Tópicos de discussão","position":"is-left","type":"is-light"}},[_c('div',{staticClass:"flex items-center text-xs font-medium gap-1.5 pt-[0.35rem] w-9 overflow-hidden overflow-ellipsis",class:{
        'text-primary': _vm.data.stats?.total_posts,
        'text-gray-400': !_vm.data.stats?.total_posts
      }},[_c('fw-icon-discuss',{staticClass:"h-[1.1rem] w-[1.1rem]"}),_vm._v(" "+_vm._s(_vm.data.stats?.total_posts ?? 0)+" ")],1)]):_vm._e(),(_vm.editable && !_vm.shortSidebar)?_c('fw-menu-more',{attrs:{"append-to-body":""}},[_c('b-dropdown-item',{attrs:{"aria-role":"listitem","paddingless":""}},[_c('fw-button',{staticClass:"w-full",attrs:{"type":"dropdown-item"},nativeOn:{"click":function($event){return _vm.$emit('edit')}}},[_vm._v("Editar nome")])],1),_c('b-dropdown-item',{attrs:{"aria-role":"listitem","paddingless":""}},[_c('fw-button',{staticClass:"w-full",attrs:{"type":"dropdown-item"},nativeOn:{"click":function($event){return _vm.$emit('delete')}}},[_vm._v("Eliminar módulo")])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }