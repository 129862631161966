<template>
  <SidebarBase>
    <template #main>
      <div class="flex-1">
        <ButtonSidebar to="/" :exact="true" label="Início">
          <template #svg>
            <fw-icon-home class="w-6 h-6" />
          </template>
        </ButtonSidebar>
        <ButtonSidebar to="/units" label="Disciplinas">
          <template #svg>
            <fw-icon-team class="w-6 h-6" />
          </template>
        </ButtonSidebar>
        <ButtonSidebar to="/v1/exams" label="Exames (v1)">
          <template #svg>
            <fw-icon-survey class="w-6 h-6" />
          </template>
        </ButtonSidebar>
        <ButtonSidebar v-if="debugMode" to="/exams" label="Exames">
          <template #svg>
            <fw-icon-survey class="w-6 h-6" />
          </template>
        </ButtonSidebar>
        <ButtonSidebar to="/groups" label="Grupos">
          <template #svg>
            <fw-icon-group class="w-6 h-6" />
          </template>
        </ButtonSidebar>
        <ButtonSidebar to="/buckets" label="Ficheiros">
          <template #svg>
            <fw-icon-box-open class="w-6 h-6" />
          </template>
        </ButtonSidebar>
        <ButtonSidebar to="/chats" label="Canais">
          <template #svg>
            <fw-icon-chats class="w-6 h-6" />
          </template>
        </ButtonSidebar>
        <ButtonSidebar to="/recordings" label="Gravações">
          <template #svg>
            <fw-icon-rec class="w-6 h-6" />
          </template>
        </ButtonSidebar>
      </div>
    </template>
  </SidebarBase>
</template>

<script>
import SidebarBase from '@/fw-modules/fw-core-vue/ui/components/sidebars/SidebarBase'
import ButtonSidebar from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonSidebar'

export default {
  components: {
    ButtonSidebar,
    SidebarBase
  },
  data() {
    return {
      debugMode: Boolean(localStorage.getItem('fw-debug') || localStorage.getItem('fw-debug'))
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser
    }
  }
}
</script>

<style>
/*a span:last-child {
  @apply text-black;
}*/
</style>
