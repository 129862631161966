<template>
  <div class="pb-5">
    <fw-heading class="mb-5" size="h2">Configurações</fw-heading>

    <fw-panel title="Apresentação" class="mt-5 mb-10">
      <div class="relative pb-12">
        <div
          class="h-96 rounded-md bg-gray-200 bg-cover bg-center relative group overflow-hidden"
          :style="{
            backgroundImage: `url('${editionUrlBanner}')`
          }"
        >
          <Uploader
            v-if="editable"
            reference-id="banner_file"
            allowed="images"
            layout="image"
            bucket-type="image"
            :is-docked="true"
            :is-custom="true"
            :clear-after="true"
            :limit="1"
            :multiple="false"
            @upload="uploaded('banner', $event)"
          >
            <template v-if="editionUrlBanner" #navbar>
              <div
                class="absolute right-0 top-0 z-10 group-hover:opacity-95 opacity-0 bg-gray-800 bg-opacity-80 w-full h-full flex items-center justify-center"
              >
                <fw-button type="link-white" @click.native="deleteFile('banner')">
                  <fw-icon-close-circle class="w-7 h-7" />
                </fw-button>
              </div>
            </template>
          </Uploader>
        </div>
        <div
          class="h-40 w-40 border-4 border-white group bg-cover bg-center bg-gray-200 absolute left-5 bottom-0 rounded-md z-20 overflow-hidden"
          :style="{
            backgroundImage: `url('${editionUrlCover}')`
          }"
        >
          <Uploader
            v-if="editable"
            reference-id="cover_file"
            allowed="images"
            layout="image"
            bucket-type="image"
            :is-docked="true"
            :is-custom="true"
            :clear-after="true"
            :limit="1"
            :multiple="false"
            @upload="uploaded('cover', $event)"
          >
            <template v-if="editionUrlCover" #navbar>
              <div
                class="absolute right-0 top-0 z-30 group-hover:opacity-95 opacity-0 bg-gray-800 bg-opacity-80 w-full h-full flex items-center justify-center"
              >
                <fw-button type="link-white" @click.native="deleteFile('cover')">
                  <fw-icon-close-circle class="w-7 h-7" />
                </fw-button>
              </div>
            </template>
          </Uploader>
        </div>
      </div>
    </fw-panel>

    <fw-panel title="Vídeo promocional" boxed="sm">
      <div v-if="promoVideo" class="border-b pb-3 mb-3">
        <div>
          <fw-label>Pré-visualização</fw-label>
        </div>
        <div class="mb-2 bg-black overflow-hidden rounded-lg">
          <div class="max-w-3xl mx-auto">
            <div v-if="promoVideo?.type === 'youtube'" class="aspect-w-16 aspect-h-9 w-full">
              <iframe
                :src="promoVideo.src"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                class="w-full"
              ></iframe>
            </div>
            <div v-else-if="promoVideo?.type === 'storage'" class="overflow-hidden image_direct">
              <video controls class="w-full">
                <source :src="promoVideo.src" type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
      <div class="flex gap-5 items-start">
        <div class="w-64 flex-shrink-0">
          <fw-label>Tipo de vídeo</fw-label>
          <b-select v-if="editable" v-model="tmp.videoType" placeholder="Fonte do vídeo">
            <option value="video">Upload de vídeo</option>
            <option value="youtube">Vídeo alojado no Youtube</option>
          </b-select>
        </div>
        <div class="flex-1">
          <div v-if="tmp.videoType == 'youtube'" class="flex flex-col gap-2">
            <div>
              <fw-label>Youtube video ID</fw-label>
              <b-input v-if="editable" v-model="tmp.youtube_video_id" :has-counter="false" class="w-32" />
              <fw-tip v-if="$v.tmp.youtube_video_id.$error" error>
                Por favor, verifique se o ID do vídeo está correto (deve conter 11 caracteres: a-z, 0-9, -, _).
              </fw-tip>
            </div>
            <div>
              <fw-button v-if="editable" type="primary" @click.native="save('introduction')">
                {{ $t('save') }}
              </fw-button>
            </div>
          </div>
          <div v-else-if="tmp.videoType == 'video'">
            <fw-label>Ficheiro de vídeo (.mp4)</fw-label>
            <template v-if="editable">
              <RecordFileEntry
                v-if="tmp.video"
                :key="tmp.video.key"
                :file="tmp.video"
                :can-edit="false"
                :can-remove="true"
                @remove="deleteFile('video')"
                @download="downloadFile(file)"
              />
              <Uploader
                v-else
                class="meta-uploader cursor-pointer hover:opacity-100 opacity-70 transition-all relative"
                :is-docked="true"
                reference-id="upload-video"
                allowed="videos"
                :clear-after="true"
                :limit="1"
                :multiple="false"
                @upload="uploaded('video', $event)"
              />
            </template>
            <RecordFileEntry
              v-else-if="tmp.video"
              :key="tmp.video.key"
              :file="tmp.video"
              :can-edit="true"
              :can-remove="true"
              @remove="removeFile('video')"
              @download="downloadFile(file)"
            />
            <fw-panel-info v-else left>Não foi associado um ficheiro de vídeo.</fw-panel-info>
          </div>
        </div>
      </div>
    </fw-panel>

    <fw-panel title="Opções" boxed="sm" class="my-5">
      <div class="mb-3">
        <div class="flex items-center mb-1 gap-2">
          <fw-label>Tipo de inscrição</fw-label>
        </div>
        <!-- <b-select v-if="editable" v-model="tmp.admission_type" placeholder="Tipo de inscrição">
          <option value="pre-admitted">Pré-admitido</option>
          <option v-if="false" value="pending-admission">Inscrições abertas com selecção manual dos candidatos</option>
          <option v-if="false" value="open">Inscrições abertas sem restrições </option>
        </b-select> -->
        <div v-if="edition.admission_type">
          {{
            edition.admission_type == 'pre-admitted'
              ? 'Lista de estudantes pré-admitidos'
              : edition.admission_type == 'pending-admission'
              ? 'Inscrições abertas com selecção manual dos candidatos'
              : 'Inscrições abertas sem restrições'
          }}
        </div>
        <div v-else class="text-gray-500 text-sm">Tipo de inscrição não definido.</div>
      </div>
      <b-field v-if="tmp.admission_type != 'pre-admitted'" label="Número de vagas">
        <b-numberinput v-if="editable" v-model="tmp.seats_total"></b-numberinput>
        <div v-else-if="edition.seats_total && edition.seats_total > 0">{{ edition.seats_total }}</div>
        <div v-else class="text-gray-500 text-sm">Vagas ilimitadas.</div>
      </b-field>
      <div v-if="false" class="mb-3">
        <div class="flex items-center mb-1 gap-2">
          <fw-label>ECTS</fw-label>
        </div>
        <b-numberinput v-if="editable" v-model="tmp.ects"></b-numberinput>
        <div v-else-if="edition.ects && edition.ects > 0">{{ edition.ects }}</div>
        <div v-else class="text-gray-500 text-sm">ECTS não definidos.</div>
      </div>
      <b-field label="Visibilidade dos temas">
        <!-- <b-select
          v-if="editable"
          v-model="tmp.options.topics_visibility_mode"
          placeholder="Selecione quando quer mostrar os temas"
        >
          <option value="manual">
            Manualmente
          </option>
          <option v-if="false" value="scheduled">
            Por agendamento
          </option>
        </b-select> -->
        <div v-if="edition.options.topics_visibility_mode">
          {{ edition.options.topics_visibility_mode == 'scheduled' ? 'Por agendamento' : 'Manualmente' }}
        </div>
        <div v-else class="text-gray-500 text-sm">Modo de disponibilidade dos temas não definido.</div>
      </b-field>
      <fw-message v-if="!editable && edition.options.topics_visibility_mode == 'scheduled'"
        >Pode agendar os temas na página da turma</fw-message
      >
      <fw-message v-if="editable && tmp.options.topics_visibility_mode == 'scheduled'"
        >Pode agendar os temas na página da turma</fw-message
      >
      <b-field label="Modo de consumo dos temas">
        <b-select
          v-if="editable"
          v-model="tmp.options.topics_consume_mode"
          placeholder="Selecione se pretende disponibilizar o próximo tema apenas se o anterior estiver concluído"
        >
          <option value="sequential">
            Sequencialmente (próximo tema apenas disponível se o anterior estiver concluído)
          </option>
          <option value="free">Livre (todos os temas disponíveis desde o início)</option>
        </b-select>
        <div v-else-if="edition.options.topics_consume_mode">
          {{ edition.options.topics_consume_mode == 'sequential' ? 'Sequencialmente' : 'Livre' }}
        </div>
        <div v-else class="text-gray-500 text-sm">Modo de consumo dos temas não definido.</div>
      </b-field>
      <div class="-mt-2 ml-2.5">
        <fw-message v-if="!editable && edition.options.topics_consume_mode == 'free'" type="tip-light" :padding="false"
          >Todos os temas estarão disponíveis para consumo consoante a data ou visibilidade dos mesmos</fw-message
        >
        <fw-message
          v-else-if="!editable && edition.options.topics_consume_mode == 'sequential'"
          type="tip-light"
          :padding="false"
        >
          Um tema só estará disponível apenas se o tema anterior tiver sido concluído.
        </fw-message>
        <fw-message v-if="editable && tmp.options.topics_consume_mode == 'free'" type="tip-light" :padding="false"
          >Todos os temas estarão disponíveis para consumo consoante a data ou visibilidade dos mesmos</fw-message
        >
        <fw-message
          v-else-if="editable && tmp.options.topics_consume_mode == 'sequential'"
          type="tip-light"
          :padding="false"
        >
          Um tema só estará disponível apenas se o tema anterior tiver sido concluído.
        </fw-message>
      </div>
      <b-field class="mt-4" label="Disponibilidade do espaço após termino">
        <b-select
          v-if="editable"
          v-model="tmp.options.access_course_content"
          placeholder="Escolha quando quer disponibilizar o conteúdo do espaço"
        >
          <option value="always">Sempre - mesmo após a data de fim da edição</option>
          <option value="while_running">Apenas durante a duração da edição</option>
        </b-select>
        <div v-else-if="edition.options.access_course_content">
          {{
            edition.options.access_course_content == 'always'
              ? 'Conteúdo disponível após o término da edição'
              : 'Conteúdo apenas disponível durante a duração da edição'
          }}
        </div>
        <div v-else class="text-gray-500 text-sm">Disponibilidade do conteúdo não definido.</div>
      </b-field>
      <fw-button v-if="editable" type="primary" @click.native="save('options')">
        {{ $t('save') }}
      </fw-button>
    </fw-panel>

    <fw-panel title="Calendarização" boxed="sm" class="my-5">
      <div class="mb-3">
        <div class="flex items-center mb-1 gap-2">
          <fw-label>Data de início da edição</fw-label>
        </div>
        <b-datepicker v-if="editable" v-model="tmp.start_date" :placeholder="'Data de início'"></b-datepicker>
        <div v-else-if="edition.start_date">{{ edition.start_date | formatDate }}</div>
        <div v-else class="text-gray-500 text-sm">Sem data de início definida.</div>
      </div>

      <div class="mb-3">
        <div class="flex items-center mb-1 gap-2">
          <fw-label>Data de fim da edição</fw-label>
        </div>
        <b-datepicker
          v-if="editable"
          v-model="tmp.end_date"
          :min-date="tmp.start_date"
          :placeholder="'Data de fim'"
        ></b-datepicker>
        <div v-else-if="edition.end_date">{{ edition.end_date | formatDate }}</div>
        <div v-else class="text-gray-500 text-sm">Sem data de fim definida.</div>
      </div>

      <div class="mb-3">
        <div class="flex items-center mb-1 gap-2">
          <fw-label>Data de início das inscrições</fw-label>
        </div>
        <b-datepicker v-if="editable" v-model="tmp.signup_start_date" :placeholder="'Data de início das inscrições'">
        </b-datepicker>
        <div v-else-if="edition.signup_start_date">{{ edition.signup_start_date | formatDate }}</div>
        <div v-else class="text-gray-500 text-sm">Sem data de início das inscrições.</div>
      </div>

      <div class="mb-3">
        <div class="flex items-center mb-1 gap-2">
          <fw-label>Data de fim das inscrições</fw-label>
        </div>
        <b-datepicker
          v-if="editable"
          v-model="tmp.signup_end_date"
          :min-date="tmp.signup_start_date"
          :placeholder="'Data de fim das inscrições'"
        >
        </b-datepicker>
        <div v-else-if="edition.signup_end_date">{{ edition.signup_end_date | formatDate }}</div>
        <div v-else class="text-gray-500 text-sm">Sem data de fim das inscrições.</div>
      </div>
      <fw-button v-if="editable" type="primary" @click.native="save('calendar')">
        {{ $t('save') }}
      </fw-button>
    </fw-panel>

    <fw-panel v-if="editable" title="Operações" boxed="sm" class="my-5">
      <div v-if="edition.status == 'draft'" class="flex gap-5 border-b py-3 mb-5 items-center justify-center">
        <div class="flex-1">
          <fw-label>Publicar edição do espaço</fw-label>
          <div
            v-if="editable && edition.status == 'draft' && edition?.missing_fields?.length > 0"
            class="text-sm text-gray-500 flex gap-3 items-center"
          >
            <div>Para publicar precisa definir todos metadados obrigatórios.</div>
            <div>
              <fw-button type="link-danger" icon="info" size="xs" @click.native="openModal()"
                >Ver campos em falta</fw-button
              >
            </div>
          </div>
        </div>
        <div>
          <fw-button
            type="light-primary"
            :loading="savingStatus"
            :disabled="edition?.missing_fields?.length > 0"
            @click.native="saveStatus('published')"
            >Publicar edição</fw-button
          >
        </div>
      </div>
      <div v-else-if="edition.status == 'published'" class="flex gap-5 border-b py-3 mb-5 items-center justify-center">
        <div class="flex-1">
          <fw-label>Terminar edição do espaço</fw-label>
        </div>
        <div>
          <fw-button type="light-primary" :loading="savingStatus" @click.native="saveStatus('closed')"
            >Terminar edição</fw-button
          >
        </div>
      </div>

      <div v-if="me && course.user_key == me.key" class="flex gap-5 items-center justify-center">
        <div class="flex-1">
          <fw-label>Eliminar edição do espaço</fw-label>
          <div class="text-sm text-gray-600 mb-1.5">
            Ao eliminar uma edição do espaço, todo o seu conteúdo, dados e configurações serão eliminados.
          </div>
        </div>
        <div>
          <fw-button type="border-danger" @click.native="deleteEdition()">Eliminar edição</fw-button>
        </div>
      </div>
    </fw-panel>

    <fw-modal v-if="isModalActive" :active.sync="isModalActive" :can-cancel="true" size="min" @close="closeModal">
      <fw-panel>
        <div class="flex flex-col gap-2">
          <fw-heading size="h2">
            <span class="font-medium text-gray-500">Estado</span>
            Publicado
          </fw-heading>
          <div>
            <div>O estado, Publicado, apenas ficará disponível após verificação dos seguintes requisitos:</div>

            <div class="mt-5 pl-5">
              <ul class="flex flex-col gap-2">
                <li v-for="(item, key) in requiredFieldsToSubmit" :key="key" class="flex gap-2">
                  <fw-icon-check
                    class="w-5 h-5 text-white rounded-full p-0.5"
                    :class="edition?.missing_fields?.includes(key) ? 'bg-gray-300' : 'bg-primary'"
                  />
                  <p>{{ item.label[lang] }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </fw-panel>

      <template #footer>
        <fw-button type="link-muted" @click.native="closeModal">Fechar</fw-button>
      </template>
    </fw-modal>
  </div>
</template>

<script>
import ServiceCourses from '@/fw-modules/fw-core-vue/courses/services/ServiceCourses'
import Uploader from '@/fw-modules/fw-core-vue/storage/components/PathUploader.vue'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import RecordFileEntry from '@/fw-modules/fw-core-vue/ui/components/form/RecordFileEntry'

import { validYoutubeVideoId } from '@/fw-modules/fw-core-vue/utilities/vuelidateHelpers'

import { REQUIRED_FIELDS } from '@/fw-modules/fw-core-vue/courses/utils'

export default {
  components: {
    Uploader,
    RecordFileEntry
  },
  props: {
    edition: {
      type: Object,
      required: true
    },
    course: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      requiredFieldsToSubmit: Object.freeze(REQUIRED_FIELDS),
      isModalActive: false,
      coverFileUpload: [],
      bannerFileUpload: [],
      status: null,
      savingStatus: false,
      tmp: {
        title: '',
        start_date: null,
        end_date: null,
        signup_start_date: null,
        signup_end_date: null,
        admission_type: null,
        seats_total: 0,
        cover: null,
        banner: null,
        options: {
          topics_visibility_mode: 'scheduled'
        }
      }
    }
  },

  validations() {
    return {
      tmp: {
        youtube_video_id: { validYoutubeVideoId }
      }
    }
  },

  computed: {
    editionUrlCover() {
      return this.edition.cover && this.edition.cover.thumb_url_format ? this.getImageViewUrl(this.edition.cover) : ''
    },
    editionUrlBanner() {
      return this.edition.banner && this.edition.banner.thumb_url_format
        ? this.getImageViewUrl(this.edition.banner)
        : ''
    },
    promoVideo() {
      if (this.edition.youtube_video_id) {
        return {
          src: `https://www.youtube-nocookie.com/embed/${this.edition.youtube_video_id}`,
          type: 'youtube'
        }
      } else if (this.edition.video && this.edition.video.filename) {
        return {
          src: ServiceStorage.getFileUrl(this.edition.video),
          type: 'storage'
        }
      }
      return {}
    },
    me() {
      return this.$store.getters.getUser
    },
    lang() {
      return this.$store.state.language
    }
  },
  mounted() {
    this.status = this.edition.status
    let tmp = JSON.parse(JSON.stringify(this.edition))
    if (tmp.start_date) {
      tmp.start_date = new Date(tmp.start_date)
    }
    if (tmp.end_date) {
      tmp.end_date = new Date(tmp.end_date)
    }
    if (tmp.signup_start_date) {
      tmp.signup_start_date = new Date(tmp.signup_start_date)
    }
    if (tmp.signup_end_date) {
      tmp.signup_end_date = new Date(tmp.signup_end_date)
    }
    if (this.edition.youtube_video_id) {
      tmp.videoType = 'youtube'
    } else if (this.edition.video) {
      tmp.videoType = 'video'
    }
    this.tmp = tmp
  },
  methods: {
    closeModal() {
      this.isModalActive = false
    },

    openModal() {
      this.isModalActive = true
    },

    async saveStatus(status) {
      this.savingStatus = true
      await utils.tryAndCatch(this, async () => {
        const response = await ServiceCourses.updateEditionStatus(this.course.key, this.edition.key, status)
        console.log('saveStatus', response)
        this.$emit('updated', response)
        this.$buefy.snackbar.open({
          message: 'Estado da edição actualizado',
          type: 'is-success'
        })
      })

      this.savingStatus = false
    },
    deleteFile(field) {
      this.tmp[field] = null
      this.saveFiles()
    },
    saveFiles() {
      let tmp = JSON.parse(JSON.stringify(this.edition))
      console.log('saveFiles', this.tmp)
      tmp.cover = this.tmp.cover
      tmp.banner = this.tmp.banner
      tmp.video = this.tmp.video
      tmp.youtube_video_id = this.tmp.youtube_video_id
      this.$emit('save-silent', tmp)
      this.$buefy.snackbar.open({
        message: 'Edição guardada com sucesso',
        type: 'is-success'
      })
    },
    async downloadFile(file) {
      const url = ServiceStorage.getFileUrl(file, this.$store.state.session.user.token)
      utils.downloadFile(url, file.filename)
    },
    getImageViewUrl(file, size) {
      let image = ServiceStorage.getImageViewUrl(file, size)
      console.log('getImageViewUrl', image)
      return image
    },
    uploaded(input, event) {
      console.log('uploaded', input, event)
      if (event.length > 0) {
        this.tmp[input] = event[0].response.data.file
        if (input == 'video') {
          this.tmp.youtube_video_id = null
        }
      }
      this.saveFiles()
    },
    deleteEdition() {
      this.$buefy.dialog.confirm({
        title: 'Eliminar edição do espaço',
        message:
          'Tem a certeza que deseja eliminar esta edição do espaço? Esta operação é irreversível. Todos os dados associados serão eliminados.',
        confirmText: 'Eliminar',
        type: 'is-danger',
        onConfirm: async () => {
          try {
            await ServiceCourses.deleteEdition(this.course.key, this.edition.key)
            this.$router.push({ name: 'course-manage', params: { id: this.course.key } })
          } catch (error) {
            this.$buefy.toast.open({
              message: 'Erro ao eliminar edição do espaço',
              type: 'is-danger'
            })
          }
        }
      })
    },
    edit() {
      let tmp = JSON.parse(JSON.stringify(this.edition))
      if (tmp.start_date) {
        tmp.start_date = new Date(tmp.start_date)
      }
      if (tmp.end_date) {
        tmp.end_date = new Date(tmp.end_date)
      }
      if (tmp.signup_start_date) {
        tmp.signup_start_date = new Date(tmp.signup_start_date)
      }
      if (tmp.signup_end_date) {
        tmp.signup_end_date = new Date(tmp.signup_end_date)
      }
      this.tmp = tmp
    },
    save(section) {
      //verify data
      let previous = JSON.parse(JSON.stringify(this.edition))
      if (section == 'options') {
        previous['admission_type'] = this.tmp.admission_type
        previous['seats_total'] = this.tmp.seats_total
        previous['ects'] = this.tmp.ects
        previous['options'] = this.tmp.options
      } else if (section == 'calendar') {
        previous.start_date = this.tmp.start_date
        previous.end_date = this.tmp.end_date
        previous.signup_start_date = this.tmp.signup_start_date
        previous.signup_end_date = this.tmp.signup_end_date
      } else if (section == 'introduction') {
        this.$v.$touch()
        if (this.$v.$invalid) return
        previous.video = null
        previous.youtube_video_id = this.tmp.youtube_video_id
        this.$v.$reset()
        this.tmp.youtube_video_id = previous.youtube_video_id
      }
      console.log('sending', previous)
      this.$emit('save', previous)
    }
  },
  watch: {
    tmp: {
      handler: function(val) {
        if (val.youtube_video_id && val.youtube_video_id.startsWith('https://')) {
          val.youtube_video_id = val.youtube_video_id.replace('https://www.youtube.com/watch?v=', '')
        }
      },
      deep: true
    }
  }
}
</script>

<i18n>
  {
    "en": {
      "cancel": "Cancel",
      "edit": "Edit",
      "save": "Save"
    },
    "pt": {
      "cancel": "Cancelar",
      "edit": "Editar",
      "save": "Guardar"
    }
  }
</i18n>
