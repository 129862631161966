<template>
  <LayoutDefault>
    <template #main-sidebar>
      <SidebarMain />
    </template>
    <template #main-content>
      <fw-panel-page-header class="xl:max-w-screen-xl mx-auto" title="Repositórios de perguntas">
        <template #toolbar>
          <fw-button v-if="appName != 'ucstudent'" type="link" @click.native="newBucket()"
            >Criar novo repositório</fw-button
          >
        </template>
      </fw-panel-page-header>
      <!--
        <ContextualSearch
          v-if="false"
          :loading="loading"
          :filter-options="filters"
          :applied-filters="appliedFilters"
          :start-value="searchInput"
          @search="search"
        >
          <template #tags>
            <FilterTag
              v-for="(filter, f) in appliedFilters"
              :key="'filter_' + f"
              :text="getFilterText(filter)"
              :show-close-button="true"
              @close="deleteFilter(f)"
            ></FilterTag>
          </template>
        </ContextualSearch>
      -->
      <div v-if="!loading && buckets.length > 0" class="bg-white rounded-md">
        <QuestionsBucketRecord
          v-for="(bucket, i) in buckets"
          :key="'bucket_' + i"
          :users="users"
          :bucket="bucket"
          @click.native="goToBucket(bucket)"
        ></QuestionsBucketRecord>
      </div>
      <div v-else-if="!loading && buckets.length == 0" class="bg-white rounded-md py-16 text-center text-gray-400">
        Sem repositório de perguntas
      </div>
      <LoadingPlaceholder v-else :list="true"></LoadingPlaceholder>
      <!--
      <div v-if="searchInput.length > 0 || searchResults.length > 0 || appliedFilters.length > 0">
        <fw-panel :title="'Resultados'" :counter="searchResults.length">
          <div v-if="searchResults.length > 0" class="bg-white rounded-md">
            <ExamRecord
              v-for="(instance, i) in searchResults"
              :key="'exam_published_' + i"
              :users="users"
              :instance="instance"
              @click.native="goToExam(instance)"
            ></ExamRecord>
          </div>
          <div v-else class="bg-white rounded-md py-16 text-center text-gray-400">
            Sem resultados
          </div>
        </fw-panel>
      </div>
      <div v-else>
        <fw-panel :title="'Repositórios de perguntas'" :counter="buckets.length" class=" pb-10"> </fw-panel>
      </div>
      -->
    </template>
    <template #tapbar>
      <TapbarMain />
    </template>
  </LayoutDefault>
</template>

<script>
import LayoutDefault from '@/fw-modules/fw-core-vue/ui/components/layouts/LayoutDefault'
import TapbarMain from '@/components/tapbars/TapbarMain'
import SidebarMain from '@/components/sidebars/SidebarMain'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
//import ContextualSearch from '@/fw-modules/fw-core-vue/ui/components/search/ContextualSearch'
//import FilterTag from '@/fw-modules/fw-core-vue/ui/components/text/FilterTag'
import FormServices from '@/fw-modules/fw-core-vue/exams/services/ServiceExams'
import QuestionsBucketRecord from '@/fw-modules/fw-core-vue/exams/components/cards/QuestionsBucketRecord'

export default {
  name: 'ViewExamBuckets',
  components: {
    LayoutDefault,
    SidebarMain,
    TapbarMain,
    //ContextualSearch,
    //FilterTag,
    QuestionsBucketRecord,
    LoadingPlaceholder
  },
  data: function() {
    return {
      showNewExamModal: false,
      showTemplateModal: false,
      loading: false,
      searchInput: '',
      selectedUser: null,
      appliedFilters: [],
      filters: [
        {
          key: 'state',
          label: 'Estado',
          options: [
            {
              key: 'reset',
              label: 'Todos'
            },
            {
              key: 'draft',
              label: 'Em edição'
            },
            {
              key: 'waiting',
              label: 'Publicados'
            },
            {
              key: 'ended',
              label: 'Terminados'
            }
          ]
        }
      ],
      sortBy: null,
      sortDirection: 'asc',
      buckets: [],
      searchResults: [],
      users: {}
    }
  },
  computed: {
    appName() {
      return this.$store.state.appName || 'ucstudent'
    },
    language() {
      return this.$store.state.language || this.$i18n.locale || 'pt'
    }
  },
  created() {
    this.loadBuckets()
  },
  methods: {
    newBucket() {
      this.$buefy.dialog.prompt({
        title: 'Criar novo repositório de perguntas',
        message: 'Insira o nome do repositório de perguntas',
        inputAttrs: {
          placeholder: 'Nome do repositório de perguntas',
          maxlength: 150,
          min: 2
        },
        confirmText: 'Criar',
        trapFocus: true,
        onConfirm: async value => {
          let result = await FormServices.createBucket(value)
          if (result.length > 0) {
            this.$router.push({ name: 'exams-manage-question-bucket', params: { id: result[0].key } })
          }
        }
      })
    },
    /* getFilterText(key) {
      var split = key.split(':')
      for (var i = 0; i < this.filters.length; i++) {
        if (this.filters[i].key == split[0]) {
          for (var j = 0; j < this.filters[i].options.length; j++) {
            if (this.filters[i].options[j].key == split[1]) {
              return this.filters[i].options[j].label
            }
          }
        }
      }
      return ''
    },
    deleteFilter(index) {
      this.appliedFilters.splice(index, 1)
      this.search()
    },
    async search(searchCriteria) {
      /*
       term: this.searchTerm,
        filters: this.selectedFilters,
        orderBy: this.orderItemsBy != null ? this.orderItemsBy.key : null,
        orderDirection: this.orderItemsBy != null ? this.orderDirection : null,
        user: this.user,
        dates: this.dates,
      */
    /*

      console.log('Searching...')
      console.log(searchCriteria)

      this.loading = true
      this.appliedFilters = typeof searchCriteria != 'undefined' ? searchCriteria.filters : []
      try {
        /*let status = null
        if (typeof searchCriteria != 'undefined') {
          for (let index = 0; index < searchCriteria.filters.length; index++) {
            const filterParts = searchCriteria.filters[index].split(':')
            if (filterParts[0] == 'state' && filterParts[1] != 'reset') {
              status = filterParts[1]
            }
          }
        }*/
    /*
        let result = await FormServices.getAllExamBuckets()
        this.searchResults = result.buckets
        /*let result = await FormServices.getAnswers(
          this.instance.key,
          true,
          this.sortBy,
          this.sortDirection,
          searchCriteria.term.length > 0 ? searchCriteria.term : null,
          searchCriteria.filters
        )
        console.log(result)
        .answers*/
    /*
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },*/
    goToBucket(bucket) {
      this.$router.push({ name: 'exams-manage-question-bucket', params: { id: bucket.key } })
    },
    async loadBuckets() {
      this.loading = true
      try {
        let data = await FormServices.getAllExamBuckets()
        this.buckets = data.buckets
        this.users = data.users
        /*.reduce(function(map, obj) {
          map[obj.key] = obj
          return map
        }, {})*/
      } finally {
        this.loading = false
      }
    }
    /*createExam(type, data) {
      if (type === 'new') {
        //1. Create form
        //2. Add it to instance
        this.createNewExam(data)
      } else {
        //this.$router.push({ name: 'exam', params: { id: 'template' } })
        this.createNewExam(data)
      }
    },*/
    /* async loadFormPage(pageKey) {
      console.log('loadFormPage', pageKey)
      let pageResponse = await FormServices.getPage(pageKey)
      if (typeof pageResponse.title[this.language] === 'undefined') {
        pageResponse.title[this.language] = ''
      }

      for (let s = 0; s < pageResponse.schema.length; s++) {
        if (typeof pageResponse.schema[s].title[this.language] === 'undefined') {
          pageResponse.schema[s].title[this.language] = ''
        }

        for (let i = 0; i < pageResponse.schema[s].content.length; i++) {
          //score
          if (typeof pageResponse.schema[s].content[i].score === 'undefined') {
            pageResponse.schema[s].content[i].score = null
          }
          //instructions
          if (
            typeof pageResponse.schema[s].content[i].instructions === 'undefined' ||
            typeof pageResponse.schema[s].content[i].instructions[this.language] === 'undefined'
          ) {
            if (typeof pageResponse.schema[s].content[i].instructions === 'undefined') {
              pageResponse.schema[s].content[i].instructions = {}
            }
            pageResponse.schema[s].content[i].instructions[this.language] = ''
          }
          //label
          if (
            typeof pageResponse.schema[s].content[i].label === 'undefined' ||
            typeof pageResponse.schema[s].content[i].label[this.language] === 'undefined'
          ) {
            if (typeof pageResponse.schema[s].content[i].label === 'undefined') {
              pageResponse.schema[s].content[i].label = {}
            }
            pageResponse.schema[s].content[i].label[this.language] = ''
          }
          //message
          if (
            pageResponse.schema[s].content[i].message &&
            typeof pageResponse.schema[s].content[i].message[this.language] === 'undefined'
          ) {
            if (typeof pageResponse.schema[s].content[i].message === 'undefined') {
              pageResponse.schema[s].content[i].message = {}
            }
            pageResponse.schema[s].content[i].message[this.language] = ''
          }
          //text
          if (
            pageResponse.schema[s].content[i].text &&
            typeof pageResponse.schema[s].content[i].text[this.language] === 'undefined'
          ) {
            if (typeof pageResponse.schema[s].content[i].text === 'undefined') {
              pageResponse.schema[s].content[i].text = {}
            }
            pageResponse.schema[s].content[i].text[this.language] = ''
          }
          //placeholder
          if (
            typeof pageResponse.schema[s].content[i].placeholder === 'undefined' ||
            typeof pageResponse.schema[s].content[i].placeholder[this.language] === 'undefined'
          ) {
            if (typeof pageResponse.schema[s].content[i].placeholder === 'undefined') {
              pageResponse.schema[s].content[i].placeholder = {}
            }

            if (pageResponse.schema[s].content[i].type === 'text_area') {
              pageResponse.schema[s].content[i]['placeholder'][this.language] =
                this.language === 'pt' ? 'Escreva aqui a sua resposta' : 'Write here your answer'
            }

            pageResponse.schema[s].content[i].placeholder[this.language] = ''
          }

          //check options
          if (pageResponse.schema[s].content[i].options && pageResponse.schema[s].content[i].options.length > 0) {
            for (let o = 0; o < pageResponse.schema[s].content[i].options.length; o++) {
              let optiontext = pageResponse.schema[s].content[i].options[o].text
              /*let optionscore = 0
              if (
                this.scores &&
                this.scores[pageResponse.key + '_' + pageResponse.schema[s].content[i].key] &&
                this.scores[pageResponse.key + '_' + pageResponse.schema[s].content[i].key].correct &&
                this.scores[pageResponse.key + '_' + pageResponse.schema[s].content[i].key].correct[o] &&
                this.scores[pageResponse.key + '_' + pageResponse.schema[s].content[i].key].correct[o].score
              ) {
                optionscore = Number(
                  this.scores[pageResponse.key + '_' + pageResponse.schema[s].content[i].key].correct[o].score
                )
              }*/

    //? this.scores[pageResponse.key + '_' + pageResponse.schema[s].content[i].key].score
    //: 0

    /*try {
                optiontext = JSON.parse(optiontext)
                console.log('é JSON', optiontext)
              } catch (e) {
                //no json detected
                console.log('NAO é JSON', optiontext)
              }*/
    /*
              if (typeof optiontext !== 'string' && typeof optiontext[this.language] === 'undefined') {
                optiontext[this.language] = ''
              }
              pageResponse.schema[s].content[i].options[o].text = optiontext
              //pageResponse.schema[s].content[i].options[o].score = optionscore
            }
          }
        }
      }

      return pageResponse
    },
    async createNewExam(data) {
      try {
        let formData = {
          instructions: {
            pt: '',
          },
          title: {
            pt: data.name,
          },
          options: {
            disableRightClick: true,
            availableLanguages: ['pt'],
            anonymous: false,
            //new
            pointsCorrectOption: 1,
            pointsWrongOption: 0,
            //new new
            scale: 100,
            pointsCorrectInput: 1,
            randomMultiplechoiceOptions: false,
            randomSections: false,
            negativePoints: false,
            limitNegativePoints: true,
            logInteractions: false,
          },
        }

        let pagesData = []

        if (data.key != null) {
          //load data from form
          let templateForm = await FormServices.getForm(data.key)
          let pagesPromises = []
          //response.pages.forEach(page => promises.push(FormServices.getPage(page.key)))
          templateForm.pages.forEach(page => pagesPromises.push(this.loadFormPage(page.key)))

          this.pages = []
          pagesData = await Promise.all(pagesPromises)
        }

        formData['type'] = 'exam'
        let responseForm = await FormServices.saveForm(formData)

        //verify if we have current language
        if (typeof responseForm.title[this.language] === 'undefined') {
          responseForm.title[this.language] = ''
        }

        let title = {}

        title[this.language] = this.language === 'pt' ? 'Página 1' : 'Page 1'

        let pagesPromises = []

        //add empty page to form
        if (pagesData.length == 0) {
          await FormServices.addFormPage(
            responseForm.key,
            title,
            [
              {
                boxed: true,
                required: false,
                title: '',
                key: '1',
                content: [],
              },
            ],
            {},
            null
          )
        } else {
          for (let index = 0; index < pagesData.length; index++) {
            pagesPromises.push(
              FormServices.addFormPage(
                responseForm.key,
                pagesData[index]['title'],
                pagesData[index]['schema'],
                pagesData[index]['scores'],
                null
              )
            )
          }
          await Promise.all(pagesPromises)
        }

        let responseInstance = await FormServices.createInstance(
          [responseForm.key],
          {
            pt: data.name,
          },
          formData.options,
          null
        )
        console.log(responseForm, responseInstance)
        this.$router.push({
          name: 'exams-manage-editor-version',
          params: { id: responseInstance.key, versionid: responseForm.key },
          query: { edit: true },
        })
      } catch (e) {
        console.error(e)
        this.$buefy.dialog.alert({
          message: 'Ocorreu um erro ao criar o exame',
          type: 'is-danger',
          hasIcon: false,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true,
        })
      }
    },
    */
  }
}
</script>
