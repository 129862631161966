<template>
  <fw-panel :title="'Importar perguntas de forma aleatória'" class="w-full">
    <div v-if="importing" class="flex flex-col text-center items-center py-28 px-5">
      <div class="text-xl mb-5 animate-pulse">A importar...</div>
      <div class="text-sm text-gray-500">Por favor não saia desta modal enquanto importamos as perguntas.</div>
    </div>
    <div v-else-if="page === 0">
      <div class="text-sm pb-1">Selecione de onde quer importar as perguntas:</div>
      <div ref="listHeader" class="flex-shrink-0 w-full">
        <div class="flex flex-col sm:flex-row gap-4 pb-4">
          <div class="flex flex-1 items-end gap-3 overflow-x-auto hide-scrollbar">
            <fw-button
              v-for="filter in filters"
              :key="filter.key"
              :type="filter.key === filterByType ? 'tab-active' : 'tab'"
              @click.native="changeFilter(filter.key)"
            >
              <v-clamp autoresize :max-lines="1">
                {{ filter.label }}
              </v-clamp>
            </fw-button>
          </div>
        </div>
      </div>
      <div v-if="filterByType === 'exam'" class="flex flex-col" style="max-height: 500px;">
        <LoadingPlaceholder v-if="searching" class="px-2 py-2" />
        <div
          v-if="!searching && numberResults > 0"
          class="flex-1 overflow-y-auto"
          style="max-height: 500px;"
          featured
          :title="listTitle"
          subtitle="Selecione de onde quer importar as perguntas:"
          :counter="numberResults"
        >
          <RecycleScroller
            :key="filterByType"
            v-slot="{ item }"
            :items="resultsList"
            :item-size="40"
            :buffer="200"
            :key-field="'key'"
            class="h-full"
          >
            <div
              :class="{
                'text-primary bg-teal-500 bg-opacity-10': selectedExams.includes(item.key)
              }"
              class="flex gap-3 items-center rounded-md px-3 py-1 h-10 hover:bg-gray-100 cursor-pointer border-b border-gray-100"
              @click="select('exam', item)"
            >
              <div class="flex-1 flex items-center gap-2" :class="{ 'text-gray-500': item.title == null }">
                <fw-icon-checkbox-circle-solid v-if="selectedExams.includes(item.key)" class="w-5 h-5 text-primary" />
                <fw-icon-checkbox-circle-blank v-else class="w-5 h-5 text-gray-400" />
                <v-clamp autoresize :max-lines="1" class="font-semibold">
                  {{ item.title != null ? item.title : 'Sem título' }}
                </v-clamp>
              </div>
              <div class="text-sm text-gray-500 font-medium">
                {{ item.createdDate | formatDateTime }}
              </div>
            </div>
          </RecycleScroller>
        </div>
        <div v-else-if="!searching" class="flex flex-col justify-center items-center text-gray-300 py-36">
          Sem resultados a mostrar
        </div>
      </div>
      <div v-else class="flex flex-col" style="max-height: 500px;">
        <LoadingPlaceholder v-if="searching" class="px-2 py-2" />
        <div
          v-if="!searching && numberResults > 0"
          class="flex-1 overflow-y-auto"
          style="max-height: 500px;"
          featured
          :title="listTitle"
          subtitle="Selecione de onde quer importar as perguntas:"
          :counter="numberResults"
        >
          <RecycleScroller
            :key="filterByType"
            v-slot="{ item }"
            :items="resultsList"
            :item-size="40"
            :buffer="200"
            :key-field="'key'"
            class="h-full"
          >
            <div
              :class="{
                'text-primary bg-teal-500 bg-opacity-10': selectedBuckets.includes(item.key)
              }"
              class="flex gap-3 items-center rounded-md px-3 py-1 h-10 hover:bg-gray-100 cursor-pointer border-b border-gray-100"
              @click="select('bucket', item)"
            >
              <div class="flex-1 flex items-center gap-2" :class="{ 'text-gray-500': item.title == null }">
                <fw-icon-checkbox-circle-solid v-if="selectedBuckets.includes(item.key)" class="w-5 h-5 text-primary" />
                <fw-icon-checkbox-circle-blank v-else class="w-5 h-5 text-gray-400" />
                <v-clamp autoresize :max-lines="1" class="font-semibold">
                  {{
                    item.titles !== null && item.titles['pt'] !== null
                      ? item.titles['pt']
                      : item.titles !== null && item.titles['en'] !== null
                      ? item.titles['en']
                      : 'Sem título'
                  }}
                </v-clamp>
              </div>
              <div class="text-sm text-gray-500 font-medium">
                {{ item.createdDate | formatDateTime }}
              </div>
            </div>
          </RecycleScroller>
        </div>
        <div v-else-if="!searching" class="flex flex-col justify-center items-center text-gray-300 py-36">
          Sem resultados a mostrar
        </div>
      </div>
      <div class="text-sm text-gray-500 mt-4">Seleccionados {{ totalExams }} exames ou repositórios</div>
      <div class="flex pt-5 gap-3">
        <div class="flex-1"></div>
        <fw-button type="link-muted" @click.native="previous()">
          Cancelar
        </fw-button>
        <fw-button :type="!allowNext ? 'disabled' : 'primary'" :disable="!allowNext" @click.native="next()">
          Seguinte
        </fw-button>
      </div>
    </div>
    <div v-else-if="page === 1">
      <b-field label="Número de perguntas a importar:">
        <b-input v-model="questions_number" type="number" min="1"></b-input>
      </b-field>

      <div v-if="totalExams > 1" class="flex gap-5 text-sm flex-1 mt-2 flex-col">
        <button
          :class="{
            'border-primary text-primary opacity-80': importationMethod == 'byexam',
            'hover:text-primary border-gray-100 hover:border-gray-200 opacity-50 hover:opacity-90':
              importationMethod != 'byexam'
          }"
          class="pt-5 border rounded-lg text-left justify-start px-4 py-5 w-full gap-3 flex items-center group transition duration-150 font-semibold"
          @click="changeSelectMethod('byexam')"
        >
          <div>
            <svg
              class="h-6 w-6 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M7 7V3a1 1 0 0 1 1-1h13a1 1 0 0 1 1 1v13a1 1 0 0 1-1 1h-4v3.993c0 .556-.449 1.007-1.007 1.007H3.007A1.006 1.006 0 0 1 2 20.993l.003-12.986C2.003 7.451 2.452 7 3.01 7H7zm2 0h6.993C16.549 7 17 7.449 17 8.007V15h3V4H9v3zm6 2H4.003L4 20h11V9zm-6.497 9l-3.536-3.536 1.414-1.414 2.122 2.122 4.242-4.243 1.414 1.414L8.503 18z"
              />
            </svg>
          </div>
          <div>
            <h3>{{ questions_number }} pergunta(s) de cada exame seleccionado</h3>
            <div class="text-sm text-gray-400">Serão importadas {{ totalQuestions }} perguntas aleatórias.</div>
          </div>
        </button>
        <button
          :class="{
            'border-primary text-primary opacity-80': importationMethod === 'total',
            'hover:text-primary border-gray-100 hover:border-gray-200 opacity-50 hover:opacity-90':
              importationMethod !== 'total'
          }"
          class="pt-5 border rounded-lg text-left justify-start px-4 py-5 w-full gap-3 flex items-center group transition duration-150 font-semibold"
          @click="changeSelectMethod('total')"
        >
          <div>
            <svg
              class="h-6 w-6 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M4 3h16a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm1 2v14h14V5H5zm6.003 11L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z"
              />
            </svg>
          </div>
          <h3>Um total de {{ questions_number }} perguntas</h3>
        </button>
      </div>

      <div class="flex pt-5 gap-3">
        <div class="flex-1"></div>
        <fw-button type="link-muted" @click.native="previous()">
          Anterior
        </fw-button>
        <fw-button
          :type="importing || totalQuestions <= 0 ? 'disabled' : 'primary'"
          :disable="importing || totalQuestions <= 0"
          @click.native="importQuestions()"
        >
          Importar {{ totalQuestions }} {{ totalQuestions == 1 ? 'pergunta' : 'perguntas' }}
        </fw-button>
      </div>
    </div>
  </fw-panel>
</template>

<script>
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import { RecycleScroller } from 'vue-virtual-scroller'
import FormServices from '@/fw-modules/fw-core-vue/exams/services/ServiceExams'
export default {
  name: 'ModalImportRandomQuestion',
  components: {
    RecycleScroller,
    LoadingPlaceholder
  },
  props: {
    isExam: {
      type: Boolean,
      default: false
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    ignoreForm: {
      type: String
    }
  },
  data() {
    return {
      page: 0,
      importing: false,
      selectedExams: [],
      selectedBuckets: [],
      searchedExams: [],
      searchedBuckets: [],
      searching: false,
      importationMethod: 'byexam',
      filters: [
        {
          label: 'Exames antigos',
          key: 'exam'
        },
        {
          label: 'Repositórios de perguntas',
          key: 'repo'
        }
      ],
      questions_number: 0,
      filterByType: 'exam',
      selectedQuestions: []
    }
  },
  computed: {
    totalQuestions() {
      return this.importationMethod === 'byexam'
        ? (this.selectedExams.length + this.selectedBuckets.length) * this.questions_number
        : this.questions_number
    },
    totalExams() {
      return this.selectedExams.length + this.selectedBuckets.length
    },
    resultsList() {
      return this.filterByType === 'exam' ? this.searchedExams : this.searchedBuckets
    },
    numberResults() {
      return this.resultsList.length
    },
    listTitle() {
      return this.filters.find(el => el.key === this.filterByType).label
    },
    allowNext() {
      return this.totalExams > 0
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    changeSelectMethod(type) {
      this.importationMethod = type
    },
    async loadForm(key) {
      return await FormServices.getForm(key)
    },
    async loadBucket(key) {
      return await FormServices.getBucket(key)
    },
    async importQuestions() {
      this.importing = true
      let resultQuestions = []
      let bucketPromises = []
      let pagePromises = []
      let formPromises = []
      let answers = {}

      try {
        this.selectedExams.forEach(formKey => {
          formPromises.push(this.loadForm(formKey))
        })

        this.selectedBuckets.forEach(bucketKey => {
          bucketPromises.push(this.loadBucket(bucketKey))
        })

        let forms = await Promise.all(formPromises)

        //load firstPage of each form
        forms.forEach(form => {
          if (form.pages.length > 0) {
            pagePromises.push(this.loadFormPage(form.pages[0].key))
          }
        })

        let pages = await Promise.all(pagePromises)
        console.log('pages', pages)
        pages.forEach(page => {
          let examquestions = []
          page.schema.forEach(schema => {
            schema.content.forEach(question => {
              examquestions.push(question)
            })
          })

          if (this.importationMethod === 'byexam') {
            resultQuestions = resultQuestions.concat(
              examquestions.sort(() => Math.random() - 0.5).slice(0, this.questions_number)
            )
          } else {
            resultQuestions = resultQuestions.concat(examquestions)
          }

          //Get answers
          examquestions.forEach(question => {
            answers[question.key] = page.scores[question.key]
          })
        })

        let buckets = await Promise.all(bucketPromises)
        buckets.forEach(bucket => {
          let examquestions = bucket.schema
          if (this.importationMethod === 'byexam') {
            resultQuestions = resultQuestions.concat(
              examquestions.sort(() => Math.random() - 0.5).slice(0, this.questions_number)
            )
          } else {
            resultQuestions = resultQuestions.concat(examquestions)
          }
          //Get answers
          examquestions.forEach(question => {
            answers[question.key] = bucket.scores[question.key]
          })
        })

        if (this.importationMethod !== 'byexam') {
          //randomize and slice
          resultQuestions = resultQuestions.sort(() => Math.random() - 0.5).slice(0, this.questions_number)
        }

        console.log('RESULT QUESTIONS', resultQuestions, answers)

        //this.filterQuestions(resultQuestions)
        this.$emit('import-questions', {
          questions: resultQuestions,
          answers: answers
        })
      } catch (error) {
        console.log(error)
      } finally {
        this.importing = false
        this.selectedExams = []
        this.selectedBuckets = []
        this.page = 0
      }
    },
    async loadFormPage(pageKey) {
      return await FormServices.getPage(pageKey)
    },
    loadOldExams() {
      this.searching = true
      FormServices.getAllForms()
        .then(response => {
          console.log('response', response.forms)
          this.searchedExams = response.forms.filter(form => form.key !== this.ignoreForm)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.searching = false
        })
    },
    loadBuckets() {
      this.searching = true
      FormServices.getAllExamBuckets()
        .then(response => {
          console.log('response', response)
          this.searchedBuckets = response.buckets
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.searching = false
        })
    },
    loadData() {
      if (this.filterByType == 'exam') {
        this.loadOldExams()
      } else {
        this.loadBuckets()
      }
    },
    changeFilter(type) {
      this.filterByType = type
      this.loadData()
    },
    select(type, item) {
      if (type == 'exam') {
        if (this.selectedExams.includes(item.key)) {
          let index = this.selectedExams.indexOf(item.key)
          this.selectedExams.splice(index, 1)
        } else {
          this.selectedExams.push(item.key)
        }
      } else {
        //bucket
        console.log('selecting bucket', item)
        if (this.selectedBuckets.includes(item.key)) {
          let index = this.selectedBuckets.indexOf(item.key)
          this.selectedBuckets.splice(index, 1)
        } else {
          this.selectedBuckets.push(item.key)
        }
      }
    },
    selectType(type) {
      this.type = type
    },
    async search() {
      try {
        this.searching = true
        let result = await FormServices.getAllForms()
        console.log(result)
        this.searchedExams = result
      } catch (e) {
        console.error(e)
      } finally {
        this.searching = false
      }
    },
    next() {
      if (this.isExam && this.page == 0) {
        //verify name
        if (this.examName.length == 0) {
          return
        }
      }

      if (this.type == 'blank') {
        this.$emit('new-from-blank', {
          key: null,
          name: this.examName
        })
      } else if (this.type != 'blank' && this.page == 0) {
        this.page++
        this.search()
      } else if (this.type != 'blank' && this.page == 1) {
        if (this.selectedTemplate != null) {
          let template = this.selectedTemplate
          this.$emit('new-from-template', {
            key: template.key,
            name: this.examName
          })
        }
      }
    },
    previous() {
      if (this.page == 0) {
        console.log('emit close')
        this.$emit('close')
      } else {
        this.page = 0
        this.selectedExams = []
        this.loadData()
      }
    }
  }
}
</script>
