<template>
  <div>
    <fw-panel :title="unit.title" boxed="xs" :custom-title-class="'px-3.5 pt-4 pb-2'" :sticky="sticky" paddingless>
      <template v-if="canEdit" #toolbar>
        <div class="pr-3 pt-1">
          <fw-menu-more>
            <b-dropdown-item v-if="unit.page_key" paddingless aria-role="listitem">
              <fw-button type="dropdown-item" class="w-full" label="Criar" @click.native="editContent">
                Editar conteúdo
              </fw-button>
            </b-dropdown-item>
            <b-dropdown-item paddingless aria-role="listitem">
              <fw-button type="dropdown-item" class="w-full" label="Criar" @click.native="openModal('edit-module')">
                Editar título do módulo
              </fw-button>
            </b-dropdown-item>
            <b-dropdown-item paddingless aria-role="listitem">
              <fw-button type="dropdown-item" class="w-full" label="Eliminar" @click.native="confirmDeleteModule()">
                Eliminar módulo
              </fw-button>
            </b-dropdown-item>
            <b-dropdown-item paddingless aria-role="listitem">
              <fw-button type="dropdown-item" label="Apagar" class="w-full" @click.native="showModalEditPost = true">
                Novo tópico de discussão
              </fw-button>
            </b-dropdown-item>
          </fw-menu-more>
        </div>
      </template>
      <template #bellow-header>
        <div class="flex gap-5">
          <fw-button
            size="md"
            custom-class="pb-3"
            :type="view === 'dashboard' ? 'tab-active' : 'tab'"
            @click.native="
              () =>
                $router.push({
                  name: 'course-manage-edition-module',
                  params: {
                    id: courseKey,
                    editionId: editionKey,
                    moduleId: unit.key
                  }
                })
            "
          >
            Apresentação
          </fw-button>
          <fw-button
            size="md"
            custom-class="pb-3"
            :type="view === 'foruns' ? 'tab-active' : 'tab'"
            @click.native="
              () =>
                $router.push({
                  name: 'course-manage-edition-module-foruns',
                  params: {
                    id: courseKey,
                    editionId: editionKey,
                    moduleId: unit.key
                  }
                })
            "
          >
            Tópicos de discussão
          </fw-button>
        </div>
      </template>
    </fw-panel>
    <fw-content-loader v-if="loading" />
    <template v-else>
      <section v-if="unit.page_key && view === 'dashboard'">
        <PanelPagePreview
          :key="unit.page_key"
          :page-id="unit.page_key"
          :show-versions-dropdown="canEdit"
          :show-title="false"
          class="p-5"
          :show-chapters="true"
          :chapters-offset="'200px'"
          :main-container="'.main-content'"
        />
      </section>
      <PanelPosts
        v-else-if="view === 'foruns'"
        type="discussions"
        no-title
        :collections="collections"
        :contexts-data="collectionsContextsData"
        class="mx-4 mt-4"
        @open="$emit('open-post', $event)"
      >
        <template v-if="canEdit" #header-end>
          <fw-menu-more v-if="showModalEditPost && unitCollection?.key">
            <b-dropdown-item paddingless aria-role="listitem">
              <fw-button type="dropdown-item" label="Apagar" class="w-full" @click.native="showModalEditPost = true">
                Nova publicação
              </fw-button>
            </b-dropdown-item>
          </fw-menu-more>
        </template>
      </PanelPosts>
    </template>

    <fw-modal v-if="modalIsActive" :active.sync="modalIsActive" :can-cancel="true" size="min" @close="closeModal()">
      <ModalNewModule
        v-if="modalAction === 'edit-module'"
        :title="unit.title"
        @save="editUnitTitle($event)"
        @cancel="closeModal()"
      />
      <ModalNewTopic v-else-if="modalAction === 'create-topic'" @save="createNewTopic($event)" @cancel="closeModal()" />
    </fw-modal>

    <fw-modal
      v-if="showModalEditPost && unitCollection?.key"
      :active.sync="showModalEditPost"
      :can-cancel="true"
      paddingless
      size="min"
      height-fit-screen
      @close="showModalEditPost = false"
    >
      <template #default>
        <BlockEditPost
          :collection-key="unitCollection?.key"
          :context="'post'"
          :post="null"
          type="discussion"
          @saved="
            data => {
              $emit('open-post', { ...data, collection: unitCollection })
              showModalEditPost = false
            }
          "
          @close="showModalEditPost = false"
        />
      </template>
    </fw-modal>
  </div>
</template>

<script>
import ServicesCourses from '@/fw-modules/fw-core-vue/courses/services/ServiceCourses'
import ModalNewModule from '@/fw-modules/fw-core-vue/courses/components/modals/manage/ModalNewModule'
import ModalNewTopic from '@/fw-modules/fw-core-vue/courses/components/modals/manage/ModalNewTopic'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import PanelPagePreview from '@/fw-modules/fw-core-vue/pages/components/panels/PanelPagePreview'
import PanelPosts from '@/fw-modules/fw-core-vue/posts/components/panels/PanelPosts'
import BlockEditPost from '@/fw-modules/fw-core-vue/posts/components/blocks/BlockEditPost'
export default {
  components: {
    ModalNewModule,
    ModalNewTopic,
    PanelPagePreview,
    PanelPosts,
    BlockEditPost
  },
  props: {
    courseKey: {
      type: String,
      required: true
    },
    editionKey: {
      type: String,
      required: true
    },
    unit: {
      type: Object,
      required: true
    },
    collections: {
      type: Array,
      default: () => []
    },
    collectionsContextsData: {
      type: Object,
      default: () => {}
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    sticky: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      modalIsActive: false,
      showModalEditPost: false,
      modalAction: null
    }
  },

  computed: {
    unitCollection() {
      return this.collections.find(el => el.unit_key === this.unit.key && !el.topic_key)
    },
    view() {
      if (this.$route.name === 'course-manage-edition-module-people') {
        return 'people'
      }

      if (this.$route.name === 'course-manage-edition-module-foruns') {
        return 'foruns'
      }

      return 'dashboard'
    }
  },

  methods: {
    openModal(action) {
      this.modalIsActive = true
      this.modalAction = action
    },

    closeModal() {
      this.modalIsActive = false
      this.modalAction = null
    },

    async editUnitTitle({ title }) {
      await utils.tryAndCatch(this, async () => {
        const response = await ServicesCourses.updateUnit(this.courseKey, this.editionKey, this.unit.key, {
          title: title
        })
        console.log(response)
        this.unit.title = title
        this.$emit('updated-unit', response)
        //show snackbar
        this.$buefy.snackbar.open({
          message: 'Título do módulo atualizado.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 5000
        })
      })
      this.closeModal()
    },

    confirmDeleteModule() {
      this.$buefy.dialog.confirm({
        title: 'Eliminar módulo',
        message: `Tem a certeza que pretende eliminar o módulo "${this.unit.title}" e todos os seus temas?`,
        confirmText: 'Eliminar',
        type: 'is-danger',
        hasIcon: false,
        onConfirm: () => {
          this.deleteModule()
        }
      })
    },

    async deleteModule() {
      await utils.tryAndCatch(this, async () => {
        const response = await ServicesCourses.deleteUnit(this.courseKey, this.editionKey, this.unit.key)
        console.log(response)
        this.$emit('delete-unit', response)
        this.$router.push({
          name: 'course-manage-edition',
          params: { id: this.courseKey, editionId: this.editionKey, view: 'modules' }
        })
      })
    },

    async createNewTopic(topic) {
      console.log('createNewTopic', topic)
      await utils.tryAndCatch(this, async () => {
        const response = await ServicesCourses.createTopic(this.courseKey, this.editionKey, this.unit.key, topic)
        console.log(response)
        this.$emit('new-topic', response.topic)
        //show snackbar
        this.$buefy.snackbar.open({
          message: 'Tema criado com sucesso.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 2000
        })
      })
      this.closeModal()
    },

    editContent() {
      this.$router.push({
        name: 'content-pages-editor',
        params: {
          key: this.unit.page_key
        }
      })
    }
  }
}
</script>
