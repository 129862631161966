<template>
  <fw-panel title="Nova edição">
    <div class="flex flex-col">
      <b-field label="Título da edição">
        <b-input ref="input" v-model="editionName" maxlength="150"></b-input>
      </b-field>
    </div>
    <div class="flex flex-shrink-0 pt-5 gap-3">
      <div class="flex-1"></div>
      <fw-button type="link-muted" @click.native="cancel()">
        Cancelar
      </fw-button>
      <fw-button :type="!allowNext ? 'disabled' : 'primary'" :disable="!allowNext" class="w-32" @click.native="next()">
        Criar
      </fw-button>
    </div>
  </fw-panel>
</template>

<script>
export default {
  name: 'ModalNewCourse',
  data() {
    return {
      editionName: ''
    }
  },
  computed: {
    allowNext() {
      return this.editionName.length > 0
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.input.focus()
    })
  },
  methods: {
    next() {
      //verify name
      if (this.editionName.length == 0) {
        return
      }

      this.$emit('new', {
        name: this.editionName
      })
    },
    cancel() {
      this.$emit('cancel')
    }
  }
}
</script>
