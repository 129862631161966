<template>
  <button
    class="flex gap-2 relative w-full text-left group rounded-lg items-start bg-white group-hover:bg-gray-100 hover:bg-gray-100"
    :class="{
      'text-primary bg-white shadow': data.key == selected,
      'bg-gray-300 bg-opacity-20 shadow-sm': !shortSidebar && data.key != selected,
      'p-3': !shortSidebar,
      'border border-gray-200 bg-gray-100': shortSidebar && data.key != selected,
      'border border-primary/50': shortSidebar && data.key == selected
    }"
  >
    <div
      v-if="data.index >= 0"
      class="rounded text-center text-xs font-bold"
      :class="{
        'text-primary': data.key == selected,
        'text-gray-500 bg-gray-100': !shortSidebar && data.key != selected,
        'bg-primary/10': data.key == selected,
        'ml-7': draggable,
        'h-7 w-7 leading-7': !shortSidebar,
        'h-9 w-10 leading-9': shortSidebar
      }"
      @click="$emit('open')"
    >
      M{{ data.index > 99 ? 99 : data.index + 1 }}
    </div>
    <div v-else class="font-bold text-sm pl-7 py-1 text-gray-700" @click="$emit('open')">
      <fw-icon-book-mark-solid class="w-5 h-5" />
    </div>
    <div v-if="!shortSidebar" class="flex-1 pt-0.5" @click="$emit('open')">
      <v-clamp class="font-medium" autoresize :max-lines="2">{{ data.title.replace('.', '') }} </v-clamp>
    </div>
    <b-tooltip v-if="!shortSidebar" label="Número de temas" position="is-left" type="is-light">
      <div
        class="flex items-center text-xs font-medium gap-1.5 pt-1 w-9 overflow-hidden overflow-ellipsis"
        :class="{
          'text-primary': data.stats?.total_topics,
          'text-gray-400': !data.stats?.total_topics
        }"
      >
        <fw-icon-list-view class="h-5 w-5" /> {{ data.stats?.total_topics ?? 0 }}
      </div>
    </b-tooltip>
    <b-tooltip v-if="!shortSidebar" label="Tópicos de discussão" position="is-left" type="is-light">
      <div
        class="flex items-center text-xs font-medium gap-1.5 pt-[0.35rem] w-9 overflow-hidden overflow-ellipsis"
        :class="{
          'text-primary': data.stats?.total_posts,
          'text-gray-400': !data.stats?.total_posts
        }"
      >
        <fw-icon-discuss class="h-[1.1rem] w-[1.1rem]" /> {{ data.stats?.total_posts ?? 0 }}
      </div>
    </b-tooltip>
    <fw-menu-more v-if="editable && !shortSidebar" append-to-body>
      <b-dropdown-item aria-role="listitem" paddingless>
        <fw-button type="dropdown-item" class="w-full" @click.native="$emit('edit')">Editar nome</fw-button>
      </b-dropdown-item>
      <b-dropdown-item aria-role="listitem" paddingless>
        <fw-button type="dropdown-item" class="w-full" @click.native="$emit('delete')">Eliminar módulo</fw-button>
      </b-dropdown-item>
    </fw-menu-more>
  </button>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    editable: {
      type: Boolean,
      default: true
    },
    selected: {
      type: String
    },
    shortSidebar: {
      type: Boolean,
      default: false
    },
    users: {
      type: Object,
      default: () => {}
    },
    draggable: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style scoped>
.exam-record .item-arrow {
  @apply transition-all;
}
.exam-record:hover .item-arrow {
  transform: translateX(3px);
}
</style>
