<template>
  <b-dropdown aria-role="list" position="is-bottom-left">
    <fw-button-dropdown slot="trigger" aria-role="listitem" type="transparent" label="Ações">
      <fw-icon-actions class="w-4 h-4" :class="{ 'text-primary animate-pulse': activeActionsCounter }" />
      <span>Ações</span>
      <fw-badge v-if="activeActionsCounter" size="xs">{{ activeActionsCounter }}</fw-badge>
    </fw-button-dropdown>
    <b-dropdown-item v-if="!activeActionsCounter" custom class="paddingless text-xs text-gray-500">
      Não existem ações disponíveis neste momento.
    </b-dropdown-item>

    <b-dropdown-item v-if="false && missingInfoNumber > 0" custom class="paddingless text-xs text-gray-500">
      <fw-message type="warning" padding="py-2 px-3" rounded="rounded">
        Falta a seguinte informação para poder
        <b>{{ missingInfoTo === 'publish' ? 'publicar' : missingInfoTo === 'lock' ? 'lacrar' : missingInfoTo }}</b> o
        exame:
        <ul class="list-disc list-inside">
          <li v-if="missing.title">Título do exame</li>
          <li v-if="missing.start">Data de início</li>
          <li v-if="missing.location">Local do exame</li>
          <li v-if="missing.versions">Preencher versões</li>
          <li v-if="missing.correction">Correção manual do exame</li>
        </ul>
      </fw-message>
    </b-dropdown-item>

    <b-dropdown-item v-if="checks['publish']" ref="actionitem" custom class="paddingless" aria-role="menuitem">
      <fw-button
        ref="publishButton"
        :type="checks.publish ? 'basic-action' : 'disabled'"
        :disabled="!checks.publish"
        class="w-full my-0.5"
        @click.native="$emit('publish')"
      >
        <template #icon>
          <fw-icon-actions class="w-5 h-5 text-gray-500" />
        </template>
        Publicar exame
      </fw-button>
    </b-dropdown-item>
    <b-dropdown-item v-if="checks['unpublish']" ref="actionitem" custom class="paddingless" aria-role="menuitem">
      <fw-button
        ref="publishButton"
        :type="checks.unpublish ? 'basic-action' : 'disabled'"
        :disabled="!checks.unpublish"
        class="w-full my-0.5"
        @click.native="$emit('unpublish')"
      >
        <template #icon>
          <fw-icon-actions class="w-5 h-5 text-gray-500" />
        </template>
        Despublicar exame
      </fw-button>
    </b-dropdown-item>
    <b-dropdown-item v-if="checks['start']" ref="actionitem" custom class="paddingless" aria-role="menuitem">
      <fw-button
        ref="notifyPublished"
        :type="'basic-action'"
        :disabled="!checks.start"
        expanded
        @click.native="$emit('start')"
      >
        <template #icon>
          <fw-icon-actions class="w-5 h-5 text-gray-500" />
        </template>
        Iniciar exame
      </fw-button>
    </b-dropdown-item>
    <b-dropdown-item v-if="checks['stop']" ref="actionitem" custom class="paddingless" aria-role="menuitem">
      <fw-button
        :type="checks.stop ? 'basic-action' : 'disabled'"
        :disabled="!checks.stop"
        class="w-full my-0.5"
        @click.native="$emit('stop')"
      >
        <template #icon>
          <fw-icon-actions class="w-5 h-5 text-gray-500" />
        </template>
        Terminar exame
      </fw-button>
    </b-dropdown-item>

    <b-dropdown-item v-if="checks['lockAnswers']" ref="actionitem" custom class="paddingless" aria-role="menuitem">
      <fw-button :type="'basic-action'" class="w-full my-0.5" @click.native="$emit('lock')">
        <template #icon>
          <fw-icon-actions class="w-5 h-5 text-gray-500" />
        </template>
        Lacrar exame
      </fw-button>
    </b-dropdown-item>

    <b-dropdown-item v-if="checks['unlockAnswers']" ref="actionitem" custom class="paddingless" aria-role="menuitem">
      <fw-button :type="'basic-action'" class="w-full my-0.5" @click.native="$emit('unlock')">
        <template #icon>
          <fw-icon-actions class="w-5 h-5 text-gray-500" />
        </template>
        Deslacrar exame
      </fw-button>
    </b-dropdown-item>

    <b-dropdown-item
      v-if="availableActions.includes('notify')"
      ref="actionitem"
      custom
      class="paddingless"
      aria-role="menuitem"
    >
      <fw-button
        ref="notifyPublished"
        :type="checks.notify ? 'basic-action' : 'disabled'"
        :disabled="!checks.notify"
        expanded
        @click.native="$emit('notify-students')"
      >
        <template #icon>
          <fw-icon-actions class="w-5 h-5 text-gray-500" />
        </template>
        Notificar estudantes
      </fw-button>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import FwMessage from '@/fw-modules/fw-core-vue/ui/components/text/FwMessage'
export default {
  components: {
    FwMessage
  },
  props: {
    checks: {
      type: Object,
      default: () => {
        return {}
      }
    },
    procedure: {
      type: Object,
      default: () => {
        return {}
      }
    },
    /*availableActions: {
      type: Array,
      default: () => {
        return
      },
    },*/
    missingInfoTo: {
      type: [String, null],
      default: null
    },
    missing: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  /*data() {
    return {
      availableActions: [
        'showActionNotifyOpenToJury',
        'showActionPublish',
        'showActionOpen',
        'showActionNotifyInterview',
        'showActionEnd',
        'showActionNotifyResults',
        'showActionClose',
      ], // Confirm every computed value to update counter
    }
  },

  delete:false
edit:false
permissions:false
publish:false
seeAnswers:true
start:true
stop:false
unpublish:false


  */

  computed: {
    missingInfoNumber() {
      return this.missing !== null ? Object.entries(this.missing).filter(([, value]) => value).length : 0
    },
    availableActions() {
      var checks = ['publish', 'start', 'stop', 'unpublish', 'lockAnswers', 'unlockAnswers', 'notify']
      var actions = []
      for (const action of checks) {
        if (this.checks[action]) actions.push(action)
      }
      return actions
    },
    activeActionsCounter() {
      //var counter = 0
      //var self = this
      //for (const action of self.availableActions) {
      //  if (self[action]) counter += 1
      //}
      return this.availableActions.length
    }
  }
}
</script>
