<template>
  <FullPageLoading v-if="initialLoading" icon="survey" title="Form" />
  <Forbidden v-else-if="forbidden" />
  <LayoutDefault
    v-else
    :header-session-nav="false"
    :header-apps="false"
    :header-notifications="false"
    :main-footer="false"
    :disable-back-to="true"
    :listen-back-event="true"
    mobile-ready
  >
    <template #main-content>
      <div v-if="form && form.schema" class=" max-w-2xl mx-auto">
        <fw-form
          :language="language"
          :form="form.schema"
          :form-key="form.key"
          :input-gap="'gap-6'"
          :section-gap="'gap-12'"
          :page-prefix="'form_'"
          :saving="saving"
          :debug="debug"
          :show-save-button="true"
          :show-send-button="true"
          @download="downloadFile"
        ></fw-form>
      </div>
    </template>
    <template #modals></template>
  </LayoutDefault>
</template>

<script>
import LayoutDefault from '@/fw-modules/fw-core-vue/ui/components/layouts/LayoutDefault'
import FormServices from '@/fw-modules/fw-core-vue/forms/services/ServiceForms'
import FwForm from '@/fw-modules/fw-core-vue/ui/components/form/Form'
import Forbidden from '@/fw-modules/fw-core-vue/id/views/Forbidden'
import FullPageLoading from '@/fw-modules/fw-core-vue/ui/views/FullPageLoading'

export default {
  name: 'ViewFormViewer',
  components: {
    FwForm,
    LayoutDefault,
    Forbidden,
    FullPageLoading
  },

  data: function() {
    return {
      form: null,
      noInternet: false,
      debug: true,
      forbidden: false,
      saving: false,
      initialLoading: true,
      silentloading: false,
      debouce_timer: null,
      realtimeDebouncer: null,
      saved: false,
      savedTimeout: null,
      autosaveTimer: null
    }
  },

  computed: {
    isDev() {
      return process.env.NODE_ENV === 'development'
    },
    me() {
      return this.$store.getters.getUser
    },
    isDigitalPad() {
      return process.env.VUE_APP_KEY == 'ucdigitalpad'
    },
    // Make sure exam is not allowed in small devices
    isMobileOrSmallTablet() {
      return window.innerWidth < 1024
    },

    // Instance and exam
    examTitle() {
      return this.form && this.form.title
    },
    examInstructions() {
      return this.form && this.form.instructions[this.language]
    },
    instanceKey() {
      return this.$route.params.key ? this.$route.params.key : null
    },
    isExam() {
      return false
    },

    language() {
      return 'pt'
    },
    dirtyData() {
      return this.$store.state.dirtyData
    }
  },

  created() {
    this.loadForm()
  },
  methods: {
    async loadForm() {
      try {
        this.initialLoading = true
        let key = this.$route.params.key
        let response = await FormServices.getForm(key)
        console.log('loadForm response', response)
        this.form = response
      } catch (error) {
        console.log('loadForm error', error)
        this.$buefy.toast.open({
          message: 'Ocorreu um erro ao carregar o formulário',
          type: 'is-danger',
          duration: 5000
        })
        this.forbidden = true
      } finally {
        this.initialLoading = false
      }
    },
    refreshPage() {
      this.$buefy.dialog.confirm({
        title: 'Tem a certeza que pretende atualizar a página?',
        message: 'Verifique ter guardado todas as respostas antes de atualizar a página.',
        confirmText: 'Atualizar',
        cancelText: 'Cancelar',
        type: 'is-danger',
        onConfirm: () => {
          location.reload()
        }
      })
    },
    goToQuestion(page, question) {
      let el = document.getElementById('question-' + page + '-' + question)
      console.log('el', el)
      el.scrollIntoView({ behavior: 'smooth', block: 'start' })
    },
    downloadFile(file) {
      const userToken = this.$store.state.session.user.token
      const fileurl = file.file ? file.file.url_format : file.url_format
      const filekey = file.file ? file.file.key : file.key
      const filename = file.file && file.file.filename ? file.file.filename : file.filename

      const url = fileurl
        .replaceAll('{TOKEN}', userToken)
        .replaceAll('{KEY}', filekey)
        .replaceAll('{FILENAME}', filename)

      const link = document.createElement('a')
      link.href = url
      link.target = '_blank'
      link.setAttribute('download', file.filename)
      document.body.appendChild(link)
      link.click()
    },

    // Main data management
    async getInstance() {
      try {
        let instanceData = await FormServices.getInstance(this.instanceKey)
        console.log('getInstance', instanceData)
      } catch (e) {
        console.log('getInstance error', e)
        //let error = JSON.stringify(e)
        this.$buefy.toast.open({
          message: this.$t('errorloading'),
          type: 'is-danger',
          duration: 5000
        })
      } finally {
        this.initialLoading = false
      }
      this.silentloading = false
    },

    async saveAnswers(all = false) {
      //this.calculateProgression()
      //loop throw dirty and save
      if (!this.saving || all) {
        this.noInternet = false
        console.log('SAVING ANSWERS all?', all)
        this.saving = true // Avoid weird behavior when saving all
        this.successRequest = false
        this.failedRequest = false
        let promises = []
        let pagesOrder = []
        if (all) {
          this.pages.forEach(page => {
            if (this.pagesState[page.key]) {
              promises.push(FormServices.saveAnswer(this.instance.key, page.key, this.pagesState[page.key]))
            }
          })
        } else {
          //only save the dirty questions - questions that were not saved yet
          //organize data per page, so we send only one request per page
          let toSaveStructure = {}

          this.dirtyQuestions.forEach(pageAndQuestionKeys => {
            let idParts = pageAndQuestionKeys.split('_')
            let pageKey = idParts[0]
            let questionKey = idParts[1]
            if (!toSaveStructure[pageKey]) {
              toSaveStructure[pageKey] = []
            }
            toSaveStructure[pageKey].push(questionKey)
          })

          //Create one request per exam page, with the questions that were not saved
          Object.keys(toSaveStructure).forEach(pageKey => {
            pagesOrder.push(pageKey)
            let pageData = {}
            toSaveStructure[pageKey].forEach(questionKey => {
              pageData[questionKey] = this.pagesState[pageKey][questionKey]
            })
            promises.push(FormServices.saveAnswer(this.instance.key, pageKey, pageData))
          })
        }

        try {
          let result = await Promise.all(promises)
          console.log('saveAnswers', result[0])
          if (result[0] && result[0]['count']) {
            //update sent answers with the value from the backend
            console.log('updating answers')
            this.answeredQuestions = result[0]['count']
          }

          pagesOrder.forEach((pageKey, p) => {
            console.log('updating ', 'page_' + pageKey)
            //send data from server from each page to the local state
            //TODO: Check why is an array and not a single object
            this.$refs['page_' + pageKey][0].updateAnswers(result[p].data)
            //update questionMap if we have an answer or not
            //TODO: TEST THIS
            Object.keys(result[p].data).forEach(questionKey => {
              let answer = result[p].data[questionKey]
              console.log('questionKey', questionKey, 'answer', answer)
              let questionMapIndex = this.questionMap.findIndex(q => q.page == pageKey && q.question == questionKey)
              if (questionMapIndex != -1) {
                this.questionMap[questionMapIndex].answer = answer != null && answer.length > 0 ? true : false
              }
            })
          })

          //reset dirty questions
          this.dirtyQuestions.clear()
          this.$store.commit('setDirtyData', false)
          this.successRequest = true
          this.saved = true
          //sucessfull save, erase backup
          this.eraseBackup()
        } catch (e) {
          if (e.code == 'ERR_NETWORK') {
            this.noInternet = true
          }
          //SAVE DATA BACKUP
          this.saveBackup()
          console.error('Problem saving data', e)
          this.failedRequest = true
        } finally {
          if (this.savedTimeout) clearTimeout(this.savedTimeout)
          this.savedTimeout = setTimeout(() => {
            this.saved = false
            this.saving = false
          }, 1000)
        }
      }
    },
    createScrollListner(remove = false) {
      let mainElement = document.querySelector('main')
      let animScrollAfter = 200
      let fixLateralToolbar = 250
      let self = this
      if (remove) {
        if (mainElement) {
          mainElement.removeEventListener(
            'scroll',
            function() {
              if (mainElement.scrollTop > animScrollAfter) {
                self.showTitle = true
              } else {
                self.showTitle = false
              }
              if (mainElement.scrollTop > fixLateralToolbar) {
                self.fixedlateral = true
              } else {
                self.fixedlateral = false
              }
            },
            false
          )
        }
      } else {
        mainElement.addEventListener(
          'scroll',
          function() {
            if (mainElement.scrollTop > animScrollAfter) {
              self.showTitle = true
            } else {
              self.showTitle = false
            }
            if (mainElement.scrollTop > fixLateralToolbar) {
              self.fixedlateral = true
            } else {
              self.fixedlateral = false
            }
          },
          false
        )
      }
    },
    goBack() {
      if (this.$store.state.lastRoutePath !== null) {
        let path = this.$store.state.lastRoutePath
        this.$store.commit('setLastRoutePath', null)
        this.$router.push({ path: path })
      } else {
        this.$router.push({ path: '/' })
      }
    },
    signout() {
      localStorage.setItem('shouldCheckout', 'true')
      this.$router.push('/logout')
    },
    goToSection(pageKey, sectionKey) {
      if (pageKey === 'start') {
        document.getElementsByTagName('main')[0].scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      } else {
        document.getElementsByTagName('main')[0].scrollTo({
          top:
            document.getElementById(pageKey + '_form_section_' + sectionKey).getBoundingClientRect().top +
            document.getElementsByTagName('main')[0].scrollTop -
            55,
          behavior: 'smooth'
        })
      }
    }
  }
}
</script>

<style scope>
.exam-content {
  @apply grid;
  grid-template-columns: 1fr 190px;
}

.instructions-box.expanded-instructions .instructions-text {
  max-height: none;
}

.instructions-box .instructions-text {
  max-height: 100px;
  overflow-y: hidden;
}

.exam-view {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.start-exam-btn {
  @apply rounded-md px-5 py-2.5 font-semibold text-sm text-center text-white text-opacity-90 bg-primary uppercase;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  letter-spacing: -0.025em;
}

.start-exam-btn:hover,
.start-exam-btn:active {
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}

.start-exam-btn.disabled {
  @apply bg-gray-200 bg-opacity-40 shadow-none cursor-not-allowed text-gray-400;
}

.cover {
  font-weight: 700;
  letter-spacing: -0.025em;
  min-height: 520px;
  @apply text-gray-800 text-center p-5 relative;
}

.cover.exam-ended {
  @apply text-gray-400;
}

.cover-background.exam-ended {
  filter: grayscale(1);
}

.cover-hide {
  transform: translateY(-115vh) scale(1.2);
}

.cover-fade {
  opacity: 0;
}

.no-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}
</style>

<i18n>
  {
    "en": {
      "notitle": "No title",
      "running": "Running",
      "endbutton": "Submit",
      "giveupbutton": "Give up",
      "giveuptitle": "Give up",
      "giveupmessage": "Are you sure you want to give up the exam?",
      "giveupcancel": "Cancel",
      "giveupconfirm": "Yes, give up",
      "submittitle": "Submit exam",
      "submitmessage": "Are you sure you want to submit the exam?",
      "submitcancel": "Cancel",
      "submitconfirm": "Yes",
      "examstartlabel": "EXAM STARTS HERE",
      "examendlabel": "EXAM ENDS HERE",
      "genericerror": "An error occurred while performing the operation",
      "errorsavingtermsandphoto": "Error saving photo and terms acceptance. Please try again later.",
      "errorloading": "An error occurred while loading the form. Please refresh the page to try again.",
      "errorsubmitting": "An error occurred while submitting the exam but your answers are saved and the exam will be considered.",
      "errorloading2": "An error occurred while loading the exam. Please try again later or contact support.",
      "timeovermessage1": "There are less than",
      "timeovermessage2": "minute(s) to",
      "timeovermessage3": "end the evaluation.",
      "timeovermessage4": "the evaluation predicted end.",
      "quitexamtitle": "Are you sure you want to quit this exam?",
      "quitexammessage": "If you leave, you may re-enter if the exam is still running. If you do not explicitly give up the exam, it will be considered automatically submitted at the end of the exam.",
      "quitexamcancel": "Cancel",
      "quitexamconfirm": "Exit",
      "recoverbackup": "Recover backup",
      "recoverbackupmessage": "It seems that you have a backup of this exam. Do you want to recover it?",
      "recoverbackupcancel": "No",
      "recoverbackupconfirm": "Yes",
      "backuprecovered": "Backup recovered",
      "backuprecoveredmessage": "The backup was successfully recovered. We will now reload the exam.",
      "backuprecoveredcancel": "Close",
      "backuprecoveredconfirm": "Reload",
      "modalsubmit": {
        "examended": "Exam ended",
        "examsubmited": "Exam submited",
        "answeredquestions": "Questions answered",
        "instructions": "Click the following button to close this window and return to the previous context.",
        "close": "Close",
        "closeandsignout": "Close and sign out"
      },
      "modalgiveup": {
        "title": "You gave up from the exam",
        "message": "Your answers will not be considered for evaluation.",
        "instructions": "Click the following button to close this window and return to the previous context.",
        "close": "Close"
      },
      "modalterms": {
        "title": "Use terms",
        "wronganswers": "The wrong answers decrease your score",
        "wronganswersmessage": "Warning: giving a wrong answer will decrease your final score. You should only give a wrong answer if you are sure that the correct answer is not available.",
        "monitoring": "Monitoring technology",
        "monitoringmessage": "We use non-invasive behavioral analysis technology to evaluate the integrity of the evaluation process. Actions such as the following will be recorded:",
        "monitoringaction1": "Copy",
        "monitoringaction2": "Paste",
        "monitoringaction3": "Leaving the window",
        "monitoringaction4": "Writting speed",
        "photo": "Control photo",
        "photomessage": "At the beginning of the exam we will take a selfie of the participant for future reference.",
        "photomessage2": "The photo will be deleted automatically after the correction of the exam.",
        "accept": "Accept",
        "takephoto": "Take photo",
        "allowcameramessage": "Allow access to the camera to verify your identity. Attention: if you are using Windows, check that the camera is not being used by another application before continuing.",
        "usephotomessage": "Looking good? If so, click \"Use photo\" to continue",
        "photoinfomessage": "Verify that your face is not cut and that it is well lit. To continue click on \"Take photo\"",
        "activatecamerabutton": "Activate camera",
        "camerabuttonagain": "Try again",
        "usephotobutton": "Use photo",
        "takephotobutton": "Take photo",
        "fullscreen": "Full screen required",
        "fullscreenmessage": "To perform the exam you need to activate full screen. To do this, click on the \"Activate full screen\" button.",
        "fullscreenmessage2": "Attention that you must keep the exam on full screen during the entire time of the exam. If you leave full screen, the monitor will be notified.",
        "fullscreenbutton": "Activate full screen"
      },
      "modaltimeover": {
        "autoend": "The exam will be automatically terminated.",
        "manualend": "The exam will be manually terminated by the responsible monitor.",
        "autoendmessage": "The presented time is an estimate based on the time defined for the start and end of the evaluation.",
        "message": "When the evaluation is over, your exam will be submitted automatically. If you want it not to be submitted, please click on the \"Give up\" option. If you want to submit your exam in advance, click on the \"Submit\" option.",
        "button": "OK, I understand. Continue."
      }
    },
    "pt": {
      "notitle": "Sem título",
      "running": "Em curso",
      "endbutton": "Terminar",
      "giveuptitle": "Desistir do exame",
      "giveupmessage": "Tem a certeza que quer desistir do exame?",
      "giveupcancel": "Cancelar",
      "giveupconfirm": "Sim",
      "giveupbutton": "Desistir",
      "submittitle": "Terminar exame",
      "submitmessage": "Tem a certeza que deseja terminar o exame?",
      "submitcancel": "Cancelar",
      "submitconfirm": "Sim",
      "examstartlabel": "INÍCIO DO EXAME",
      "examendlabel": "FIM DO EXAME",
      "genericerror": "Ocorreu um erro a realizar a operação",
      "errorsavingtermsandphoto": "Erro ao guardar a foto e aceitação dos termos. Por favor tente mais tarde.",
      "errorloading": "Ocorreu um erro ao carregar o formulário. Por favor, atualize a página para tentar novamente.",
      "errorloading2": "Ocorreu um erro ao carregar o formulário. Tente de novo mais tarde ou contacte o suporte.",
      "errorsubmitting": "Ocorreu um erro ao submeter o exame mas as suas respostas estão guardadas e o exame será considerado.",
      "timeovermessage1": "Faltam menos de",
      "timeovermessage2": "minuto(s) para",
      "timeovermessage3": "o exame terminar.",
      "timeovermessage4": "a duração prevista do exame terminar.",
      "quitexamtitle": "Tem a certeza que deseja sair deste exame?",
      "quitexammessage": "Se sair, poderá voltar a reentrar caso o exame ainda esteja a decorrer. Caso não desista explicitamente do exame, o mesmo será considerado automaticamente entregue no final do exame.",
      "quitexamcancel": "Cancelar",
      "quitexamconfirm": "Sair",
      "recoverbackup": "Recuperar cópia de segurança",
      "recoverbackupmessage": "Parece que tem uma cópia de segurança deste exame. Deseja recuperá-la?",
      "recoverbackupcancel": "Não",
      "recoverbackupconfirm": "Sim",
      "backuprecovered": "Cópia de segurança recuperada",
      "backuprecoveredmessage": "A cópia de segurança foi recuperada com sucesso. Vamos agora recarregar o exame.",
      "backuprecoveredconfirm": "Recarregar",
      "modalsubmit": {
        "examended": "Exame terminado",
        "examsubmited": "Exame submetido",
        "answeredquestions": "Perguntas respondidas",
        "instructions": "Carregue no botão seguinte para fechar esta janela e voltar ao contexto anterior.",
        "close": "Fechar",
        "closeandsignout": "Fechar e terminar sessão"
      },
      "modalgiveup": {
        "title": "Desistiu do exame",
        "message": "O seu exame não será considerado para a avaliação.",
        "instructions": "Carregue no botão seguinte para fechar esta janela e voltar ao contexto anterior.",
        "close": "Fechar"
      },
      "modalterms": {
        "title": "Termos de utilização",
        "wronganswers": "Respostas erradas descontam",
        "wronganswersmessage": "Atenção que selecionar uma opção errada desconta na pontuação do exame, pelo que no final poderá ter uma pontuação negativa.",
        "monitoring": "Tecnologia de monitorização",
        "monitoringmessage": "Os monitores deste exame serão notificados caso execute uma das seguintes operações:",
        "monitoringaction1": "Copiar",
        "monitoringaction2": "Colar",
        "monitoringaction3": "Sair da janela",
        "monitoringaction4": "Velocidade de escrita",
        "photo": "Verificação de identidade",
        "photomessage": "No início do exame iremos tirar uma selfie ao participante para referência posterior.",
        "photomessage2": "O registo fotográfico é automaticamente eliminado após a correção do exame.",
        "accept": "Aceito",
        "takephoto": "Tirar foto",
        "allowcameramessage": "Permita o acesso à câmara para podermos verificar a sua identidade. Atenção: se usar Windows verifique que a câmara não está a ser usada por outra aplicação antes de continuar.",
        "usephotomessage": "Parece-lhe bem? Se sim clique em \"Usar foto\" para continuar",
        "photoinfomessage" :"Verifique que a sua cara não está cortada e que está bem iluminada. Para continuar clique em \"Tirar foto\"",
        "activatecamerabutton": "Ativar câmara",
        "camerabuttonagain": "Tirar novamente",
        "usephotobutton": "Usar foto",
        "takephotobutton": "Tirar foto",
        "fullscreen": "Ecrã inteiro obrigatório",
        "fullscreenmessage": "Para realizar o exame necessita de ativar o ecrã inteiro. Para isso, clique no botão \"Ativar ecrã inteiro\".",
        "fullscreenmessage2": " Atenção que deverá manter o exame em ecrã inteiro durante todo o tempo de realização do exame. Caso saia do ecrã inteiro, o vigilante será notificado.",
        "fullscreenbutton": "Ativar ecrã inteiro"
      },
      "modaltimeover": {
        "autoend": "O exame será terminado automaticamente.",
        "manualend": "O exame será terminado pelo(a) vigilante responsável.",
        "autoendmessage": "O tempo apresentado é uma estimativa com base na hora definida para início e fim da avaliação.",
        "message": "Quando a avaliação foi terminada, o seu exame será submetido de forma automática. Se desejar que o mesmo não seja submetido, por favor, carregue na opção \"Desistir\". Caso pretenda submeter antecipadamente o seu exame, carregue na opção \"Terminar\".",
        "button": "OK, compreendo. Continuar."
      }
    }
  }
  </i18n>
