import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
//import querystring from 'querystring'
import Api from '@/fw-modules/fw-core-vue/api/Api'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
//import store from '@/store'

export default {
  base(config) {
    let api = Api(config)
    api.defaults.baseURL = FwEnvConfig.apiUrlForms
    api.defaults.quietly = true
    return api
  },

  /**
   * Forms
   */
  async getAllForms(page = null, limit = null) {
    let params = {}
    if (page !== null) {
      params['page'] = page
    }
    if (limit !== null) {
      params['limit'] = limit
    }
    const response = await this.base().get(`/v1/templates`, {
      params: params
    })
    return response.data
  },
  async getForms() {
    const response = await this.base().get('/v1/templates')

    for (let form of response.data) {
      form.created_date_obj = Dates.build(form.created_date)
    }

    response.data.sort((a, b) => {
      if (a.created_date_obj < b.created_date_obj) return 1
      else return -1
    })

    return response.data
  },
  async getForm(key) {
    const response = await this.base().get(`/v1/templates/${key}`)
    return response.data
  },
  async createForm(data) {
    const response = await this.base().post(`/v1/templates`, data)
    return response.data
  },
  async saveForm(key, data) {
    const response = await this.base().put(`/v1/templates/${key}`, data)
    return response.data
  },
  async deleteForm(key) {
    await this.base().delete(`/v1/form/${key}`)
  },
  async updateFormStatus(key, status) {
    const response = await this.base().post(`/v1/templates/${key}`, {
      state: status
    })
    return response.data
  },

  async addFormPage(formKey, title, schema = [], scores = {}, nextTo = null) {
    const response = await this.base().post(`/v1/template/${formKey}/pages`, {
      title: title,
      nextTo: nextTo,
      schema: schema,
      scores: scores
    })
    return response.data
  },

  async updateFormPage(pageKey, data) {
    const response = await this.base().post(`/v1/page/${pageKey}`, data)
    return response.data
  },

  async getPage(pageKey) {
    const response = await this.base().get(`/v1/page/${pageKey}`)
    return response.data
  },

  async deleteFormPage(pageKey) {
    const response = await this.base().delete(`/v1/page/${pageKey}`)
    return response.data
  },

  /**
   * Instances
   */
  async getAllInstances({ filterStatus = null, sortBy = null, direction = null, dashboard = false, term = null }) {
    let query = ''
    if (dashboard) {
      query = '?section=dashboard'
    } else {
      if (filterStatus !== null) {
        query += `status=${filterStatus}`
      }
      if (sortBy !== null) {
        query += (query.length > 0 ? '&' : '') + `sortBy=${sortBy}`
      }

      if (direction !== null) {
        query += (query.length > 0 ? '&' : '') + `direction=${direction}`
      }

      if (term != null) {
        query += (query.length > 0 ? '&' : '') + `query=${term}`
      }
      query = query.length > 0 ? '?' + query : ''
    }

    const response = await this.base().get(`/v1/instances${query}`)
    return response.data
  },
  async getInstance(key) {
    const response = await this.base().get(`/v1/form/${key}`)
    return response.data
  },
  async createInstance(formKeys = [], formTitle = null, formOptions = null, startDate = null, duration = 60) {
    let options = {
      logInteractions: formOptions.logInteractions ? formOptions.logInteractions : false,
      disableRightClick: formOptions.disableRightClick ? formOptions.disableRightClick : false,
      anonymous: formOptions.anonymous ? formOptions.anonymous : false
    }
    let title =
      formTitle !== null
        ? formTitle
        : {
            pt: '',
            en: ''
          }
    const response = await this.base().post(`/v1/instances`, {
      duration: duration,
      formKeys: formKeys,
      title: title,
      startDate: startDate,
      options: options,
      location: '',
      mode: 'remote'
    })
    return response.data
  },
  async updateInstance(
    instanceKey,
    formKeys = [],
    examTitle = null,
    instanceOptions = null,
    startDate = null,
    duration = 60,
    mode = 'remote',
    location = '',
    organicUnit = null
  ) {
    let options =
      instanceOptions !== null
        ? instanceOptions
        : {
            logInteractions: false,
            disableRightClick: false,
            anonymous: false,
            autoEnd: false,
            autoStart: false,
            autoStartEnd: false,
            isPublic: false
          }

    let title =
      examTitle !== null
        ? examTitle
        : {
            pt: '',
            en: ''
          }
    const response = await this.base().post(`/v1/instance/${instanceKey}`, {
      formKeys: formKeys,
      title: title,
      startDate: startDate,
      options: options,
      duration: duration,
      mode: mode,
      location: location,
      organic_unit_key: organicUnit
    })
    return response.data
  },
  async updateOwner(instanceKey, owenerKey) {
    const response = await this.base().post(`/v1/instance/${instanceKey}`, {
      user_key: owenerKey
    })
    return response.data
  },
  async publishInstance(instanceKey, setStatus = 'publish') {
    const response = await this.base().post(`/v1/instance/${instanceKey}`, {
      status: setStatus
    })
    return response.data
  },
  async startInstance(instanceKey, setStatus = 'start') {
    const response = await this.base().post(`/v1/instance/${instanceKey}`, {
      status: setStatus
    })
    return response.data
  },
  async deleteInstance(instanceKey) {
    const response = await this.base().delete(`/v1/instance/${instanceKey}`)
    return response.data
  },
  async bulkSearch(instanceKey, query) {
    const response = await this.base().get(`/v1/instance/${instanceKey}/users/bulk-search?q=${query}`)
    return response.data
  },

  /*
   * Exam buckets
   */
  async getAllExamBuckets() {
    const response = await this.base().get(`/v1/buckets`)
    return response.data
  },
  async getBucket(id) {
    const response = await this.base().get(`/v1/bucket/${id}`)
    return response.data
  },
  async createBucket(name) {
    const response = await this.base().post(`/v1/buckets`, {
      title: {
        pt: name
      }
    })
    return response.data
  },
  async deleteBucket(id) {
    const response = await this.base().delete(`/v1/bucket/${id}`)
    return response.data
  },
  async updateBucket(id, data) {
    const response = await this.base().post(`/v1/bucket/${id}`, data)
    return response.data
  },
  // ActivityAPI handler
  async getActivityLogs(payload) {
    // PAYLOAD ===
    // "service": "apply",
    // "page": 1,
    // "start_date": "2022-01-01",
    // "end_date": "2022-12-31",
    // "reference_keys": {
    // },
    // "code": "",
    // "context": "",
    // "context_key": "",
    // "by_user_key": "",
    // "by_token_key": ""
    let instanceID = payload['instanceKey']
    payload['context_key'] = instanceID
    delete payload['instanceKey']
    delete payload['service']
    //delete payload['service']
    const response = await this.base().get(
      `/v1/instance/${instanceID}/activity?service=forms&service=${decodeURIComponent(
        'ucstudent-app'
      )}&service=${decodeURIComponent('ucteacher-app')}`,
      { params: payload }
    )
    return response.data
  },

  async getNotifications(instanceKey, limit = 50) {
    let payload = { limit: limit }
    const response = await this.base().get(`/v1/instance/${instanceKey}/notifications`, { params: payload })
    return response.data
  },

  /**
   * Instances Permissions
   */
  async getInstancePermissions(instanceKey) {
    const response = await this.base().get(`/v1/instance/${instanceKey}/permissions`)
    return response.data
  },
  async addInstancePermissions(instanceKey, email = '', permissions = {}) {
    let permissionData = { ...permissions }
    permissionData['email'] = email
    const response = await this.base().post(`/v1/instance/${instanceKey}/permissions`, permissionData)
    return response.data
  },
  async addInstancePermissionsByUserkey(instanceKey, userKeys = [], permissions = {}) {
    let permissionData = { ...permissions }
    permissionData['user_keys'] = userKeys
    const response = await this.base().post(`/v1/instance/${instanceKey}/permissions`, permissionData)
    return response.data
  },
  async updateInstancePermissions(instanceKey, userKey = '', permissions = {}) {
    const response = await this.base().post(`/v1/instance/${instanceKey}/permissions/${userKey}`, permissions)
    return response.data
  },
  async deleteInstancePermissions(instanceKey, userKey = '') {
    await this.base().delete(`/v1/instance/${instanceKey}/permissions/${userKey}`)
  },

  /**
   * Answers Endpoint
   */

  async saveAnswer(instanceKey, answers, submit = false) {
    let response
    response = await this.base().put(`/v1/instances/${instanceKey}/answers?check=` + submit, answers)
    if (submit) {
      if (response.data['__errors__'] && response.data['__errors__'].length > 0) {
        console.error(response.data['__errors__'])
        return response.data
      }
      /*await this.base().post(`/v1/instances/${instanceKey}/answers`, {
        status: 'close',
      })*/
    }
    return response.data
  },
  async getAnswers(instanceKey) {
    const response = await this.base().get(`/v1/instances/${instanceKey}/answers`)
    return response.data
  }
}
