<template>
  <LayoutDashboardDefault dark>
    <div class="flex-1 h-full flex gap-5">
      <div class="flex flex-col h-full flex-1 gap-5">
        <div v-if="instance && instance.mode == 'presential'" class="flex">
          <fw-button :type="tab == 'people' ? 'tab-active' : 'tab'" @click.native="tab = 'people'">
            <span
              :class="{
                'text-white': tab != 'people'
              }"
            >
              Participantes
            </span>
          </fw-button>
          <fw-button :type="tab == 'devices' ? 'tab-active' : 'tab'" @click.native="tab = 'devices'">
            <span
              :class="{
                'text-white': tab != 'devices'
              }"
            >
              Dispositivos
            </span>
          </fw-button>
          <fw-button :type="tab == 'accesses' ? 'tab-active' : 'tab'" @click.native="tab = 'accesses'">
            <span
              :class="{
                'text-white': tab != 'accesses'
              }"
            >
              Acessos por PIN
            </span>
          </fw-button>
        </div>
        <PanelDahsboardParticipants
          v-if="instance && !waitingForUsers && !loading && tab === 'people'"
          class="flex-1 min-h-0"
          :answers="answers"
          :versions="versions"
          :versions-map="versionsMap"
          @open-user="openUserModal($event)"
        ></PanelDahsboardParticipants>
        <PanelDashboardDevices
          v-if="instance && !waitingForUsers && !loading && tab === 'devices'"
          :instance="instance"
        ></PanelDashboardDevices>
        <PanelDashboardAccesses
          v-if="instance && !waitingForUsers && !loading && tab === 'accesses'"
          :instance="instance"
        ></PanelDashboardAccesses>
      </div>
      <PanelDashboardActivity
        v-if="instance && !waitingForUsers && !loading"
        :activity="activity"
        :users="users"
        :main-activity-codes="mainActivityCodes"
        @open-user="openUserModal($event)"
      ></PanelDashboardActivity>
      <div
        v-if="instance && waitingForUsers && !loading"
        class="rounded-3xl flex-1 bg-panel p-5 flex flex-col justify-center items-center"
      >
        <fw-icon-rest class="color-icon-panel h-12 w-12 mb-2"></fw-icon-rest>
        <div class="text-title text-sm">
          {{ $t('examNotOpen') }}
        </div>
      </div>
    </div>
    <PanelDashboardControls
      v-if="instance && !loading"
      :instance="instance"
      :answers="answers"
      :versions="versions"
      :language="language"
      :remaining-time="remainingTime"
      :loading="loadingRefresh"
      @refresh="loadAllData"
    ></PanelDashboardControls>

    <LoadingPage v-if="loading" :dark="true" icon="exam" title="Dashboard Live"></LoadingPage>
    <div v-if="false" class="flex flex-col items-center  text-center justify-center w-full h-full text-gray-200">
      <div class="text-2xl font-bold">{{ $t('loading') }}</div>
    </div>
    <div
      v-else-if="instance == null && !loading"
      class="flex flex-col items-center  text-center justify-center w-full h-full text-gray-200"
    >
      <div class="text-2xl font-bold">{{ $t('error') }}</div>
    </div>
    <ModalDashboardUser
      :show-user-modal="showUserModal"
      :user-answer="userModalData"
      :versions="versions"
      :versions-map="versionsMap"
      :user-activity="userActivity"
      :users="users"
      @close="closeUserModal"
      @revoke="revokeDevice"
    ></ModalDashboardUser>
  </LayoutDashboardDefault>
</template>

<script>
import FormServices from '@/fw-modules/fw-core-vue/exams/services/ServiceExams'
import PanelDahsboardParticipants from '../components/panels/PanelDahsboardParticipants.vue'
import PanelDashboardDevices from '../components/panels/PanelDashboardDevices.vue'
import PanelDashboardAccesses from '../components/panels/PanelDashboardAccesses.vue'
import PanelDashboardActivity from '../components/panels/PanelDashboardActivity.vue'
import PanelDashboardControls from '../components/panels/PanelDashboardControls.vue'
import LoadingPage from '@/fw-modules/fw-core-vue/ui/components/loading/LoadingPage'
import ModalDashboardUser from '@/fw-modules/fw-core-vue/exams/components/modals/ModalDashboardUser'
import LayoutDashboardDefault from '@/fw-modules/fw-core-vue/ui/components/layouts/LayoutDashboardDefault'
import Vue from 'vue'
export default {
  name: 'ViewExamDashboardLive',
  components: {
    PanelDahsboardParticipants,
    PanelDashboardActivity,
    PanelDashboardControls,
    LoadingPage,
    ModalDashboardUser,
    LayoutDashboardDefault,
    PanelDashboardDevices,
    PanelDashboardAccesses
  },
  data() {
    return {
      instance: null,
      remainingTime: 0, //we use this for a live update of the remaining time, the PanelDashboardControls will update the instance.remainingTime
      permissions: null,
      users: null,
      loading: true,
      loadingRefresh: false,
      answers: [],
      activity: [],
      language: 'pt',
      versions: [],
      pendingActivityMessages: [],
      versionsMap: {}, //here we store the index for each formID
      //activity that should be shown in the main activity section
      mainActivityCodes: FormServices.activityMainCodes(),
      alarmActivityCodes: FormServices.activityAlarmCodes(),
      activityMessages: FormServices.activityMessages(),
      userModalKey: null, //data to show in the modal
      screenHeight: 500,
      tab: 'people'
    }
  },
  computed: {
    showUserModal() {
      return this.userModalKey !== null
    },
    userModalData() {
      return this.userModalKey !== null ? this.answers.find(answer => answer.user.key == this.userModalKey) : null
    },
    userActivity() {
      return this.activity.filter(activity => activity.by_user_key == this.userModalKey)
    },
    waitingForUsers() {
      return this.instance.status == 'waiting' || this.instance.status == 'scheduled' || this.instance.status == 'draft'
    },
    socketId() {
      return this.$store.state.socket.connectionId
    },
    instanceID() {
      return this.$route.params.id ? this.$route.params.id : null
    },
    examWSMessages() {
      return this.$store.state.session.unreadExamWsMessages
    }
  },
  watch: {
    // whenever question changes, this function will run
    socketId(newSocketId) {
      if (newSocketId != null) {
        this.subscribeExam()
      }
    },
    examWSMessages(newMessages) {
      if (this.realtimeDebouncer == null && newMessages.length > 0 && this.instanceID) {
        this.realtimeDebouncer = setTimeout(() => {
          console.log('examWSMessages changed', newMessages)
          //change user state or number of answers
          //if not found, load answers again
          let userfound = true
          let updateUsers = false
          for (let index = 0; index < newMessages.length; index++) {
            const message = newMessages[index]
            console.log('newMessage', message)
            if (message.type == 'updateStatus' && message.user_key) {
              if (message.status !== 'noAnswer') {
                let user = this.answers.find(x => x.user.key == message.user_key)
                if (user) {
                  user.state = message.status == 'close' ? 'closed' : message.status
                } else {
                  userfound = false
                }

                if (message.user && !userfound) {
                  userfound = true
                  //add to user map
                  this.users[message.user_key] = message.user
                  let formKey = message.formKey

                  //add to answers (if it doesn't exist)
                  let answerExists = this.answers.find(x => x.user.key == message.user_key)
                  if (!answerExists) {
                    this.answers.push({
                      acceptedTermsDate: null,
                      answers: null,
                      closed_date: null,
                      formKey: formKey,
                      grades: null,
                      photo: null,
                      started_date: null,
                      state: message.status,
                      summary: {
                        answers: 0,
                        correctAnswers: 0,
                        scaleScore: null,
                        score: null,
                        state: 'needs_manual_correction',
                        uncheckedAnswers: null
                      },
                      user: message.user
                    })
                  }
                }

                if (
                  message.status == 'close' ||
                  message.status == 'reopen' ||
                  message.status == 'open' ||
                  message.status == 'withdraw'
                ) {
                  updateUsers = true
                  this.createActivityMessage(message.status, message.user_key)
                }
              }
            } else if (message.type == 'instanceActivity' && message.instance_key == this.instanceID) {
              if (message.user_key) {
                console.log('ANTI-CHEAT MESSAGE')
                this.createActivityMessage(message.code, message.user_key)
                if (this.alarmActivityCodes.includes(message.code)) {
                  let msg = this.activityMessages['frontend'][message.code][this.language]
                  this.activateUserAlarm(message.user_key, msg)
                }

                if (message.code === 'exam-selfie-taken' || message.code === 'exam-use-terms-accepted') {
                  updateUsers = true
                }
              }
            } else if (message.type == 'instanceStatus' && message.instance_key == this.instanceID) {
              if (message.remaining_time != null) {
                console.log('updating time left')
                this.remainingTime = message.remaining_time
              }
            } else if (message.type == 'instanceUpdate' && message.instance_key == this.instanceID) {
              //ignore status that we don't want
              if (
                message.status === 'lock_answers' ||
                message.status === 'unlock_answers' ||
                message.status === 'publish' ||
                message.status === 'unpublish'
              )
                return
              //we don't want to update the status if it's a lock_answers message
              this.instance.status = message.status == 'stop' ? 'ended' : 'running'
              if (message.remaining_time != null) {
                console.log('updating time left')
                this.remainingTime = message.remaining_time
              }
            } else if (message.type == 'newPage') {
              //page loaded
              let user = this.answers.find(x => x.user.key == message.user_key)
              if (user) {
                user.state = 'answering'
              } else {
                userfound = false
              }
              //TODO: update stats
            } else if (message.type == 'awnserUpdate') {
              let numberQuestions = 0
              let questionsKeys = Object.keys(message.page_awnser.data)
              for (let index = 0; index < questionsKeys.length; index++) {
                const element = message.page_awnser.data[questionsKeys[index]]
                if (element && element.length > 0) {
                  numberQuestions++
                }
              }
              console.log('Number questions', numberQuestions)
              let user = this.answers.find(x => x.user.key == message.user_key)
              if (user) {
                user['summary']['answers'] = numberQuestions
              } else {
                userfound = false
              }
              /*
              Answers stats update
              */
              let versionIndex = this.versionsMap[message.awnser.form_key]
              let user_key = message.awnser.user_key
              let questionMap = this.versions[versionIndex].questionIdMap
              //Update stats count for each answer question
              Object.keys(message.page_awnser.data).forEach(questionID => {
                let questionStat = this.versions[versionIndex].statsQuestiosn[questionMap[questionID]]
                if (!questionStat.user_keys.includes(user_key)) {
                  Vue.set(
                    this.versions[versionIndex].statsQuestiosn[questionMap[questionID]],
                    'count',
                    this.versions[versionIndex].statsQuestiosn[questionMap[questionID]].count + 1
                  )
                  this.versions[versionIndex].statsQuestiosn[questionMap[questionID]].user_keys.push(user_key)
                }
              })
            }
          }
          this.$store.commit('removeFromExamMessageQueue', newMessages.length)
          if (!userfound || updateUsers) {
            //we don't have info of the user, so we need to reload all answers
            this.loadAnswers()
          }
          this.realtimeDebouncer = null
        }, 1000)
      }
    }
  },
  beforeDestroy() {
    this.unsubscribeExam()
    window.removeEventListener(
      'resize',
      function() {
        this.screenHeight = window.innerHeight
      },
      true
    )
  },
  mounted() {
    this.screenHeight = window.innerHeight
    let self = this
    window.addEventListener(
      'resize',
      function() {
        self.screenHeight = window.innerHeight
      },
      true
    )
    this.subscribeExam()
    this.loadAllData()
  },
  methods: {
    revokeDevice() {
      const userKey = this.userModalKey
      this.$buefy.dialog.confirm({
        title: 'Confirmação de revogação de dispositivo',
        message:
          'Tem a certeza que pretende revogar o dispositivo deste utilizador? Após esta ação o utilizador poderá' +
          'entrar no exame com qualquer dispositivo.',
        cancelText: 'Cancelar',
        confirmText: 'Confirmar',
        type: 'is-danger',
        onConfirm: async () => {
          await FormServices.revokeDevice(this.instanceID, userKey)
          //remove device from answers
          let answer = this.answers.find(answer => answer.user.key == userKey)
          if (answer) {
            answer.device = null
          }
          this.closeUserModal()
        }
      })
    },
    activateUserAlarm(userKey, reason = '') {
      let found = this.answers.find(answer => answer.user.key == userKey)
      if (found) {
        found.alarm = true
        found.alarm_reason = reason
      }
    },
    openUserModal(userKey) {
      this.userModalKey = userKey
    },
    closeUserModal() {
      this.userModalKey = null
    },
    createActivityMessage(code, userKey) {
      let userInfo = this.users[userKey]
      if (userInfo) {
        console.log('activity userinfo', userInfo)
        this.activity.unshift({
          code: code,
          user: userInfo.user,
          by_user_key: userKey,
          created_date: new Date().toISOString(),
          key: Math.random()
            .toString(36)
            .substring(7),
          context: 'answer'
        })
      } else {
        if (!this.pendingActivityMessages.includes({ code: code, userKey: userKey })) {
          this.pendingActivityMessages.push({ code: code, userKey: userKey })
        }
      }
    },
    //this should be executed after updating the users list
    processPendingActivityMessages() {
      for (let index = 0; index < this.pendingActivityMessages.length; index++) {
        const message = this.pendingActivityMessages[index]
        this.createActivityMessage(message.code, message.userKey)
      }
      this.pendingActivityMessages = []
    },
    async loadAllData() {
      try {
        this.loadingRefresh = true
        await this.loadDashboard()
        this.structureVersionsData()
        //await this.loadExamInstance()
        await this.loadAnswers()
        this.loadActivity()
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
        this.loadingRefresh = false
      }
    },
    async loadActivity() {
      let result = await FormServices.getActivityLogs({
        instanceKey: this.instanceID,
        sort: 'created_date',
        direction: 'desc'
      })
      this.activity = result.logs
    },
    async loadAnswers() {
      if (this.instance.status != 'draft') {
        console.log('loading answers')
        let result = await FormServices.getAnswers(this.instanceID, true, null, 'asc', null, [])
        result.answers.sort((a, b) =>
          a.user.name.toLowerCase() > b.user.name.toLowerCase()
            ? 1
            : b.user.name.toLowerCase() > a.user.name.toLowerCase()
            ? -1
            : 0
        )
        //verify if the user info its in the users data
        if (this.users === null) this.users = {}
        result.answers.forEach(answer => {
          if (!this.users[answer.user.key]) {
            Vue.set(this.users, answer.user.key, answer.user)
          }
        })
        this.answers = result.answers
        if (this.pendingActivityMessages.length > 0) {
          this.processPendingActivityMessages()
        }
      }
    },
    structureVersionsData() {
      this.versions = this.instance.forms.map((form, f) => {
        //for quick access to the array in live events
        this.versionsMap[form.key] = f
        let sumSections = 0
        let sumQuestions = 0

        let statsSections = []
        let questionIdMap = {}

        let stats = Object.keys(this.instance['metrics']['form_questions'][form.key]).map((key, k) => {
          let stat = this.instance['metrics']['form_questions'][form.key][key]
          let splitKey = key.split('.')
          //section + question number
          stat['relativeRef'] = key
          stat['section'] = splitKey[0]
          stat['question'] = splitKey[1]
          questionIdMap[stat['key']] = k
          let sectionIndex = parseInt(stat['section']) - 1 //sections start at 1 and we want to convert to the array index
          if (statsSections[sectionIndex] == null || typeof statsSections[sectionIndex] === 'undefined') {
            //create section stats
            statsSections[sectionIndex] = {
              section: sectionIndex + 1,
              count: stat.count,
              user_keys: stat.user_keys ?? []
            }
          } else {
            //update section stats
            statsSections[sectionIndex].count += stat.count
            //remove duplicates from user_keys
            statsSections[sectionIndex].user_keys = [
              ...new Set([...statsSections[sectionIndex].user_keys, ...(stat.user_keys ?? [])])
            ]
          }
          return stat
        })
        form.pages.forEach(page => {
          sumSections += page.sections.length
          page.sections.forEach(section => {
            sumQuestions += section.questionCount
          })
        })

        //create stats
        let version = {
          letter: form.letter,
          key: form.key,
          title: form.title,
          availableLanguages: form.availableLanguages,
          numSections: sumSections,
          numQuestions: sumQuestions,
          statsType: sumSections > 1 ? 'sections' : 'questions',
          statsQuestiosn: stats,
          statsSections: statsSections,
          questionIdMap: questionIdMap
        }
        return version
      })
    },
    async subscribeExam() {
      if (this.$store.state.socket && this.$store.state.socket.connectionId) {
        let connectionId = this.$store.state.socket.connectionId
        let subscriptionData = await FormServices.subscribeInstance(connectionId, this.instanceID)
        console.log('WS exam subscription data', subscriptionData)
      } else {
        console.error('No socket connection')
      }
    },
    async unsubscribeExam() {
      if (this.$store.state.socket && this.$store.state.socket.connectionId) {
        let connectionId = this.$store.state.socket.connectionId
        let subscriptionData = await FormServices.unsubscribeInstance(connectionId, this.instanceID)
        console.log('WS exam unsubscription data', subscriptionData)
      } else {
        console.error('No socket connection')
      }
    },
    async loadDashboard() {
      let result = await FormServices.getDashboardData(this.instanceID)
      console.log('DASHBOARD DATA', result)
      //order versions, to be sure we have the same versions letters
      result.forms.sort((a, b) => {
        return a.index - b.index
      })
      this.permissions = result.permissions
      this.users = result.users
      delete result.users
      delete result.permissions
      this.instance = result
    }
    /*async loadExamInstance() {
      let instance = await FormServices.getInstance(this.instanceID)
      this.instance = instance
      this.permissions = instance.permissions
      this.users = instance.users
      delete this.instance.users
      delete this.instance.permissions
      if (this.instance.status == 'running') {
        this.startTimer()
      }
    },*/
  }
}
</script>

<i18n>
  {
    "pt": {
      "examNotOpen": "O exame ainda não foi aberto a participantes.",
      "loading": "A carregar...",
      "error": "Ocorreu um erro ao carregar o exame"
    },
    "en": {
      "examNotOpen": "The exam was not open yet.",
      "loading": "Loading...",
      "error": "There was an error loading the exam"
    }
  }
  </i18n>
