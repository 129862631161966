<template>
  <section>
    <fw-heading size="h1">Configurações</fw-heading>
    <fw-panel title="Metadados" class="my-5" boxed="sm" custom-class="flex flex-col gap-5">
      <div>
        <fw-label>Nome do espaço</fw-label>
        <template v-if="editable">
          <TextInput
            v-model="title"
            :minlength="2"
            :maxlength="150"
            :class="{
              error: $v.title.$error
            }"
          />
          <fw-tip v-if="$v.title.$error" error>
            <span v-if="!$v.title.min">Insira no mínimo 2 caracteres</span>
            <span v-if="!$v.title.max">Insira no máximo 150 caracteres</span>
            <span v-else>Insira o nome do espaço</span>
          </fw-tip>
        </template>
        <fw-panel v-else>{{ course.title }}</fw-panel>
      </div>
      <div v-if="editable">
        <fw-button type="primary" @click.native="saveMetadata()">
          Guardar
        </fw-button>
      </div>
    </fw-panel>
  </section>
</template>

<script>
import TextInput from '@/fw-modules/fw-core-vue/ui/components/form/TextInput'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'PanelManageCourseSettings',

  components: {
    TextInput
  },

  props: {
    course: Object,
    editable: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      title: this.course.title
    }
  },

  validations: {
    title: {
      required,
      min: minLength(2),
      max: maxLength(150)
    }
  },

  methods: {
    saveMetadata() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      this.course.title = this.title
      this.$emit('save', this.course)
    }
  }
}
</script>
