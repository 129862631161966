<template>
  <fw-panel title="Estudantes" featured :counter="studentsFiltered.length" class="p-5">
    <div v-if="!studentsSearchOpen && students && students.length > 10" class="opacity-50 hover:opacity-90">
      <fw-button
        class="flex items-center gap-2"
        type="link-muted"
        @click.native="studentsSearchOpen = !studentsSearchOpen"
      >
        <fw-icon-search class="w-5 h-5 mr-2" /> Pesquisar
      </fw-button>
    </div>
    <div v-if="studentsSearchOpen || searchQuery.length" class="pb-2">
      <b-input v-model="searchQuery" placeholder="Pesquisar..." autofocus type="search"></b-input>
    </div>
    <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6 gap-2">
      <fw-card v-for="student in studentsFiltered" :key="student.key" no-clamp no-link :title="student.user.full_name">
        <template #header>
          <fw-avatar size="md" :user="student.user" />
        </template>
        <template #main>
          <div class="text-sm text-gray-500">{{ student.user.number }}</div>
          <div class="flex gap-3 items-center justify-center relative py-2">
            <fw-badge inline>{{ student.presences }}</fw-badge>
            <fw-badge inline color="red">{{ student.absences }}</fw-badge>
          </div>
        </template>
        <template v-if="student.user.chat" #footer>
          <fw-button
            label="Abrir canal de conversação"
            :type="student.user.chat.unread ? 'light-primary' : 'light'"
            class="flex gap-2 items-center justify-center relative"
            @click.native="$emit('go-to-user-chat', student.user)"
          >
            <span>Conversa</span>
            <fw-icon-chats class="w-5 h-5" />
            <fw-badge v-if="student.user.chat.unread" inline color="primary">{{ student.user.chat.unread }}</fw-badge>
          </fw-button>
        </template>
      </fw-card>
    </div>
  </fw-panel>
</template>

<script>
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  props: {
    view: {
      type: String,
      default: 'liveclasses'
    },
    students: {
      type: Array
    }
  },

  data() {
    return {
      searchQuery: '',
      studentsSearchOpen: false
    }
  },

  computed: {
    studentsFiltered() {
      if (this.searchQuery) {
        const find = utils.existsInSearchFactory(this.searchQuery)
        return this.students.filter(student => {
          return find(student.user.full_name)
        })
      }
      return this.students
    }
  }
}
</script>
