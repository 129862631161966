<template>
  <div
    class="flex gap-5 p-3 items-center cursor-pointer exam-record hover:bg-gray-100"
    :class="{ 'border-b  border-gray-100': !last }"
  >
    <!-- Title -->
    <div class="flex-1">
      <div class="flex gap-2 text-left items-center">
        <fw-icon-arrow-right class="text-gray-400 h-5 w-5 item-arrow" />
        <div>
          <v-clamp
            v-if="form.title != null && form.title.length > 0"
            autoresize
            :max-lines="1"
            class="text-black font-semibold "
          >
            {{ form.title }}
          </v-clamp>
          <div v-else class="text-gray-400">Sem título</div>
        </div>
      </div>
      <div class="text-xs text-gray-500 flex gap-2 ml-7">
        <div>Criado em {{ form.created_date | formatDateTime }}</div>
      </div>
    </div>
    <!-- Status -->
    <div class="flex gap-1">
      <fw-tag v-if="form.status == 'published'" type="primary">
        Publicado
      </fw-tag>
      <fw-tag v-else type="orange">
        Rascunho
      </fw-tag>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RecordForm',
  //components: { TextStatus },
  props: {
    language: {
      type: String,
      default: 'pt'
    },
    form: {
      type: Object
    },
    last: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style scoped>
.exam-record .item-arrow {
  @apply transition-all;
}
.exam-record:hover .item-arrow {
  transform: translateX(3px);
}
</style>
