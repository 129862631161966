<template>
  <div>
    <LoadingPlaceholder v-if="loading.groups" />
    <ExpandList
      v-else
      add-title="Adicionar grupo"
      :show-add-items="canEdit"
      :disabled="!canEdit"
      empty-title="Turma sem grupos."
      :empty="!groups.length"
      @add-element="openModal('create-group')"
    >
      <template #list>
        <div v-for="group in groups" :key="group.key">
          <RecordGroup
            :data="group"
            :editable="canEdit"
            :users="users"
            @edit-students="openModal('choose-group-students', group)"
          >
            <template v-if="canEdit" #end>
              <fw-menu-more>
                <b-dropdown-item paddingless aria-role="listitem">
                  <fw-button type="dropdown-item" label="Apagar" class="w-full" @click.native="deleteGroup(group)">
                    Apagar grupo
                  </fw-button>
                </b-dropdown-item>
              </fw-menu-more>
            </template>
          </RecordGroup>
        </div>
      </template>
    </ExpandList>

    <fw-modal v-if="isModalActive" :active.sync="isModalActive" :can-cancel="true" size="min" @close="closeModal">
      <ModalNewClassGroup v-if="modalType == 'create-group'" @save="createGroup" @cancel="closeModal()" />
      <ModalChooseGroupStudents
        v-else-if="modalType == 'choose-group-students' && selectedGroup"
        :key="selectedGroup.key"
        :class-key="classKey"
        :edition-key="edition.key"
        :course-key="course.key"
        :students="selectedGroup.students"
        :group="selectedGroup"
        :users="users"
        :show-remove="true"
        @close="closeModal()"
        @add="addToGroup(selectedGroup, $event)"
        @remove="deleteFromGroup(selectedGroup, $event)"
      ></ModalChooseGroupStudents>
    </fw-modal>
  </div>
</template>

<script>
import ExpandList from '@/fw-modules/fw-core-vue/ui/components/lists/ExpandList'
import ModalNewClassGroup from '@/fw-modules/fw-core-vue/courses/components/modals/manage/ModalNewClassGroup'
import ModalChooseGroupStudents from '@/fw-modules/fw-core-vue/courses/components/modals/manage/ModalChooseGroupStudents'
import RecordGroup from '@/fw-modules/fw-core-vue/courses/components/records/RecordGroup'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import ServiceCourses from '@/fw-modules/fw-core-vue/courses/services/ServiceCourses'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    ModalNewClassGroup,
    ModalChooseGroupStudents,
    LoadingPlaceholder,
    ExpandList,
    RecordGroup
  },

  props: {
    course: {
      type: Object,
      required: true
    },
    edition: {
      type: Object,
      required: true
    },
    classKey: {
      type: String,
      required: true
    },
    canEdit: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      loading: {
        groups: false
      },
      groups: [],
      users: {},
      isModalActive: false,
      modalType: null,
      selectedGroup: null
    }
  },

  computed: {
    courseKey() {
      return this.course.key
    },

    editionKey() {
      return this.edition.key
    }
  },

  created() {
    this.loadGroups()
  },

  methods: {
    async loadGroups() {
      this.loading.groups = true

      await utils.tryAndCatch(this, async () => {
        const response = await ServiceCourses.getClassGroups(this.courseKey, this.editionKey, this.classKey, true)
        console.log('getUnits :>> ', response)
        this.groups = response.groups
        this.users = response.users
      })

      this.loading.groups = false
    },

    async createGroup(payload) {
      await utils.tryAndCatch(this, async () => {
        const response = await ServiceCourses.addClassGroups(this.courseKey, this.editionKey, this.classKey, payload)
        console.log('getUnits :>> ', response)
        this.groups.push(response)

        this.closeModal()
      })
    },

    async deleteGroup(group) {
      await utils.tryAndCatch(this, async () => {
        const response = await ServiceCourses.deleteClassGroup(
          this.courseKey,
          this.editionKey,
          this.classKey,
          group.key
        )
        console.log('getUnits :>> ', response)
        this.groups = this.groups.filter(el => el.key !== group.key)
      })
    },

    async addToGroup(group, userkeys) {
      console.log('addToGroup :>> ', group, userkeys)
      await utils.tryAndCatch(this, async () => {
        const response = await ServiceCourses.addClassGroupUsers(
          this.courseKey,
          this.editionKey,
          this.classKey,
          group.key,
          userkeys
        )
        console.log('addClassGroupUsers :>> ', response)
        group.students = [...(group?.students ?? []), ...userkeys]
        this.users = { ...this.users, ...response.users }
      })
    },

    async deleteFromGroup(group, userkey) {
      console.log('group, userkeys :>> ', group, userkey)
      await utils.tryAndCatch(this, async () => {
        const response = await ServiceCourses.deleteClassGroupUsers(
          this.courseKey,
          this.editionKey,
          this.classKey,
          group.key,
          userkey
        )
        group.students = group.students.filter(el => el !== userkey)
        console.log('deleteClassGroupUsers :>> ', response)
      })
    },

    closeModal() {
      this.isModalActive = false
      this.modalType = null
    },

    openModal(type, selectedGroup = null) {
      this.isModalActive = true
      this.modalType = type
      this.selectedGroup = selectedGroup
    }
  }
}
</script>
