var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-1 rounded-3xl bg-panel p-5 flex gap-2 flex-col flex-shrink-0 select-none"},[_c('div',{staticClass:"text-sm text-title font-semibold flex-shrink-0"},[_vm._v(_vm._s(_vm.$t('participants')))]),_c('div',{staticClass:"flex flex-shrink-0 gap-3 text-sm"},[_c('div',{staticClass:"color-inactive-tab font-bold"},[_vm._v(" "+_vm._s(_vm.$t('results'))+" ")]),_c('div',{staticClass:"cursor-pointer whitespace-nowrap",class:{
        'text-primary font-semibold': _vm.usersFilter === 'all',
        'color-inactive-tab': _vm.usersFilter !== 'all'
      },on:{"click":function($event){return _vm.changeUsersFilter('all')}}},[_vm._v(" "+_vm._s(_vm.$t('all'))+" ")]),_c('div',{staticClass:"cursor-pointer whitespace-nowrap",class:{
        'text-primary font-semibold': _vm.usersFilter === 'answering',
        'color-inactive-tab': _vm.usersFilter !== 'answering'
      },on:{"click":function($event){return _vm.changeUsersFilter('answering')}}},[_vm._v(" "+_vm._s(_vm.$t('running'))+" ")]),_c('div',{staticClass:"cursor-pointer whitespace-nowrap",class:{
        'text-primary font-semibold': _vm.usersFilter === 'closed',
        'color-inactive-tab': _vm.usersFilter !== 'closed'
      },on:{"click":function($event){return _vm.changeUsersFilter('closed')}}},[_vm._v(" "+_vm._s(_vm.$t('submited'))+" ")]),_c('div',{staticClass:"cursor-pointer whitespace-nowrap",class:{
        'text-primary font-semibold': _vm.usersFilter === 'withdraw',
        'color-inactive-tab': _vm.usersFilter !== 'withdraw'
      },on:{"click":function($event){return _vm.changeUsersFilter('withdraw')}}},[_vm._v(" "+_vm._s(_vm.$t('giveup'))+" ")]),_c('div',{staticClass:"cursor-pointer whitespace-nowrap",class:{
        'text-primary font-semibold': _vm.usersFilter === 'noAnswer',
        'color-inactive-tab': _vm.usersFilter !== 'noAnswer'
      },on:{"click":function($event){return _vm.changeUsersFilter('noAnswer')}}},[_vm._v(" "+_vm._s(_vm.$t('noanswer'))+" ")])]),_c('div',{staticClass:"flex flex-1 overflow-hidden"},[_c('div',{staticClass:"transition-all flex flex-col justify-center gap-3 text-white font-semibold",style:({
        width: _vm.usersOveflow ? '50px' : '0px',
        opacity: _vm.usersOveflow ? '1' : '0'
      })},_vm._l((26),function(index){return _c('div',{key:'alphabet_' + index,staticClass:"flex gap-2 text-alphabet items-center hover:text-white group cursor-pointer",class:{
          'opacity-20 cursor-not-allowed': _vm.existingLetters.indexOf(String.fromCharCode(64 + index)) === -1
        },on:{"click":function($event){_vm.scrollToLetter(String.fromCharCode(64 + index))}}},[_c('div',{staticClass:"h-3 w-3 rounded-full bg-alphabet-ball group-hover:bg-white"}),_vm._v(" "+_vm._s(String.fromCharCode(64 + index))+" ")])}),0),(_vm.filteredUsers.length > 0)?_c('div',{staticClass:"flex-1 relative"},[_c('div',{ref:"answerslist",staticClass:"h-full overflow-y-scroll flex flex-col gap-3 justify-start rounded-lg hide-scrollbar"},_vm._l((_vm.filteredUsers),function(answer){return _c('AnswerCard',{key:answer.key,attrs:{"answer":answer,"versions":_vm.versions,"versions-map":_vm.versionsMap},nativeOn:{"click":function($event){return _vm.openUser(answer)}}})}),1),(_vm.usersOveflow)?_c('div',{staticClass:"absolute bottom-0 left-0 right-0 h-10 dashboard-overflow-gradient"}):_vm._e()]):_c('div',{staticClass:"flex-1 flex flex-col justify-center items-center"},[_c('fw-icon-rest',{staticClass:"color-icon-panel h-12 w-12 mb-2"}),_c('div',{staticClass:"text-title text-sm"},[_vm._v(" "+_vm._s(_vm.usersFilter === 'answering' ? _vm.$t('noanswers_answering') : _vm.usersFilter === 'closed' ? _vm.$t('noanswers_closed') : _vm.usersFilter === 'withdraw' ? _vm.$t('noanswers_withdraw') : _vm.usersFilter === 'noAnswer' ? _vm.$t('noanswers_noanswer') : _vm.$t('noanswers'))+" ")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }