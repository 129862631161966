<template>
  <fw-panel :title="title" :subtitle="subtitle" class="add-users-modal m-4">
    <b-tabs v-model="activeTab">
      <b-tab-item label="Pesquisar" value="search">
        <ChoosePeopleModal
          v-bind="parsedProps.choosePeople"
          custom-class="!p-2"
          :show-header="true"
          v-on="$listeners"
        />
      </b-tab-item>

      <b-tab-item label="Lista" value="bulk">
        <ModalImportBulkUsers
          v-bind="parsedProps.importBulk"
          custom-class="!p-2"
          :show-header="true"
          v-on="$listeners"
        />
      </b-tab-item>
    </b-tabs>
  </fw-panel>
</template>

<script>
import ChoosePeopleModal from '@/fw-modules/fw-core-vue/ui/components/modals/ChoosePeopleModal.vue'
import ModalImportBulkUsers from '@/fw-modules/fw-core-vue/ui/components/modals/ModalImportBulkUsers.vue'

export default {
  components: {
    ChoosePeopleModal,
    ModalImportBulkUsers
  },

  props: {
    title: {
      type: String,
      default: 'Adicionar utilizadores'
    },
    subtitle: {
      type: String
    }
  },

  data() {
    return {
      activeTab: 0
    }
  },

  computed: {
    parsedProps() {
      const result = {
        importBulk: {},
        choosePeople: {}
      }
      for (const [key, value] of Object.entries(this.$attrs)) {
        if (key.startsWith('bulk')) {
          result.importBulk[key.replace('bulk-', '')] = value
        } else {
          result.choosePeople[key] = value
        }
      }

      return result
    }
  }
}
</script>

<style>
.add-users-modal .b-tabs .tab-content {
  padding: 10px 0 0 0;
}
</style>
