<template>
  <section>
    <fw-panel :title="$t('notifications')" featured>
      <template #toolbar>
        <b-dropdown v-if="showExportToolbar" aria-role="list" position="is-bottom-left" class="paddingless">
          <fw-button-dropdown slot="trigger" type="xlight" class="flex flex-col" :label="$t('export')" />
          <b-dropdown-item class="w-56" custom aria-role="menuitem">
            <fw-button type="link" :disabled="loadingExportToCSV" @click.native="exportResults">
              <template #icon>
                <div class="w-5 h-5">
                  <fw-icon-loading v-if="loadingExportToCSV" class="flex-shrink-0 w-4 h-4" />
                  <fw-icon-download v-else class="flex-shrink-0 w-5 h-5" />
                </div>
              </template>
              {{ $t('exportCSV') }}
            </fw-button>
          </b-dropdown-item>
        </b-dropdown>
      </template>
      <ContextualSearch
        :loading="loading"
        :filter-options="filters"
        :order-by-options="orderBy"
        :applied-filters="appliedFilters"
        :applied-sort="orderByValue"
        :applied-sort-direction="orderDirection"
        :start-period="startPeriod"
        :end-period="endPeriod"
        :show-time-period="true"
        :multifilter="false"
        :show-user-picker="true"
        :time-period-label="'Data'"
        :start-value="searchInput"
        :applied-user="selectedUser"
        :can-close="activeModal === null"
        :restore-filters="true"
        @sort-order-changed="sortOrderChanged"
        @search="search"
        @open-user-picker-modal="openModal('user_selector')"
        @delete-user="deleteUser()"
      >
        <template #tags>
          <UserTag v-if="user != null" class="ml-2" :user="user" @close="deleteUser"></UserTag>
          <FilterTag
            v-for="(filter, f) in appliedFilters"
            :key="'filter_' + f"
            :text="getFilterText(filter)"
            :show-close-button="true"
            @close="deleteFilter(f)"
          ></FilterTag>
          <FilterTag
            v-if="startPeriod != null && endPeriod != null"
            :text="startPeriod + ' - ' + endPeriod"
            :show-close-button="true"
            @close="deleteDates()"
          ></FilterTag>
        </template>
      </ContextualSearch>
      <fw-panel
        :title="$t('results')"
        :counter="notificationsData.length"
        :counter-total="totalResults"
        boxed
        class="my-5"
        custom-class="bg-white p-0"
      >
        <LoadingPlaceholder v-if="loading" class="p-2" />

        <fw-panel-info v-else-if="!loading && !notificationsData.length" empty clean center>
          {{ $t('noActivity') }}.
        </fw-panel-info>
        <div v-else class="h-full">
          <template v-for="item in notificationsData">
            <RecordNotification
              :key="item.key"
              :item="item"
              :users="notificationsUsers"
              :api-service="apiService"
              :api-endpoint="detailsApiEndpoint"
              :get-details-api="getNotificationApiFunction"
              :extra-payload="detailsPayload"
            />
          </template>
        </div>
        <BlockPagination
          v-if="totalPages > 1 && !loading"
          :per-page="limit"
          :total="totalResults"
          :total-pages="totalPages"
          :current.sync="page"
          @page-changed="pageChanged"
        />
      </fw-panel>
    </fw-panel>

    <b-modal
      :active="activeModal !== null"
      scroll="keep"
      :can-cancel="true"
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :width="700"
      :on-cancel="closeModal"
      :custom-class="'rounded-buefy-modal'"
    >
      <ChoosePeopleModal
        v-if="activeModal === 'user_selector'"
        :title="$t('selectUser')"
        :instructions="$('selectUserInstructions')"
        :multiselect="false"
        :selected="currentSelectedUsers"
        :override-user-list="usersList"
        :invite-people="false"
        @selected="selectedPeople"
        @close="closeModal"
      ></ChoosePeopleModal>
    </b-modal>
  </section>
</template>

<script>
import RecordNotification from '@/fw-modules/fw-core-vue/ui/components/records/RecordNotification'
import ChoosePeopleModal from '@/fw-modules/fw-core-vue/ui/components/modals/ChoosePeopleModal'
import ContextualSearch from '@/fw-modules/fw-core-vue/ui/components/search/ContextualSearch'
import UserTag from '@/fw-modules/fw-core-vue/ui/components/text/UserTag'
import FilterTag from '@/fw-modules/fw-core-vue/ui/components/text/FilterTag'
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'

import dates from '@/fw-modules/fw-core-vue/utilities/dates'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  name: 'PanelNotifications',
  components: {
    ChoosePeopleModal,
    ContextualSearch,
    UserTag,
    FilterTag,
    RecordNotification,
    BlockPagination,
    LoadingPlaceholder
  },

  props: {
    activeFilters: {
      type: Object,
      default: () => {}
    },
    injectPayload: {
      type: Object,
      default: () => {}
    },
    showExportToolbar: {
      type: Boolean,
      default: true
    },
    apiService: {
      type: Object,
      default: null
    },
    apiEndpoint: {
      type: String,
      default: 'getNotifications'
    },
    detailsApiEndpoint: {
      type: String,
      default: 'getNotification'
    },
    getNotificationsApiFunction: {
      type: Function,
      default: null
    },
    getNotificationApiFunction: {
      type: Function,
      default: null
    },
    detailsPayload: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      activeModal: null,
      notificationsUsers: null,
      notificationsData: [],
      loading: true,
      loadingExportToCSV: false,
      searchInput: '',
      page: 1,
      totalResults: 0,
      totalPages: 1,
      limit: 25,
      orderByValue: '',
      startPeriod: null,
      endPeriod: null,
      orderDirection: 'none',
      appliedFilters: [],
      userKey: null,
      user: null, //use userKey to get user info (eg: user profile image)
      itemKey: null,
      itemType: null,
      selectedUser: null,
      filters: [],
      orderBy: [
        {
          key: 'created_date',
          label: this.$t('date'),
          type: 'date'
        }
      ],
      usersList: []
    }
  },

  computed: {
    currentSelectedUsers() {
      return this.user != null ? [this.user] : []
    },

    api() {
      return this.$store.state.api.base
    },

    appName() {
      return this.$store.state.appName
    }
  },

  async created() {
    await utils.sleep(100)
    await this.getUrlParams()
    await this.getUser()
    // await this.getNotifications()
  },

  methods: {
    async getUser() {
      //hidrate user key
      if (this.userKey != null) {
        try {
          const user = await this.api.getUserDetails(this.userKey)
          this.user = user
          this.selectedUser = user
        } catch (error) {
          console.error(error)
        }
      }
    },

    selectedPeople(value) {
      if (value.length > 0) {
        this.selectedUser = value[0]
      } else {
        this.selectedUser = null
      }
    },

    getFilterText(key) {
      var split = key.split(':')
      for (var i = 0; i < this.filters.length; i++) {
        if (this.filters[i].key == split[0]) {
          for (var j = 0; j < this.filters[i].options.length; j++) {
            if (this.filters[i].options[j].key == split[1]) {
              return this.filters[i].options[j].label
            }
          }
        }
      }
      return ''
    },

    search(data) {
      this.appliedFilters = JSON.parse(JSON.stringify(data.filters))
      this.searchInput = data.term

      if (data.orderBy != null) {
        this.orderByValue = data.orderBy
        this.orderDirection = data.orderDirection
      }

      this.user = data.user
      this.userKey = data.user != null ? data.user.key : null

      this.startPeriod = data.dates.length == 2 ? dates.buildCore(data.dates[0]).format('DD/MM/YYYY') : null
      this.endPeriod = data.dates.length == 2 ? dates.buildCore(data.dates[1]).format('DD/MM/YYYY') : null

      this.setUrlParams()
      this.getNotifications()
    },

    getUrlParams() {
      if (this.$route.query.q) {
        this.searchInput = this.$route.query.q
      }
      if (this.$route.query.f) {
        this.appliedFilters = this.$route.query.f.split(',')
      }
      if (this.$route.query.s) {
        this.orderByValue = this.$route.query.s
        this.orderDirection =
          this.$route.query.o == 'ASC' || this.$route.query.o == 'DESC' ? this.$route.query.o : 'none'
      }
      if (this.$route.query.p) {
        this.page = this.$route.query.p
      }
      if (this.$route.query.user) {
        this.userKey = this.$route.query.user
      }

      if (this.$route.query.start) {
        this.startPeriod = this.$route.query.start
      }

      if (this.$route.query.end) {
        this.endPeriod = this.$route.query.end
      }

      if (this.activeFilters) {
        for (const [key, value] of Object.entries(this.activeFilters)) {
          console.log(`Key ${key}`, value)
          if (key == 'user') {
            this.userKey = value
          } else {
            this.itemKey = value
            this.itemType = key
          }
        }
      }
    },

    setUrlParams() {
      let query = {}
      if (this.searchInput.length > 0) {
        query['q'] = this.searchInput
      }
      if (this.appliedFilters.length > 0) {
        query['f'] = this.appliedFilters.join(',')
      }
      if (this.orderByValue.length > 0) {
        query['s'] = this.orderByValue
        query['o'] = this.orderDirection
      }
      if (this.page > 1) {
        query['p'] = parseInt(this.page)
      }
      if (this.startPeriod != null && this.endPeriod != null) {
        query['start'] = this.startPeriod
        query['end'] = this.endPeriod
      }

      if (this.userKey != null && !this.activeFilters.user) {
        query['user'] = this.userKey
      }

      if (this.itemType != null && this.itemKey != null && !this.activeFilters.item) {
        query['item'] = this.itemKey
        query['type'] = this.itemType
      }

      this.$router.push({ query: query })
    },

    deleteUser() {
      this.userKey = null
      this.user = null
      this.selectedUser = null
      this.setUrlParams()
      this.getNotifications()
    },

    deleteFilter(index) {
      this.appliedFilters.splice(index, 1)
      this.setUrlParams()
      this.getNotifications()
    },

    deleteDates() {
      this.startPeriod = null
      this.endPeriod = null
      this.setUrlParams()
      this.getNotifications()
    },

    sortOrderChanged(newsort) {
      if (newsort != null && newsort.key != null) {
        this.orderByValue = newsort.key.key
        this.orderDirection = newsort.direction
      }
      this.setUrlParams()
      this.getNotifications()
    },

    async getNotificationsApi(searchPayload) {
      if (this.getNotificationsApiFunction != null) {
        return await this.getNotificationsApiFunction(searchPayload)
      }
      const api = this.apiService != null ? this.apiService : this.api
      return await api[this.apiEndpoint](searchPayload)
    },

    async getNotifications(csv = false) {
      let searchPayload = {
        service: this.appName,
        start_date: this.parseDatesForPayload(this.startPeriod),
        end_date: this.parseDatesForPayload(this.endPeriod),
        page: this.page,
        limit: this.limit,
        users: true,
        ...this.injectPayload
      }

      if (this.searchInput.length > 0) {
        searchPayload['q'] = this.searchInput
        searchPayload['query'] = this.searchInput
      }

      if (this.orderByValue.length > 0) {
        searchPayload['sort'] = this.orderByValue
        searchPayload['direction'] = this.orderDirection.toLowerCase()
      }

      if (this.appliedFilters.length) {
        searchPayload['filters'] = this.appliedFilters.join(',')
      }

      if (this.itemKey != null) {
        searchPayload['item_type'] = this.itemType
        searchPayload['item_key'] = this.itemKey
      }

      console.log('this.itemKey', this.itemKey)

      if (csv) {
        searchPayload['csv'] = true
        return await this.getNotificationsApi(searchPayload)
      }

      console.log('searchPayload', searchPayload)

      this.loading = true

      try {
        const result = await this.getNotificationsApi(searchPayload)
        console.log('result', result)
        this.notificationsData = result.notifications.map(v => {
          v.expanded = false
          v.details = null
          v.id = v.key
          return v
        })
        this.totalPages = result.pagination.total_pages
        this.page = result.pagination.current_page
        this.totalResults = result.pagination.total_items
        this.notificationsUsers = result.users
      } catch (error) {
        this.handleErrors(error)
      }

      await utils.sleep(500)
      this.loading = false
    },

    async exportResults() {
      this.loadingExportToCSV = true
      try {
        const data = await this.getNotifications(true)
        const url = window.URL.createObjectURL(new Blob([data]))
        const now = dates.now().format('YYYY-MM-DD_HH:mm:ss')
        utils.downloadFile(url, `activity_${this.appName}_${now}.csv`)
      } catch (error) {
        const errorKey = utils.errors(error).getKey()
        console.log('Error KEY', errorKey)
        this.$buefy.dialog.alert({
          title: this.$t('error'),
          message: this.$t('errorInfoCSV', { key: errorKey ? `(${errorKey})` : '' }),
          type: 'is-danger'
        })
      }
      this.loadingExportToCSV = false
    },

    openModal(type) {
      this.activeModal = type
    },

    async closeModal() {
      await utils.sleep(100)
      this.activeModal = null
    },

    openPersonInfo(user) {
      console.log('hello user', user)
    },

    pageChanged(page) {
      if (page) this.page = page
      this.setUrlParams()
      this.getNotifications()
    },

    async addUser(user, roles) {
      if (!roles || !roles.length) {
        roles = ['jury']
      }

      try {
        if (user.key) {
          const newUser = await this.api.addUser({
            roles: roles,
            key: user.key
          })
          console.log('createdUser ==> ', newUser)
          this.getNotifications()
        } else {
          const invitedUser = await this.api.inviteUser({
            email: user.email,
            name: user.name || user.full_name,
            roles: roles,
            no_email: false
          })
          console.log('inviteUser ==>', invitedUser)
          this.getInvitedUsers()
        }
      } catch (error) {
        console.error(error)
      }
      this.closeModal()
    },

    parseDatesForPayload(formatedDate) {
      if (formatedDate) {
        return dates.from(formatedDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
      }
      return formatedDate
    },

    handleErrors(error) {
      const errorKey = utils.errors(error).getKey()
      if (errorKey && errorKey == 'Forbidden') {
        return this.$router.push({ name: 'forbidden' })
      }
      if (errorKey && errorKey == 'NotFound') {
        return this.$router.push({ name: 'error' })
      }
      console.error(error)
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "results": "Resultados",
    "notifications": "Notificações",
    "exportCSV": "Exportar para CSV",
    "export": "Exportar",
    "noActivity": "Não existem notificações para apresentar.",
    "loadMoreData": "Mostrar mais resultados",
    "selectUser": "Selecione um utilizador",
    "selectUserInstructions": "Selecione um utilizador para filtrar as atividades",
    "date": "Data",
    "error": "Ocorreu um erro",
    "errorInfoCSV": "Ocorreu um erro não esperado{key} a exportar o CSV. Por favor, contacte a nossa equipa de suporte."
  },
  "en": {
    "results": "Results",
    "notifications": "Notifications",
    "exportCSV": "Export to CSV",
    "export": "Export",
    "noActivity": "No notifications to show",
    "loadMoreData": "Load more data",
    "selectUser": "Select a user",
    "date": "Date",
    "selectUserInstructions": "Select a user to filter activities",
    "error": "Ocorreu um erro",
    "errorInfoCSV": "An unexpected error occurred{key} exporting to CSV. Please contact our support team."
  }
}
</i18n>
