<template>
  <div>
    <fw-panel :title="topic.title" boxed="xs" :custom-title-class="'px-3.5 pt-4 pb-2'" :sticky="sticky" paddingless>
      <template v-if="canEdit" #toolbar>
        <div class="pr-3 pt-1">
          <fw-menu-more>
            <b-dropdown-item paddingless aria-role="listitem">
              <fw-button v-if="topic.page_key" type="dropdown-item" class="w-full" @click.native="editContent">
                Editar conteúdo
              </fw-button>
            </b-dropdown-item>
            <b-dropdown-item paddingless aria-role="listitem">
              <fw-button type="dropdown-item" class="w-full" @click.native="editModalIsActive = true">
                Mudar nome do tema
              </fw-button>
            </b-dropdown-item>
            <b-dropdown-item paddingless aria-role="listitem">
              <fw-button type="dropdown-item" class="w-full" @click.native="confirmDeleteTopic">
                Eliminar tema
              </fw-button>
            </b-dropdown-item>
            <b-dropdown-item paddingless aria-role="listitem">
              <fw-button type="dropdown-item" label="Apagar" class="w-full" @click.native="showModalEditPost = true">
                Novo tópico de discussão
              </fw-button>
            </b-dropdown-item>
          </fw-menu-more>
        </div>
      </template>
      <template #bellow-header>
        <div class="flex gap-5">
          <fw-button
            size="md"
            custom-class="pb-3"
            :type="view === 'dashboard' ? 'tab-active' : 'tab'"
            @click.native="
              () =>
                $router.push({
                  name: 'course-manage-edition-module-topic',
                  params: {
                    id: courseKey,
                    editionId: editionKey,
                    moduleId: unitKey
                  }
                })
            "
          >
            Apresentação
          </fw-button>
          <fw-button
            size="md"
            custom-class="pb-3"
            :type="view === 'foruns' ? 'tab-active' : 'tab'"
            @click.native="
              () =>
                $router.push({
                  name: 'course-manage-edition-module-topic-foruns',
                  params: {
                    id: courseKey,
                    editionId: editionKey,
                    moduleId: unitKey,
                    topicId: topic.key
                  }
                })
            "
          >
            Tópicos de discussão
          </fw-button>
          <fw-button
            size="md"
            custom-class="pb-3"
            :type="view === 'agenda' ? 'tab-active' : 'tab'"
            @click.native="
              () =>
                $router.push({
                  name: 'course-manage-edition-module-topic-agenda',
                  params: {
                    id: courseKey,
                    editionId: editionKey,
                    moduleId: unitKey,
                    topicId: topic.key
                  }
                })
            "
          >
            Agendamentos
          </fw-button>
        </div>
      </template>
    </fw-panel>
    <fw-content-loader v-if="loading" />
    <template v-else>
      <section v-if="topic.page_key && view === 'dashboard'">
        <PanelPagePreview
          :key="topic.page_key"
          class="p-5"
          :page-id="topic.page_key"
          :show-versions-dropdown="canEdit"
          :show-title="false"
          :show-chapters="true"
          :chapters-offset="'200px'"
          :main-container="'.main-content'"
        />
      </section>

      <div v-else-if="view === 'agenda'" class="p-4">
        <RecordClass
          v-for="classData in classes"
          :key="classData.key"
          :data="classData"
          :show-actions="canEdit"
          :topic-is-visible="Boolean(sessions?.[classData.key]?.start_date)"
          @toggle-visibility="toggleVisibility(classData, $event)"
        />
        <div v-if="classes.length == 0" class="my-20 text-center text-gray-500">
          Sem turmas para agendar
        </div>
      </div>

      <PanelPosts
        v-else-if="view === 'foruns'"
        type="discussions"
        :collections="collections"
        :contexts-data="collectionsContextsData"
        class="m-4"
        @open="$emit('open-post', $event)"
      >
        <template v-if="canEdit" #header-end>
          <fw-menu-more v-if="showModalEditPost && collections[0]?.key">
            <b-dropdown-item paddingless aria-role="listitem">
              <fw-button type="dropdown-item" label="Apagar" class="w-full" @click.native="showModalEditPost = true">
                Nova publicação
              </fw-button>
            </b-dropdown-item>
          </fw-menu-more>
        </template>
      </PanelPosts>
    </template>
    <fw-modal
      v-if="showModalEditPost && collections[0]?.key"
      :active.sync="showModalEditPost"
      :can-cancel="true"
      paddingless
      size="min"
      height-fit-screen
      @close="showModalEditPost = false"
    >
      <template #default>
        <BlockEditPost
          :collection-key="collections[0]?.key"
          :context="'post'"
          :post="null"
          type="discussion"
          @saved="
            data => {
              $emit('open-post', { ...data, collection: collections[0] })
              showModalEditPost = false
            }
          "
          @close="showModalEditPost = false"
        />
      </template>
    </fw-modal>

    <fw-modal
      v-if="editModalIsActive"
      :active.sync="editModalIsActive"
      :can-cancel="true"
      size="min"
      @close="editModalIsActive = false"
    >
      <template #default>
        <ModalNewTopic :title="topic.title" @save="editTopicTitle($event)" @cancel="editModalIsActive = false" />
      </template>
    </fw-modal>
  </div>
</template>

<script>
import ServicesCourses from '@/fw-modules/fw-core-vue/courses/services/ServiceCourses'
import ModalNewTopic from '@/fw-modules/fw-core-vue/courses/components/modals/manage/ModalNewTopic'
import RecordClass from '@/fw-modules/fw-core-vue/courses/components/records/RecordClass'
import PanelPagePreview from '@/fw-modules/fw-core-vue/pages/components/panels/PanelPagePreview'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import PanelPosts from '@/fw-modules/fw-core-vue/posts/components/panels/PanelPosts'
import BlockEditPost from '@/fw-modules/fw-core-vue/posts/components/blocks/BlockEditPost'
export default {
  components: {
    ModalNewTopic,
    PanelPagePreview,
    RecordClass,
    BlockEditPost,
    PanelPosts
  },
  props: {
    courseKey: {
      type: String,
      required: true
    },
    editionKey: {
      type: String,
      required: true
    },
    unitKey: {
      type: String,
      required: true
    },
    topic: {
      type: Object,
      required: true
    },
    sessions: {
      type: Object,
      default: () => {}
    },
    collections: {
      type: Array,
      default: () => []
    },
    collectionsContextsData: {
      type: Object,
      default: () => {}
    },
    classes: {
      type: Array,
      default: () => []
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    sticky: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      editModalIsActive: false,
      showModalEditPost: false
    }
  },

  computed: {
    view() {
      if (this.$route.name === 'course-manage-edition-module-topic-agenda') {
        return 'agenda'
      }

      if (this.$route.name === 'course-manage-edition-module-topic-foruns') {
        return 'foruns'
      }

      return 'dashboard'
    }
  },

  methods: {
    async editTopicTitle({ title }) {
      await utils.tryAndCatch(this, async () => {
        const response = await ServicesCourses.updateTopic(
          this.courseKey,
          this.editionKey,
          this.unitKey,
          this.topic.key,
          {
            title: title
          }
        )
        console.log(response)
        this.topic.title = title
        this.$emit('updated-topic', response)
        //show snackbar
        this.$buefy.snackbar.open({
          message: 'Título do tema atualizado.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 5000
        })
      })
      this.editModalIsActive = false
    },

    confirmDeleteTopic() {
      this.$buefy.dialog.confirm({
        title: 'Eliminar tema',
        message: `Tem a certeza que pretende eliminar o tema "${this.topic.title}" e todos os seus temas?`,
        confirmText: 'Eliminar',
        type: 'is-danger',
        hasIcon: false,
        onConfirm: () => {
          this.deleteTopic()
        }
      })
    },

    async deleteTopic() {
      await utils.tryAndCatch(this, async () => {
        const response = await ServicesCourses.deleteTopic(
          this.courseKey,
          this.editionKey,
          this.unitKey,
          this.topic.key
        )
        console.log(response)
        this.$emit('delete-topic', response)
        this.$router.push({
          name: 'course-manage-edition-module',
          params: { id: this.courseKey, editionId: this.editionKey, moduleId: this.unitKey }
        })
      })
    },

    editContent() {
      this.$router.push({
        name: 'content-pages-editor',
        params: {
          key: this.topic.page_key
        }
      })
    },

    async toggleVisibility(classData, visible) {
      console.log('toggleVisibility', visible)
      let session = this.sessions?.[classData.key] ?? {}
      //today from midnight
      let today = new Date()
      today.setHours(0, 0, 0, 0)
      session['start_date'] = visible ? today : null
      session['end_date'] = null

      let result
      await utils.tryAndCatch(this, async () => {
        if (session.key) {
          result = await ServicesCourses.updateClassSession(this.courseKey, this.editionKey, classData.key, {
            [session.key]: session
          })
          this.$emit('update:sessions', { ...this.sessions, [classData.key]: result[0] })
        } else {
          result = await ServicesCourses.createClassSession(this.courseKey, this.editionKey, classData.key, {
            [this.unitKey]: { [this.topic.key]: session }
          })
          this.$emit('update:sessions', { ...this.sessions, [classData.key]: result[this.unitKey][this.topic.key] })
        }
        console.log('toggleVisibility', result)
        this.$emit('updated-topic', null)

        this.$buefy.snackbar.open({
          message: `Tópico ${visible ? 'disponível' : 'indisponível'}.`,
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 5000
        })
      })
    }
  }
}
</script>
