<template>
  <DashboardCard class="p-2 device-card">
    <div class="flex items-center gap-3 w-full pr-2">
      <fw-avatar class="mr-1.5 flex-shrink-0" size="md" custom-class="force-rounded'" :user="to" />
      <div class="leading-5 text-right flex-1 flex flex-col items-start text-white">
        <div class="flex gap-2 mb-0.5">
          <v-clamp autoresize :max-lines="1" class="font-semibold">
            {{ toPersonName() }}
          </v-clamp>
          <div v-if="to.number" class="text-xs secondary-text">
            {{ to.number }}
          </div>
        </div>
        <div class="text-base secondary-text font-normal flex gap-2 items-center">
          Acesso dado por {{ fromPersonName() }}
        </div>
      </div>
      <fw-button type="black" size="sm" @click.native="showPin(pin)">
        Revelar PIN
      </fw-button>
    </div>
  </DashboardCard>
</template>
<script>
import DashboardCard from './DashboardCard.vue'
export default {
  name: 'AccessCard',
  components: {
    DashboardCard
  },
  props: {
    from: {
      type: Object,
      required: true
    },
    to: {
      type: Object,
      required: true
    },
    pin: {
      type: String,
      required: true
    }
  },
  methods: {
    fromPersonName() {
      return this.from.display_name || this.from.full_name
    },
    toPersonName() {
      return this.to.display_name || this.to.full_name
    },
    showPin(pin) {
      //separate the pin in groups of 3 digits
      pin = pin.replace(/(\d{3})(\d{3})/, '$1-$2')
      this.$buefy.dialog.alert({
        title: 'Código de Login por PIN',
        message: 'Utilize o seguinte PIN para iniciar sessão no tablet:<br/><b class="text-xl">' + pin + '</b>',
        ariaRole: 'alertdialog',
        ariaModal: true,
        ariaLabel: 'PIN',
        ariaDescribedby: 'PIN'
      })
    }
  }
}
</script>

<style>
.force-rounded {
  border-radius: 0.45rem !important;
}
.device-card .secondary-text {
  color: #cccccc;
}
</style>
