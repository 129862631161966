<template>
  <div
    class="flex gap-5 p-3 items-center cursor-pointer exam-record hover:bg-gray-100"
    :class="{ 'border-b  border-gray-100': !last }"
  >
    <!-- Title -->
    <div class="flex-1">
      <div class="flex gap-2 text-left items-center">
        <fw-icon-arrow-right class="text-gray-400 h-5 w-5 item-arrow" />
        <div>
          <v-clamp
            v-if="instance.title != null && instance.title.length > 0"
            autoresize
            :max-lines="1"
            class="text-black font-semibold "
          >
            {{ instance.title }}
          </v-clamp>
          <div v-else class="text-gray-400">Sem título</div>
        </div>
      </div>
      <div class="text-xs text-gray-500 flex gap-2 ml-7">
        <div>Criado em {{ instance.createdDate | formatDateTime }}</div>
      </div>
    </div>
    <!-- Versions and language -->
    <div class="hidden lg:flex w-48 gap-2">
      <fw-tag type="light-border-box" size="xs">
        {{ instance.mode === 'presential' ? 'Presencial' : 'Remoto' }}
      </fw-tag>
      <fw-tag type="light-border-box" size="xs" class="flex items-center gap-0.5 justify-center">
        <span>{{ instance.forms.length }}</span> <span v-if="instance.forms.length == 1">versão</span
        ><span v-else>versões</span>
      </fw-tag>
      <div class="flex gap-1 flex-1 uppercase">
        <fw-tag
          v-for="(lang, l) in instance.availableLanguages"
          :key="'language_' + l"
          type="light-border-box"
          size="xs"
        >
          {{ lang }}
        </fw-tag>
      </div>
    </div>
    <!-- Status -->
    <div class="w-56 flex gap-1">
      <fw-tag v-if="instance.status == 'ended'" type="xlight">
        Terminado
      </fw-tag>
      <fw-tag v-else-if="instance.status == 'draft'" type="orange">
        Rascunho
      </fw-tag>
      <fw-tag v-else-if="instance.startDate != null && instance.status != 'ended'" type="primary">
        Publicado
      </fw-tag>
      <fw-tag v-if="instance.status == 'ended'" type="xlight" class="flex gap-1 items-center flex-shrink-0">
        <fw-icon-people class="w-3 h-3" /> <span>{{ instance.answerCount }}</span> <fw-icon-check class="w-4 h-4" />
      </fw-tag>
      <fw-tag v-else-if="instance.startDate" type="xlight" class="flex gap-1 items-center flex-shrink-0">
        <fw-icon-calendar class="h-4 w-4" /> {{ instance.startDate | formatDateTime }}
      </fw-tag>
    </div>
    <!-- Owner -->
    <div class="hidden lg:flex w-10 items-center justify-center">
      <fw-avatar :user="users[instance.user_key]" size="xs" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExamCard',
  //components: { TextStatus },
  props: {
    users: {
      type: Object
    },
    language: {
      type: String,
      default: 'pt'
    },
    instance: {
      type: Object
    },
    last: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style scoped>
.exam-record .item-arrow {
  @apply transition-all;
}
.exam-record:hover .item-arrow {
  transform: translateX(3px);
}
</style>
