var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center gap-1 text-xs",class:[
    {
      'bg-red-400 text-opacity-70 text-gray-900 bg-opacity-90': _vm.dirtyData && !_vm.savingData,
      'text-opacity-60 text-gray-600 bg-opacity-70': !_vm.dirtyData || _vm.savingData,
      'font-semibold': _vm.bold,
      'leading-4 py-0': _vm.shorter,
      'leading-5 py-0.5': !_vm.shorter,
      'px-1.5': !_vm.paddingLess && _vm.shorter,
      'px-2': !_vm.paddingLess && !_vm.shorter
    }
  ]},[(_vm.savingData)?_c('svg',{staticClass:"fill-current flipanimation",class:{ 'h-3 w-3': _vm.shorter, 'h-4 w-4': !_vm.shorter },attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24","width":"24","height":"24"}},[_c('path',{attrs:{"fill":"none","d":"M0 0h24v24H0z"}}),_c('path',{attrs:{"d":"M18 19h1V6.828L17.172 5H16v4H7V5H5v14h1v-7h12v7zM4 3h14l2.707 2.707a1 1 0 0 1 .293.707V20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm4 11v5h8v-5H8z"}})]):(_vm.dirtyData)?_c('faicon',{class:{ 'transform scale-75': _vm.shorter },attrs:{"icon":"exclamation-triangle"}}):_c('svg',{staticClass:"fill-current",class:{ 'h-3 w-3': _vm.shorter, 'h-4 w-4': !_vm.shorter },attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24","width":"24","height":"24"}},[_c('path',{attrs:{"fill":"none","d":"M0 0h24v24H0z"}}),_c('path',{attrs:{"d":"M18 19h1V6.828L17.172 5H16v4H7V5H5v14h1v-7h12v7zM4 3h14l2.707 2.707a1 1 0 0 1 .293.707V20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm4 11v5h8v-5H8z"}})]),_vm._v(" "+_vm._s(_vm.label)+" ")],1)
}
var staticRenderFns = []

export { render, staticRenderFns }