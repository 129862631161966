<template>
  <div class="flex flex-col gap-3">
    <div>
      <fw-label marginless>Estado</fw-label>
      <div :class="{ 'text-primary font-semibold': invite.active }">
        {{ invite.active ? 'Ativo' : 'Não ativo' }}
      </div>
    </div>
    <div>
      <fw-label marginless>Dados de convite</fw-label>
      <div class="flex items-center justify-between gap-4">
        <div v-if="invite.sent_date">Convite enviado em {{ invite.sent_date | formatDateTime }}.</div>
        <div v-else class="text-sm text-gray-500">Ainda não foi enviado convite.</div>

        <fw-button
          v-if="!invite.active"
          type="border-primary"
          size="sm"
          :disabled="loadingSendInvite || !canInviteAgain"
          :loading="loadingSendInvite"
          label="Apagar"
          @click.native="sendInvite()"
        >
          {{ $t('resendInvite') }}
        </fw-button>
      </div>
    </div>
  </div>
</template>

<script>
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'

export default {
  name: 'BlockInviteDetails',
  props: {
    user: {
      type: Object,
      default: () => {}
    },
    invite: {
      type: Object,
      default: () => {}
    },
    sendInviteEndpoint: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      loadingSendInvite: false
    }
  },

  computed: {
    canInviteAgain() {
      return (!this.invite.sent_date || Dates.nowDiff(this.invite.sent_date, 'hours') < 0) && !this.invite.active
    }
  },

  methods: {
    async sendInvite() {
      this.loadingSendInvite = true
      await utils.tryAndCatch(this, async () => {
        const { invite } = await this.sendInviteEndpoint(this.user.key)
        this.invite.sent_date = invite.sent_date
        this.$emit('update:invite', invite)
        this.$buefy.dialog.alert({
          title: this.$t('inviteSent'),
          message: this.$t('inviteWasSentTo', { email: this.user.email }),
          type: 'is-primary',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.loadingSendInvite = false
      })
    }
  }
}
</script>

<i18n lang="json">
{
	"en": {
		"resendInvite": "Resend invite",
		"inviteSent": "Invite sent",
		"inviteWasSentTo": "The invite was sent to {email}."
	},
	"pt": {
		"resendInvite": "Reenviar convite",
		"inviteSent": "Convite enviado",
		"inviteWasSentTo": "O convite foi enviado para {email}."
	}
}
</i18n>
