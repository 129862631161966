<template>
  <div>
    <fw-panel
      :title="$t('modules')"
      featured
      :counter="pagination.total_items"
      :boxed="pagination.total_items ? 'xs' : null"
    >
      <template v-if="editable" #toolbar>
        <fw-button type="link" @click.native="newUnit()">Criar módulo</fw-button>
      </template>
      <template #default>
        <LoadingPlaceholder v-if="loading" />
        <template v-else>
          <div v-if="results.length > 0 && editable">
            <Container
              group-name="topics"
              drop-class="card-ghost-drop"
              :drop-placeholder="dropPlaceholderOptions"
              :should-animate-drop="() => true"
              :get-child-payload="index => getChildPayload(index)"
              drag-handle-selector=".drag-handle-new"
              class="flex flex-col gap-1"
              @drop="onDropSection($event)"
            >
              <Draggable
                v-for="moduleData in results"
                :key="'input_' + moduleData.key"
                class="relative box-question border-b border-gray-100 pb-0.5 group"
              >
                <div class="drag-handle-new top-2.5 opacity-50 group-hover:opacity-100">
                  <fw-icon-draggable class="w-5 h-5" />
                </div>
                <RecordModule
                  :data="moduleData"
                  :edition-key="editionKey"
                  :course-key="courseKey"
                  :editable="editable"
                  :users="users"
                  @open="openModule(moduleData)"
                  @edit="editModule(moduleData)"
                  @delete="confirmDeleteModule(moduleData)"
                ></RecordModule>
              </Draggable>
            </Container>
          </div>
          <div v-else-if="results.length > 0">
            <RecordModule
              v-for="moduleData in results"
              :key="moduleData.key"
              class="mb-1"
              :data="moduleData"
              :edition-key="editionKey"
              :course-key="courseKey"
              :editable="false"
              :users="users"
              @open="openModule(moduleData)"
            ></RecordModule>
          </div>
          <fw-panel-info v-else clean centered class="my-5">
            Não foram definidos módulos para esta edição do espaço.
          </fw-panel-info>
          <BlockPagination
            v-if="pagination && pagination.total_pages > 1"
            :total="pagination.total_items"
            :total-pages="pagination.total_pages"
            :current.sync="page"
            @page-changed="pageChanged"
          />
        </template>
      </template>
    </fw-panel>
    <fw-modal v-if="modalIsActive" :active.sync="modalIsActive" :can-cancel="true" size="min" @close="close()">
      <ModalNewModule
        v-if="modalAction === 'edit-unit'"
        :title="selectedUnit.title"
        @save="editUnitTitle($event)"
        @cancel="close()"
      />
      <ModalNewModule v-else-if="modalAction === 'create-unit'" @save="createNewUnit($event)" @cancel="close()" />
    </fw-modal>
  </div>
</template>

<script>
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import ServiceCourses from '@/fw-modules/fw-core-vue/courses/services/ServiceCourses'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import ModalNewModule from '@/fw-modules/fw-core-vue/courses/components/modals/manage/ModalNewModule'
import RecordModule from '@/fw-modules/fw-core-vue/courses/components/records/RecordModule'
import { Container, Draggable } from 'vue-dndrop'
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'

export default {
  components: {
    LoadingPlaceholder,
    ModalNewModule,
    RecordModule,
    Container,
    Draggable,
    BlockPagination
  },
  props: {
    course: {
      type: Object,
      required: true
    },
    edition: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data: function() {
    return {
      modalIsActive: false,
      modalAction: null,
      selectedUnit: null,
      loading: false,
      results: [],
      pagination: {
        total_items: 0,
        total_pages: 0
      },
      page: 1,
      users: {},
      dropPlaceholderOptions: {
        className: 'drop-preview',
        animationDuration: '150',
        showOnTop: true
      }
    }
  },
  computed: {
    debug() {
      return Boolean(localStorage.getItem('fw-debug'))
    },
    courseKey() {
      return this.course.key
    },
    editionKey() {
      return this.edition.key
    }
  },
  created() {
    this.loadModules()
  },
  methods: {
    onDropSection(dropResult) {
      console.log('onDropSection', dropResult)
      this.changeOrder(dropResult.payload.key, dropResult.addedIndex)
      let result = this.applyDrag(this.results, dropResult)
      console.log('applyDrag', result)
      this.results = result
      this.$emit('update', this.results)
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr

      const result = [...arr]
      let itemToAdd = payload

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }

      return result
    },

    getChildPayload(index) {
      return this.results[index]
    },

    confirmDeleteModule(unit) {
      this.$buefy.dialog.confirm({
        title: 'Eliminar módulo',
        message: `Tem a certeza que pretende eliminar o módulo "${unit.title}" e todos os seus temas?`,
        confirmText: 'Eliminar',
        type: 'is-danger',
        hasIcon: false,
        onConfirm: () => {
          this.deleteUnit(unit)
        }
      })
    },

    async deleteUnit(unit) {
      await utils.tryAndCatch(this, async () => {
        const response = await ServiceCourses.deleteUnit(this.courseKey, this.editionKey, unit.key)
        console.log('deleteUnit', response)
        this.results = this.results.filter(item => item.key !== unit.key)
        this.$emit('update', this.results)
      })
    },

    async editUnitTitle({ title }) {
      await utils.tryAndCatch(this, async () => {
        await ServiceCourses.updateUnit(this.courseKey, this.editionKey, this.selectedUnit.key, {
          title: title
        })
        this.results = this.results.map(item => {
          if (item.key === this.selectedUnit.key) {
            item.title = title
          }
          return item
        })
        this.$emit('update', this.results)
        this.$buefy.snackbar.open({
          message: 'Título do módulo atualizado.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 5000
        })
      })
      this.close()
    },

    async changeOrder(moduleKey, newIndex) {
      if (!this.editable) return
      console.log('changeOrder', moduleKey, newIndex)
      await utils.tryAndCatch(this, async () => {
        let result = await ServiceCourses.changeOrderUnit(this.courseKey, this.editionKey, moduleKey, newIndex)
        console.log('changeOrderUnit result', result)
        this.$buefy.snackbar.open({
          message: 'Módulos reordenados com sucesso.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 3000
        })
      })
    },

    savedOrder(data) {
      if (!this.editable) return
      console.log('savedOrder', data)
      this.results = data
      this.$emit('update', this.results)
    },

    openModule(module) {
      this.$router.push({
        name: 'course-manage-edition-module',
        params: {
          id: this.course.key,
          editionId: this.edition.key,
          moduleId: module.key
        }
      })
    },

    async createNewUnit({ title }) {
      await utils.tryAndCatch(this, async () => {
        let result = await ServiceCourses.createUnit(this.courseKey, this.editionKey, {
          title: title
        })
        console.log('createNewUnit', result)
        this.results.push(result)
        this.$emit('update', this.results)
        this.close()
        this.$buefy.snackbar.open({
          message: 'Módulo criado com sucesso.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 2000
        })
      })
      this.close()
    },

    async loadModules() {
      this.loading = true
      try {
        let data = await ServiceCourses.getUnits(this.courseKey, this.editionKey, { page: this.page })
        console.log('load modules', data)
        this.results = data.units
        this.users = { ...this.users, ...data.users }
      } finally {
        this.loading = false
      }
    },
    close() {
      this.modalIsActive = false
      this.modalAction = null
      this.selectedUnit = null
    },
    newUnit() {
      this.modalIsActive = true
      this.modalAction = 'create-unit'
    },
    editModule(unit) {
      this.modalIsActive = true
      this.modalAction = 'edit-unit'
      this.selectedUnit = unit
    },
    pageChanged(page) {
      this.page = page
      this.loadModules()
    }
  }
}
</script>

<i18n>
  {
    "en": {
      "modules": "Units"
    },
    "pt": {
      "modules": "Módulos"
    }
  }
</i18n>

<style scoped>
.drag-handle-new {
  @apply h-6 w-6 rounded-lg items-center flex justify-center text-center absolute left-3 top-3 z-10;
  cursor: grab;
}
</style>
