<template>
  <BlockUserDetails :user="user" :loading="loading">
    <template v-if="invite" #more-meta>
      <BlockInviteDetails :user="user" :invite.sync="invite" :send-invite-endpoint="sendInvite"></BlockInviteDetails>
    </template>

    <fw-panel title="Módulos" :loading="savingData" after-loading-checked boxed="xs" custom-class="bg-gray-100">
      <template #toolbar>
        <div class="flex gap-1 items-center">
          <div v-if="canEdit" class="flex gap-5">
            <fw-button
              :type="!editMode ? 'link' : 'link-light'"
              :disabled="savingData"
              @click.native="toggleEditMode()"
            >
              {{ editMode ? 'Fechar edicão' : 'Editar' }}
            </fw-button>
            <fw-button
              v-if="editMode"
              type="primary"
              :disabled="savingData"
              :loading="savingData"
              @click.native="save()"
              >Guardar</fw-button
            >
          </div>
        </div>
      </template>

      <template v-if="canEdit && editMode">
        <b-switch v-model="asGlobalInstructor">
          Formador em todos os módulos
        </b-switch>

        <multiselect
          v-if="!asGlobalInstructor"
          v-model="selectedModules"
          :options="modules"
          :show-labels="false"
          :clear-on-select="false"
          :searchable="true"
          :multiple="true"
          placeholder="Selecione os módulos"
          track-by="key"
          label="title"
          :loading="loadingModules"
          @search-change="searchModules"
        ></multiselect>

        <fw-tip v-if="$v.selectedModules.$error" error>
          Escolha pelo menos um módulo
        </fw-tip>
      </template>

      <fw-panel-info v-else clean>
        <b-switch v-model="asGlobalInstructor" :disabled="true">
          Formador em todos os módulos
        </b-switch>

        <div
          v-if="selectedModules?.length"
          class="text-sm text-gray-500 font-normal flex gap-2 items-center flex-wrap pt-2"
        >
          <b-tooltip
            v-for="module in selectedModules"
            :key="module.key"
            type="is-light"
            :label="module.title"
            position="is-bottom"
          >
            <fw-tag type="light-border-box" size="xs">
              <span class="truncate max-w-[20ch]">{{ module.title }}</span>
            </fw-tag>
          </b-tooltip>
        </div>
        <fw-panel-info v-else clean empty>
          Sem grupos
        </fw-panel-info>
      </fw-panel-info>
    </fw-panel>
  </BlockUserDetails>
</template>

<script>
import BlockUserDetails from '@/fw-modules/fw-core-vue/id/components/blocks/BlockUserDetails'
import Multiselect from 'vue-multiselect'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import ServiceCourses from '@/fw-modules/fw-core-vue/courses/services/ServiceCourses'
import BlockInviteDetails from '@/fw-modules/fw-core-vue/courses/components/blocks/BlockInviteDetails'
export default {
  components: {
    BlockUserDetails,
    Multiselect,
    BlockInviteDetails
  },
  props: {
    user: {
      type: Object,
      default: () => {}
    },
    savingData: {
      type: Boolean,
      default: false
    },
    canEdit: {
      type: Boolean,
      default: true
    },
    courseKey: {
      type: String
    },
    editionKey: {
      type: String
    },
    invite: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      loading: true,
      asGlobalInstructor: false,
      selectedModules: [],
      debounceTimer: null,
      loadingModules: false,
      modules: [],
      editMode: false
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    loggedUser() {
      return this.$store.getters.getUser
    }
  },

  watch: {
    asGlobalInstructor() {
      if (this.asGlobalInstructor) {
        this.selectedModules = []
      } else if (this.user.modules?.length) {
        this.selectedModules = [...this.user.modules]
      }
    }
  },

  validations() {
    return {
      selectedModules: {
        required: value => this.asGlobalInstructor === true || (value && value?.length > 0)
      }
    }
  },

  mounted() {
    if (this.user.modules?.length) {
      this.selectedModules = [...this.user.modules]
      this.asGlobalInstructor = false
    } else {
      this.asGlobalInstructor = true
    }
    this.getUnits()
  },

  methods: {
    save() {
      this.$v.$touch()
      console.log('this.$v :>> ', this.$v)
      if (this.$v.$invalid) return
      this.$emit('update', {
        userkey: this.user.key,
        unitKeys: this.selectedModules.map(item => item.key),
        is_global: this.asGlobalInstructor
      })
      this.$emit('close')
    },

    searchModules(query) {
      this.debounce(async () => {
        await this.getUnits(query)
      })
    },

    async sendInvite(userKey) {
      return await ServiceCourses.sendUserInviteNotification(userKey)
    },

    async getUnits(query) {
      this.loadingModules = true

      await utils.tryAndCatch(this, async () => {
        const response = await ServiceCourses.getUnits(this.courseKey, this.editionKey, {
          query,
          limit: 50
        })
        console.log('getUnits :>> ', response)
        this.modules = response.units
      })

      this.loadingModules = false
    },

    debounce(func, timeout = 300) {
      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer)
      }
      this.debounceTimer = setTimeout(() => {
        func.apply()
      }, timeout)
    },

    toggleEditMode() {
      this.editMode = !this.editMode
    }
  }
}
</script>
