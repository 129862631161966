<template>
  <div class="flex gap-3">
    <div
      class="flex-1 pr-5 pl-5 flex-shrink-0"
      :class="{
        'border-r border-black/5': preview
      }"
    >
      <div class=" max-w-2xl mx-auto h-full">
        <slot />
      </div>
    </div>
    <div v-if="preview" class="max-w-xl w-full">
      <div class="max-w-xl mx-auto w-full h-full pl-2 pr-5 ">
        <slot name="preview"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PanelFormComponent',
  props: {
    preview: {
      type: Boolean,
      default: false
    }
  }
}
</script>
