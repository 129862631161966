<template>
  <div class=" flex-1 rounded-3xl bg-panel p-5 flex gap-2 flex-col flex-shrink-0 select-none">
    <div class="text-sm text-title font-semibold flex-shrink-0">{{ $t('participants') }}</div>
    <div class="flex flex-shrink-0 gap-3 text-sm">
      <div class="color-inactive-tab font-bold">
        {{ $t('results') }}
      </div>
      <div
        class="cursor-pointer whitespace-nowrap"
        :class="{
          'text-primary font-semibold': usersFilter === 'all',
          'color-inactive-tab': usersFilter !== 'all'
        }"
        @click="changeUsersFilter('all')"
      >
        {{ $t('all') }}
      </div>
      <div
        class="cursor-pointer whitespace-nowrap"
        :class="{
          'text-primary font-semibold': usersFilter === 'answering',
          'color-inactive-tab': usersFilter !== 'answering'
        }"
        @click="changeUsersFilter('answering')"
      >
        {{ $t('running') }}
      </div>
      <div
        class="cursor-pointer whitespace-nowrap"
        :class="{
          'text-primary font-semibold': usersFilter === 'closed',
          'color-inactive-tab': usersFilter !== 'closed'
        }"
        @click="changeUsersFilter('closed')"
      >
        {{ $t('submited') }}
      </div>
      <div
        class="cursor-pointer whitespace-nowrap"
        :class="{
          'text-primary font-semibold': usersFilter === 'withdraw',
          'color-inactive-tab': usersFilter !== 'withdraw'
        }"
        @click="changeUsersFilter('withdraw')"
      >
        {{ $t('giveup') }}
      </div>
      <div
        class="cursor-pointer whitespace-nowrap"
        :class="{
          'text-primary font-semibold': usersFilter === 'noAnswer',
          'color-inactive-tab': usersFilter !== 'noAnswer'
        }"
        @click="changeUsersFilter('noAnswer')"
      >
        {{ $t('noanswer') }}
      </div>
    </div>
    <div class="flex flex-1 overflow-hidden">
      <div
        class="transition-all flex flex-col justify-center gap-3 text-white font-semibold"
        :style="{
          width: usersOveflow ? '50px' : '0px',
          opacity: usersOveflow ? '1' : '0'
        }"
      >
        <div
          v-for="index in 26"
          :key="'alphabet_' + index"
          class="flex gap-2 text-alphabet items-center hover:text-white group cursor-pointer"
          :class="{
            'opacity-20 cursor-not-allowed': existingLetters.indexOf(String.fromCharCode(64 + index)) === -1
          }"
          @click="scrollToLetter(String.fromCharCode(64 + index))"
        >
          <div class="h-3 w-3 rounded-full bg-alphabet-ball group-hover:bg-white"></div>
          {{ String.fromCharCode(64 + index) }}
        </div>
      </div>
      <div v-if="filteredUsers.length > 0" class="flex-1 relative">
        <div
          ref="answerslist"
          class="h-full overflow-y-scroll flex flex-col gap-3 justify-start rounded-lg hide-scrollbar"
        >
          <AnswerCard
            v-for="answer in filteredUsers"
            :key="answer.key"
            :answer="answer"
            :versions="versions"
            :versions-map="versionsMap"
            @click.native="openUser(answer)"
          >
          </AnswerCard>
        </div>
        <div v-if="usersOveflow" class="absolute bottom-0 left-0 right-0 h-10 dashboard-overflow-gradient"></div>
      </div>
      <div v-else class="flex-1 flex flex-col justify-center items-center">
        <fw-icon-rest class="color-icon-panel h-12 w-12 mb-2"></fw-icon-rest>
        <div class="text-title text-sm">
          {{
            usersFilter === 'answering'
              ? $t('noanswers_answering')
              : usersFilter === 'closed'
              ? $t('noanswers_closed')
              : usersFilter === 'withdraw'
              ? $t('noanswers_withdraw')
              : usersFilter === 'noAnswer'
              ? $t('noanswers_noanswer')
              : $t('noanswers')
          }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AnswerCard from '../cards/AnswerCard.vue'
import Vue from 'vue'
export default {
  name: 'PanelDashboardPArticipants',
  components: {
    AnswerCard
  },
  props: {
    answers: {
      type: Array,
      default: () => []
    },
    versions: {
      type: Array,
      default: () => []
    },
    versionsMap: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      usersFilter: 'all',
      usersOveflow: false
    }
  },
  computed: {
    existingLetters() {
      return this.filteredUsers.map(a => a.user.name[0].toUpperCase())
    },
    filteredUsers() {
      if (this.usersFilter === 'all') return this.answers
      return this.answers.filter(a => a.state === this.usersFilter)
    }
  },
  mounted() {
    Vue.nextTick(() => {
      this.verifyUsersOverflow()
    })
  },
  methods: {
    openUser(answer) {
      this.$emit('open-user', answer.user.key)
    },
    changeUsersFilter(filter) {
      this.usersFilter = filter
      Vue.nextTick(() => {
        this.verifyUsersOverflow()
      })
    },
    scrollToLetter(letter) {
      var el = document.querySelector('.answer-dashboard-card[data-letter="' + letter + '"]')
      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
      }
    },
    verifyUsersOverflow() {
      let container = this.$refs.answerslist
      if (container) {
        this.usersOveflow = container.scrollHeight > container.clientHeight
        if (this.usersOveflow) {
          container.addEventListener('scroll', () => {
            if (container.offsetHeight + container.scrollTop >= container.scrollHeight) {
              this.usersOveflow = false
            } else {
              this.usersOveflow = true
            }
          })
        }
      } else {
        this.usersOveflow = false
      }
    }
  }
}
</script>
<i18n>
  {
    "pt": {
      "participants": "Participantes",
      "results": "Resultados",
      "all": "Todos",
      "running": "Em curso",
      "submited": "Entregues",
      "giveup": "Desistências",
      "noanswer": "Sem resposta",
      "noanswers": "Sem avaliações para mostrar.",
      "noanswers_answering": "Sem avaliações a decorrer.",
      "noanswers_closed": "Sem respostas entregues.",
      "noanswers_withdraw": "Sem desistências.",
      "noanswers_noanswer": "Sem avaliações sem resposta."
    },
    "en": {
      "participants": "Participants",
      "results": "Results",
      "all": "All",
      "running": "Running",
      "submited": "Submited",
      "giveup": "Withdrawn",
      "noanswer": "No answer",
      "noanswers": "Without any answers to show.",
      "noanswers_answering": "Without any running evaluations.",
      "noanswers_closed": "Without any submited answers.",
      "noanswers_withdraw": "Without any withdraws.",
      "noanswers_noanswer": "Without any evaluations without answers."
    }
  }
</i18n>
