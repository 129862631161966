<template>
  <div>
    <div class="flex items-center mb-1">
      <div class="flex-1">
        <fw-label>{{ label }}</fw-label>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'PlaceholderBase',
  props: {
    label: {
      type: String,
      default: 'Etiqueta'
    },
    isRequired: {
      type: Boolean,
      default: false
    }
  }
}
</script>
