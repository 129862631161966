<template>
  <div class="flex flex-col text-center gap-3 w-full h-full items-center justify-center">
    <component
      :is="'fw-icon-' + icon"
      class="h-16 w-16 fill-current "
      :class="{ 'text-primary': dark, 'text-gray-500': !dark }"
    />
    <div
      class="text-lg text-center text-opacity-60 mx-auto max-w-sm"
      :class="{
        'text-white text-opacity-60': dark,
        'text-gray-800': !dark
      }"
    >
      {{ title }}
    </div>
    <div
      class="text-sm font-medium  text-opacity-90 animate-pulse"
      :class="{
        'text-white text-opacity-60': dark,
        'text-primary': !dark
      }"
    >
      A carregar...
    </div>
    <div
      class="w-32 h-3 rounded-xl shadow-inner"
      :class="{
        'bg-white bg-opacity-10': dark,
        'bg-gray-400 bg-opacity-80': !dark
      }"
    >
      <div class="loading-bar h-3 rounded-full bg-primary shadow-md border-t border-white border-opacity-20"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: 'cube'
    },
    title: {
      type: String,
      default: 'Página'
    },
    dark: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.loading-bar {
  width: 12%;
  animation-name: loading-bar-anim;
  animation-duration: 3s;
  animation-delay: 500ms;
  animation-fill-mode: forwards;
}

@keyframes loading-bar-anim {
  from {
    width: 12%;
  }
  to {
    width: 100%;
  }
}
</style>
