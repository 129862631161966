<template>
  <div class="w-full">
    <div class="flex gap-5 mb-1">
      <PlaceholderBase class="flex-1" :label="address[language]">
        <div class="placeholder-input">
          {{ address_placeholder[language] }}
        </div>
      </PlaceholderBase>
      <PlaceholderBase :label="number[language]">
        <div class="placeholder-input">
          {{ number_placeholder[language] }}
        </div>
      </PlaceholderBase>
    </div>
    <div class="flex gap-5 mb-1">
      <PlaceholderBase class="flex-1" :label="postal_code[language]">
        <div class="placeholder-input">
          {{ postal_code_placeholder[language] }}
        </div>
      </PlaceholderBase>
      <PlaceholderBase class="flex-1" :label="locality[language]">
        <div class="placeholder-input">
          {{ locality_placeholder[language] }}
        </div>
      </PlaceholderBase>
    </div>
    <PlaceholderBase class="w-full" :label="country[language]">
      <div class="placeholder-input w-full">
        {{ country_placeholder[language] }}
        <fw-icon-chevron-up class="absolute top-1.5 right-2 h-5 w-5" />
        <fw-icon-chevron-down class="absolute bottom-1.5 right-2 h-5 w-5" />
      </div>
    </PlaceholderBase>
  </div>
</template>

<script>
import PlaceholderBase from '@/fw-modules/fw-core-vue/forms/components/editor_placeholders/PlaceholderBase'

export default {
  name: 'PlaceholderTextInput',
  components: {
    PlaceholderBase
  },
  props: {
    isRequired: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: Object,
      default: () => {
        return {
          pt: 'Insira o número do documento',
          en: 'Enter here the document number'
        }
      }
    },
    language: {
      type: String,
      default: 'pt'
    }
  },
  data() {
    return {
      address: {
        pt: 'Morada completa',
        en: 'Full address'
      },
      address_placeholder: {
        pt: 'Insira a morada',
        en: 'Enter here the address'
      },
      number: {
        pt: 'Número',
        en: 'Number'
      },
      number_placeholder: {
        pt: 'Insira o número',
        en: 'Enter here the number'
      },
      postal_code: {
        pt: 'Código Postal',
        en: 'Postal Code'
      },
      postal_code_placeholder: {
        pt: 'Insira o código postal',
        en: 'Enter here the postal code'
      },
      locality: {
        pt: 'Localidade',
        en: 'Locality'
      },
      locality_placeholder: {
        pt: 'Insira a localidade',
        en: 'Enter here the locality'
      },
      country: {
        pt: 'País',
        en: 'Country'
      },
      country_placeholder: {
        pt: 'Selecione o país',
        en: 'Select the country'
      }
    }
  }
}
</script>
