<template>
  <div
    class="flex px-3 cursor-pointer py-3 gap-3 my-1 relative w-full text-left group rounded-lg text-sm items-center"
    :class="{
      'bg-white shadow': data.key == selected,
      'bg-gray-300 bg-opacity-20 hover:text-gray-800': data.key !== selected
    }"
  >
    <div class="flex-1">
      <div class="flex text-base gap-2 text-left items-center">
        <fw-icon-group class="text-gray-500 h-5 w-5" />
        <div>
          <v-clamp autoresize :max-lines="1">
            {{ data.title }}
          </v-clamp>
        </div>
      </div>
      <div v-if="data.created_date" class="text-xs text-gray-500 flex gap-2 ml-7">
        <div>Criado em {{ data.created_date | formatDateTime }}</div>
      </div>
    </div>

    <div v-if="data.students?.length" class="flex items-center gap-1 -mb-px">
      <b-tooltip
        v-for="userkey in data.students"
        :key="userkey"
        :label="users?.[userkey]?.full_name"
        type="is-light"
        position="is-bottom"
      >
        <fw-avatar :key="userkey" size="micro" :user="users?.[userkey]"> </fw-avatar>
      </b-tooltip>
    </div>

    <fw-button
      v-if="editable"
      class="flex gap-1 w-32"
      type="border-light"
      icon="add-circle"
      @click.native="$emit('edit-students', data)"
    >
      Adicionar
    </fw-button>

    <slot name="end"></slot>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object
    },
    selected: {
      type: String,
      default: null
    },
    users: {
      type: Object,
      default: () => {}
    },
    editable: {
      type: Boolean,
      default: false
    }
  }
}
</script>
