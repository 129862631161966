<template>
  <fw-modal
    :title="$t('userdetails')"
    :active.sync="showUserModal"
    :can-cancel="true"
    size="7xl"
    :width="null"
    @close="closeUserModal()"
  >
    <template v-if="userAnswer != null || userObject != null" #default>
      <div class="flex gap-5 justify-start">
        <div class="flex-1 h-full flex flex-col gap-3 items-stretch justify-start text-left">
          <BlockUserDetails
            class="text-left"
            :user="userAnswer !== null ? userAnswer.user : userObject"
            :paddingless="true"
            :only-header="true"
          ></BlockUserDetails>

          <div v-if="userVersion != null">
            <fw-label>Exame</fw-label>
            <div class="bg-gray-100 px-5 py-2 rounded-lg flex gap-5 justify-between">
              <div>
                <fw-label>{{ $t('status') }}</fw-label>
                <div class="text-lg font-bold">{{ userStatusMessage }}</div>
              </div>
              <div>
                <fw-label>{{ $t('version') }}</fw-label>
                <div class="text-lg font-bold">{{ userVersion.letter }}</div>
              </div>

              <div>
                <fw-label>{{ $t('answers') }}</fw-label>
                <div class="text-lg font-bold">
                  {{ userAnswer.summary.answers !== null ? userAnswer.summary.answers : '--' }} /
                  {{ userVersion.numQuestions }}
                </div>
              </div>

              <div>
                <fw-label>{{ $t('started') }}</fw-label>
                <div v-if="userAnswer.started_date" class="text-lg font-bold">
                  {{ userAnswer.started_date | humanDateTimePT }}
                </div>
                <div v-else class="text-gray-300">{{ $t('notstarted') }}</div>
              </div>

              <div>
                <fw-label>{{ $t('ended') }}</fw-label>
                <div v-if="userAnswer.closed_date" class="text-lg font-bold">
                  {{ userAnswer.closed_date | humanDateTimePT }}
                </div>
                <div v-else class="text-gray-300">{{ $t('notended') }}</div>
              </div>
            </div>
          </div>

          <div v-if="userDevice != null">
            <fw-label>Dispositivo</fw-label>
            <div class="bg-gray-100 px-5 py-2 rounded-lg flex gap-5 justify-between">
              <div>
                <fw-label>{{ $t('reference') }}</fw-label>
                <div class="text-lg font-bold">{{ userDevice.reference_key }}</div>
              </div>
              <div>
                <fw-label>{{ $t('description') }}</fw-label>
                <div class="text-lg font-bold">{{ userDevice.description ?? '--' }}</div>
              </div>
              <div>
                <fw-label>{{ $t('revoke') }}</fw-label>
                <div>
                  <fw-button type="regular-light" @click.native="$emit('revoke')">{{ $t('revoke_device') }}</fw-button>
                </div>
              </div>
            </div>
          </div>

          <div v-if="userAnswer">
            <fw-label>{{ $t('termsofusage') }}</fw-label>
            <div class="bg-gray-100 pr-5 pl-3 py-3 rounded-lg flex items-start">
              <div
                ref="userphoto"
                class="w-44 h-56 rounded-lg overflow-hidden bg-center bg-cover bg-gray-200 flex flex-col items-center justify-center text-sm font-semibold text-gray-300"
                :style="{ backgroundImage: 'url(' + userPhoto + ')' }"
              >
                <div v-if="userPhoto === ''">{{ $t('nophoto') }}</div>
              </div>
              <div class="flex flex-col gap-2 px-5 py-3">
                <div v-if="userAnswer.acceptedTermsDate != null" class="items-center">
                  <div class="bg-primary h-4 w-4 rounded-full inline-block">
                    <fw-icon-check class="text-white h-4 w-4" />
                  </div>
                  {{ $t('acceptedterms') }}
                  <div class="font-bold">{{ userAnswer.acceptedTermsDate | humanDateTimePT }}</div>
                </div>
                <div v-else class="flex gap-2 items-center">
                  <div class="bg-gray-200 h-4 w-4 rounded-full"></div>
                  {{ $t('notacceptedterms') }}
                </div>
                <div v-if="userAnswer.photo !== null" class="flex gap-2 items-center">
                  <div class="bg-primary h-4 w-4 rounded-full">
                    <fw-icon-check class="text-white h-4 w-4" />
                  </div>
                  {{ $t('phototaken') }}
                </div>
                <div v-else class="flex gap-2 items-center">
                  <div class="bg-gray-200 h-4 w-4 rounded-full"></div>
                  {{ $t('photonottaken') }}
                </div>
              </div>
            </div>
          </div>

          <fw-panel-info
            v-if="userAnswer !== null || userObject !== null"
            debug
            label="User answers (raw)"
            class="my-3"
          >
            <json-viewer v-if="userAnswer" :value="userAnswer || userObject"></json-viewer>
            <json-viewer v-if="userObject" :value="userObject"></json-viewer>
          </fw-panel-info>
        </div>
        <div class="bg-gray-100 px-3 py-2 rounded-lg flex flex-col gap-2 w-96 flex-shrink-0">
          <fw-label>{{ $t('participantactivity') }}</fw-label>
          <div
            v-if="!loadingActivity && activity.length"
            class="flex flex-col gap-2 overflow-y-auto rounded-md max-h-screen-3-4"
          >
            <ActivityCard v-for="actv in activity" :key="'user_' + actv.key" :activity="actv" :users="users">
            </ActivityCard>
          </div>
          <div
            v-else-if="loadingActivity"
            class="flex flex-col gap-2 justify-center rounded-md max-h-screen-3-4 animate-pulse text-gray-400 text-center text-sm"
          >
            A carregar...
          </div>
          <div
            v-else-if="!loadingActivity"
            class="flex flex-col gap-2 justify-center rounded-md max-h-screen-3-4 animate-pulse text-gray-400 text-center text-sm"
          >
            Sem atividade
          </div>
        </div>
      </div>
    </template>
    <template v-else #default>
      {{ $t('usernotfound') }}
    </template>
    <template #toolbar>
      <fw-button type="link" @click.native="closeUserModal()">{{ $t('close') }}</fw-button>
    </template>
  </fw-modal>
</template>

<script>
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import BlockUserDetails from '@/fw-modules/fw-core-vue/id/components/blocks/BlockUserDetails'
import ActivityCard from '@/fw-modules/fw-core-vue/exams/components/cards/ActivityCard'
import FormServices from '@/fw-modules/fw-core-vue/exams/services/ServiceExams'
import axios from 'axios'

export default {
  name: 'ModalDashboardUser',
  components: {
    BlockUserDetails,
    ActivityCard
  },
  props: {
    showUserModal: {
      type: Boolean,
      required: true
    },
    userAnswer: {
      type: Object,
      default: () => {
        return null
      }
    },
    userObject: {
      type: Object,
      default: () => {
        return null
      }
    },
    versionsMap: {
      type: Object,
      required: true
    },
    versions: {
      type: Array,
      required: true
    },
    userActivity: {
      type: Array,
      required: true
    },
    users: {
      type: Object
    },
    instance: {
      type: Object
    }
  },
  data() {
    return {
      userPhoto: '',
      localActivity: [],
      loadingActivity: false
    }
  },
  computed: {
    activity() {
      return this.localActivity.length > 0 ? this.localActivity : this.userActivity
    },
    userDevice() {
      return this.userAnswer !== null && this.userAnswer.device !== null ? this.userAnswer.device : null
    },
    userVersion() {
      return this.userModalKey !== null && this.userAnswer !== null
        ? this.versions[this.versionsMap[this.userAnswer.formKey]]
        : null
    },
    userStatusMessage() {
      if (this.userModalKey === null || this.userAnswer === null) return ''
      return this.userAnswer.state == 'noAnswer'
        ? 'Sem resposta'
        : this.userAnswer.state == 'closed' || this.userAnswer.state == 'close'
        ? 'Entregue'
        : this.userAnswer.state == 'withdraw'
        ? 'Desistiu'
        : this.userAnswer.state == 'answering'
        ? 'Em curso'
        : this.userAnswer.state ?? ''
    }
  },
  watch: {
    userAnswer(val) {
      this.userPhoto = ''
      if (val !== null && val.photo !== null) {
        this.renterUserPhoto(val.photo)
      }
    }
  },
  created() {
    if (this.userActivity.length === 0) {
      // get user activity
      this.getUserActivity()
    }
  },
  mounted() {
    this.userPhoto = ''
    if (this.userAnswer !== null) {
      this.renterUserPhoto(this.userAnswer.photo)
    }
  },
  methods: {
    async getUserActivity() {
      if (this.instance && this.userAnswer && this.userAnswer.user.key) {
        this.loadingActivity = true
        try {
          let result = await FormServices.getActivityLogs({
            instanceKey: this.instance.key,
            sort: 'created_date',
            direction: 'desc'
          })
          this.activity = result.logs.filter(activity => activity.by_user_key == this.userAnswer.user.key)
          this.loadingActivity = false
        } catch (e) {
          console.log('error', e)
        } finally {
          this.loadingActivity = false
        }
      }
    },
    closeUserModal() {
      this.$emit('close')
    },
    renterUserPhoto(photo) {
      console.log('rendering photo', photo)
      let url = ServiceStorage.getFileUrl(photo, null)
      axios
        .get(url)
        .then(response => {
          console.log('response', response.data)
          this.userPhoto = response.data
        })
        .catch(e => {
          this.userPhoto = ''
          console.log('error', e)
        })
    }
  }
}
</script>
<i18n>
{
  "pt": {
    "reference": "Referência do tablet",
    "description": "Descrição",
    "status": "Estado",
    "version": "Versão",
    "answers": "Respostas",
    "started": "Iniciou em",
    "ended": "Terminou em",
    "notstarted": "Não iniciou",
    "notended": "Não terminou",
    "userdetails": "Detalhe do participante",
    "termsofusage": "Termos de utilização e foto de controlo",
    "nophoto": "SEM FOTO",
    "acceptedterms": "O participante aceitou os termos de realização do exame",
    "notacceptedterms": "O participante ainda não aceitou os termos de realização do exame",
    "phototaken": "Foi tirada foto ao participante no início do exame",
    "photonottaken": "Não foi tirada foto ao participante no início do exame",
    "participantactivity": "Atividade do participante",
    "usernotfound": "Dados do utilizador não encontrados",
    "close": "Fechar",
    "revoke": "Revogar",
    "revoke_device": "Revogar dispositivo"
  },
  "en": {
    "reference": "Tablet reference",
    "description": "Description",
    "status": "Status",
    "version": "Version",
    "answers": "Answers",
    "started": "Started at",
    "ended": "Ended at",
    "userdetails": "Participant details",
    "notstarted": "Didn't start",
    "notended": "Didn't end",
    "termsofusage": "Terms of usage and control photo",
    "notacceptedterms": "The participant did not accept the terms of use of this exam",
    "nophoto": "NO PHOTO",
    "acceptedterms": "The participant accepted the terms of use of this exam",
    "phototaken": "A photo of the participant was taken at the satrt of the exam",
    "photonottaken": "It was not taken a photo of the participant at the start of teh exam",
    "participantactivity": "Participant activity",
    "usernotfound": "User data not found",
    "close": "Close",
    "revoke": "Revoke",
    "revoke_device": "Revoke device"
  }
}
</i18n>
