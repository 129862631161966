<template>
  <div
    class="flex items-center gap-1 text-xs"
    :class="[
      {
        'bg-red-400 text-opacity-70 text-gray-900 bg-opacity-90': dirtyData && !savingData,
        'text-opacity-60 text-gray-600 bg-opacity-70': !dirtyData || savingData,
        'font-semibold': bold,
        'leading-4 py-0': shorter,
        'leading-5 py-0.5': !shorter,
        'px-1.5': !paddingLess && shorter,
        'px-2': !paddingLess && !shorter
      }
    ]"
  >
    <svg
      v-if="savingData"
      class="fill-current flipanimation"
      :class="{ 'h-3 w-3': shorter, 'h-4 w-4': !shorter }"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        d="M18 19h1V6.828L17.172 5H16v4H7V5H5v14h1v-7h12v7zM4 3h14l2.707 2.707a1 1 0 0 1 .293.707V20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm4 11v5h8v-5H8z"
      />
    </svg>
    <faicon v-else-if="dirtyData" icon="exclamation-triangle" :class="{ 'transform scale-75': shorter }" />
    <svg
      v-else
      class="fill-current"
      :class="{ 'h-3 w-3': shorter, 'h-4 w-4': !shorter }"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        d="M18 19h1V6.828L17.172 5H16v4H7V5H5v14h1v-7h12v7zM4 3h14l2.707 2.707a1 1 0 0 1 .293.707V20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm4 11v5h8v-5H8z"
      />
    </svg>
    {{ label }}
  </div>
</template>

<script>
export default {
  name: 'SavedIndicator',
  props: {
    dirtyData: {
      type: Boolean,
      default: false
    },
    savingData: {
      type: Boolean,
      default: false
    },
    bold: {
      type: Boolean,
      default: true
    },
    shorter: {
      type: Boolean,
      default: false
    },
    paddingLess: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    label() {
      return this.savingData ? 'A guardar...' : this.dirtyData ? 'Dados não guardados' : 'Dados guardados'
    }
  }
}
</script>

<style scoped></style>
