var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col text-center gap-3 w-full h-full items-center justify-center"},[_c('fw-icon-' + _vm.icon,{tag:"component",staticClass:"h-16 w-16 fill-current",class:{ 'text-primary': _vm.dark, 'text-gray-500': !_vm.dark }}),_c('div',{staticClass:"text-lg text-center text-opacity-60 mx-auto max-w-sm",class:{
      'text-white text-opacity-60': _vm.dark,
      'text-gray-800': !_vm.dark
    }},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"text-sm font-medium text-opacity-90 animate-pulse",class:{
      'text-white text-opacity-60': _vm.dark,
      'text-primary': !_vm.dark
    }},[_vm._v(" A carregar... ")]),_c('div',{staticClass:"w-32 h-3 rounded-xl shadow-inner",class:{
      'bg-white bg-opacity-10': _vm.dark,
      'bg-gray-400 bg-opacity-80': !_vm.dark
    }},[_c('div',{staticClass:"loading-bar h-3 rounded-full bg-primary shadow-md border-t border-white border-opacity-20"})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }