<template>
  <SidebarBase>
    <template #main>
      <ButtonSidebar label="Edições" icon="cube" :active="view == 'dashboard'" :to="`${baseUrl}/dashboard`" />
      <ButtonSidebar
        v-if="false"
        label="Metadados"
        icon="journal"
        :active="view == 'metadata'"
        :to="`${baseUrl}/metadata`"
      />
      <ButtonSidebar label="Pessoas" icon="people" :active="view == 'people'" :to="`${baseUrl}/people`" />
      <ButtonSidebar
        v-if="validations?.can_edit"
        :to="`${baseUrl}/activity`"
        icon="activity"
        label="Atividade"
        class="rounded-md"
        :active="view == 'activity'"
      />
      <ButtonSidebar
        v-if="validations?.can_edit"
        :to="`${baseUrl}/notifications`"
        label="Notificações"
        icon="message-sent"
        class="rounded-md"
        :active="view == 'notifications'"
      />
      <ButtonSidebar :active="view == 'settings'" label="Configurações" icon="settings" :to="`${baseUrl}/settings`" />
    </template>
  </SidebarBase>
</template>

<script>
import SidebarBase from '@/fw-modules/fw-core-vue/ui/components/sidebars/SidebarBase'
import ButtonSidebar from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonSidebar'

export default {
  components: {
    ButtonSidebar,
    SidebarBase
  },

  props: {
    course: {
      type: Object,
      default: () => ({})
    },
    validations: {
      type: Object,
      default: () => {
        return {
          can_edit: true,
          can_view: true
        }
      }
    }
  },

  computed: {
    user() {
      return this.$store.getters.getUser
    },
    permissions() {
      return this.$store.getters.userPermissions
    },
    currentURL() {
      return this.$router.currentRoute.path
    },
    baseUrl() {
      return '/manage/course/' + this.course.key
    },
    view() {
      return this.$route.params.view ?? 'dashboard' // this.$store.state.examManageView
    }
  }
}
</script>
