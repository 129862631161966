var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"flex py-3 px-2 gap-3 relative w-full text-left group rounded-lg items-center font-medium text-gray-600 hover:text-gray-800 font-medium",class:{
    'text-primary bg-white shadow': _vm.data.key == _vm.selected,
    'bg-gray-300 bg-opacity-20 shadow-sm': _vm.data.key != _vm.selected
  }},[_c('div',{staticClass:"font-bold text-sm pl-7 py-1 pr-1 flex gap-1 text-gray-500"},[(_vm.data.type == 'normal')?_c('span',[_vm._v(_vm._s(_vm.data.index + 1))]):_vm._e(),_c('fw-icon-list-view',{staticClass:"w-5 h-5 text-gray-500"})],1),_c('div',{staticClass:"flex-1"},[(_vm.data.title)?_c('v-clamp',{staticClass:"font-medium",attrs:{"autoresize":"","max-lines":1}},[_vm._v(" "+_vm._s(_vm.data.title)+" ")]):_c('div',{staticClass:"text-gray-400 text-sm"},[_vm._v("Sem título")])],1),(_vm.data.class_availability)?_c('fw-tag',{attrs:{"type":_vm.data.class_availability == 'full'
        ? 'light-primary'
        : _vm.data.class_availability == 'partial'
        ? 'light-orange'
        : 'light-danger'}},[_vm._v(" "+_vm._s(_vm.data.class_availability == 'full' ? 'Disponível' : _vm.data.class_availability == 'partial' ? 'Parte disponível' : 'Indisponível')+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }