<template>
  <div
    class="rounded-xl transition-all shadow-lg hover:shadow-2xl cursor-pointer overflow-hidden relative bg-center bg-cover text-center flex-shrink-0"
    :style="{ backgroundImage: 'url(' + backgroundImage + ')' }"
  >
    <div class="exam-cover-gradient flex flex-col p-4 gap-5 text-white h-full">
      <div class="flex text-sm items-center gap-2 font-semibold text-left">
        <div>
          <fw-icon-survey class="h-5 w-5 opacity-80"></fw-icon-survey>
        </div>
        <div class="flex-1 opacity-80">
          {{ instance.mode == 'presential' ? $t('metadata.presential') : $t('metadata.remote') }}
        </div>
        <div v-if="false">
          <div
            v-for="(lang, l) in instance.availableLanguages"
            :key="'language_' + l"
            class=" uppercase border border-white opacity-80 rounded px-1 py-0.5 text-xs font-semibold"
          >
            {{ lang }}
          </div>
        </div>
      </div>
      <div class="flex-1 flex flex-col justify-center gap-3 mb-2">
        <div class="text-center text-white text-sm font-bold ">{{ instance.startDate | formatDateTime }}</div>
        <div
          v-if="instance.title != null && instance.title.length > 0"
          class="font-bold text-center w-4/5 mx-auto text-lg"
        >
          <v-clamp autoresize :max-lines="2"> {{ instance.title }}</v-clamp>
        </div>
        <div v-else class="text-white text-opacity-50 text-center w-3/5 mx-auto">
          Sem título
        </div>
        <div
          v-if="instance.status == 'running'"
          class="text-white text-sm font-bold text-center w-3/5 mx-auto flex flex-col gap-1.5 justify-center items-center"
        >
          <div class="inline-flex flex-shrink-0 text-primary relative gap-2">
            <div>Em curso</div>
            <fw-dot color="primary" inline class="mt-1" />
          </div>
          <div class="flex w-full gap-2 mt-0.5 text-white text-opacity-40 font-bold items-center">
            <div class="h-3 rounded-full flex-1 bg-white bg-opacity-20">
              <div
                :style="{ width: instance.progress.toString() + '%' }"
                class="h-3 rounded-full progress-gradient"
              ></div>
            </div>
            {{ instance.progress }}%
          </div>
        </div>
        <div v-if="instance.location" class="text-center opacity-80 font-medium text-white text-xs">
          <v-clamp autoresize :max-lines="1">{{ instance.location }}</v-clamp>
        </div>
      </div>
      <div class="flex text-sm items-end gap-5 text-left justify-end">
        <div v-if="examCreator != null && !instance.isOwner" class="flex-1">
          <fw-avatar size="xs" :user="examCreator" />
        </div>
        <div class="opacity-90">
          <fw-tag type="light"><span class="opacity-50 mr-1">Duração</span> {{ examDuration | prettySeconds }}</fw-tag>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExamCard',
  //components: { TextStatus },
  props: {
    users: {
      type: Object
    },
    language: {
      type: String,
      default: 'pt'
    },
    instance: {
      type: Object
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser
    },
    backgroundImage() {
      let bkimgMap = {
        'abstract-01': 'https://static.fw.uc.pt/banners/abstract-01.jpg',
        'abstract-02': 'https://static.fw.uc.pt/banners/abstract-02.jpg',
        'abstract-03': 'https://static.fw.uc.pt/banners/abstract-03.jpg'
      }
      return bkimgMap[this.instance && this.instance.cover ? this.instance.cover : 'abstract-01']
    },
    predictedStartDate() {
      return this.instance != null && this.instance.startDate != null
        ? this.$options.filters.formatDayWithTime(this.instance.startDate)
        : ''
    },
    examDuration() {
      // Convert to seconds
      return this.instance && this.instance.duration ? Number(this.instance.duration) * 60 : 0
    },
    examCreator() {
      return this.users != null && this.users[this.instance.user_key] ? this.users[this.instance.user_key] : null
    },
    examDynamizer() {
      return this.users != null && this.instance.managers.length > 0 ? this.users[this.instance.managers[0]] : null
    },
    examName() {
      return this.overrideExamName.length > 0 ? this.overrideExamName : this.form && this.form.title[this.language]
    }
  }
}
</script>

<i18n>
    {
      "en": {
        "metadata": {
          "presential": "Presential exam",
          "remote": "Remote exam",
          "open": "Public",
          "restricted": "Restricted"
        },
        "instructions": {
          "examcreatedby": "Exam created by",
          "examdynamizedby": "Exam dynamized by"
        }
      },
      "pt": {
        "metadata": {
          "presential": "Presencial",
          "remote": "Remoto",
          "open": "Aberto",
          "restricted": "Restrito"
        },
        "instructions": {
          "examcreatedby": "Exame criado por",
          "examdynamizedby": "Exame dinamizado por"
        }
      }
    }
    </i18n>
<style scoped>
.exam-cover-gradient {
  background: rgb(38, 38, 38);
  background: linear-gradient(0deg, rgba(38, 38, 38, 1) 25%, rgba(54, 54, 54, 0.48) 80%);
}
.progress-gradient {
  background: rgb(56, 195, 177);
  background: linear-gradient(90deg, rgba(56, 195, 177, 1) 0%, rgba(116, 246, 230, 1) 85%);
}
</style>
