<template>
  <div
    class="answer-dashboard-card flex flex-col gap-1.5 rounded-xl"
    :class="{
      'bg-normal': type === 'normal',
      'bg-warning': type === 'warning',
      'bg-attention': type === 'attention',
      'flex-shrink-0': noShrink,
      'w-full': fullWidth
    }"
  >
    <slot name="header"></slot>
    <div class="flex gap-3">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'DashboardCard',
  props: {
    type: {
      type: String,
      default: 'normal'
    },
    noShrink: {
      type: Boolean,
      default: true
    },
    fullWidth: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style scoped>
.answer-dashboard-card.bg-normal:hover {
  background-color: #555555;
}
.answer-dashboard-card.bg-normal {
  background-color: #464646;
}
.answer-dashboard-card.bg-warning {
  background-color: #f09000;
}
.answer-dashboard-card.bg-warning:hover {
  background-color: #f9ac39;
}
.answer-dashboard-card.bg-attention {
  background-color: #38c3b1;
}
.answer-dashboard-card.bg-attention:hover {
  background-color: #59dfcd;
}
</style>
