<template>
  <div class="fw-multiplechoice flex w-full flex-col gap-2">
    <div
      v-for="(option, o) in options"
      :key="'option_' + o"
      class="multiplechoice_option_editor py-1 px-2 relative border border-gray-100 rounded-md text-base font-semibold text-gray-800 bg-white w-full text-left flex justify-start items-center group gap-4"
    >
      <div
        class="multiplechoice_letter text-center py-2 bg-gray-200 uppercase rounded-xl leading-4 text-lg font-medium"
        style="width: 2rem"
      >
        {{ String.fromCharCode(97 + o) }}
      </div>
      <div class="flex-1 flex gap-2 items-center">
        <div class="flex-1 w-full text-base font-semibold">
          {{ option.text[language] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlaceholderTextInput',
  props: {
    language: {
      type: String,
      default: 'pt'
    },
    options: {
      type: Array,
      default: () => {
        return []
      }
    }
  }
}
</script>
