<template>
  <fw-layout :footer="false" extended-logo mobile-ready :back-to-enable="false">
    <template #header-toolbar>
      <HeaderLanguagePanel />
    </template>

    <template #main-content>
      <div class="centered-container">
        <fw-panel :title="$t('document_invitation')" :custom-class="'bg-white p-8 w-96'">
          <div class="flex flex-row gap-2 md:gap-8">
            <div class="w-full">
              {{ $t('message1') }} <b>{{ documentTitle }}</b
              >. {{ $t('message2') }} <b>{{ inviteEmail }}</b> {{ $t('message3') }}
              <div class="mt-4 flex flex-col gap-2">
                <fw-button
                  v-if="isLoggedIn"
                  expanded
                  :disabled="!canAcceptInvite || loading"
                  :type="canAcceptInvite ? 'primary' : 'xlight'"
                  @click.native="acceptInvite"
                >
                  {{ $t('accept_invite') }}
                </fw-button>
                <fw-button v-else expanded type="primary" @click.native="login()">
                  {{ $t('login') }}
                </fw-button>
                <fw-button v-if="!isLoggedIn" expanded type="xlight" @click.native="createAccount()">
                  {{ $t('create_account') }}
                </fw-button>
              </div>
            </div>
          </div>
        </fw-panel>
      </div>
    </template>
  </fw-layout>
</template>

<script>
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import HeaderLanguagePanel from '@/fw-modules/fw-core-vue/ui/legacy/components/header/HeaderLanguagePanel'
import ServicePages from '@/fw-modules/fw-core-vue/pages/services/ServicePages'
export default {
  name: 'ViewInvite',

  components: {
    HeaderLanguagePanel
  },

  data() {
    return {
      supportEmail: 'support@ucframework.pt',
      debugMode: Boolean(localStorage.getItem('fw-debug')),
      loading: false,
      invite: null,
      page: null,
      error: null
    }
  },

  computed: {
    documentTitle() {
      return this.page ? this.page.versions.find(v => v.is_original).title : this.$t('no_title')
    },
    canAcceptInvite() {
      return true
    },
    inviteKey() {
      return this.$route.params.token
    },
    pageKey() {
      return this.$route.params.key
    },
    inviteEmail() {
      return this.invite ? this.invite.email : ''
    },
    isLoggedIn() {
      return this.$store.getters['isLoggedIn']
    }
  },

  async created() {
    //load invite info
    await utils.tryAndCatch(this, async () => {
      let result = await ServicePages.getInvite(this.pageKey, this.inviteKey)
      console.log('getInvite', result)
      this.invite = result.invite
      this.page = result.page
    })
  },

  methods: {
    login() {
      this.$router.push({ name: 'login', query: { redirect: '/pages/' + this.pageKey + '/invite/' + this.inviteKey } })
    },
    createAccount() {
      this.$router.push({
        name: 'external-account-signup',
        query: { redirect: '/pages/' + this.pageKey + '/invite/' + this.inviteKey }
      })
    },
    async acceptInvite() {
      //accept invite
      this.loading = true
      await utils.tryAndCatch(this, async () => {
        let result = await ServicePages.acceptInvite(this.pageKey, this.inviteKey)
        console.log('acceptInvite', result)
        this.$buefy.snackbar.open({ message: this.$t('accept_invite_success'), type: 'is-success' })
        //redirect to page
        this.$router.push({ name: 'content-pages-editor', params: { key: this.pageKey } })
      })
      this.loading = false
    }
  }
}
</script>

<i18n>
{
  "en": {
    "document_invitation": "Collaboration invitation",
    "accept_document_invite": "Accept document invite",
    "accept_invite": "Accept invite",
    "accept_invite_success": "Invite accepted successfully",
    "accept_invite_error": "Error accepting invite",
    "message1": "You have been invited to collaborate on the document",
    "message2": "To accept the invite, please login with the account",
    "message3": "and access the document.",
    "no_title": "No title",
    "login": "Login",
    "create_account": "Create account"
  },
  "pt": {
    "document_invitation": "Convite de colaboração",
    "accept_document_invite": "Aceitar convite de documento",
    "accept_invite": "Aceitar convite",
    "accept_invite_success": "Convite aceite com sucesso",
    "accept_invite_error": "Erro ao aceitar convite",
    "message1": "Foi convidado para colaborar no documento",
    "message2": "Para aceitar o convite, por favor, inicie sessão na conta",
    "message3": "e acesse o documento.",
    "no_title": "Sem título",
    "login": "Iniciar sessão",
    "create_account": "Criar conta"
  }
}
</i18n>
