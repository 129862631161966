<template>
  <div class="h-full flex flex-col">
    <div class="text-lg font-bold mx-5 pb-2 mt-5">
      {{ data != null ? 'Editar entrada' : 'Nova entrada' }}
    </div>

    <div class="px-5 flex-1 flex flex-col gap-5 overflow-y-auto overflow-x-hidden" style="max-height: 650px">
      <InnerForm
        :ref="'innerform'"
        name="ModalForm"
        :data="data"
        :language="language"
        :schema="schema"
        :has-section="false"
        :input-gap="'gap-6'"
        :section-gap="'gap-12'"
        :page-prefix="'form_'"
        :debug="false"
      ></InnerForm>
    </div>
    <div class="flex items-center justify-end px-5 py-4 gap-5">
      <fw-button type="link-muted" @click.native="closeModal">
        {{ cancelText }}
      </fw-button>

      <fw-button :type="!confirmDisabled ? 'primary' : 'disabled'" :disabled="confirmDisabled" @click.native="save">
        {{ saveText }}
      </fw-button>
    </div>
  </div>
</template>

<script>
import InnerForm from '@/fw-modules/fw-core-vue/ui/components/form/InnerForm.vue'
import { required, requiredIf } from 'vuelidate/lib/validators'

export default {
  name: 'CustomObjectModal',
  components: {
    InnerForm
  },
  props: {
    messageText: {
      type: String,
      default: function() {
        return this.$t('save')
      }
    },
    saveText: {
      type: String,
      default: function() {
        return this.$t('save')
      }
    },
    cancelText: {
      type: String,
      default: function() {
        return this.$t('cancel')
      }
    },
    modalTitle: {
      type: String,
      default: ''
    },
    virtualMeeting: {
      type: Object,
      default: function() {
        return null
      }
    },
    showSendMessageForm: {
      type: Boolean,
      default: false
    },
    confirmDisabled: {
      type: Boolean,
      default: false
    },
    meetingData: {
      type: Object
    },
    subjectOptions: {
      type: Array,
      default: function() {
        return []
      }
    },
    typeOptions: {
      type: Array,
      default: function() {
        return [
          {
            text: this.$t('onlineMeeting'),
            value: 'online'
          },
          {
            text: this.$t('inPersonMeeting'),
            value: 'presential'
          }
        ]
      }
    },
    minDate: {
      type: Date
    },
    schema: {
      type: Array
    },
    language: {
      type: String,
      default: 'pt'
    },
    data: {
      type: Object,
      default: function() {
        return null
      }
    }
  },

  data() {
    return {
      meetingData_tmp: {
        key: null,
        subject: '',
        type: 'online',
        date: new Date(),
        hour: null,
        min: null,
        duration: 0,
        location: null
      }
    }
  },

  validations() {
    return {
      meetingData_tmp: {
        type: { required },
        subject: { required },
        date: { required },
        location: {
          required: requiredIf(function(nestedModel) {
            return nestedModel.type !== 'online'
          })
        }
      }
    }
  },

  created() {
    if (this.meetingData) {
      this.meetingData_tmp = this.meetingData
    }
  },

  methods: {
    dataChanged(newData) {
      console.log(newData)
    },

    closeModal() {
      this.$emit('close')
    },

    save() {
      let formData = this.$refs.innerform.getData()
      console.log('save', formData)
      this.$emit('save', formData)
    }
  }
}
</script>

<style>
.datepicker .dropdown .dropdown-menu {
  z-index: 100 !important;
}
</style>

<i18n>
{
  "pt": {
    "editMeeting": "Editar reunião",
    "newMeeting": "Nova reunião",
    "meetingDate": "Hora e data da reunião",
    "chooseDate": "Escolha uma data",
    "meetingGoal": "Objetivo da reunião",
    "meetingType": "Tipo de reunião",
    "meetingLocation": "Local da reunião",
    "enterMeetingLocation": "Insira o local da reunião",
    "virtualMeeting": "Sala virtual",
    "save": "Guardar",
    "cancel": "Cancelar",
    "onlineMeeting": "Reunião online",
    "inPersonMeeting": "Reunião presencial"
  },
  "en": {
    "editMeeting": "Edit meeting",
    "newMeeting": "New meeting",
    "meetingDate": "Meeting time and date",
    "chooseDate": "Choose a date",
    "meetingGoal": "Meeting goal",
    "meetingType": "Meeting type",
    "meetingLocation": "Meeting location",
    "enterMeetingLocation": "Enter the meeting location",
    "virtualMeeting": "Virtual Meeting",
    "save": "Save",
    "cancel": "Cancel",
    "onlineMeeting": "Online meeting",
    "inPersonMeeting": "In-person meeting"
  }
}
</i18n>
