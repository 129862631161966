var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fw-panel',{staticClass:"w-full",attrs:{"title":'Importar perguntas de forma aleatória'}},[(_vm.importing)?_c('div',{staticClass:"flex flex-col text-center items-center py-28 px-5"},[_c('div',{staticClass:"text-xl mb-5 animate-pulse"},[_vm._v("A importar...")]),_c('div',{staticClass:"text-sm text-gray-500"},[_vm._v("Por favor não saia desta modal enquanto importamos as perguntas.")])]):(_vm.page === 0)?_c('div',[_c('div',{staticClass:"text-sm pb-1"},[_vm._v("Selecione de onde quer importar as perguntas:")]),_c('div',{ref:"listHeader",staticClass:"flex-shrink-0 w-full"},[_c('div',{staticClass:"flex flex-col sm:flex-row gap-4 pb-4"},[_c('div',{staticClass:"flex flex-1 items-end gap-3 overflow-x-auto hide-scrollbar"},_vm._l((_vm.filters),function(filter){return _c('fw-button',{key:filter.key,attrs:{"type":filter.key === _vm.filterByType ? 'tab-active' : 'tab'},nativeOn:{"click":function($event){return _vm.changeFilter(filter.key)}}},[_c('v-clamp',{attrs:{"autoresize":"","max-lines":1}},[_vm._v(" "+_vm._s(filter.label)+" ")])],1)}),1)])]),(_vm.filterByType === 'exam')?_c('div',{staticClass:"flex flex-col",staticStyle:{"max-height":"500px"}},[(_vm.searching)?_c('LoadingPlaceholder',{staticClass:"px-2 py-2"}):_vm._e(),(!_vm.searching && _vm.numberResults > 0)?_c('div',{staticClass:"flex-1 overflow-y-auto",staticStyle:{"max-height":"500px"},attrs:{"featured":"","title":_vm.listTitle,"subtitle":"Selecione de onde quer importar as perguntas:","counter":_vm.numberResults}},[_c('RecycleScroller',{key:_vm.filterByType,staticClass:"h-full",attrs:{"items":_vm.resultsList,"item-size":40,"buffer":200,"key-field":'key'},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('div',{staticClass:"flex gap-3 items-center rounded-md px-3 py-1 h-10 hover:bg-gray-100 cursor-pointer border-b border-gray-100",class:{
              'text-primary bg-teal-500 bg-opacity-10': _vm.selectedExams.includes(item.key)
            },on:{"click":function($event){return _vm.select('exam', item)}}},[_c('div',{staticClass:"flex-1 flex items-center gap-2",class:{ 'text-gray-500': item.title == null }},[(_vm.selectedExams.includes(item.key))?_c('fw-icon-checkbox-circle-solid',{staticClass:"w-5 h-5 text-primary"}):_c('fw-icon-checkbox-circle-blank',{staticClass:"w-5 h-5 text-gray-400"}),_c('v-clamp',{staticClass:"font-semibold",attrs:{"autoresize":"","max-lines":1}},[_vm._v(" "+_vm._s(item.title != null ? item.title : 'Sem título')+" ")])],1),_c('div',{staticClass:"text-sm text-gray-500 font-medium"},[_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.createdDate))+" ")])])]}}],null,false,292920620)})],1):(!_vm.searching)?_c('div',{staticClass:"flex flex-col justify-center items-center text-gray-300 py-36"},[_vm._v(" Sem resultados a mostrar ")]):_vm._e()],1):_c('div',{staticClass:"flex flex-col",staticStyle:{"max-height":"500px"}},[(_vm.searching)?_c('LoadingPlaceholder',{staticClass:"px-2 py-2"}):_vm._e(),(!_vm.searching && _vm.numberResults > 0)?_c('div',{staticClass:"flex-1 overflow-y-auto",staticStyle:{"max-height":"500px"},attrs:{"featured":"","title":_vm.listTitle,"subtitle":"Selecione de onde quer importar as perguntas:","counter":_vm.numberResults}},[_c('RecycleScroller',{key:_vm.filterByType,staticClass:"h-full",attrs:{"items":_vm.resultsList,"item-size":40,"buffer":200,"key-field":'key'},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('div',{staticClass:"flex gap-3 items-center rounded-md px-3 py-1 h-10 hover:bg-gray-100 cursor-pointer border-b border-gray-100",class:{
              'text-primary bg-teal-500 bg-opacity-10': _vm.selectedBuckets.includes(item.key)
            },on:{"click":function($event){return _vm.select('bucket', item)}}},[_c('div',{staticClass:"flex-1 flex items-center gap-2",class:{ 'text-gray-500': item.title == null }},[(_vm.selectedBuckets.includes(item.key))?_c('fw-icon-checkbox-circle-solid',{staticClass:"w-5 h-5 text-primary"}):_c('fw-icon-checkbox-circle-blank',{staticClass:"w-5 h-5 text-gray-400"}),_c('v-clamp',{staticClass:"font-semibold",attrs:{"autoresize":"","max-lines":1}},[_vm._v(" "+_vm._s(item.titles !== null && item.titles['pt'] !== null ? item.titles['pt'] : item.titles !== null && item.titles['en'] !== null ? item.titles['en'] : 'Sem título')+" ")])],1),_c('div',{staticClass:"text-sm text-gray-500 font-medium"},[_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.createdDate))+" ")])])]}}],null,false,4103091089)})],1):(!_vm.searching)?_c('div',{staticClass:"flex flex-col justify-center items-center text-gray-300 py-36"},[_vm._v(" Sem resultados a mostrar ")]):_vm._e()],1),_c('div',{staticClass:"text-sm text-gray-500 mt-4"},[_vm._v("Seleccionados "+_vm._s(_vm.totalExams)+" exames ou repositórios")]),_c('div',{staticClass:"flex pt-5 gap-3"},[_c('div',{staticClass:"flex-1"}),_c('fw-button',{attrs:{"type":"link-muted"},nativeOn:{"click":function($event){return _vm.previous()}}},[_vm._v(" Cancelar ")]),_c('fw-button',{attrs:{"type":!_vm.allowNext ? 'disabled' : 'primary',"disable":!_vm.allowNext},nativeOn:{"click":function($event){return _vm.next()}}},[_vm._v(" Seguinte ")])],1)]):(_vm.page === 1)?_c('div',[_c('b-field',{attrs:{"label":"Número de perguntas a importar:"}},[_c('b-input',{attrs:{"type":"number","min":"1"},model:{value:(_vm.questions_number),callback:function ($$v) {_vm.questions_number=$$v},expression:"questions_number"}})],1),(_vm.totalExams > 1)?_c('div',{staticClass:"flex gap-5 text-sm flex-1 mt-2 flex-col"},[_c('button',{staticClass:"pt-5 border rounded-lg text-left justify-start px-4 py-5 w-full gap-3 flex items-center group transition duration-150 font-semibold",class:{
          'border-primary text-primary opacity-80': _vm.importationMethod == 'byexam',
          'hover:text-primary border-gray-100 hover:border-gray-200 opacity-50 hover:opacity-90':
            _vm.importationMethod != 'byexam'
        },on:{"click":function($event){return _vm.changeSelectMethod('byexam')}}},[_c('div',[_c('svg',{staticClass:"h-6 w-6 fill-current",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24","width":"24","height":"24"}},[_c('path',{attrs:{"fill":"none","d":"M0 0h24v24H0z"}}),_c('path',{attrs:{"d":"M7 7V3a1 1 0 0 1 1-1h13a1 1 0 0 1 1 1v13a1 1 0 0 1-1 1h-4v3.993c0 .556-.449 1.007-1.007 1.007H3.007A1.006 1.006 0 0 1 2 20.993l.003-12.986C2.003 7.451 2.452 7 3.01 7H7zm2 0h6.993C16.549 7 17 7.449 17 8.007V15h3V4H9v3zm6 2H4.003L4 20h11V9zm-6.497 9l-3.536-3.536 1.414-1.414 2.122 2.122 4.242-4.243 1.414 1.414L8.503 18z"}})])]),_c('div',[_c('h3',[_vm._v(_vm._s(_vm.questions_number)+" pergunta(s) de cada exame seleccionado")]),_c('div',{staticClass:"text-sm text-gray-400"},[_vm._v("Serão importadas "+_vm._s(_vm.totalQuestions)+" perguntas aleatórias.")])])]),_c('button',{staticClass:"pt-5 border rounded-lg text-left justify-start px-4 py-5 w-full gap-3 flex items-center group transition duration-150 font-semibold",class:{
          'border-primary text-primary opacity-80': _vm.importationMethod === 'total',
          'hover:text-primary border-gray-100 hover:border-gray-200 opacity-50 hover:opacity-90':
            _vm.importationMethod !== 'total'
        },on:{"click":function($event){return _vm.changeSelectMethod('total')}}},[_c('div',[_c('svg',{staticClass:"h-6 w-6 fill-current",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24","width":"24","height":"24"}},[_c('path',{attrs:{"fill":"none","d":"M0 0h24v24H0z"}}),_c('path',{attrs:{"d":"M4 3h16a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm1 2v14h14V5H5zm6.003 11L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z"}})])]),_c('h3',[_vm._v("Um total de "+_vm._s(_vm.questions_number)+" perguntas")])])]):_vm._e(),_c('div',{staticClass:"flex pt-5 gap-3"},[_c('div',{staticClass:"flex-1"}),_c('fw-button',{attrs:{"type":"link-muted"},nativeOn:{"click":function($event){return _vm.previous()}}},[_vm._v(" Anterior ")]),_c('fw-button',{attrs:{"type":_vm.importing || _vm.totalQuestions <= 0 ? 'disabled' : 'primary',"disable":_vm.importing || _vm.totalQuestions <= 0},nativeOn:{"click":function($event){return _vm.importQuestions()}}},[_vm._v(" Importar "+_vm._s(_vm.totalQuestions)+" "+_vm._s(_vm.totalQuestions == 1 ? 'pergunta' : 'perguntas')+" ")])],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }