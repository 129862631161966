<template>
  <fw-layout
    :notfound="!loading.initial && !loading && !course"
    :loading="loading.initial"
    loading-title="Espaço"
    loading-icon="cube"
    :back-to-enable="false"
    :small="isLayoutSmall"
    :wide="isLayoutWide"
  >
    <template #header-nav>
      <b-dropdown aria-role="list" position="is-bottom-left" append-to-body>
        <fw-button-dropdown slot="trigger" aria-role="listitem" type="transparent">
          <div class="font-medium text-base max-w-xs">
            <v-clamp autorize :max-lines="1">
              {{ course?.title }}
            </v-clamp>
          </div>
        </fw-button-dropdown>
        <b-dropdown-item has-link class="max-w-xs">
          <router-link
            class="font-semibold"
            :to="{ name: 'course-manage', params: { id: course.key, view: 'dashboard' } }"
          >
            <fw-label size="xs" marginless>Espaço</fw-label>
            <v-clamp autoresize :max-lines="3">
              {{ course.title }}
            </v-clamp>
          </router-link>
        </b-dropdown-item>
        <hr class="dropdown-divider" />
        <b-dropdown-item has-link>
          <router-link class="text-gray-500 font-medium" to="/manage/courses">Todos os espaços</router-link>
        </b-dropdown-item>
      </b-dropdown>
    </template>

    <template #main-sidebar>
      <SidebarManageCourse :course="course" :validations="validations" />
    </template>

    <template #main-content>
      <PanelManageCourseDashboard v-if="view === 'dashboard'" :course="course" :validations="validations" />
      <PanelManageCoursePeople v-else-if="view === 'people'" :course="course" />
      <PanelManageCourseNotifications v-else-if="view === 'notifications' && validations.can_edit" :course="course" />
      <PanelManageCourseActivity v-else-if="view === 'activity' && validations.can_edit" :course="course" />
      <PanelManageCourseSettings
        v-else-if="view === 'settings'"
        :editable="validations.can_edit"
        :course="course"
        @save-metadata="saveCourseMetadata"
        @save="saveCourse"
      />
    </template>

    <template #modals></template>
  </fw-layout>
</template>

<script>
import SidebarManageCourse from '@/fw-modules/fw-core-vue/courses/components/sidebars/manage/SidebarManageCourse'
import PanelManageCourseDashboard from '@/fw-modules/fw-core-vue/courses/components/panels/manage/PanelManageCourseDashboard'
import PanelManageCourseSettings from '@/fw-modules/fw-core-vue/courses/components/panels/manage/PanelManageCourseSettings'
import ServicesCourses from '@/fw-modules/fw-core-vue/courses/services/ServiceCourses'
import PanelManageCourseNotifications from '@/fw-modules/fw-core-vue/courses/components/panels/manage/PanelManageCourseNotifications'
import PanelManageCoursePeople from '@/fw-modules/fw-core-vue/courses/components/panels/manage/PanelManageCoursePeople'
import PanelManageCourseActivity from '@/fw-modules/fw-core-vue/courses/components/panels/manage/PanelManageCourseActivity'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    SidebarManageCourse,
    PanelManageCourseDashboard,
    PanelManageCourseSettings,
    PanelManageCoursePeople,
    PanelManageCourseNotifications,
    PanelManageCourseActivity
  },

  data() {
    return {
      edition: null,
      course: null,
      loading: {
        initial: true
      },
      validations: {}
    }
  },

  computed: {
    courseKey() {
      return this.$route.params.id
    },
    editionKey() {
      return this.$route.params.editionId
    },
    view() {
      return this.$route.params.view || 'dashboard'
    },
    isLayoutSmall() {
      return this.view === 'settings' || this.view === 'people'
    },
    isLayoutWide() {
      return !this.isLayoutSmall && ['activity', 'notifications'].includes(this.view)
    }
  },

  watch: {
    // On route change
    $route() {
      if (this.editionKey) {
        this.loadEdition()
      } else {
        this.edition = null
      }
    }
  },

  created() {
    this.loadCourse()
    if (this.editionKey) {
      this.loadEdition()
    } else {
      this.edition = null
    }
  },

  methods: {
    async loadCourse() {
      this.loading.course = true
      utils.tryAndCatch(
        this,
        async () => {
          let res = await ServicesCourses.getCourse(this.courseKey)
          console.log('course', res)
          this.course = res.course
          this.validations = res.validations
        },
        () => {
          setTimeout(() => {
            this.loading.course = false
            this.loading.initial = false
          }, 250)
        }
      )
    },

    async loadEdition() {
      if (this.editionKey) {
        utils.tryAndCatch(this, async () => {
          let res = await ServicesCourses.getEdition(this.courseKey, this.editionKey)
          console.log('edition', res)
          this.edition = res.edition
        })
      }
    },

    async saveCourse(data) {
      console.log(data)
      this.course[data.key] = data.value

      let body = JSON.parse(JSON.stringify(this.course))
      delete body.user_key
      utils.tryAndCatch(this, async () => {
        let course = await ServicesCourses.updateCourse(this.courseKey, body)
        console.log('update course', course)
        this.course = course

        this.$buefy.snackbar.open({
          message: 'Dados guardados.',
          type: 'is-primary'
        })
      })
    },

    async saveCourseMetadata(metadata) {
      console.log(metadata)
      this.course.duration = metadata.duration
      this.course.mode = metadata.mode
      this.course.title = metadata.title

      utils.tryAndCatch(this, async () => {
        let course = await ServicesCourses.updateCourse(this.courseKey, this.course)
        console.log('update course', course)
        this.course = course

        this.$buefy.snackbar.open({
          message: 'Dados guardados.',
          type: 'is-primary'
        })
      })
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "courseStatus": {
      "draft": "Rascunho",
      "published": "Publicado",
      "running": "A decorrer",
      "closed": "Terminado",
      "ended": "Terminado",
      "scheduled": "Agendado"
    }
  },
  "en": {
    "courseStatus": {
      "draft": "Rascunho",
      "published": "Publicado",
      "running": "A decorrer",
      "closed": "Terminado",
      "ended": "Terminado",
      "scheduled": "Agendado"
    }
  }
}
</i18n>
