export default {
  statusLabels: {
    draft: 'Rascunho',
    published: 'Publicado',
    ended: 'Terminado'
  }
}

export const REQUIRED_FIELDS = {
  title: { label: { pt: 'Título', en: 'Título' } },
  signup_start_date: { label: { pt: 'Data de início das inscrições', en: 'Data de início das inscrições' } },
  signup_end_date: { label: { pt: 'Data de fim das inscrições', en: 'Data de fim das inscrições' } },
  start_date: { label: { pt: 'Data de início da edição', en: 'Data de início da edição' } },
  end_date: { label: { pt: 'Data de fim da edição', en: 'Data de fim da edição' } }
  // summary: { label: { pt: 'Sumário', en: 'Sumário' } },
  // goals: { label: { pt: 'Objetivos do espaço', en: 'Objetivos do espaço' } },
  // methodology: { label: { pt: 'Metodologia', en: 'Metodologia' } },
  // evaluation: { label: { pt: 'Avaliação', en: 'Avaliação' } },
  // certification: { label: { pt: 'Certificação', en: 'Certificação' } },
  // languages: { label: { pt: 'Idiomas', en: 'Idiomas' } },
  // duration: { label: { pt: 'Duração', en: 'Duração' } },
  // admission_type: { label: { pt: 'Tipo de inscrição', en: 'Tipo de inscrição' } },
  // introduction: { label: { pt: 'Introdução', en: 'Introdução' } },
  // professional_goals: {
  //   label: {
  //     pt: 'Competências a desenvolver / Objetivos Profissionais',
  //     en: 'Competências a desenvolver / Objetivos Profissionais'
  //   }
  // },
  // prerequisites: { label: { pt: 'Pré-Requisitos', en: 'Pré-Requisitos' } },
  // observations: { label: { pt: 'observations', en: 'observations' } },
  // bibliography: { label: { pt: 'Bibliografia', en: 'Bibliografia' } },
  // scientific_area: { label: { pt: 'Área Científica', en: 'Área Científica' } }
}
