<template>
  <div class="formatted-text">
    <bubble-menu
      v-if="editor && editable"
      :tippy-options="{ animation: 'perspective-subtle', duration: [275, 150] }"
      :editor="editor"
      class="bubble-menu"
    >
      <b-tooltip label="Negrito" position="is-top" type="is-light">
        <button
          :class="{ 'is-active': editor.isActive('bold') }"
          @click="
            editor
              .chain()
              .focus()
              .toggleBold()
              .run()
          "
        >
          <svg
            class="fill-current h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M8 11h4.5a2.5 2.5 0 1 0 0-5H8v5zm10 4.5a4.5 4.5 0 0 1-4.5 4.5H6V4h6.5a4.5 4.5 0 0 1 3.256 7.606A4.498 4.498 0 0 1 18 15.5zM8 13v5h5.5a2.5 2.5 0 1 0 0-5H8z"
            />
          </svg>
        </button>
      </b-tooltip>
      <b-tooltip label="Itálico" position="is-top" type="is-light">
        <button
          :class="{ 'is-active': editor.isActive('italic') }"
          @click="
            editor
              .chain()
              .focus()
              .toggleItalic()
              .run()
          "
        >
          <svg
            class="fill-current h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M15 20H7v-2h2.927l2.116-12H9V4h8v2h-2.927l-2.116 12H15z" />
          </svg>
        </button>
      </b-tooltip>
      <b-tooltip label="Sublinhado" position="is-top" type="is-light">
        <button
          :class="{ 'is-active': editor.isActive('underline') }"
          @click="
            editor
              .chain()
              .focus()
              .toggleUnderline()
              .run()
          "
        >
          <svg
            class="fill-current h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M8 3v9a4 4 0 1 0 8 0V3h2v9a6 6 0 1 1-12 0V3h2zM4 20h16v2H4v-2z" />
          </svg>
        </button>
      </b-tooltip>
      <b-tooltip label="Riscado" position="is-top" type="is-light">
        <button
          :class="{ 'is-active': editor.isActive('strike') }"
          @click="
            editor
              .chain()
              .focus()
              .toggleStrike()
              .run()
          "
        >
          <svg
            class="fill-current h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M17.154 14c.23.516.346 1.09.346 1.72 0 1.342-.524 2.392-1.571 3.147C14.88 19.622 13.433 20 11.586 20c-1.64 0-3.263-.381-4.87-1.144V16.6c1.52.877 3.075 1.316 4.666 1.316 2.551 0 3.83-.732 3.839-2.197a2.21 2.21 0 0 0-.648-1.603l-.12-.117H3v-2h18v2h-3.846zm-4.078-3H7.629a4.086 4.086 0 0 1-.481-.522C6.716 9.92 6.5 9.246 6.5 8.452c0-1.236.466-2.287 1.397-3.153C8.83 4.433 10.271 4 12.222 4c1.471 0 2.879.328 4.222.984v2.152c-1.2-.687-2.515-1.03-3.946-1.03-2.48 0-3.719.782-3.719 2.346 0 .42.218.786.654 1.099.436.313.974.562 1.613.75.62.18 1.297.414 2.03.699z"
            />
          </svg>
        </button>
      </b-tooltip>
      <b-tooltip label="Sobrescrito" position="is-top" type="is-light">
        <button
          :class="{ 'is-active': editor.isActive('superscript') }"
          @click="
            editor
              .chain()
              .focus()
              .toggleSuperscript()
              .run()
          "
        >
          <svg
            class="fill-current h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M5.596 5l4.904 5.928L15.404 5H18l-6.202 7.497L18 19.994V20h-2.59l-4.91-5.934L5.59 20H3v-.006l6.202-7.497L3 5h2.596zM21.55 6.58a.8.8 0 1 0-1.32-.36l-1.155.33A2.001 2.001 0 0 1 21 4a2 2 0 0 1 1.373 3.454L20.744 9H23v1h-4V9l2.55-2.42z"
            />
          </svg>
        </button>
      </b-tooltip>
      <b-tooltip label="Subscrito" position="is-top" type="is-light">
        <button
          :class="{ 'is-active': editor.isActive('subscript') }"
          @click="
            editor
              .chain()
              .focus()
              .toggleSubscript()
              .run()
          "
        >
          <svg
            class="fill-current h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M5.596 4L10.5 9.928 15.404 4H18l-6.202 7.497L18 18.994V19h-2.59l-4.91-5.934L5.59 19H3v-.006l6.202-7.497L3 4h2.596zM21.55 16.58a.8.8 0 1 0-1.32-.36l-1.155.33A2.001 2.001 0 0 1 21 14a2 2 0 0 1 1.373 3.454L20.744 19H23v1h-4v-1l2.55-2.42z"
            />
          </svg>
        </button>
      </b-tooltip>
    </bubble-menu>
    <editor-content :editor="editor" @focus="emitFocus()" />
  </div>
</template>

<script>
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import Typography from '@tiptap/extension-typography'
import Subscript from '@tiptap/extension-subscript'
import Superscript from '@tiptap/extension-superscript'
import Underline from '@tiptap/extension-underline'
import { Editor, EditorContent, BubbleMenu } from '@tiptap/vue-2'

export default {
  name: 'FormattedText',
  components: {
    EditorContent,
    BubbleMenu
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    html: {
      type: String,
      default: ''
    }
  },
  data: function() {
    return {
      editor: null
    }
  },
  computed: {
    editable() {
      return this.disabled === true ? false : true
    }
  },
  created() {
    console.log('FORMATED TEXT', this.value, 'end', this.html, 'prop', this.$props.html)
    this.editor = new Editor({
      editorProps: {
        attributes: {
          class: 'formatted-text-editor focus:outline-none'
        }
      },
      extensions: [
        StarterKit,
        Subscript,
        Superscript,
        Underline,
        Placeholder.configure({
          placeholder: this.placeholder ? this.placeholder : 'Escreva aqui...'
        }),
        Typography
      ],
      parseOptions: {
        preserveWhitespace: 'full'
      },
      editable: this.editable,
      injectCSS: false,
      content: this.value,
      autofocus: this.autofocus,
      onUpdate: ({ editor }) => {
        let message = editor.getHTML()
        // HTML
        this.$emit('input', message)
        this.$emit('changed')
      }
    })
  },
  mounted() {
    //console.log('Mounted FORMATED TEXT', this.value, 'end', this.html, 'prop', this.$props.html, this.hello)
  },
  beforeDestroy() {
    this.editor.destroy()
  },
  methods: {
    emitFocus() {
      this.$emit('focus', this.id)
    }
  }
}
</script>

<style lang="scss">
.bubble-menu {
  @apply bg-white rounded-lg flex gap-0.5 p-0.5 border border-gray-100;
  box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.2), 0 7px 10px 1px rgba(0, 0, 0, 0.14), 0 2px 16px 1px rgba(0, 0, 0, 0.12) !important;
}

.bubble-menu button {
  @apply p-1 rounded text-gray-600;
}

.bubble-menu button.is-active {
  @apply text-gray-700;
  padding-top: 0.29rem;
  padding-bottom: 0.21rem;
  box-shadow: rgb(223, 222, 222) 0px 0px 3px 0.5px inset !important;
  background-color: rgba(233, 233, 233, 0.25) !important;
}

.drop-cursor {
  border-radius: 3px;
  overflow: hidden;
}

.ProseMirror .h1 .content {
  font-size: 2em;
}

.ProseMirror .h2 .content {
  font-size: 1.5em;
}

.ProseMirror .h3 .content {
  font-size: 1.2em;
}

.ProseMirror.formatted-text-editor {
  background-color: transparent !important;
  @apply px-0 py-2;
}
.ProseMirror.formatted-text-editor p.is-editor-empty:first-child::before {
  @apply text-gray-400;
}
</style>
