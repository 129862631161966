<template>
  <ChoosePeopleModal
    v-if="!loading"
    :title="`Selecionar elementos do grupo ${group?.title}`"
    instructions="Selecione as pessoas que quer como elementos do grupo."
    :selected="students"
    :multiselect="true"
    :invite-people="false"
    :users-list="usersList"
    :exclude-users="excludeUsers"
    :endpoint="searchEndpoint"
    paddingless
    @selected="newSelection"
    @close="close"
  >
    <template v-if="students && students.length" #previous>
      <div class="flex flex-col gap-3 mt-1.5 mb-3">
        <div class="flex-shrink-0 rounded-xl bg-gray-100 px-3 py-2">
          <fw-label>Elementos do grupo</fw-label>

          <fw-person v-for="userkey in students" :key="userkey" :person="users[userkey]">
            <template v-if="showRemove" #options>
              <div class="flex-shrink-0 opacity-70 hover:opacity-100">
                <fw-button type="xlight" label="Remover" @click.native="removeUser(userkey)">
                  <fw-icon-close-circle class="h-5 w-5" />
                </fw-button>
              </div>
            </template>
          </fw-person>
        </div>
      </div>
    </template>
  </ChoosePeopleModal>
</template>

<script>
import ChoosePeopleModal from '@/fw-modules/fw-core-vue/ui/components/modals/ChoosePeopleModal'
import ServiceCourses from '@/fw-modules/fw-core-vue/courses/services/ServiceCourses'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: { ChoosePeopleModal },
  props: {
    students: {
      type: Array,
      default: () => []
    },
    courseKey: {
      type: String,
      required: true
    },
    editionKey: {
      type: String,
      required: true
    },
    classKey: {
      type: String,
      required: true
    },
    group: {
      type: Object,
      default: () => {}
    },
    users: {
      type: Object,
      default: () => {}
    },
    showRemove: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      newUsers: [],
      loading: true,
      usersList: []
    }
  },

  computed: {
    user() {
      return this.$store.getters.getUser
    },

    confirmDisabled() {
      return this.newUsers.length < 1
    },

    excludeUsers() {
      return this.students
    }
  },

  mounted() {
    this.loadStartingUsers()
  },

  methods: {
    async searchEndpoint(params) {
      return await ServiceCourses.searchUsers(this.courseKey, this.editionKey, this.classKey, params)
    },

    async loadStartingUsers() {
      this.loading = true
      await utils.tryAndCatch(this, async () => {
        const response = await ServiceCourses.getClassStudents(this.courseKey, this.editionKey, this.classKey)
        console.log('loadStartingUsers :>> ', response)
        this.usersList = response.students
          .filter(el => !this.students?.includes(el.user_key))
          .map(el => response.users[el.user_key])
      })

      this.loading = false
    },

    close() {
      this.$emit('close')
    },

    removeUser(userkey) {
      this.$emit('remove', userkey)
      this.usersList.push(this.users[userkey])
    },

    newSelection(users) {
      console.log('selected users :>> ', users)
      if (!users || !users.length) return
      this.newUsers = users
      this.$emit(
        'add',
        this.newUsers.map(el => el.key)
      )
    }
  }
}
</script>
