<template>
  <fw-layout wide :back-to-enable="false">
    <template #main-content>
      <fw-panel-info type="orange" boxed icon class="mb-5">
        <div>
          A funcionalidades de Espaços de Aprendizagem está em
          <span class="font-bold">fase de testes</span>.<br />Caso se depare com algum erro ou identifique aspetos
          passíveis de melhoria, por favor, contacte-nos através do nosso
          <a class="underline" href="" @click.prevent="$router.push({ name: 'tasks-support-new' })">canal de suporte</a
          >.
        </div>
      </fw-panel-info>
      <fw-panel :title="$t('courses')" :subtitle="$t('learningSpaces')" featured>
        <template v-if="canCreateCourses" #toolbar>
          <fw-button type="link" @click.native="newCourse()">Criar novo espaço</fw-button>
        </template>

        <ContextualSearch :loading="loading" :start-value="searchInput" @search="search"></ContextualSearch>
        <PanelStats
          v-if="!isSearching && hasInstances && !loadingStats"
          class="pt-2"
          title="Números"
          :stats="stats"
          :loading="loadingStats"
        />
      </fw-panel>

      <div v-if="isSearching">
        <fw-panel :title="'Resultados'" :counter="courses.length" class="my-5"> </fw-panel>
      </div>

      <!-- Homepage (no search) -->
      <div class="my-5">
        <LoadingPlaceholder v-if="loading" />
        <template v-else>
          <div v-if="courses.length > 0" class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5">
            <CardManageCourse
              v-for="course in courses"
              :key="'course_draft_' + course.key"
              :users="users"
              :managers="managers[course.key]"
              :item="course"
              @click.native="goToCourse(course)"
            ></CardManageCourse>
          </div>
          <fw-panel-info v-else centered clean>
            <div class="flex flex-col gap-5 items-center justify-center p-10">
              <div class="text-center">
                <div class="rounded-full p-5 bg-gray-300 text-white">
                  <fw-icon-cube class="w-12 h-12" />
                </div>
              </div>
              <div>Ainda não tem espaços criados.</div>
              <div v-if="canCreateCourses" class="flex gap-5 items-center">
                <fw-button type="link" @click.native="newCourse()">Criar espaço</fw-button>
              </div>
            </div>
          </fw-panel-info>
        </template>
      </div>

      <BlockPagination
        v-if="pagination.totalPages > 1"
        :per-page="pagination.limit"
        :total="pagination.totalResults"
        :total-pages="pagination.totalPages"
        :current.sync="pagination.page"
        @page-changed="pageChanged($event)"
      />
    </template>

    <template #modals>
      <fw-modal
        v-if="showNewCourseModal"
        :active.sync="showNewCourseModal"
        :can-cancel="true"
        size="min"
        @close="close"
      >
        <template #default>
          <ModalNewCourse @new="createNewCourse($event)" @cancel="close()" />
        </template>
      </fw-modal>
    </template>
  </fw-layout>
</template>

<script>
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import PanelStats from '@/fw-modules/fw-core-vue/ui/components/panels/PanelStats'
import ContextualSearch from '@/fw-modules/fw-core-vue/ui/components/search/ContextualSearch'
import CardManageCourse from '@/fw-modules/fw-core-vue/courses/components/cards/manage/CardManageCourse'
import ServiceCourses from '@/fw-modules/fw-core-vue/courses/services/ServiceCourses'
import ModalNewCourse from '@/fw-modules/fw-core-vue/courses/components/modals/manage/ModalNewCourse'
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    ContextualSearch,
    PanelStats,
    CardManageCourse,
    LoadingPlaceholder,
    ModalNewCourse,
    BlockPagination
  },

  data: function() {
    return {
      showNewCourseModal: false,
      stats: [],
      rawStats: null,
      loadingStats: true,
      loading: false,
      searchInput: '',
      sortBy: null,
      sortDirection: 'asc',
      courses: [],
      users: {},
      managers: {},
      pagination: {
        page: 1,
        totalResults: 0,
        totalPages: 1,
        limit: 25
      }
    }
  },

  computed: {
    debug() {
      return Boolean(localStorage.getItem('fw-debug'))
    },
    language() {
      return this.$store.state.language || this.$i18n.locale || 'pt'
    },
    hasInstances() {
      return !!(!this.loading && this.courses.length)
    },
    archivedCounter() {
      if (!this.rawStats) return 0
      return this.rawStats.ended + this.rawStats.deleted + this.rawStats.archived
    },

    // Checks
    isSearching() {
      return this.searchInput.length > 0
    },

    user() {
      return this.$store.getters.getUser
    },

    // Permissions
    canCreateCourses() {
      // TODO: add global admin?
      return this.user?.roles.includes('staff') || this.user?.roles.includes('teacher')
    }
  },

  created() {
    this.search()
  },

  methods: {
    pageChanged(page) {
      console.log('pageChanged', page)
      this.pagination.page = page
      this.search({ term: this.searchInput })
    },

    async createNewCourse(data) {
      await utils.tryAndCatch(this, async () => {
        let result = await ServiceCourses.createCourse({
          title: data.name
        })
        console.log('createNewCourse', result)
        this.$router.push({ name: 'course-manage', params: { id: result.key } })
      })
    },

    async search(searchCriteria) {
      if (searchCriteria?.term) {
        this.searchInput = searchCriteria.term
      } else {
        this.searchInput = ''
      }

      this.loading = true

      await utils.tryAndCatch(this, async () => {
        let result = await ServiceCourses.getAllCourses({
          query: this.searchInput,
          orderDirection: this.sortDirection,
          page: this.pagination.page,
          limit: this.pagination.limit
        })
        console.log('search', result)
        this.courses = result.courses
        this.pagination = {
          page: result.pagination.current_page,
          totalPages: result.pagination.total_pages,
          limit: result.pagination.active_limit,
          totalResults: result.pagination.total_items
        }
        this.managers = result.managers
        this.users = result.users
        console.log('courses :>> ', this.courses)
      })

      this.loading = false
    },

    goToCourse(instance) {
      this.$router.push({ name: 'course-manage', params: { id: instance.key } })
    },

    close() {
      this.showNewCourseModal = false
    },
    newCourse() {
      this.showNewCourseModal = true
    }
  }
}
</script>

<i18n lang="json">
  {
    "en": {
      "courses": "Spaces",
      "learningSpaces": "Learning Digital Spaces"
    },
    "pt": {
      "courses": "Espaços",
      "learningSpaces": "Espaços de Aprendizagem"
    }
  }
</i18n>
