<template>
  <!-- <b-dropdown aria-role="list" position="is-bottom-left">
    <template #trigger="{ active }">
      <fw-button
        size="sm"
        class="flex gap-2 font-semibold"
        :class="{
          'opacity-100': active,
        }"
      >
        <div class="max-width-text flex items-center gap-2">
          <fw-icon-filter class="fill-current text-gray-500 h-5 w-5"></fw-icon-filter>
          <span v-if="showFilterLabel">{{ $t('filter') }} {{ selectedFilter ? `(${selectedFilter.label})` : '' }}</span>
        </div>
      </fw-button>
    </template>

    <div class="mb-3 z-10">
      <div v-for="(filter, key) in filters" :key="key">
        <fw-label v-if="filter.label && filter.label.length > 0" class="ml-4 pt-3 pb-2">{{ filter.label }}</fw-label>
        <b-dropdown-item
          v-for="option in filter.options"
          :key="option.key"
          aria-role="menu-item"
          :focusable="true"
          class="font-semibold flex justify-start gap-3 items-center"
          @click.native="selectFilter(option)"
        >
          <div
            class="border-2 border-gray-200 h-4 w-4 rounded-full -mt-0.5"
            :class="{
              'bg-primary bg-opacity-90': selectedFilter.key === option.key,
              'bg-gray-200': selectedFilter.key !== option.key,
            }"
          ></div>
          <div>{{ option.label }}</div>
        </b-dropdown-item>
      </div>
    </div> -->
  <b-dropdown
    ref="filterOptionsDropdown"
    aria-role="list"
    position="is-bottom-left"
    class="z-1"
    :scrollable="scrollable"
    :max-height="maxDropdownHeight"
  >
    <template #trigger="{ active }">
      <fw-button
        size="sm"
        class="flex gap-1"
        :class="{
          'opacity-100': active,
          'font-semibold': filtersCounter > 0,
          'font-normal': filtersCounter == 0
        }"
      >
        <div class="max-width-text flex items-center gap-2">
          <fw-icon-filter class="fill-current text-gray-500 h-5 w-5" />
        </div>
        <div
          v-if="filtersCounter > 0"
          class="rounded-full px-1 py-0.5 text-xs font-semibold bg-primary text-white"
          style="min-width: 17px"
        >
          {{ filtersCounter }}
        </div>
      </fw-button>
    </template>

    <div class="mb-3">
      <div v-for="(filter, key) in filters" :key="'filter_cat_' + key">
        <fw-label v-if="filter.label && filter.label.length > 0" class="ml-4 pt-3 pb-2">{{ filter.label }}</fw-label>
        <div class="flex flex-wrap gap-2 mx-4">
          <div
            v-for="(option, o) in filter.options"
            :key="'filter_cat_' + key + '_opt_' + o"
            class="filter-tag"
            :class="{ active: checkIsFilterActive(filter.key, option.key) }"
            @click="selectFilter(filter, option)"
          >
            {{ option.label }}
          </div>
        </div>
        <div class="clear"></div>
      </div>

      <slot name="after-filters"></slot>
    </div>
  </b-dropdown>
</template>

<script>
export default {
  props: {
    selectedFilters: {
      type: Array,
      default: () => []
    },

    filters: {
      type: Array,
      default: () => []
    },
    showFilterLabel: {
      type: Boolean,
      default: true
    },
    scrollable: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      appliedFilters: []
    }
  },

  computed: {
    maxDropdownHeight() {
      return window.innerHeight * 0.65
    },

    filtersCounter() {
      return this.appliedFilters.length
    }
  },

  watch: {
    selectedFilters(value) {
      this.appliedFilters = [...value]
    }
  },

  mounted() {
    this.appliedFilters = [...this.selectedFilters]
  },

  methods: {
    selectFilter({ key, multifilter }, option) {
      const filter = `${key}:${option.key}`
      const isMultifilter = Boolean(multifilter) || this.multifilter
      const parts = filter.split(':')

      if (parts.length > 1 && (parts[1] == 'reset' || !isMultifilter)) {
        //reset
        this.appliedFilters = this.appliedFilters.filter(function(e) {
          return !e.startsWith(parts[0] + ':')
        })
        if (!isMultifilter) {
          this.appliedFilters.push(filter)
        }
      } else {
        if (this.appliedFilters.includes(filter)) {
          this.appliedFilters = this.appliedFilters.filter(function(e) {
            return e !== filter
          })
        } else {
          this.appliedFilters.push(filter)
        }
      }

      this.$emit('filter-changed', this.appliedFilters)
    },

    checkIsFilterActive(filterGroupKey, filterValueKey) {
      if (filterValueKey === 'reset' && !this.selectedFilters.find(el => el.startsWith(filterGroupKey + ':'))) {
        return true
      }

      // Check any valid option
      if (this.selectedFilters.includes(filterGroupKey + ':' + filterValueKey)) {
        return true
      }

      return false
    }
  }
}
</script>

<style scoped lang="postcss">
.tags-box {
  opacity: 0;
  height: 0px;
  transition: all 300ms ease-in-out;
  @apply flex items-center w-full py-0;
}
.tags-box.active {
  opacity: 1;
  height: 40px;
}
.filter-tag {
  @apply px-2.5 select-none py-1 flex items-center cursor-pointer bg-gray-100 text-gray-600 rounded-lg text-sm;
}
.filter-tag.active {
  @apply bg-primary text-white font-medium;
}

.max-width-text {
  max-width: 130px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

<i18n>
{
  "pt": {
    "applyFilters": "Aplicar filtros",
    "filter": "Filtrar"
  },
  "en": {
    "applyFilters": "Apply filters",
    "filter": "Filter"
  }
}
</i18n>
