<template>
  <div class="flex py-0.5 items-center gap-5">
    <div class="text-2xl font-bold  flex-1">
      {{ mainText }} <span v-if="secondaryText.length > 0" class="text-primary">/ {{ secondaryText }}</span>
    </div>
    <slot name="options"></slot>
  </div>
</template>

<script>
export default {
  name: 'TextHeadingDual',
  props: {
    mainText: {
      type: String,
      default: ''
    },
    secondaryText: {
      type: String,
      default: ''
    }
  }
}
</script>
