<template>
  <fw-panel title="Nova turma">
    <form @submit.prevent="next()">
      <div class="flex flex-col">
        <div>
          <fw-label>Nome da turma</fw-label>
          <TextInput
            v-model="className"
            :minlength="2"
            :maxlength="150"
            :class="{
              error: $v.className.$error
            }"
            autofocus
          >
          </TextInput>

          <fw-tip v-if="$v.className.$error" error>
            <span v-if="!$v.className.min">Insira no mínimo 2 caracteres</span>
            <span v-if="!$v.className.max">Insira no máximo 150 caracteres</span>
            <span v-else>Insira o nome da turma</span>
          </fw-tip>
        </div>
      </div>
      <div class="flex flex-shrink-0 pt-5 gap-3">
        <div class="flex-1"></div>
        <fw-button type="link-muted" @click.native="cancel()">
          Cancelar
        </fw-button>
        <fw-button :type="!allowNext ? 'disabled' : 'primary'" :disable="!allowNext" class="w-32" role="submit">
          Criar
        </fw-button>
      </div>
    </form>
  </fw-panel>
</template>

<script>
import TextInput from '@/fw-modules/fw-core-vue/ui/components/form/TextInput'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
export default {
  name: 'ModalNewCourse',

  components: {
    TextInput
  },

  data() {
    return {
      className: ''
    }
  },

  validations: {
    className: {
      required,
      min: minLength(2),
      max: maxLength(150)
    }
  },

  computed: {
    allowNext() {
      return this.className.length > 0
    }
  },

  // mounted() {
  //   this.$nextTick(() => {
  //     this.$refs.classNameInput.$el.querySelector('input').focus()
  //   })
  // },

  methods: {
    next() {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.$emit('new', {
        name: this.className
      })
    },

    cancel() {
      this.$emit('cancel')
    }
  }
}
</script>
