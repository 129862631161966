<template>
  <fw-layout full :main-sidebar="true" :header-session-nav="true" back-to="emit" @go-back-to="backTo">
    <template #main-sidebar>
      <SidebarClass :edition="edition" :view="view" profile="teacher" :teachers="teachers" @go-to-view="goToView" />
    </template>
    <template #main-content>
      <PanelLiveClasses
        v-if="view == 'liveclasses' && edition"
        class="flex"
        :edition="edition"
        :is-teacher="true"
        :view-list="viewList"
        @load-session="viewList = !viewList"
        @go-to-user-chat="goToUserChat"
      />
      <PanelTeachers v-if="view == 'teachers'" :teachers="teachers" @go-to-user-chat="goToUserChat" />
      <PanelStudents v-if="view == 'students'" :students="students" @go-to-user-chat="goToUserChat" />
      <PanelBucketAdd
        v-if="view == 'new-bucket'"
        :application-context="{ application: 'academic', item_type: 'class_edition', item_key: edition.key }"
        class="mx-auto max-w-prose"
        @go-back="view = 'buckets'"
      />
      <fw-panel v-if="view == 'buckets'" title="Materiais de apoio" featured class="p-5 xl:max-w-screen-xl mx-auto">
        <template #toolbar>
          <fw-button type="regular" @click.native="view = 'new-bucket'">Novo repositório</fw-button>
        </template>
        <PanelBucketList
          :create-allowed="edition && edition.bucket_permissions.create"
          paddingless
          :application-context="{
            application: 'academic',
            item_type: 'class_edition', //, 'unit_edition'],
            include_unit_edition_buckets: edition !== null ? edition.unit.key : '',
            include_unit_buckets: edition !== null ? edition.unit.unit_key_for_bucket : '',
            item_key: edition.key
          }"
          @go-to-new-bucket="goToView('new-bucket')"
        />
      </fw-panel>
      <PanelChats
        v-if="inChatView"
        ref="chat"
        :chats="chats"
        :chat-users="chatUsers"
        :all-chat-users="allChatUsers"
        :chat-active="chatActive"
        :view-chats-list="viewChatsList"
        :chat-locked="chatLocked"
        :load-old-messages-core="loadOldMessagesCore"
        :load-more-chats-core="loadMoreChatsCore"
        @update-active-chat="updateActiveChat"
        @load-chat="loadChatAndSetNav"
        @load-main-chat="loadMainChat"
        @unload-chat="unloadChatAndSetNav"
        @set-chat-messages="setChatMessages"
        @delete-chat-message="deleteChatMessage"
      />
      <PanelCalendar v-if="view == 'calendar' && edition" :edition="edition" />
      <PanelClassSettings
        v-if="view == 'settings' && edition"
        :edition="edition"
        @update-edition="localUpdateEdition"
      />
    </template>
    <template #tapbar>
      <TapbarClass :view="view" :is-teacher="isTeacher" @go-to-view="goToView" />
    </template>
  </fw-layout>
</template>

<script>
//import LayoutDefault from '@/fw-modules/fw-core-vue/ui/components/layouts/LayoutDefault'
import TapbarClass from '@/fw-modules/fw-core-vue/academic/components/tapbars/TapbarClass'
import SidebarClass from '@/fw-modules/fw-core-vue/academic/components/sidebars/SidebarClass'
import PanelChats from '@/fw-modules/fw-core-vue/chats/components/panels/PanelChats'
import ChatLive from '@/fw-modules/fw-core-vue/chats/mixins/ChatLive'
import PanelCalendar from '@/fw-modules/fw-core-vue/academic/components/panels/PanelCalendar'
import PanelClassSettings from '@/fw-modules/fw-core-vue/academic/components/panels/PanelClassSettings'
import PanelStudents from '@/fw-modules/fw-core-vue/academic/components/panels/PanelStudents'
import PanelTeachers from '@/fw-modules/fw-core-vue/academic/components/panels/PanelTeachers'
import PanelLiveClasses from '@/fw-modules/fw-core-vue/academic/components/panels/PanelLiveClasses'
import PanelBucketAdd from '@/fw-modules/fw-core-vue/buckets/components/panels/PanelBucketAdd'
import PanelBucketList from '@/fw-modules/fw-core-vue/buckets/components/panels/PanelBucketList'
import ServiceAcademic from '@/fw-modules/fw-core-vue/academic/services/ServiceAcademic'
import ServiceMeetings from '@/fw-modules/fw-meetings-vue/services/ServiceMeetings'
//import FwLayout from '../../fw-modules/fw-core-vue/ui/components/layouts/FwLayout.vue'

export default {
  components: {
    //LayoutDefault,
    SidebarClass,
    TapbarClass,
    PanelChats,
    PanelClassSettings,
    PanelStudents,
    PanelTeachers,
    PanelLiveClasses,
    PanelCalendar,
    PanelBucketAdd,
    PanelBucketList
    //FwLayout
  },

  mixins: [ChatLive],

  data() {
    this.loadClass()
    let data = this.getChatDefaultData()
    data.view = 'liveclasses'
    data.viewList = true
    data.viewChatsList = true
    data.edition = null
    data.teachers = []
    data.students = []
    data.meetingSubscription = null
    return data
  },

  computed: {
    isMobile() {
      return window.innerWidth < 640
    },
    inChatView() {
      return this.isInChatView()
    },
    user() {
      return this.$store.getters.getUser
    },
    isTeacher() {
      return Boolean(this.user && this.user.key && this.user.roles.includes('teacher'))
    }
  },

  watch: {
    $route() {
      if (this.$route.params.subpage && this.view !== this.$route.params.subpage) {
        this.view = this.$route.params.subpage
      }
    }
  },

  beforeDestroy() {
    this.$store.dispatch('setContext')
    this.$store.commit('unsubscribeWS', { code: 'ws-reconnect', name: 'ViewClass' })
    this.$store.commit('unsubscribeWS', { code: 'meetings', name: 'ViewClass' })
    this.unregisterChat()

    if (this.meetingSubscription) {
      ServiceMeetings.unsubscribe(this.meetingSubscription)
      this.meetingSubscription = null
    }
  },

  created() {
    this.$store.commit('subscribeWS', { code: 'ws-reconnect', name: 'ViewClass', callback: this.WSReconnect })
    this.$store.commit('subscribeWS', { code: 'meetings', name: 'ViewClass', callback: this.meetingsWSMessages })
    this.registerChat()
  },

  methods: {
    async WSReconnect() {
      // TODO
    },

    localUpdateEdition(response) {
      Object.assign(this.edition, response)
    },

    loadChatAndSetNav($data) {
      this.viewChatsList = false
      this.loadChat($data)
    },
    unloadChatAndSetNav($data) {
      this.viewChatsList = true
      this.unloadChat($data)
    },

    backTo() {
      if (this.viewList && !this.isMobile) this.$router.back()
      else this.viewList = true
    },

    async meetingsWSMessages(messages) {
      if (messages.meetingDelta && this.edition) {
        const self = this
        messages.meetingDelta.forEach(function(message) {
          if (message.key == self.edition.meeting.key) {
            for (const [key, value] of Object.entries(message.delta)) {
              self.edition.meeting[key] = value
            }
            console.debug('Meeting updated', message)
          }
        })
      }
    },

    goToView(name) {
      this.view = name
      if (name == 'liveclasses') {
        this.viewList = true
      }
      let url = '/class/' + this.$route.params.key + '/' + name
      this.$router.push({ path: url })
    },

    async subscribeMeeting() {
      if (this.edition) {
        const meetingKey = this.edition.meeting.key
        await ServiceMeetings.subscribe(meetingKey)
        this.meetingSubscription = meetingKey
      }
    },
    async loadClass() {
      let response = await ServiceAcademic.getTeacherClass(this.$route.params.key)
      this.edition = response.edition
      this.edition.sessions = response.sessions
      this.students = this.edition.students = response.students
      this.teachers = response.teachers

      let users = []
      for (let teacher of this.teachers) users.push(teacher.user)
      for (let student of this.students) users.push(student.user)
      users.sort((a, b) => a.name.localeCompare(b.name))

      response.edition.chat.title = this.edition.title
      this.setChat(response.edition.chat)
      for (let user of users) this.addChatUser(user)
      this.sortChatUsers(true)
      this.setAllChatUnread()

      this.$store.dispatch('setContext', { code: 'class_edition', key: this.edition.key })
      this.$store.commit('subscribeWS', { code: 'auth', name: 'ViewClass', callback: this.subscribeMeeting })

      if (this.$route.params.subpage) {
        this.view = this.$route.params.subpage
      }
    },

    isInChatView() {
      return this.view == 'channels'
    },
    goToUserChat(user) {
      this.goToView('channels')
      this.chatOnLoadGoToUser = user
    }
  }
}
</script>
