<template>
  <fw-panel title="Novo formulário">
    <div class="flex flex-col pb-5">
      <b-field label="Nome do formulário" class="mt-5">
        <b-input v-model="name"></b-input>
      </b-field>
      <b-field label="Idioma principal" class="mt-1">
        <div>
          <b-radio v-model="language" name="language" native-value="pt">
            Português
          </b-radio>
          <b-radio v-model="language" name="language" native-value="en">
            Inglês
          </b-radio>
        </div>
      </b-field>
    </div>
    <div class="flex flex-shrink-0 gap-3">
      <div class="flex-1"></div>
      <fw-button type="link-muted" @click.native="cancel()">
        Cancelar
      </fw-button>
      <fw-button
        :type="!allowCreate ? 'disabled' : 'primary'"
        :disable="!allowCreate"
        class="w-32"
        @click.native="create()"
      >
        Criar
      </fw-button>
    </div>
  </fw-panel>
</template>

<script>
export default {
  name: 'ModalNewForm',
  props: {
    showCloseButton: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      name: '',
      language: 'pt'
    }
  },
  computed: {
    allowCreate() {
      return this.name.length > 0
    }
  },
  methods: {
    create() {
      //verify name
      if (this.name.length == 0) {
        return
      }
      this.$emit('new', {
        key: null,
        name: this.name,
        language: this.language
      })
    },
    cancel() {
      this.$emit('close')
    }
  }
}
</script>
