<template>
  <fw-layout mobile-ready>
    <template #main-content>
      <fw-panel title="Disciplinas" featured>
        <template #default>
          <PanelUnitsList
            :key="'panel_units_' + unitsFiltered.length + view + activeAcademicYear + selectedSemester + randomKey"
            :unit-editions="unitsFiltered"
            :view="view"
            :show-semester="false"
            :loading="loading"
            :group-units="true"
          />
        </template>
        <template #toolbar>
          <div class="flex items-center">
            <ViewSelector :view-options="['row', 'card']" :selected-view="view" @select-view="setView" />
            <span
              v-if="academicYears.length && !loading"
              class="mx-2 w-1 mr-4 h-4 border-l border-gray-500 opacity-50"
            ></span>
            <AcademicSelector
              v-if="academicYears.length && !loading"
              ref="academic_selector"
              show-semesters
              :selected-academic-year="activeAcademicYear"
              :selected-semester="selectedSemester"
              :academic-years="academicYears"
              :loading="loading"
              @select-academic-year="changeAcademicYear"
              @select-semester="changeSemester"
            />
          </div>
        </template>
      </fw-panel>
      <fw-panel
        v-if="!loading && unitEditions.length && totalStats"
        :title="'Uma visão geral do ano letivo ' + activeAcademicYear"
        featured
        section
        class="xl:max-w-screen-xl mx-auto px-5"
      >
        <PanelStats v-if="isLatestYear" :stats="stats" />
        <PanelChartNextClasses
          :total-stats="totalStats"
          :next-stats="nextStats"
          :done-stats="doneStats"
          class="mt-10"
        />
      </fw-panel>
    </template>
  </fw-layout>
</template>

<script>
import PanelStats from '@/fw-modules/fw-core-vue/ui/components/panels/PanelStats'
import PanelUnitsList from '@/fw-modules/fw-core-vue/academic/components/panels/PanelUnitsList'
import PanelChartNextClasses from '@/fw-modules/fw-core-vue/ui/components/home/dashboard/PanelChartNextClasses'
import AcademicSelector from '@/fw-modules/fw-core-vue/ui/components/buttons/AcademicSelector'
import ServiceAcademic from '@/fw-modules/fw-core-vue/academic/services/ServiceAcademic'
import config from '@/fw-modules/fw-core-vue/config'
import ServiceSettings from '@/fw-modules/fw-core-vue/id/services/ServiceSettings'
import ViewSelector from '@/fw-modules/fw-core-vue/ui/components/buttons/ViewSelector'

export default {
  components: {
    ViewSelector,
    PanelUnitsList,
    PanelChartNextClasses,
    PanelStats,
    AcademicSelector
  },

  data() {
    return {
      loading: true,
      unitEditions: [],
      academicYears: [],
      activeAcademicYear: '',
      totalStats: [],
      nextStats: [],
      doneStats: [],
      stats: [
        {
          key: 'academic-units',
          label: 'Disciplinas',
          value: '0',
          valueSuffix: null,
          featured: false
        },
        {
          key: 'academic-classes',
          label: 'Turmas',
          value: '0',
          valueSuffix: null,
          featured: false
        },
        {
          key: 'academic-students',
          label: 'Estudantes',
          value: '0',
          valueSuffix: null,
          featured: false
        },
        {
          key: 'academic-next_sessions',
          label: 'Aulas',
          value: '0',
          valueSuffix: null,
          featured: false,
          description: 'Previstas'
        },
        {
          key: 'academic-done_sessions',
          label: 'Aulas dadas',
          value: '0',
          valueSuffix: null,
          featured: false
        }
      ],
      selectedSemester: 1,
      view: 'card',
      loadedUserViewPreferences: false,
      randomKey: 1
    }
  },

  computed: {
    loggedUser() {
      return this.$store.getters.getUser
    },
    isTeacher() {
      return Boolean(this.loggedUser && this.loggedUser.key && this.loggedUser.roles.includes('teacher'))
    },
    isStudent() {
      return Boolean(this.loggedUser && this.loggedUser.key && this.loggedUser.roles.includes('student'))
    },
    isLatestYear() {
      return Boolean(this.academicYears && this.activeAcademicYear == this.academicYears[0])
    },
    unitsFiltered() {
      return this.unitEditions.filter(bucket => {
        return (
          (bucket.regime === 'semiannual' && bucket.regime_occurrence === this.selectedSemester) ||
          (bucket.regime === 'quarterly' &&
            this.selectedSemester == 1 &&
            (bucket.regime_occurrence == 1 || bucket.regime_occurrence == 2)) ||
          (bucket.regime === 'quarterly' &&
            this.selectedSemester == 2 &&
            (bucket.regime_occurrence == 3 || bucket.regime_occurrence == 4)) ||
          (bucket.regime !== 'semiannual' && bucket.regime !== 'quarterly')
        )
      })
    }
  },

  created() {
    this.restoreUserViewSettings()
    this.processRouteChange()
    this.loadUnits()
    this.loadStats()
  },

  methods: {
    setView(viewType) {
      //save in thge user settings
      this.view = viewType
      this.saveViewSettings()
    },
    async saveViewSettings() {
      let settings = {
        view_type: this.view
      }
      await ServiceSettings.setSetting(`general-view-settings`, JSON.stringify(settings))
    },
    async restoreUserViewSettings() {
      let data = await ServiceSettings.getSetting(`general-view-settings`)
      if (data && data !== null) {
        let viewSettings = JSON.parse(data.value)
        //console.log(bucketSettings)
        //{"view_type":"row","view_order_type":"created_date","view_order_direction":"DESC"}
        if (viewSettings && viewSettings['view_type']) {
          const viewType = viewSettings['view_type']
          if (viewType && ['row', 'card'].includes(viewType)) {
            this.loadedUserViewPreferences = true
            this.view = viewType
          }
        }
      }
    },
    processRouteChange() {
      let currentSemester = 1
      if (this.$route.query.semester && parseInt(this.$route.query.semester) !== 'NaN') {
        currentSemester = parseInt(this.$route.query.semester)
      } else {
        //Auto select semester
        let now = new Date()
        let currentYear = now.getFullYear()
        let semesterStartDates = config.semesterStart

        for (let i = 0; i < semesterStartDates.length; i++) {
          let start = Date.parse(currentYear + '-' + semesterStartDates[i])
          //console.log('start: ', currentYear + '-' + semesterStartDates[i], start)
          if (now.getTime() >= start) {
            currentSemester = i === 0 ? 1 : 2 //2 : 1
            break
          }
        }
      }

      this.selectedSemester = currentSemester
    },
    changeAcademicYear(academicYear) {
      console.log('new academic year: ' + academicYear + ' previous active: ' + this.activeAcademicYear)
      if (academicYear != this.activeAcademicYear) {
        this.activeAcademicYear = academicYear
        this.loadUnits(academicYear)
        //this.$refs.academicYearsDropdown.toggle()
      }
    },
    changeSemester(semester) {
      //console.log('new semester: ' + semester)
      this.selectedSemester = semester
    },
    async loadUnits(academicYear = null) {
      try {
        this.loading = true
        if (this.isTeacher) {
          const response = await ServiceAcademic.getTeacherUnits(academicYear)
          if (!response.academic_years.includes(response.current_academic_year)) {
            response.academic_years.push(response.current_academic_year)
            response.academic_years.sort().reverse()
          }

          //const activeIdx = response.academic_years.indexOf(response.academic_year)
          /*if (activeIdx !== -1) {
            response.academic_years.splice(activeIdx, 1)
          }*/

          this.activeAcademicYear = response.academic_year
          this.academicYears = response.academic_years
          this.unitEditions = response.units
          this.totalStats = response.stats.total
          this.nextStats = response.stats.next
          this.doneStats = response.stats.done
          this.randomKey = Math.random() * 1000
        }
      } finally {
        this.loading = false
      }
    },

    async loadStats() {
      const stats = {}
      if (this.isTeacher) {
        try {
          const academicStats = await ServiceAcademic.getTeacherStats()
          for (let [key, value] of Object.entries(academicStats)) {
            stats[`academic-${key}`] = value
          }
        } catch (error) {
          console.error('Failed to get academic stats', error)
        }
      }

      for (const stat of this.stats) {
        const value = stats[stat.key]
        if (value) stat.value = String(value)
        if (stat.key == 'academic-done_sessions') {
          const sessions = stats['academic-sessions']
          if (sessions) stat.description = `Total de ${sessions}`
        }
      }
    }
  }
}
</script>
