<template>
  <fw-panel :title="currentClass ? currentClass.name : 'Turma'">
    <template v-if="canEdit" #toolbar>
      <fw-menu-more>
        <b-dropdown-item paddingless aria-role="listitem">
          <fw-button type="dropdown-item" class="w-full" label="Criar" @click.native="newClass()">
            Criar nova turma
          </fw-button>
        </b-dropdown-item>
        <b-dropdown-item v-if="currentClass" paddingless aria-role="listitem">
          <fw-button type="dropdown-item" class="w-full" label="Eliminar" @click.native="deleteClass()">
            Eliminar turma
          </fw-button>
        </b-dropdown-item>
      </fw-menu-more>
    </template>

    <fw-content-loader v-if="loading" />
    <template v-else>
      <div v-if="currentClass" :key="currentClass.key">
        <b-tabs v-model="tab" :animated="false" @input="changeTab">
          <b-tab-item label="Pessoas" value="people">
            <PanelManageCourseEditionClassPeople
              v-if="subview == 'people' && currentClass"
              :key="currentClass.key"
              :class-data="currentClass"
              :course="course"
              :edition="edition"
              :can-edit="canEdit"
            />
          </b-tab-item>
          <!-- <b-tab-item label="Agendamentos" value="agenda">
            <PanelManageCourseEditionClassAgenda
              v-if="subview == 'agenda' && currentClass"
              :key="currentClass.key"
              :class-data="currentClass"
              :course="course"
              :edition="edition"
              :class-key="currentClassKey"
            />
          </b-tab-item> -->
          <b-tab-item label="Grupos" value="groups">
            <PanelManageCourseEditionClassGroups
              v-if="subview == 'groups' && currentClass"
              :key="currentClass.key"
              :class-data="currentClass"
              :course="course"
              :edition="edition"
              :can-edit="canEdit"
              :class-key="currentClassKey"
            />
          </b-tab-item>
        </b-tabs>
      </div>
      <fw-panel-info v-else type="basic" centered class="m-4 h-full flex justify-center items-center">
        <div class="flex items-center justify-center mb-3">
          <fw-icon-list-view class="w-10 h-10 opacity-70" />
        </div>
        <div>
          <div v-if="!classes.length">Não foram definidas turmas.</div>
          <div v-else>Escolha uma turma para explorar.</div>
          <div v-if="!classes.length && canEdit" class="flex items-center justify-center mt-3">
            <fw-button type="xlight" @click.native="newClass()">Criar nova turma</fw-button>
          </div>
        </div>
      </fw-panel-info>
    </template>
    <fw-modal
      v-if="showNewClassModal && canEdit"
      :active.sync="showNewClassModal"
      :can-cancel="true"
      size="min"
      @close="close"
    >
      <template #default>
        <ModalNewClass @new="createNewClass($event)" @cancel="close()" />
      </template>
    </fw-modal>
  </fw-panel>
</template>

<script>
import ModalNewClass from '@/fw-modules/fw-core-vue/courses/components/modals/manage/ModalNewClass'
import CoursesServices from '@/fw-modules/fw-core-vue/courses/services/ServiceCourses'
import PanelManageCourseEditionClassPeople from '@/fw-modules/fw-core-vue/courses/components/panels/manage/PanelManageCourseEditionClassPeople'
// import PanelManageCourseEditionClassAgenda from '@/fw-modules/fw-core-vue/courses/components/panels/manage/PanelManageCourseEditionClassAgenda'
import PanelManageCourseEditionClassGroups from '@/fw-modules/fw-core-vue/courses/components/panels/manage/PanelManageCourseEditionClassGroups'
import ServiceCourses from '@/fw-modules/fw-core-vue/courses/services/ServiceCourses'

export default {
  name: 'PanelManageCourseEditionClass',

  components: {
    ModalNewClass,
    PanelManageCourseEditionClassPeople,
    // PanelManageCourseEditionClassAgenda,
    PanelManageCourseEditionClassGroups
  },

  props: {
    course: {
      type: Object,
      required: true
    },
    edition: {
      type: Object,
      required: true
    },
    canEdit: {
      type: Boolean,
      default: true
    }
  },

  data: function() {
    return {
      showNewClassModal: false,
      loading: false,
      loadingList: false,
      page: 1,
      currentClass: null,
      users: {},
      classes: [],
      tab: 'people'
    }
  },

  computed: {
    subview() {
      return this.$route.params.subview || 'people'
    },
    debug() {
      return Boolean(localStorage.getItem('fw-debug'))
    },
    canCreateClasses() {
      return true
    },
    currentClassKey() {
      return this.$route.params.classKey || null
    }
  },

  watch: {
    currentClassKey() {
      this.loadClassData()
    },
    subview() {
      this.tab = this.subview
    }
  },
  created() {
    this.loadClasses()
    this.loadClassData()
  },

  mounted() {
    this.tab = this.subview
  },

  methods: {
    deleteClass() {
      //ask confirmation
      this.$buefy.dialog.confirm({
        title: 'Apagar turma',
        message: 'Tem a certeza que deseja <b>eliminar</b> esta turma? Todos os dados serão eliminados.',
        confirmText: 'Apagar turma',
        type: 'is-danger',
        hasIcon: false,
        onConfirm: async () => {
          await ServiceCourses.deleteClass(this.course.key, this.edition.key, this.currentClassKey)
          this.classes = this.classes.filter(c => c.key != this.currentClassKey)
          this.currentClass = null

          this.$emit('class-deleted', this.currentClassKey)

          this.$router.push({
            name: 'course-manage-edition',
            params: { id: this.course.key, editionId: this.edition.key, view: 'classes' }
          })
        }
      })
    },

    async loadClassData() {
      if (this.currentClassKey) {
        console.log('loading current class')
        this.loading = true
        try {
          let data = await CoursesServices.getClass(this.course.key, this.edition.key, this.currentClassKey)
          console.log('load current class', data)
          this.currentClass = data.class
          this.users = data.users
        } finally {
          this.loading = false
        }
      }
    },

    openClass(classeData) {
      if (this.currentClassKey == classeData.key) {
        return
      }
      this.currentClass = null
      this.$router.push({
        name: 'course-manage-edition-class',
        params: { id: this.course.key, editionId: this.edition.key, classKey: classeData.key }
      })
    },

    changeTab(tab) {
      console.log('changeTab', tab)
      this.$router.push({
        name: 'course-manage-edition-class',
        params: { id: this.course.key, editionId: this.edition.key, classKey: this.currentClassKey, subview: tab }
      })
    },

    async createNewClass(data) {
      try {
        let result = await CoursesServices.createClass(this.course.key, this.edition.key, {
          title: data.name
        })
        console.log('createNewClass', result)
        this.$emit('class-created', result)
        this.classes.push(result)
        this.close()
        this.$router.push({
          name: 'course-manage-edition-class',
          params: { id: this.course.key, editionId: this.edition.key, classKey: result.key }
        })
      } catch (e) {
        console.error(e)
        this.$buefy.dialog.alert({
          message: 'Ocorreu um erro ao criar a turma.',
          type: 'is-danger',
          hasIcon: false,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
    },

    async loadClasses() {
      this.loadingList = true
      try {
        let data = await CoursesServices.getClasses(this.course.key, this.edition.key, { page: this.page })
        console.log('load classes', data)
        this.classes = data.classes
        if (this.currentClassKey == null && this.classes[0]) {
          this.openClass(this.classes[0])
        }
      } finally {
        this.loadingList = false
      }
    },

    close() {
      this.showNewClassModal = false
    },

    newClass() {
      this.showNewClassModal = true
    }

    // pageChanged(page) {
    //   this.page = page
    //   this.loadClasses()
    // }
  }
}
</script>

<i18n>
    {
      "en": {
        "classes": "Classes"
      },
      "pt": {
        "classes": "Turmas"
      }
    }
  </i18n>
