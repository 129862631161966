<template>
  <div
    class="rounded-xl gap-3 transition-all hover:shadow-lg hover:bg-white cursor-pointer overflow-hidden relative bg-gray-200 bg-opacity-80 text-center flex flex-col py-5 px-4"
  >
    <div class="text-gray-500 text-sm gap-1 font-semibold flex items-center">
      <fw-icon-cube class="h-5 w-5 opacity-80" />
      <div class="opacity-80">
        Espaço
      </div>
    </div>

    <div class="text-gray-800 text-xl font-semibold leading-6 text-left">
      <v-clamp autoresize :max-lines="2">{{ item?.title || 'Espaço sem título' }}</v-clamp>
    </div>

    <div class="flex gap-2 flex-1">
      <!-- Owner -->
      <div v-if="item.user_key && users[item.user_key]">
        <fw-avatar :key="item.user_key" size="micro" :user="users[item.user_key]" />
      </div>
      <!-- Space managers -->
      <div v-if="managers?.length" class="flex gap-2">
        <template v-for="manager in managers">
          <fw-avatar v-if="manager?.user_key" :key="manager.key" size="micro" :user="users[manager.user_key]" />
        </template>
      </div>
    </div>

    <div class="flex flex-col gap-2">
      <fw-label>Edições</fw-label>
      <fw-tag :counter="item.stats.draft || 0" force-counter expanded type="xlight">Em rascunho</fw-tag>
      <fw-tag :counter="item.stats.published || 0" force-counter expanded type="xlight">Publicadas</fw-tag>
      <fw-tag :counter="item.stats.closed || 0" force-counter expanded type="xlight">Terminadas</fw-tag>
    </div>
  </div>
</template>

<script>
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'

export default {
  props: {
    language: {
      type: String,
      default: 'pt'
    },
    item: {
      type: Object,
      default: () => {}
    },
    managers: {
      type: Array,
      default: () => []
    },
    users: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    backgroundImage() {
      return this.item?.edition?.cover ? ServiceStorage.getImageViewUrl(this.item.edition.cover) : null
    }
  }
}
</script>

<i18n>
    {
      "en": {
        "metadata": {
          "presential": "Presential exam",
          "remote": "Remote exam",
          "open": "Public",
          "restricted": "Restricted"
        },
        "instructions": {
          "examcreatedby": "Exam created by",
          "examdynamizedby": "Exam dynamized by"
        }
      },
      "pt": {
        "metadata": {
          "presential": "Presencial",
          "remote": "Remoto",
          "open": "Aberto",
          "restricted": "Restrito"
        },
        "instructions": {
          "examcreatedby": "Exame criado por",
          "examdynamizedby": "Exame dinamizado por"
        }
      }
    }
    </i18n>
<style scoped>
.exam-cover-gradient {
  background: rgb(38, 38, 38);
  background: linear-gradient(0deg, rgba(38, 38, 38, 1) 25%, rgba(54, 54, 54, 0.48) 80%);
}
.progress-gradient {
  background: rgb(56, 195, 177);
  background: linear-gradient(90deg, rgba(56, 195, 177, 1) 0%, rgba(116, 246, 230, 1) 85%);
}
</style>
