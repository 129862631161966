<template>
  <div>
    <fw-panel title="Participantes" :counter="students.length" boxed="xs">
      <template v-if="canEdit" #toolbar>
        <fw-button type="link-primary" class="w-full" @click.native="openModal"> Adicionar </fw-button>
        <!-- <fw-menu-more>
            <b-dropdown-item aria-role="listitem" paddingless>
              <fw-button
                type="dropdown-item"
                label="Remover"
                class="w-full"
                @click.native="peopleModal('chose_people', 'student')"
              >
                Participante individual
              </fw-button>
            </b-dropdown-item>
            <b-dropdown-item aria-role="listitem" paddingless>
              <fw-button
                type="dropdown-item"
                label="Remover"
                class="w-full"
                @click.native="peopleModal('import_bulk', 'student')"
              >
                Lista de participantes
              </fw-button>
            </b-dropdown-item>
          </fw-menu-more> -->
      </template>

      <div class="flex flex-1 px-2 pb-2">
        <fw-button
          size="md"
          :type="activePage === 'students' ? 'tab-active' : 'tab'"
          @click.native="activePage = 'students'"
        >
          Ativos
          <span v-if="!loading.students" class="text-xs opacity-50 ml-2">{{
            activePage === 'students' ? pagination.students.total_items : totalRemainingTabResults
          }}</span>
        </fw-button>

        <fw-button
          size="md"
          :type="activePage === 'pending-invites' ? 'tab-active' : 'tab'"
          @click.native="activePage = 'pending-invites'"
        >
          Pendentes
          <span v-if="!loading.students" class="text-xs opacity-50 ml-2">{{
            activePage === 'pending-invites' ? pagination.students.total_items : totalRemainingTabResults
          }}</span>
        </fw-button>
      </div>

      <LoadingPlaceholder v-if="loading.students" list></LoadingPlaceholder>
      <fw-panel-info v-else-if="students.length == 0" centered empty> Sem participantes. </fw-panel-info>

      <div v-else class="flex flex-col gap-3">
        <fw-person
          v-for="(student, e) in students"
          :key="'student' + e"
          :person="users[student.user_key]"
          :selectable="false"
          :clickable="canEdit"
          @clicked="
            openPersonDetails({
              ...users[student.user_key],
              groups: student.groups.map(key => groups[key])
            })
          "
        >
          <template #secondline>
            <div v-if="users[student.user_key]" class="text-sm text-gray-500 font-normal flex gap-2 items-center">
              {{ users[student.user_key].email }}
            </div>
            <div class="text-sm text-gray-500 font-normal flex gap-2 items-center pt-2">
              <b-tooltip
                v-for="groupKey in student.groups"
                :key="groupKey"
                type="is-light"
                :label="groups[groupKey].title"
                position="is-bottom"
              >
                <fw-tag type="light-border-box" size="xs">
                  <span class="truncate max-w-[20ch]">{{ groups[groupKey].title }}</span>
                </fw-tag>
              </b-tooltip>
            </div>
          </template>
          <template #options>
            <div v-if="student.enrolled_date != null" class="w-20 mr-3">
              <ProgressIcon
                :progress="student.progress"
                show-percentage
                percentage-pos="right"
                :color="
                  student.progress == 100 ? 'bg-gradient-to-r from-teal-300 to-teal-400' : 'bg-gray-500 bg-opacity-80'
                "
              />
            </div>
            <fw-tag v-if="student.enrolled_date != null" type="light-primary"> Inscrito </fw-tag>
            <fw-tag v-else type="xlight"> Não inscrito </fw-tag>
            <fw-menu-more v-if="canEdit">
              <b-dropdown-item aria-role="listitem" paddingless>
                <fw-button
                  type="dropdown-item"
                  class="w-full"
                  label="Remover"
                  @click.native="removeStudent(student.user_key)"
                >
                  Remover
                </fw-button>
              </b-dropdown-item>
            </fw-menu-more>
          </template>
        </fw-person>
      </div>

      <BlockPagination
        v-if="pagination.students.totalPages > 1"
        :per-page="pagination.students.limit"
        :total="pagination.students.totalResults"
        :total-pages="pagination.students.totalPages"
        :current.sync="pagination.students.page"
        @page-changed="pageChanged"
      />
    </fw-panel>

    <fw-panel-info debug label="Data (raw)">
      <json-viewer
        :value="{ students, groups, students, pagination, activePage, totalRemainingTabResults }"
      ></json-viewer>
    </fw-panel-info>

    <fw-modal
      :active.sync="activeAddUsersModal"
      :can-cancel="false"
      boxed="xs"
      size="min"
      width="54rem"
      @close="closeModal"
    >
      <ModalAddUsers
        v-if="activeAddUsersModal"
        title="Adicionar participantes"
        instructions="Selecione os estudantes a adicionar à turma"
        :multiselect="true"
        :not-alowed-users="notAlowedUsers"
        :invite-people="true"
        :endpoint="searchEndpoint"
        :bulk-search-engine="bulkSearch"
        @selected="selectedPeople"
        @import="importPeople"
        @close="closeModal"
        @save-invite="saveUserInvite"
      ></ModalAddUsers>
    </fw-modal>

    <fw-modal
      :active.sync="activeUserDetailsModal"
      :can-cancel="true"
      boxed="xs"
      size="min"
      width="42rem"
      @close="closePersonDetails"
    >
      <ModalParticipantsUserDetails
        v-if="activeUserDetailsModal"
        :user="selectedUser"
        :course-key="course.key"
        :edition-key="edition.key"
        :class-key="classData.key"
        @update="updateUserGroups($event)"
        @close="closePersonDetails"
      />
    </fw-modal>
  </div>
</template>

<script>
import ServiceCourses from '@/fw-modules/fw-core-vue/courses/services/ServiceCourses'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
// import ChoosePeopleModal from '@/fw-modules/fw-core-vue/ui/components/modals/ChoosePeopleModal'
// import ModalImportBulkUsers from '@/fw-modules/fw-core-vue/ui/components/modals/ModalImportBulkUsers'
import ModalParticipantsUserDetails from '@/fw-modules/fw-core-vue/courses/components/modals/manage/ModalParticipantsUserDetails'
import ModalAddUsers from '@/fw-modules/fw-core-vue/ui/components/modals/ModalAddUsers'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import ProgressIcon from '@/fw-modules/fw-core-vue/ui/components/ProgressIcon'

export default {
  components: {
    // ChoosePeopleModal,
    LoadingPlaceholder,
    // ModalImportBulkUsers,
    ProgressIcon,
    ModalParticipantsUserDetails,
    ModalAddUsers
  },

  props: {
    course: {
      type: Object,
      default: () => ({})
    },
    edition: {
      type: Object,
      default: () => ({})
    },
    classData: {
      type: Object,
      default: () => ({})
    },
    canEdit: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      loading: {
        staff: false,
        students: false
      },
      users: {},
      groups: {},
      students: [],
      staff: [],
      pagination: {
        students: {
          page: 1,
          totalResults: 0,
          totalPages: 1,
          limit: 50
        }
      },
      totalRemainingTabResults: 0,
      activeAddUsersModal: false,
      activeUserDetailsModal: false,
      selectedUser: null,
      activePage: 'students'
    }
  },

  computed: {
    notAlowedUsers() {
      return this.students.map(el => el.user_key)
    },
    user() {
      return this.$store.getters.getUser
    },
    editionKey() {
      return this.edition.key
    },
    courseKey() {
      return this.course.key
    },
    classKey() {
      return this.classData.key
    }
  },

  watch: {
    activePage() {
      console.log('activePage', this.activePage)
      this.getStudents()
    }
  },

  mounted() {
    this.getStudents()
  },

  methods: {
    pageChanged(page) {
      this.pagination.students.page = page
      this.getStudents()
    },

    openModal() {
      this.activeAddUsersModal = true
    },

    async importPeople({ users, invites }) {
      console.log('selectedPeople :>> ', { users, invites })
      if (invites?.length > 0) {
        const invited = await this.saveInvites(invites)
        this.addClassStudent(users, invited)
      } else {
        this.addClassStudent(users)
      }

      this.closeModal()
    },

    async selectedPeople(users) {
      console.log('selectedPeople :>> ', users)
      this.addClassStudent(users)
      this.closeModal()
    },

    async saveUserInvite(inviteData) {
      const invited = await this.saveInvites([inviteData])
      this.addClassStudent([], invited)
    },

    async addClassStudent(users, invites = []) {
      if (!users?.length && !invites?.length) return
      const toAdd = invites?.length ? users.concat(invites) : users
      try {
        const response = await ServiceCourses.addClassStudent(
          this.courseKey,
          this.editionKey,
          this.classKey,
          toAdd.map(el => el.key)
        )

        for (const user of toAdd) {
          if (!this.users[user.key]) this.users[user.key] = user
        }

        console.log('addClassStudent :>> ', response)

        if (response.students?.length) {
          this.users = { ...this.users, ...response.users }

          const usersKeys = users?.map(el => el.key) ?? []
          const invitedUsersKeys = invites?.map(el => el.key) ?? []
          for (const student of response.students) {
            if (usersKeys.includes(student.user_key)) {
              this.students.push(student)
              if (this.activePage === 'students') this.pagination.students.total_items += 1
              else this.totalRemainingTabResults += 1
            } else if (invitedUsersKeys.includes(student.user_key)) {
              if (this.activePage === 'pending-invites') this.pagination.students.total_items += 1
              else this.totalRemainingTabResults += 1
            }
          }
          this.$buefy.snackbar.open({
            message: `Participantes adicionados.`,
            type: 'is-primary',
            position: 'is-top-right',
            duration: 3000,
            queue: false
          })
        }

        if (response.__errors__ && response.__errors__.length > 0) {
          for (const apiError of response.__errors__) {
            console.log('apiError :>> ', apiError)
            console.log(this.users)
            if (apiError && apiError?.detail == 'User already in course') {
              this.$buefy.snackbar.open({
                message: this.$t('userAlreadyInCourse', { user: this.users[apiError.context]?.full_name }),
                type: 'is-warning',
                position: 'is-top-right',
                queue: false,
                duration: 2000
              })
            }
          }
        }
      } catch (e) {
        utils.errorDialogAlert(this, e)
      }
    },

    async removeStudent(key) {
      //ask for confirmation
      this.$buefy.dialog.confirm({
        title: 'Remover estudante',
        message: `Tem a certeza que deseja remover o estudante?`,
        confirmText: 'Remover',
        type: 'is-danger',
        onConfirm: async () => {
          try {
            const result = await ServiceCourses.removeClassStudent(this.courseKey, this.editionKey, this.classKey, [
              key
            ])
            console.log('removeClassStudent :>> ', result)
            this.students = this.students.filter(el => el.user_key != key)
            this.pagination.students.total_items -= 1

            this.$buefy.snackbar.open({
              message: `Participante removido.`,
              type: 'is-primary',
              position: 'is-top-right',
              queue: false,
              duration: 3000
            })
          } catch (error) {
            utils.errorDialogAlert(this, error)
          }
        }
      })
    },

    async searchEndpoint(params) {
      console.log('searchEndpoint params :>> ', params)
      return await ServiceCourses.searchUsers(this.courseKey, this.editionKey, null, params)
    },
    async bulkSearch(query) {
      query = query.split(',')
      return await ServiceCourses.bulkSearchUsers(this.courseKey, this.editionKey, query)
    },

    async getStudents() {
      this.loading.students = true

      await utils.tryAndCatch(
        this,
        // Main code to run
        async () => {
          const response = await ServiceCourses.getClassStudents(this.courseKey, this.editionKey, this.classKey, {
            page: this.pagination.students.page,
            limit: this.pagination.students.limit,
            pending_invites: this.activePage == 'pending-invites'
          })
          console.log('getStudents :>> ', response)
          this.totalRemainingTabResults = response.total_remaining_invites
          this.students = response.students
          this.pagination.students = response.pagination
          this.users = { ...this.users, ...response.users }
          this.groups = { ...this.groups, ...response.groups }
        }
      )
      this.loading.students = false
    },

    closeModal() {
      this.activeAddUsersModal = false
    },

    goToStudent(studentKey) {
      let routeName = 'course-manage-edition-class-student'
      let routeParams = {
        editionId: this.editionKey,
        id: this.courseKey,
        classId: this.classKey,
        studentId: studentKey
      }

      this.$router.push({
        name: routeName,
        params: routeParams
      })
    },

    openPersonDetails(user) {
      console.log('openPersonDetails :>> ', user)
      this.selectedUser = user
      this.activeUserDetailsModal = true
    },

    closePersonDetails() {
      console.log('closePersonDetails')
      this.activeUserDetailsModal = false
      this.selectedUser = {}
    },

    async updateUserGroups({ userKey, groupKeys }) {
      console.log('updateUserGroups', userKey, groupKeys)
      await utils.tryAndCatch(this, async () => {
        const response = await ServiceCourses.updateClassGroupsUsers(
          this.courseKey,
          this.editionKey,
          this.classKey,
          [userKey],
          groupKeys
        )
        console.log('updateUserGroups :>> ', response)
        this.groups = { ...this.groups, ...response.groups }
        this.students.find(el => el.user_key == userKey).groups = groupKeys
      })
    },

    async saveInvites(invitesData) {
      console.log('saveInvites :>> ', invitesData)
      try {
        const response = await ServiceCourses.createUsersInvites(invitesData, true)

        console.log('createUsersInvites :>> ', response)

        const invites = response.invites
        console.log('invited ==>', invites)

        for (const invite of invites) {
          this.$buefy.snackbar.open({
            message: this.$t('accountAdded', { user: invite.name, email: invite.email }),
            type: 'is-primary',
            position: 'is-top-right',
            duration: 2000,
            queue: false
          })
        }

        if (response.__errors__ && response.__errors__.length > 0) {
          const apiError = utils.errors(response).get()
          if (apiError && apiError.key && apiError.key == 'UserAlreadyExists') {
            this.$buefy.snackbar.open({
              message: this.$t('userAlreadyExists', { user: apiError.name }),
              type: 'is-danger',
              position: 'is-top-right',
              duration: 2000,
              queue: false
            })
          }
        }

        return invites
      } catch (error) {
        const apiError = utils.errors(error).get()
        if (apiError && apiError.key && apiError.key == 'UserAlreadyExists') {
          this.$buefy.dialog.alert({
            title: this.$t('userAlreadyExists', { user: apiError.name }),
            message: this.$t('userAlreadyExistsInfo'),
            type: 'is-danger'
          })
        } else {
          utils.errorDialogAlert(this, error)
        }
      }
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "leader": "Líder",
    "confirmedAt": "Confirmada em",
    "deniedAt": "Rejeitada em",
    "deletedAt": "Removida em",
    "messages": "Mensagens",
    "logs": "Atividade",
		"accountAdded": "A conta de {user} ({email}) foi adicionada.",
		"userAlreadyInCourse": "O utilizador {user} ja está no curso",
    "userAlreadyExists": "O utilizador {user} já existe",
    "userAlreadyExistsInfo": "O utilizador que tentou convidar já existe."
  },
  "en": {
    "leader": "Líder",
    "confirmedAt": "Confirmada em",
    "deniedAt": "Rejeitada em",
    "deletedAt": "Removida em",
    "messages": "Mensagens",
    "logs": "Atividade",
		"accountAdded": "The account of {user} ({email}) was added.",
    "userAlreadyInCourse": "User {user} already in course",
    "userAlreadyExists": "User {user} already exists",
    "userAlreadyExistsInfo": "The user you tried to invite already exists."
  }
}
</i18n>
