<template>
  <div class="w-full min-h-full">
    <div class="sticky top-0 bg-gray-50" style="z-index: 2">
      <PanelFormComponent :preview="show_preview">
        <div class="question-container flex mx-auto w-full py-4 min-w-lg flex-1 h-full items-center">
          <TextHeadingDual class="flex-1" :main-text="'Editor'" />
          <b-dropdown v-if="editable && form_states.length > 0" aria-role="list" position="is-bottom-left">
            <fw-button-dropdown slot="trigger" aria-role="listitem" type="xlight" label="Mudar estado" />
            <b-dropdown-item
              v-for="(st, s) in form_states"
              :key="s"
              ref="actionitem"
              aria-role="menuitem"
              @click="changeStatus(st)"
            >
              {{ statesLabels[st]['pt'] }}
            </b-dropdown-item>
          </b-dropdown>
          <b-tooltip v-if="editable" :active="errorSaving" label="Dados não guardados">
            <fw-button type="xlight" @click.native="saveForm">
              <fw-icon-loading v-if="savingData" class="w-5 h-5 mr-2" />
              <fw-icon-alert v-else-if="errorSaving" class="h-5 w-5 mr-2 text-red-500"></fw-icon-alert>
              <fw-icon-cloud
                v-else
                class="h-5 w-5 mr-2"
                :class="{ 'text-primary animate-pulse': dirtyData, 'text-gray-300': !dirtyData }"
              ></fw-icon-cloud>
              <div :class="{ 'text-gray-500': !dirtyData }">Guardar</div>
            </fw-button>
          </b-tooltip>

          <fw-button v-if="editable" type="xlight" @click.native="show_preview = !show_preview">
            Pré-visualizar
          </fw-button>
        </div>
        <template #preview>
          <div class="py-4">
            <TextHeadingDual class="flex-1" :main-text="'Pré-visualização'" />
          </div>
        </template>
      </PanelFormComponent>
    </div>

    <!-- Index of sections -->
    <div
      v-if="false"
      class="fixed right-6 w-36 top-48 pt-3 flex-col justify-end hidden xl:flex gap-1"
      style="z-index: 21"
    >
      <span class="text-gray-400 text-sm mb-2 font-semibold text-right pr-2">Índice</span>
      <template v-for="page in pages">
        <button
          v-for="section in page.schema"
          :key="form.key + 'bt_nav_' + section.key"
          class="relative text-gray-600 text-sm px-2 py-2 inline-flex flex items-center font-bold transition-colors duration-150 rounded-lg hover:opacity-75 focus:outline-none justify-end"
          @click="goToSection(section.key)"
        >
          <div
            v-show="typeof section.title[language] === 'undefined' || section.title[language].length === 0"
            class="empty_nav_bt bt_nav_lateral"
          >
            ---
          </div>
          <div
            v-show="!(typeof section.title[language] === 'undefined' || section.title[language].length === 0)"
            class="bt_nav_lateral"
          >
            {{ section.title[language] }}
          </div>
        </button>
      </template>
    </div>

    <div
      v-if="tmp_form && tmp_form.schema.length > 0"
      ref="form_main_body"
      class="flex flex-col h-screen"
      @click.self="clickBackground"
    >
      <div
        v-for="(section, s) in tmp_form.schema"
        :key="tmp_form.key + 'section_' + '_' + s"
        :ref="'section_' + section.key"
        class="page-section relative"
      >
        <PanelFormComponent :preview="show_preview">
          <div
            class="flex items-center w-full"
            :class="{
              'pt-8': s !== 0
            }"
          >
            <input
              v-if="editable || (!editable && section.title[language] && section.title[language].length > 0)"
              v-model="section.title[language]"
              class="w-full bg-transparent border-transparent text-xl font-semibold px-2.5 py-1 outline-none text-gray-600"
              placeholder="Título da secção"
              :disabled="!editable"
              type="text"
              @input="dataChanged()"
            />
            <b-dropdown
              v-if="editable && tmp_form.schema.length > 1"
              :scrollable="true"
              :max-height="200"
              aria-role="list"
              position="is-bottom-left"
            >
              <template #trigger>
                <fw-button type="transparent" size="sm" class="text-gray-500 text-sm">
                  <fw-icon-more class="h-5 w-5" />
                </fw-button>
              </template>

              <b-dropdown-item aria-role="listitem">
                <div
                  class="flex justify-start items-center gap-3 font-semibold text-gray-600"
                  @click="removeSection(s)"
                >
                  Eliminar secção
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="h-1.5 mt-2 rounded-t-md bg-white"></div>
          <template #preview>
            <div class="h-full flex flex-col">
              <div class="text-xl font-semibold py-1 flex-1 text-gray-600 px-2.5 flex flex-col justify-end">
                {{ section.title[language] }}
              </div>
              <div class="h-1.5 mt-2 rounded-t-md bg-white"></div>
            </div>
          </template>
        </PanelFormComponent>
        <div class="text-gray-800">
          <PanelFormComponent v-if="section.content.length === 0" :preview="show_preview">
            <div class="bg-white px-5 pb-5 pt-3 relative box-question">
              <div class="text-gray-300 text-lg text-center py-5">Secção sem campos</div>
              <div v-if="editable" class="mb-3 text-sm text-gray-600 text-center">
                Comece por adicionar um campo ao seu formulário
              </div>
              <div v-if="editable" class="flex gap-3 justify-center text-gray-700 items-center">
                <fw-button type="xlight" @click.native="addContent(s, null)"> Adicionar campo </fw-button>
              </div>
            </div>
            <template #preview>
              <div class="bg-white px-5 pb-5 rounded-b-md min-h-44"></div>
            </template>
          </PanelFormComponent>
          <Container
            v-else
            group-name="questions"
            drop-class="card-ghost-drop"
            :drop-placeholder="dropPlaceholderOptions"
            :should-animate-drop="() => true"
            :get-child-payload="index => getChildPayload(index, s)"
            drag-handle-selector=".drag-handle"
            class="flex flex-col form-editor"
            @drop="onDropSection(s, $event)"
          >
            <Draggable v-for="(input, i) in section.content" :key="'input_' + input.key" class="relative box-question">
              <PanelFormComponent :preview="show_preview">
                <div class="question-container flex bg-white mx-auto min-w-lg w-full px-4 py-1 h-full">
                  <div v-if="editable" class="drag-handle hidden sm:flex">
                    <svg
                      class="fill-current h-3 w-3"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 10 16"
                      width="10"
                      height="16"
                    >
                      <path
                        fill-opacity="0.13"
                        d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
                      />
                    </svg>
                  </div>
                  <div class="flex-1">
                    <div class="flex flex-col text-sm text-gray-500">
                      <div class="flex gap-2 items-center -mb-2 mt-2">
                        <div class="flex-1 text-sm text-gray-300">
                          {{ getInputTypeLabel(input.type) }}
                        </div>
                        <div
                          class="w-[140px] relative h-[22px] bg-gray-100 rounded-full text-xs font-bold select-none cursor-pointer mr-10"
                          @click="toggleRequired(s, i)"
                        >
                          <div
                            class="absolute top-0.5 bottom-0.5 rounded-full z-[0] transition-all"
                            :class="{
                              'bg-primary/80 left-0 translate-x-0.5 w-[60px]': !input.required,
                              'bg-red-500/80 left-[60px] w-[78px]': input.required
                            }"
                          ></div>
                          <div
                            class="w-[62px] absolute left-0 h-[22px] transition-all leading-[22px] top-0 bottom-0 z-[1] text-center"
                            :class="{
                              'text-black/30': input.required,
                              'text-white/90': !input.required
                            }"
                          >
                            Opcional
                          </div>
                          <div
                            class="w-[80px] absolute right-0 h-[22px] transition-all leading-[22px] top-0 bottom-0 z-[1] text-center"
                            :class="{
                              'text-black/30': !input.required,
                              'text-white/90': input.required
                            }"
                          >
                            Obrigatório
                          </div>
                        </div>
                      </div>
                      <div class="mb-1">
                        <fw-label>Nome do campo</fw-label>
                        <div v-if="errors[input.key + ':label']" class=" text-red-500 pb-1.5 text-sm">
                          {{ errors[input.key + ':label'] }}
                        </div>
                        <TextInput
                          :id="input.key"
                          v-model="input.label[language]"
                          class="flex-1 text-base font-medium text-black px-3 mb-1 border shadow-sm rounded-md"
                          :class="{
                            'border-gray-200': errors[input.key + ':label'] === undefined,
                            'border-red-500': errors[input.key + ':label'] !== undefined
                          }"
                          :disabled="!editable"
                          :placeholder="'Insira aqui o nome do campo'"
                          color="white"
                          paddingless
                          @input="dataChanged()"
                        ></TextInput>
                      </div>
                      <div class="mb-1">
                        <fw-label>Instruções (opcional)</fw-label>
                        <div v-if="errors[input.key + ':instructions']" class=" text-red-500 py-1 text-sm">
                          {{ errors[input.key + ':instructions'] }}
                        </div>
                        <FormattedText
                          :id="input.key"
                          v-model="input.instructions[language]"
                          class="flex-1 text-base font-medium text-black px-3 mb-1 border shadow-sm rounded-md"
                          :class="{
                            'border-gray-200': errors[input.key + ':instructions'] === undefined,
                            'border-red-500': errors[input.key + ':instructions'] !== undefined
                          }"
                          :disabled="!editable"
                          :autofocus="false"
                          :placeholder="'Insira aqui informação adicional para o utilizador'"
                          @input="dataChanged()"
                        ></FormattedText>
                      </div>
                      <div v-if="input.type === 'multiple_choice'" class="fw-multiplechoice flex flex-col gap-2">
                        <div
                          v-for="(option, o) in input.options.options"
                          :key="'option_' + o"
                          class="multiplechoice_option_editor py-1 px-2 relative border border-gray-100 rounded-md text-base font-semibold text-gray-800 bg-white w-full text-left flex justify-start items-center group gap-4"
                          :class="{
                            editable: editable,
                            //'cursor-pointer': editing_option !== input.key + '_' + option.key && editable,
                            //' ': editing_option !== input.key + '_' + option.key,
                            active: editing_option === input.key + '_' + option.key && editable,
                            'py-0.5 pr-1': editing_option === input.key + '_' + option.key
                          }"
                        >
                          <div
                            class="multiplechoice_letter text-center py-2 bg-gray-200 uppercase rounded-xl leading-4 text-lg font-medium"
                            style="width: 2rem"
                          >
                            {{ String.fromCharCode(97 + o) }}
                          </div>
                          <div class="flex-1 flex gap-2 items-center">
                            <input
                              v-if="editable"
                              :ref="input.key + '_' + option.key"
                              v-model="option.text[language]"
                              placeholder="Texto da opção"
                              class="flex-1 w-full text-base font-semibold"
                              type="text"
                              @input="dataChanged()"
                            />
                            <div v-else class="flex-1 w-full text-base font-semibold">
                              {{ option.text[language] }}
                            </div>
                            <b-dropdown
                              v-if="input.options.options.length > 2 && editable"
                              :scrollable="true"
                              :max-height="200"
                              aria-role="list"
                              position="is-bottom-left"
                            >
                              <template #trigger>
                                <fw-button type="transparent" size="sm" class="text-gray-400">
                                  <fw-icon-more class="h-5 w-5" />
                                </fw-button>
                              </template>

                              <b-dropdown-item aria-role="listitem">
                                <div
                                  class="flex justify-start items-center gap-3 font-semibold text-gray-600"
                                  @click="deleteMultiplechoiceOption(s, i, o, false)"
                                >
                                  Eliminar opção
                                </div>
                              </b-dropdown-item>
                            </b-dropdown>
                          </div>
                        </div>
                        <button
                          v-if="input.options.options.length < 10 && editable"
                          class="flex bg-gray-50 border border-gray-100 hover:border-primary hover:bg-white items-center justify-center group cursor-pointer text-center rounded-md text-gray-400 font-semibold px-2 py-2 hover:text-gray-700"
                          @click="addNewMultiplechoiceOption(s, i)"
                        >
                          <div
                            class="multiplechoice_letter text-center flex flex-col justify-center items-center bg-gray-200 uppercase rounded-xl leading-4 text-lg font-medium"
                            style="width: 2rem; height: 2rem"
                          >
                            <svg
                              class="h-5 w-5 text-gray-500 group-hover:text-primary fill-current"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="24"
                              height="24"
                            >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" />
                            </svg>
                          </div>
                          <div class="flex-1 text-left pl-5">Adicionar nova opção</div>
                        </button>
                      </div>
                      <div v-else-if="input.type === 'custom_objects' && input.options && input.options.schema">
                        <fw-label>Estrutura de uma entrada</fw-label>
                        <div
                          class="bg-gray-50 relative border w-full border-gray-100 rounded-md text-gray-400 gap-1.5 font-semibold px-3 py-3 flex flex-col"
                        >
                          <MiniPlaceholder
                            v-for="(subfield, sf) in input.options.schema"
                            :key="sf"
                            editable
                            :name="subfield.label[language]"
                            :required="subfield.required"
                            :type="subfield.type"
                            :can-move-up="sf > 0"
                            :can-move-down="sf < input.options.schema.length - 1"
                            :options="subfield.options.options"
                            @input="subobjectFieldNameChanged(s, i, sf, $event)"
                            @delete="deleteSubobjectField(s, i, sf)"
                            @move-up="moveSubobjectFieldUp(s, i, sf)"
                            @move-down="moveSubobjectFieldDown(s, i, sf)"
                            @set-required="subobjectFiledRequired(s, i, sf, $event)"
                            @delete-option="deleteSubobjectFieldOption(s, i, sf, $event)"
                            @update-option="updateSubobjectFieldOption(s, i, sf, $event)"
                            @add-option="addSubobjectFieldOption(s, i, sf)"
                          />
                          <div v-if="input.options.schema.length == 0" class="py-8 text-center text-gray-400">
                            Entrada sem campos definidos
                          </div>
                          <button
                            v-if="editable"
                            class="flex bg-white border w-full border-gray-100 hover:border-primary hover:bg-white items-center justify-center group cursor-pointer text-center rounded-md text-gray-400 font-semibold px-2 py-2 hover:text-gray-700"
                            @click="newSubobjectField(s, i)"
                          >
                            <div
                              class="multiplechoice_letter h-7 w-7 text-center flex flex-col justify-center items-center bg-gray-200 uppercase rounded-md leading-4 text-lg font-medium"
                            >
                              <svg
                                class="h-5 w-5 text-gray-500 group-hover:text-primary fill-current"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                              >
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" />
                              </svg>
                            </div>
                            <div class="flex-1 text-left pl-2">Adicionar novo campo à entrada</div>
                          </button>
                        </div>
                      </div>
                      <div v-else-if="input.type === 'select'" class="fw-multiplechoice flex flex-col gap-2">
                        <div v-if="input.options && input.options.options" class="flex flex-col gap-2">
                          <div
                            v-for="(option, o) in input.options.options"
                            :key="'option_' + o"
                            class="py-1 px-3 relative border border-gray-100 rounded-md text-base font-semibold text-gray-800 bg-white w-full text-left flex justify-start items-center group gap-4"
                            :class="{
                              editable: editable,
                              //'cursor-pointer': editing_option !== input.key + '_' + option.key && editable,
                              //' ': editing_option !== input.key + '_' + option.key,
                              active: editing_option === input.key + '_' + option.key && editable,
                              'py-0.5 pr-1': editing_option === input.key + '_' + option.key
                            }"
                          >
                            <div class="flex-1 flex gap-2 items-center">
                              <input
                                v-if="editable"
                                :ref="input.key + '_' + option.key"
                                v-model="option.text[language]"
                                placeholder="Texto da opção"
                                class="flex-1 w-full text-base font-semibold min-h-9"
                                type="text"
                                @input="dataChanged()"
                              />
                              <div v-else class="flex-1 w-full text-base font-semibold min-h-9">
                                {{ option.text[language] }}
                              </div>
                              <b-dropdown
                                v-if="input.options.options.length > 2 && editable"
                                :scrollable="true"
                                :max-height="200"
                                aria-role="list"
                                position="is-bottom-left"
                              >
                                <template #trigger>
                                  <fw-button type="transparent" size="sm" class="text-gray-400">
                                    <fw-icon-more class="h-5 w-5" />
                                  </fw-button>
                                </template>

                                <b-dropdown-item aria-role="listitem">
                                  <div
                                    class="flex justify-start items-center gap-3 font-semibold text-gray-600"
                                    @click="deleteMultiplechoiceOption(s, i, o, false)"
                                  >
                                    Eliminar opção
                                  </div>
                                </b-dropdown-item>
                              </b-dropdown>
                            </div>
                          </div>
                        </div>
                        <div v-else class="py-5 text-center text-gray-500 text-sm">
                          Sem opções definidas
                        </div>
                        <button
                          v-if="
                            (typeof input.options.options == 'undefined' || input.options.options.length < 10) &&
                              editable
                          "
                          class="flex bg-gray-50 border border-gray-100 hover:border-primary hover:bg-white items-center justify-center group cursor-pointer text-center rounded-md text-gray-400 font-semibold px-2 py-2 hover:text-gray-700"
                          @click="addNewMultiplechoiceOption(s, i)"
                        >
                          <div
                            class="multiplechoice_letter text-center flex flex-col justify-center items-center bg-gray-200 uppercase rounded-xl leading-4 text-lg font-medium"
                            style="width: 2rem; height: 2rem"
                          >
                            <svg
                              class="h-5 w-5 text-gray-500 group-hover:text-primary fill-current"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="24"
                              height="24"
                            >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" />
                            </svg>
                          </div>
                          <div class="flex-1 text-left pl-5">Adicionar nova opção</div>
                        </button>
                      </div>
                      <div class="absolute top-1.5 right-2 flex gap-2">
                        <b-dropdown :scrollable="true" :max-height="200" aria-role="list" position="is-bottom-left">
                          <template #trigger="{ active }">
                            <fw-button type="transparent" size="sm" class="text-gray-500 text-sm">
                              <fw-icon-more class="h-5 w-5" />
                            </fw-button>
                          </template>

                          <b-dropdown-item v-if="editable && input.type == 'text_area'" aria-role="listitem">
                            <div
                              class="flex justify-start items-center gap-3 font-semibold text-gray-600"
                              @click="changeMaxChar(p, s, i)"
                            >
                              Alterar limite da resposta
                            </div>
                          </b-dropdown-item>
                          <b-dropdown-item v-if="editable" aria-role="listitem">
                            <div
                              class="flex justify-start items-center gap-3 font-semibold text-gray-600"
                              @click="removeInput(p, s, i)"
                            >
                              {{
                                input.type === 'text_content' || input.type === 'image_direct' || input.type === 'image'
                                  ? 'Eliminar conteúdo'
                                  : 'Eliminar campo'
                              }}
                            </div>
                          </b-dropdown-item>

                          <div
                            class="flex justify-start items-center font-semibold text-gray-400 bg-gray-100 mx-2 p-2 rounded-lg text-sm my-2"
                          >
                            #{{ input.key }}
                          </div>
                        </b-dropdown>
                      </div>
                    </div>
                  </div>
                </div>
                <template #preview>
                  <div class="bg-white min-w-lg mx-auto h-full px-4 py-3">
                    <div class="flex items-start gap-2">
                      <PlaceholderToogle v-if="input.type === 'bool'" />
                      <div
                        v-if="
                          input.label[language] &&
                            input.label[language].length > 0 &&
                            input.label[language] != '<p></p>'
                        "
                        class="font-medium text-black mb-0.5 min-h-7 flex-1"
                        v-html="input.label[language]"
                      ></div>
                      <div v-else class="text-gray-300 mb-0.5 min-h-7 flex-1">Campo sem nome</div>
                      <fw-tag :type="input.required ? 'light' : 'light-gray'">
                        {{ input.required ? 'Obrigatório' : 'Opcional' }}
                      </fw-tag>
                    </div>
                    <PlaceholderTextInput v-if="input.type === 'text_input'" />
                    <PlaceholderTextArea v-else-if="input.type === 'text_area'" />
                    <PlaceholderFileUpload v-else-if="input.type === 'upload_file'" />
                    <PlaceholderPhoneNumber v-else-if="input.type === 'phone_number'" />
                    <PlaceholderEmail v-else-if="input.type === 'email'" />
                    <PlaceholderDate v-else-if="input.type === 'date'" />
                    <PlaceholderAddress v-else-if="input.type === 'address'" />
                    <PlaceholderVat v-else-if="input.type === 'vat'" />
                    <PlaceholderCountry v-else-if="input.type === 'country_input'" />
                    <PlaceholderIdentityDoc v-else-if="input.type === 'identity_doc'" />
                    <PlaceholderTextHtml v-else-if="input.type === 'text_html'" />
                    <PlaceholderDateTime v-else-if="input.type === 'datetime'" />
                    <PlaceholderSelect v-else-if="input.type === 'select'" />
                    <PlaceholderMultipleChoice
                      v-else-if="input.type === 'multiple_choice'"
                      :options="input.options.options"
                    />
                    <PlaceholderCustomObject
                      v-else-if="input.type === 'custom_objects'"
                      :schema="input.schema"
                      @new-field="newSubobjectField(s, i)"
                      @move-left="moveSubobjectLeft(s, i, $event)"
                      @move-right="moveSubobjectRight(s, i, $event)"
                      @delete="deleteSubobject(s, i, $event)"
                      @edit="editSubobject(s, i, $event)"
                    />
                    <div
                      v-if="
                        input.instructions[language] &&
                          input.instructions[language].length > 0 &&
                          input.instructions[language] != '<p></p>'
                      "
                      class="font-medium text-gray-500 text-sm mt-3 mb-1 min-h-7 flex-1"
                      :class="{
                        'ml-16': input.type === 'bool'
                      }"
                      v-html="input.instructions[language]"
                    ></div>
                  </div>
                </template>
              </PanelFormComponent>
            </Draggable>
            <PanelFormComponent :preview="show_preview">
              <div class="mb-2 p-3 min-h-2 rounded-b-md bg-white">
                <button
                  v-if="editable"
                  class="flex w-full items-center justify-center gap-2 text-gray-400 border border-dashed py-4 border-gray-200 rounded-md text-sm font-semibold hover:text-gray-500"
                  @click="addContent(s, null)"
                >
                  <div
                    class="border-2 border-gray-300 text-semibold rounded-sm h-5 w-5 text-center"
                    style="line-height: 0.8rem"
                  >
                    +
                  </div>
                  Adicionar campo
                </button>
              </div>
              <button
                v-if="editable"
                class="flex w-full items-center justify-center gap-2 text-gray-400 border border-dashed py-4 border-gray-200 rounded-md text-sm font-semibold hover:text-gray-500"
                @click="addSection(s)"
              >
                <svg
                  class="fill-current"
                  style="width: 1.4rem; height: 1.4rem"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M17 21v-4H7v4H5v-5a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v5h-2zM7 3v4h10V3h2v5a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V3h2zM2 9l4 3-4 3V9zm20 0v6l-4-3 4-3z"
                  />
                </svg>
                Adicionar secção
              </button>
              <div class="h-5"></div>
              <template #preview>
                <div class="h-1.5 mb-2 rounded-b-md bg-white"></div>
              </template>
            </PanelFormComponent>
          </Container>
        </div>
      </div>
      <div class="flex-1 flex justify-end">
        <div class="max-w-xl w-full border-l border-black/5 mr-[11.5px]"></div>
      </div>
    </div>
    <div
      v-else-if="editable"
      class="border-dashed hover:border-gray-400 transition-all cursor-pointer border-2 flex gap-3 items-center justify-center rounded-lg py-5 text-gray-700 font-medium text-center"
      @click="addContent(s, null)"
    >
      <fw-icon-add class="h-5 w-5" /> Adicionar campo
    </div>
    <div v-else>
      Sem conteúdo
    </div>

    <fw-modal
      :active.sync="isModalActive"
      :paddingless="currentModal === 'addContent'"
      :can-cancel="currentModal === 'addContent'"
      size="min"
      @close="closeModal()"
    >
      <div class="flex flex-col rounded-lg overflow-hidden">
        <div class="bg-gray-100 p-2 border-b border-gray-200 relative">
          <input
            ref="search-input"
            v-model="menu_search"
            class="w-full rounded-md bg-white border text-base border-gray-200 outline-none pr-2.5 pl-10 py-2"
          />
          <fw-icon-search class="h-6 w-6 absolute top-4 left-4 pointer-events-none text-gray-300" />
        </div>
        <div class="flex-1 max-h-72 overflow-y-auto px-1 py-1">
          <div
            v-for="(menu, index) in menu_questions_filtered"
            :key="index"
            class="flex justify-start items-center gap-3 p-2 rounded hover:bg-gray-50 cursor-pointer"
            @click="addInput(menu.type, null, null, null)"
          >
            <div class="bg-white rounded-lg overflow-hidden border border-gray-100 leading-4" v-html="menu.icon"></div>
            <div>
              <div class="font-bold">{{ menu.text[language] }}</div>
              <div class="text-gray-600 text-sm">{{ menu.description[language] }}</div>
            </div>
          </div>
          <div v-if="menu_questions_filtered.length == 0" class=" py-7 text-sm text-center text-gray-500">
            Nenhum resultado encontrado
          </div>
        </div>
        <div class="bg-gray-100 border-t border-gray-200 p-2 text-sm flex gap-2 items-center text-gray-500">
          <div class="border rounded-md border-gray-200 px-2 py-1 text-gray-400 text-xs font-semibold">
            ESC
          </div>
          Pressione ESC para fechar
        </div>
      </div>
    </fw-modal>
  </div>
</template>

<script>
import PlaceholderTextInput from '@/fw-modules/fw-core-vue/forms/components/editor_placeholders/PlaceholderTextInput.vue'
import PlaceholderTextArea from '@/fw-modules/fw-core-vue/forms/components/editor_placeholders/PlaceholderTextArea.vue'
import PlaceholderFileUpload from '@/fw-modules/fw-core-vue/forms/components/editor_placeholders/PlaceholderFileUpload.vue'
import PlaceholderIdentityDoc from '@/fw-modules/fw-core-vue/forms/components/editor_placeholders/PlaceholderIdentityDoc.vue'
import PlaceholderPhoneNumber from '@/fw-modules/fw-core-vue/forms/components/editor_placeholders/PlaceholderPhoneNumber.vue'
import PlaceholderEmail from '@/fw-modules/fw-core-vue/forms/components/editor_placeholders/PlaceholderEmail.vue'
import PlaceholderDate from '@/fw-modules/fw-core-vue/forms/components/editor_placeholders/PlaceholderDate.vue'
import PlaceholderAddress from '@/fw-modules/fw-core-vue/forms/components/editor_placeholders/PlaceholderAddress.vue'
import PlaceholderCustomObject from '@/fw-modules/fw-core-vue/forms/components/editor_placeholders/PlaceholderCustomObject.vue'
import PlaceholderDateTime from '@/fw-modules/fw-core-vue/forms/components/editor_placeholders/PlaceholderDateTime.vue'
import PlaceholderTextHtml from '@/fw-modules/fw-core-vue/forms/components/editor_placeholders/PlaceholderTextHtml.vue'
import PlaceholderCountry from '@/fw-modules/fw-core-vue/forms/components/editor_placeholders/PlaceholderCountry.vue'
import PlaceholderSelect from '@/fw-modules/fw-core-vue/forms/components/editor_placeholders/PlaceholderSelect.vue'
import PlaceholderVat from '@/fw-modules/fw-core-vue/forms/components/editor_placeholders/PlaceholderVat.vue'
import PlaceholderToogle from '@/fw-modules/fw-core-vue/forms/components/editor_placeholders/PlaceholderToogle.vue'
import PlaceholderMultipleChoice from '@/fw-modules/fw-core-vue/forms/components/editor_placeholders/PlaceholderMultipleChoice.vue'
import MiniPlaceholder from '@/fw-modules/fw-core-vue/forms/components/editor_placeholders/MiniPlaceholder.vue'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import ServiceForms from '@/fw-modules/fw-core-vue/forms/services/ServiceForms'
import utils from '@/fw-modules/fw-core-vue/utilities/utils.js'
import FormattedText from '@/fw-modules/fw-core-vue/ui/components/form/FormattedText'
import TextInput from '@/fw-modules/fw-core-vue/ui/components/form/TextInput.vue'
import { Container, Draggable } from 'vue-dndrop'
import Vue from 'vue'
//import Uploader from '@/fw-modules/fw-core-vue/storage/components/Uploader'
import TextHeadingDual from '../text/TextHeadingDual.vue'
import PanelFormComponent from '@/fw-modules/fw-core-vue/forms/components/panels/PanelFormComponent.vue'

export default {
  name: 'PanelFormEditor',
  components: {
    Container,
    Draggable,
    FormattedText,
    //Uploader,
    TextHeadingDual,
    PlaceholderTextInput,
    PlaceholderTextArea,
    PlaceholderFileUpload,
    PlaceholderIdentityDoc,
    PlaceholderPhoneNumber,
    PlaceholderEmail,
    PlaceholderDate,
    PlaceholderToogle,
    PlaceholderAddress,
    PlaceholderCustomObject,
    PlaceholderDateTime,
    PlaceholderTextHtml,
    PlaceholderCountry,
    PanelFormComponent,
    PlaceholderVat,
    PlaceholderMultipleChoice,
    MiniPlaceholder,
    TextInput,
    PlaceholderSelect
  },
  props: {
    showBackButton: {
      type: Boolean,
      default: true
    },
    form: {
      type: Object,
      required: true
    },
    permissions: {
      type: Object,
      default: () => {
        return {}
      }
    },
    answersid: {
      type: String,
      default: null
      //return this.$route.params.answerid ? this.$route.params.answerid : null
    }
  },
  data: function() {
    return {
      statesLabels: {
        draft: {
          pt: 'Rascunho',
          en: 'Draft'
        },
        published: {
          pt: 'Publicado',
          en: 'Published'
        },
        deprecated: {
          pt: 'Arquivado',
          en: 'Archived'
        }
      },
      show_preview: true,
      permissions_email: '',
      usersWithPermissions: [],
      currentModal: null,
      tmpPosition: null,
      publishing: false,
      loading: false,
      saving: false,
      errorSaving: false,
      editing_option: '',
      editing_score: '',
      editing_score_value: 1,
      dirtyPages: new Set(),
      allPagesCompleted: false,
      showOptions: false,
      rightMargin: 200, //used in header animation (for the right toolbar)
      animateTitle: false,
      animateToolbar: false,
      isMobile: false,
      menu_search: '',
      menu_add_question: [
        {
          icon:
            "<div class='w-12 h-12 flex flex-col justify-center gap-1'>" +
            "<div class='rounded-l flex flex-col py-1 gap-1 pl-1 leading-4 border border-gray-200 ml-2 text-gray-300 border-r-0'>" +
            '<svg class="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M10 6V21H8V6H2V4H16V6H10ZM18 14V21H16V14H13V12H21V14H18Z"></path></svg>' +
            '</div>' +
            '</div>',
          text: {
            pt: 'Campo de texto simples',
            en: 'Simple text field'
          },
          description: {
            pt: 'Permite recolher informação curta até 250 caracteres',
            en: 'Allow to collect short information up to 250 characters'
          },
          type: 'text_input'
        },
        {
          type: 'text_area',
          icon:
            '<div class="w-12 h-12 flex flex-col justify-center gap-1">' +
            '<div class="rounded-l flex flex-col py-1 gap-1 pl-1 leading-4 border border-gray-200 ml-2 text-gray-300 border-r-0">' +
            '<div class="bg-gray-100 rounded-l-lg h-1.5 w-full"></div>' +
            '<div class="bg-gray-100 rounded-l-lg h-1.5 w-full"></div>' +
            '<div class="bg-gray-100 rounded-lg h-1.5 w-6"></div>' +
            '</div>' +
            '</div>',
          text: {
            pt: 'Área de texto livre',
            en: 'Free text area'
          },
          description: {
            pt: 'Permite responder em texto livre',
            en: 'Allow to answer in plain text'
          }
        },
        {
          type: 'text_html',
          icon:
            '<div class="w-12 h-12 flex flex-col justify-center gap-1">' +
            '<div class="rounded-l flex flex-col py-1 gap-1 pl-1 leading-4 border border-gray-200 ml-2 text-gray-300 border-r-0">' +
            '<div class="bg-gray-100 rounded-l-lg h-1.5 w-full"></div>' +
            '<div class="bg-gray-100 rounded-l-lg h-1.5 w-full"></div>' +
            '<div class="bg-gray-100 rounded-lg h-1.5 w-6"></div>' +
            '</div>' +
            '</div>',
          text: {
            pt: 'Texto formatável',
            en: 'Formatted text'
          },
          description: {
            pt: 'Permite responder com texto formatado',
            en: 'Allow to answer in formated text'
          }
        },
        {
          type: 'identity_doc',
          icon:
            '<div class="w-12 h-12 flex flex-col justify-center gap-1">' +
            '<div class="rounded-l flex flex-col py-1 gap-1 pl-1 leading-4 border border-gray-200 ml-2 text-gray-300 border-r-0">' +
            '<div class="bg-gray-100 rounded-l-lg h-1.5 w-full"></div>' +
            '<div class="bg-gray-100 rounded-l-lg h-1.5 w-full"></div>' +
            '<div class="bg-gray-100 rounded-lg h-1.5 w-6"></div>' +
            '</div>' +
            '</div>',
          text: {
            pt: 'Documento de identificação',
            en: 'Identity document'
          },
          description: {
            pt: 'Permite recolher um documento de identificação',
            en: 'Allow to collect an identity document'
          }
        },
        {
          type: 'phone_number',
          icon:
            "<div class='w-12 h-12 flex flex-col justify-center gap-1'>" +
            "<div class='rounded-l flex flex-col py-1 gap-1 pl-1 leading-4 border border-gray-200 ml-2 text-gray-300 border-r-0'>" +
            '<svg class="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M9.36556 10.6821C10.302 12.3288 11.6712 13.698 13.3179 14.6344L14.2024 13.3961C14.4965 12.9845 15.0516 12.8573 15.4956 13.0998C16.9024 13.8683 18.4571 14.3353 20.0789 14.4637C20.599 14.5049 21 14.9389 21 15.4606V19.9234C21 20.4361 20.6122 20.8657 20.1022 20.9181C19.5723 20.9726 19.0377 21 18.5 21C9.93959 21 3 14.0604 3 5.5C3 4.96227 3.02742 4.42771 3.08189 3.89776C3.1343 3.38775 3.56394 3 4.07665 3H8.53942C9.0611 3 9.49513 3.40104 9.5363 3.92109C9.66467 5.54288 10.1317 7.09764 10.9002 8.50444C11.1427 8.9484 11.0155 9.50354 10.6039 9.79757L9.36556 10.6821ZM6.84425 10.0252L8.7442 8.66809C8.20547 7.50514 7.83628 6.27183 7.64727 5H5.00907C5.00303 5.16632 5 5.333 5 5.5C5 12.9558 11.0442 19 18.5 19C18.667 19 18.8337 18.997 19 18.9909V16.3527C17.7282 16.1637 16.4949 15.7945 15.3319 15.2558L13.9748 17.1558C13.4258 16.9425 12.8956 16.6915 12.3874 16.4061L12.3293 16.373C10.3697 15.2587 8.74134 13.6303 7.627 11.6707L7.59394 11.6126C7.30849 11.1044 7.05754 10.5742 6.84425 10.0252Z"></path></svg>' +
            '</div>' +
            '</div>',
          text: {
            pt: 'Número de telefone',
            en: 'Phone number'
          },
          description: {
            pt: 'Permite recolher um número de telefone',
            en: 'Allow to collect a phone number'
          }
        },
        {
          type: 'email',
          icon:
            "<div class='w-12 h-12 flex flex-col justify-center gap-1'>" +
            "<div class='rounded-l flex flex-col py-1 gap-1 pl-1 leading-4 border border-gray-200 ml-2 text-gray-300 border-r-0'>" +
            '<svg class="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M3 3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3ZM20 7.23792L12.0718 14.338L4 7.21594V19H20V7.23792ZM4.51146 5L12.0619 11.662L19.501 5H4.51146Z"></path></svg>' +
            '</div>' +
            '</div>',
          text: {
            pt: 'Endereço de e-mail',
            en: 'Email address'
          },
          description: {
            pt: 'Permite recolher um endereço de e-mail válido',
            en: 'Allow to collect a valid email address'
          }
        },
        {
          type: 'address',
          icon:
            '<div class="w-12 h-12 flex flex-col justify-center gap-1">' +
            '<div class="rounded-l flex flex-col py-1 gap-1 pl-1 leading-4 border border-gray-200 ml-2 text-gray-300 border-r-0">' +
            '<div class="bg-gray-100 rounded-l-lg h-1.5 w-full"></div>' +
            '<div class="bg-gray-100 rounded-l-lg h-1.5 w-full"></div>' +
            '<div class="bg-gray-100 rounded-lg h-1.5 w-6"></div>' +
            '</div>' +
            '</div>',
          text: {
            pt: 'Campo de morada',
            en: 'Address field'
          },
          description: {
            pt: 'Permite recolher uma morada completa',
            en: 'Allow to collect a full address'
          }
        },
        {
          icon:
            "<div class='w-12 h-12 flex flex-col justify-center gap-1'>" +
            "<div class='rounded-l flex flex-col py-1 gap-1 pl-1 leading-4 border border-gray-200 ml-2 text-gray-300 border-r-0'>" +
            '<svg class="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M10 6V21H8V6H2V4H16V6H10ZM18 14V21H16V14H13V12H21V14H18Z"></path></svg>' +
            '</div>' +
            '</div>',
          text: {
            pt: 'Campo de escolha única',
            en: 'Single choice field'
          },
          description: {
            pt: 'Permite escolher uma única opção dentro de uma lista de opções',
            en: 'Allow to choose a single option from a list of options'
          },
          type: 'select'
        },
        {
          icon:
            "<div class='w-12 h-12 flex flex-col justify-center gap-1'>" +
            "<div class='rounded-l flex flex-col py-1 gap-1 pl-1 leading-4 border border-gray-200 ml-2 text-gray-300 border-r-0'>" +
            '<svg class="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M10 6V21H8V6H2V4H16V6H10ZM18 14V21H16V14H13V12H21V14H18Z"></path></svg>' +
            '</div>' +
            '</div>',
          text: {
            pt: 'Escolher país',
            en: 'Choose country'
          },
          description: {
            pt: 'Permite recolher um país',
            en: 'Allow to collect a country'
          },
          type: 'country_input'
        },
        {
          type: 'vat',
          icon:
            '<div class="w-12 h-12 flex flex-col justify-center gap-1">' +
            '<div class="rounded-l flex flex-col py-1 gap-1 pl-1 leading-4 border border-gray-200 ml-2 text-gray-300 border-r-0">' +
            '<div class="bg-gray-100 rounded-l-lg h-1.5 w-full"></div>' +
            '<div class="bg-gray-100 rounded-l-lg h-1.5 w-full"></div>' +
            '<div class="bg-gray-100 rounded-lg h-1.5 w-6"></div>' +
            '</div>' +
            '</div>',
          text: {
            pt: 'Campo de número de contribuinte',
            en: 'VAT number field'
          },
          description: {
            pt: 'Permite recolher um número de contribuinte',
            en: 'Allow to collect a VAT number'
          }
        },
        {
          type: 'bool',
          icon:
            '<div class="w-12 h-12 flex flex-col justify-center gap-1">' +
            '<div class="rounded-l flex flex-col py-1 gap-1 pl-1 leading-4 border border-gray-200 ml-2 text-gray-300 border-r-0">' +
            '<div class="bg-gray-100 rounded-l-lg h-1.5 w-full"></div>' +
            '<div class="bg-gray-100 rounded-l-lg h-1.5 w-full"></div>' +
            '<div class="bg-gray-100 rounded-lg h-1.5 w-6"></div>' +
            '</div>' +
            '</div>',
          text: {
            pt: 'Campo de resposta sim ou não',
            en: 'Yes or no answer field'
          },
          description: {
            pt: 'Permite responder com sim ou não',
            en: 'Allow to answer with yes or no'
          }
        },
        {
          type: 'date',
          icon:
            "<div class='w-12 h-12 flex flex-col justify-center gap-1'>" +
            "<div class='rounded-l flex flex-col py-1 gap-1 pl-1 leading-4 border border-gray-200 ml-2 text-gray-300 border-r-0'>" +
            '<svg class=\'fill-current h-5 w-5\' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M9 1V3H15V1H17V3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3H7V1H9ZM20 11H4V19H20V11ZM7 5H4V9H20V5H17V7H15V5H9V7H7V5Z"></path></svg>' +
            '</div>' +
            '</div>',
          text: {
            pt: 'Campo de data',
            en: 'Date field'
          },
          description: {
            pt: 'Permite recolher uma data',
            en: 'Allow to collect a date'
          }
        },
        {
          type: 'datetime',
          icon:
            "<div class='w-12 h-12 flex flex-col justify-center gap-1'>" +
            "<div class='rounded-l flex flex-col py-1 gap-1 pl-1 leading-4 border border-gray-200 ml-2 text-gray-300 border-r-0'>" +
            '<svg class=\'fill-current h-5 w-5\' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M9 1V3H15V1H17V3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3H7V1H9ZM20 11H4V19H20V11ZM7 5H4V9H20V5H17V7H15V5H9V7H7V5Z"></path></svg>' +
            '</div>' +
            '</div>',
          text: {
            pt: 'Campo de data e hora',
            en: 'Date and time field'
          },
          description: {
            pt: 'Permite recolher uma data e hora',
            en: 'Allow to collect a date and time'
          }
        },
        {
          icon:
            '<div class="w-12 h-12 pt-1 flex flex-col gap-1">' +
            '<div class="rounded-l pl-0.5 leading-4 border border-gray-100 ml-2 flex items-center text-gray-300 border-r-transparent">A</div>' +
            '<div class="rounded-l pl-0.5 leading-4 border border-gray-100 ml-2 flex items-center text-gray-300 border-r-transparent">B</div>' +
            '</div>',
          text: {
            pt: 'Escolha múltipla',
            en: 'Multiple choice'
          },
          description: {
            pt: 'Permite escolher uma ou mais opções definidas',
            en: 'Allow choose one or more options'
          },
          type: 'multiple_choice'
        },
        {
          type: 'upload_file',
          icon:
            "<div class='w-12 h-12 flex flex-col justify-center gap-1'>" +
            "<div class='rounded-l flex flex-col py-1 gap-1 pl-1 leading-4 border border-gray-200 ml-2 text-gray-300 border-r-0'>" +
            "<svg class='fill-current h-5 w-5' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'>" +
            "<path fill='none' d='M0 0h24v24H0z' /> <path d='M1 14.5a6.496 6.496 0 0 1 3.064-5.519 8.001 8.001 0 0 1 15.872 0 6.5 6.5 0 0 1-2.936 12L7 21c-3.356-.274-6-3.078-6-6.5zm15.848 4.487a4.5 4.5 0 0 0 2.03-8.309l-.807-.503-.12-.942a6.001 6.001 0 0 0-11.903 0l-.12.942-.805.503a4.5 4.5 0 0 0 2.029 8.309l.173.013h9.35l.173-.013zM13 13v4h-2v-4H8l4-5 4 5h-3z'  />" +
            '</svg>' +
            '</div>' +
            '</div>',
          text: {
            pt: 'Zona para envio de ficheiros',
            en: 'Place to upload files'
          },
          description: {
            pt: 'Permitir anexar ficheiros',
            en: 'Allow to attach files'
          }
        },
        {
          type: 'custom_objects',
          icon:
            '<div class="w-12 h-12 flex flex-col justify-center gap-1">' +
            '<div class="rounded-l flex flex-col py-1 gap-1 pl-1 leading-4 border border-gray-200 ml-2 text-gray-300 border-r-0">' +
            '<div class="bg-gray-100 rounded-l-lg h-1.5 w-full"></div>' +
            '<div class="bg-gray-100 rounded-l-lg h-1.5 w-full"></div>' +
            '<div class="bg-gray-100 rounded-lg h-1.5 w-6"></div>' +
            '</div>' +
            '</div>',
          text: {
            pt: 'Lista de entradas',
            en: 'List of entries'
          },
          description: {
            pt: 'Permite recolher uma lista de entradas personalizadas',
            en: 'Allow to collect a list of custom entries'
          }
        }
      ],
      dropPlaceholderOptions: {
        className: 'drop-preview',
        animationDuration: '150',
        showOnTop: true
      },
      cover: null,
      startAt: null,
      endsAt: null,
      pages: [], //por página, secção, campo
      scores: null,
      editors: {},
      scrollY: 0,
      language: 'pt', //currently editing
      total_score: 0,
      user: null,
      debouce_timer: null,
      instance: null,
      tmpSelection: null,
      ignoreQuestionValue: 'give_score',
      showSaveCorrection: false,
      totalQuestions: 0,
      MAX_QUESTIONS: 100,
      tmp_form: null,
      errors: {}
    }
  },
  computed: {
    form_states() {
      if (this.form) {
        let all = ['draft', 'published', 'deprecated']
        //remove all states before the current state
        let currentStateIndex = all.indexOf(this.form.state)
        return all.slice(currentStateIndex + 1)
        //return all.filter(item => item !== this.form.state)
      } else {
        return []
      }
    },
    menu_questions_filtered() {
      let forbidden_subfields = ['custom_objects', 'multiple_choice']
      return this.menu_add_question.filter(
        item =>
          (item.text[this.language].toLowerCase().includes(this.menu_search.toLowerCase()) ||
            item.description[this.language].toLowerCase().includes(this.menu_search.toLowerCase())) &&
          this.tmpPosition != null &&
          (this.tmpPosition.subobject == false ||
            (this.tmpPosition.subobject == true && !forbidden_subfields.includes(item.type)))
      )
    },
    instanceEnded() {
      return this.originalInstance && this.originalInstance.status == 'ended'
    },
    isModalActive() {
      return this.currentModal !== null
    },
    currentVersionText() {
      return this.form && this.form.title ? this.form.title : 'Sem título'
    },
    myStorageToken() {
      return this.$store.getters.getUser.token
    },
    ServiceStorage() {
      return ServiceStorage
    },
    isPermissionEmailValid() {
      return utils.isValidEmail(this.permissions_email)
    },
    canCorrectExam() {
      return this.permissions.canWriteAnswersComments
    },
    examLink() {
      return process.env.VUE_APP_IS_PRODUCTION
        ? `https://ucstudent.uc.pt/ex/${this.instance.key}`
        : `https://ucstudent.dev.ucframework.pt/ex/${this.instance.key}`
    },
    editable() {
      return true
    },
    currentVersion() {
      let subid = this.subid
      return this.instance !== null && this.instance.forms.length > 1
        ? this.instance.forms.findIndex(item => item.key === subid)
        : -1
    },
    dirtyData() {
      return this.$store.state.dirtyData
    },
    savingData() {
      return this.saving
    },
    canStart() {
      //TODO: if minimal conditions are meet the user can publich the form
      return this.instance !== null && this.permissions.start === true
    },
    canIgnoreQuestions() {
      //TODO: Mudar quando o Tomás implementar a funcionalidade
      return this.permissions.canIgnoreQuestions
    },
    canUpdateQuestions() {
      return this.permissions.canUpdateOptions
    },
    formKey() {
      return this.$route.params.key ? this.$route.params.key : null
    },
    examVersions() {
      if (this.instance == null) return []
      if (Array.isArray(this.instance.forms)) {
        return this.instance.forms.map(form => form.key)
      } else {
        return Object.keys(this.instance.forms)
      }
    }
  },
  mounted() {
    this.verifyFormData()
    this.calculateRightMargin()
    window.addEventListener('resize', this.calculateRightMargin)
  },
  methods: {
    addSubobjectFieldOption(s, i, o) {
      console.log('addSubobjectFieldOption', s, i)
      let newOptionData = {
        key: this.makeid(8), //
        text: {}
      }
      newOptionData['text']['pt'] = ''
      newOptionData['text']['en'] =
        'Option' + (this.tmp_form.schema[s].content[i].options.schema[o].options.options.length + 1)
      this.tmp_form.schema[s].content[i].options.schema[o].options.options.push(newOptionData)
      this.dataChanged()
    },
    updateSubobjectFieldOption(s, i, o, data) {
      let optionIndex = data.index
      let text = data.value
      this.tmp_form.schema[s].content[i].options.schema[o].options.options[optionIndex].text[this.language] = text
    },
    removeSubobjectFieldOption(s, i, o) {
      this.$buefy.dialog.confirm({
        title: 'Eliminar opção',
        message: 'Tem a certeza que quer eliminar esta opção?',
        confirmText: 'Eliminar',
        type: 'is-danger',
        onConfirm: () => {
          console.log('removeSubobjectFieldOption', s, i, o)
          this.tmp_form.schema[s].content[i].options.options.splice(o, 1)
          this.dataChanged()
        }
      })
    },
    newSubobjectField(s, i) {
      console.log('newSubobjectField', s, i)
      this.tmpPosition = {
        section: s,
        index: i,
        subobject: true
      }
      this.menu_search = ''
      this.currentModal = 'addContent'
      setTimeout(() => {
        this.$refs['search-input'].focus()
      }, 500)
    },
    subobjectFiledRequired(s, i, subobject_index, value) {
      console.log('subobjectFiledRequired', s, i, subobject_index, value)
      Vue.set(this.tmp_form.schema[s].content[i].options.schema[subobject_index], 'required', value)
      this.dataChanged()
    },
    moveSubobjectFieldUp(s, i, subobject_index) {
      console.log('moveSubobjectLeft', s, i, subobject_index)
      this.tmp_form.schema[s].content[i].options.schema.splice(
        subobject_index - 1,
        0,
        this.tmp_form.schema[s].content[i].options.schema.splice(subobject_index, 1)[0]
      )
      this.dataChanged()
    },
    moveSubobjectFieldDown(s, i, subobject_index) {
      console.log('moveSubobjectRight', s, i, subobject_index)
      this.tmp_form.schema[s].content[i].options.schema.splice(
        subobject_index + 1,
        0,
        this.tmp_form.schema[s].content[i].options.schema.splice(subobject_index, 1)[0]
      )
      this.dataChanged()
    },
    subobjectFieldNameChanged(s, i, subobject_index, value) {
      this.tmp_form.schema[s].content[i].options.schema[subobject_index].label[this.language] = value
      this.dataChanged()
    },
    deleteSubobjectField(s, i, subobject_index) {
      this.$buefy.dialog.confirm({
        title: 'Eliminar campo',
        message: 'Tem a certeza que quer eliminar este campo?',
        confirmText: 'Eliminar',
        type: 'is-danger',
        onConfirm: () => {
          console.log('deleteSubobject', s, i, subobject_index)
          this.tmp_form.schema[s].content[i].options.schema.splice(subobject_index, 1)
          this.dataChanged()
        }
      })
    },
    editSubobject(s, i, subobject_index) {
      this.tmpPosition = {
        section: s,
        index: i,
        subobject: true,
        subobject_index: subobject_index
      }
      //change name
      this.$buefy.dialog.prompt({
        title: 'Editar sub-campo',
        message: 'Introduza o novo nome do campo',
        input: {
          type: 'text',
          value: this.tmp_form.schema[s].content[subobject_index].title[this.language],
          placeholder: this.tmp_form.schema[s].content[subobject_index].title[this.language],
          maxlength: 100,
          autofocus: true,
          readonly: false
        },
        confirmText: 'Confirmar',
        cancelText: 'Cancelar',
        onConfirm: value => {
          this.tmp_form.schema[s].content[subobject_index].title[this.language] = value
          this.dataChanged()
        },
        onCancel: () => {}
      })
    },
    getInputTypeLabel(type) {
      let data = this.menu_add_question.find(item => item.type === type)
      if (data) {
        return data.text[this.language]
      }
      return ''
    },
    verifyFormData() {
      let original = this.form
      let newForm = JSON.parse(JSON.stringify(original))
      if (newForm.schema == null) {
        newForm.schema = []
      }
      //verify if we have at least one section in schema
      if (newForm.schema.length === 0) {
        newForm.schema.push({
          key: this.makeid(8),
          title: {
            pt: '',
            en: ''
          },
          content: []
        })
      }
      this.tmp_form = newForm
    },
    toggleRequired(s, i) {
      this.tmp_form.schema[s].content[i].required = !this.tmp_form.schema[s].content[i].required
      this.dataChanged()
    },
    addContent(s, i) {
      console.log('addContent', s, i)
      if (i == null) {
        i = this.tmp_form.schema[s].content.length
      }
      //if (this.totalQuestions < this.MAX_QUESTIONS) {
      this.tmpPosition = {
        section: s,
        index: i,
        subobject: false
      }
      this.menu_search = ''
      this.currentModal = 'addContent'
      //delay 500ms
      setTimeout(() => {
        this.$refs['search-input'].focus()
      }, 500)
      //}
      //}
    },
    closeModal() {
      console.log('closeModal')
      this.currentModal = null
    },
    downloadFile(file) {
      console.log('downloadFile', file)
      const userToken = file.token ? file.token : this.$store.state.session.user.token
      const fileurl = file.file ? file.file.url_format : file.url_format
      const filekey = file.file ? file.file.key : file.key
      const filename = file.file && file.file.filename ? file.file.filename : file.filename

      const url = fileurl
        .replaceAll('{TOKEN}', userToken)
        .replaceAll('{KEY}', filekey)
        .replaceAll('{FILENAME}', filename)

      const link = document.createElement('a')
      link.href = url
      link.target = '_blank'
      link.setAttribute('download', file.filename)
      document.body.appendChild(link)
      link.click()
    },
    changeMaxChar(p, s, i) {
      let currentValue = this.pages[p].schema[s].content[i].maxChars
      this.$buefy.dialog.prompt({
        message: `Qual o comprimento máximo para a resposta a esta pergunta?`,
        inputAttrs: {
          type: 'number',
          placeholder: 'Insira o número máximo de caracteres',
          value: currentValue,
          min: 0
        },
        trapFocus: true,
        onConfirm: value => {
          console.log(value)
          Vue.set(this.pages[p].schema[s].content[i], 'maxChars', value)
          this.dataChanged(p)
        }
      })
    },
    uploadFileToQuestion(p, s, i, event) {
      console.log('UPLOAD RESULT')
      console.log(event)
      if (event.length > 0) {
        let serverFile = event[0].response.data.file
        Vue.set(this.pages[p].schema[s].content[i], 'file', serverFile)
        this.dataChanged(p)
      }
    },
    removeFile(p, s, i) {
      this.$buefy.dialog.confirm({
        message: 'Tem a certeza que quer remover a imagem?',
        type: 'is-danger',
        onConfirm: () => {
          Vue.set(this.pages[p].schema[s].content[i], 'file', null)
          this.dataChanged(p)
        }
      })
    },
    imageThumbUrl(file) {
      return file.thumb_url_format
        .replace('{KEY}', file.key)
        .replace('{SIZE}', 'max2k')
        .replace('{FILENAME}', file.thumb_filename || file.filename)
    },
    checkPagesCompleteness() {
      for (let i = 0; i < this.pages.length; i++) {
        if (this.pages[i].complete !== true) {
          this.allPagesCompleted = false
          return
        }
      }
      this.allPagesCompleted = true
    },
    goToSection(key) {
      document.getElementsByTagName('main')[0].scrollTo({
        top:
          this.$refs['section_' + key][0].getBoundingClientRect().top +
          document.getElementsByTagName('main')[0].scrollTop -
          55,
        behavior: 'smooth'
      })
    },
    renderNav() {
      let all = document.getElementsByClassName('empty_nav_bt')
      for (let i = 0; i < all.length; i++) {
        //if (all[i].classList.contains('empty_nav_bt')) {
        all[i].innerHTML = 'S' + (i + 1)
        //}
      }
    },
    async changeLanguage(language) {
      this.language = language
    },
    async formatPageData() {
      //when you change language just verify if the data inside pages is correct
      this.pages.forEach((page, p) => {
        if (page.title[this.language] === undefined) {
          page.title[this.language] = this.language === 'pt' ? 'Página ' + (p + 1) : 'Page ' + (p + 1)
        }
        page.schema.forEach(section => {
          if (section.title[this.language] === undefined) {
            section.title[this.language] = ''
          }
          section.content.forEach(input => {
            if (input.instructions[this.language] === undefined) {
              input.instructions[this.language] = ''
            }

            if (input.placeholder[this.language] === undefined) {
              input.placeholder[this.language] = ''
            }

            if (Array.isArray(input.options)) {
              input.options.forEach((option, o) => {
                if (option.text[this.language] === undefined) {
                  option.text[this.language] =
                    this.language == 'pt'
                      ? 'Opção ' + String.fromCharCode(65 + o)
                      : 'Option ' + String.fromCharCode(65 + o)
                }
              })
            }
          })
        })
      })
    },
    showError(message = 'Ocorreu um erro. Tente de novo mais tarde ou contacte o suporte.') {
      this.$buefy.dialog.alert({
        message: message,
        type: 'is-danger',
        hasIcon: false,
        icon: 'times-circle',
        iconPack: 'fa',
        ariaRole: 'alertdialog',
        ariaModal: true
      })
    },
    clickBackground() {
      this.editing_option = ''
    },
    addNewMultiplechoiceOption(s, i) {
      let options =
        this.tmp_form.schema[s].content[i].options && this.tmp_form.schema[s].content[i].options.options
          ? this.tmp_form.schema[s].content[i].options.options
          : []

      let letter = String.fromCharCode(97 + options.length).toUpperCase()
      //let input_score = this.pages[p].schema[s].content[i].score

      let newOptionData = {
        key: this.makeid(8), //
        text: {}
      }
      newOptionData['text']['pt'] = 'Opção ' + letter
      newOptionData['text']['en'] = 'Option ' + letter
      options.push(newOptionData)
      /*if (input_score > 0) {
        let partial_score = input_score / options.length
        //redistribuir score pelas opções
        options.map(opt => {
          opt['score'] = partial_score
          return opt
        })
      }*/
      Vue.set(this.tmp_form.schema[s].content[i].options, 'options', options)
      setTimeout(() => {
        this.$refs[this.tmp_form.schema[s].content[i].key + '_' + newOptionData.key][0].focus()
      }, 500)
      this.dataChanged()
    },
    deleteMultiplechoiceOption(s, i, o, force = false) {
      if (force) {
        this.tmp_form.schema[s].content[i].options.options.splice(o, 1)
        this.dataChanged()
      } else {
        this.$buefy.dialog.confirm({
          message: 'Tem a certeza que quer remover a opção?',
          type: 'is-danger',
          onConfirm: () => {
            this.deleteMultiplechoiceOption(s, i, o, true)
          }
        })
      }
    },

    editMultiplechoiceOption(val) {
      if (this.editable) {
        this.editing_option = val
        let self = this
        Vue.nextTick(function() {
          //console.log(val, self.$refs[val], self.$refs)
          self.$refs[val][0].focus()
        })
      }
    },
    async closeForm() {
      let response = await ServiceForms.updateFormStatus(this.formKey, 'close')
      console.log('closeForm', response)
    },
    async changeStatus(status) {
      try {
        let response = await ServiceForms.updateFormStatus(this.formKey, status)
        console.log('closeForm', response)
        this.form.state = status
      } catch (e) {
        console.error(e)
      }
    },
    async saveForm() {
      console.log('saveForm activated!')
      if (!this.saving) {
        this.saving = true
        let self = this
        console.log('saving')
        //remove all errors
        this.errors = {}
        try {
          let result = await ServiceForms.saveForm(this.formKey, {
            schema: this.tmp_form.schema,
            title: this.tmp_form.title ? this.tmp_form.title : ''
          })
          console.log('saving result', result)
          Vue.nextTick(function() {
            self.checkPagesCompleteness()
            self.$emit('saved')
          })
          this.$store.commit('setDirtyData', false)
        } catch (e) {
          console.error(e)
          //Process errors
          let errors = e.response.data['__errors__']
          if (errors && errors.length > 0) {
            for (let i = 0; i < errors.length; i++) {
              let errorParts = errors[i].field.split('.')
              let sectionIndex = parseInt(errorParts[0])
              let inputIndex = parseInt(errorParts[2])
              let inputid = this.tmp_form.schema[sectionIndex].content[inputIndex].key
              let field = errorParts[3]
              Vue.set(this.errors, inputid + ':' + field, errors[i].detail)
            }
          }
        } finally {
          this.saving = false
        }
      }
    },
    dataChanged() {
      //console.log('dataChanged ' + page + ' activated!')
      //this.dirtyPages.add(page)
      this.$store.commit('setDirtyData', true)
      //debounce, save
      let self = this
      if (this.debouce_timer !== null) {
        clearTimeout(this.debouce_timer)
      }
      this.debouce_timer = setTimeout(() => {
        self.debouce_timer = null
        self.saveForm()
      }, 500)
    },
    closeOptionsModal() {
      this.showOptions = false
    },
    changeOptions(option) {
      console.log('changing', option)
      Vue.set(this.form.options, option, !this.form.options[option])
      //this.form.options[option] =
    },
    calculateRightMargin() {
      //console.log(this.$refs['form_main_body'])
      //console.log(window.innerWidth, this.$refs['form_main_body'].getBoundingClientRect().right)
      this.isMobile = window.innerWidth < 640

      if (this.animateTitle) {
        this.rightMargin = 15
      } else if (this.form !== null && !this.loading && this.$refs['form_main_body']) {
        this.rightMargin = window.innerWidth - this.$refs['form_main_body'].getBoundingClientRect().right
      }
    },
    getChildPayload(index, s) {
      //console.log('getChildPayload', p, s, index)
      return this.tmp_form.schema[s].content[index]
    },
    toogleCover() {
      if (this.cover === null) {
        this.cover = true
      } else {
        this.cover = null
      }
    },

    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr

      const result = [...arr]
      let itemToAdd = payload

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }

      return result
    },
    onDropSection(s, dropResult) {
      if (this.editable) {
        let result = this.applyDrag(this.tmp_form.schema[s].content, dropResult)
        console.log('applyDrag', result)
        Vue.set(this.tmp_form.schema[s], 'content', result)
        this.dataChanged()
      }
    },
    onDropPage(dropResult) {
      console.log(dropResult)
      //TODO
    },
    makeid(length) {
      var result = ''
      var characters = 'abcdefghijklmnopqrstuvwxyz0123456789'
      var charactersLength = characters.length
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
      }
      return (
        Date.now()
          .toString()
          .slice(5) + result
      )
    },
    addInput(type, page, section, i = null) {
      this.closeModal()

      //page = this.tmpPosition.page
      section = this.tmpPosition.section
      i = this.tmpPosition.index + 1
      if (this.tmpPosition.subobject && this.tmpPosition.subobject_index !== null) {
        i = this.tmpPosition.subobject_index + 1
      }

      let inputData = {
        placeholder: {},
        //TODO: generate uid
        key: Date.now().toString(), //this.makeid(8),
        type: type,
        instructions: {},
        label: {},
        file: null,
        options: {},
        required: false
      }

      inputData['placeholder'][this.language] = ''
      inputData['instructions'][this.language] = ''
      inputData['label'][this.language] = ''

      if (type === 'multiple_choice' || type === 'select') {
        inputData['random'] = false
        inputData['options']['options'] = [
          {
            key: Date.now().toString() + '1',
            text: {}
          },
          {
            key: Date.now().toString() + '2',
            text: {}
          }
        ]
        inputData['options']['options'][0]['text'][this.language] = this.language === 'pt' ? 'Opção A' : 'Option A'
        inputData['options']['options'][1]['text'][this.language] = this.language === 'pt' ? 'Opção B' : 'Option B'
      } else if (type === 'text_area') {
        inputData['options']['min'] = 0
        //inputData['message'] = { pt: '', en: '' }
        inputData['message'] = {}
        inputData['message'][this.language] = ''
        inputData['placeholder'] = {}
        inputData['placeholder'][this.language] =
          this.language === 'pt' ? 'Escreva aqui a sua resposta' : 'Write here your answer'
        inputData['options']['max'] = 0
      } else if (type === 'upload_file') {
        inputData['options']['allowed'] = 'any'
        inputData['options']['max'] = 0
      } else if (type === 'custom_objects') {
        inputData['options']['max'] = 0
        inputData['options']['min'] = 0
        inputData['options']['schema'] = []
      }
      console.log('ADDING', inputData, section)
      if (this.tmpPosition.subobject && this.tmpPosition.subobject_index >= 0) {
        this.tmp_form['schema'][section]['content'][this.tmpPosition.index]['options']['schema'].splice(
          this.tmpPosition.subobject_index,
          0,
          inputData
        )
      } else if (this.tmpPosition.subobject) {
        this.tmp_form['schema'][section]['content'][this.tmpPosition.index]['options']['schema'].push(inputData)
      } else {
        if (i === null) {
          this.tmp_form['schema'][section]['content'].push(inputData)
        } else {
          this.tmp_form['schema'][section]['content'].splice(i, 0, inputData)
        }
      }
      this.dataChanged(page)
    },
    calculateTotalPoints() {
      let totalQuestions = 0
      this.missingPoints = false
      // go through all pages and sections and calculate total points
      this.currentTotalPoints = 0
      this.pages.forEach(page => {
        page.schema.forEach(section => {
          section.content.forEach(input => {
            if (input.score) {
              const points = Number.parseFloat(input.score)
              this.currentTotalPoints += points
              if (points <= 0) {
                this.missingPoints = true
              }
            } else {
              this.missingPoints = true
            }
            totalQuestions++
          })
        })
      })
      this.totalQuestions = totalQuestions
    },
    removeInput(page, section, i) {
      this.$buefy.dialog.confirm({
        message: 'Tem a certeza que quer remover o campo?',
        type: 'is-danger',
        onConfirm: () => {
          this.tmp_form['schema'][section]['content'].splice(i, 1)
          this.dataChanged(page)
        }
      })
    },
    addSection(s) {
      let data = {
        key: this.makeid(8),
        title: {},
        content: [],
        boxed: true
      }
      data.title['pt'] = ''
      data.title['en'] = ''
      this.tmp_form.schema.splice(s + 1, 0, data)
      this.dataChanged()
      //let self = this
      /*Vue.nextTick(function() {
        self.renderNav()
      })*/
    },
    removeSection(p, s) {
      this.$buefy.dialog.confirm({
        message: 'Tem a certeza que quer remover a secção e todos os seus dados?',
        type: 'is-danger',
        onConfirm: () => {
          this.tmp_form.schema.splice(s, 1)
          this.dataChanged(p)
          this.renderNav()
          this.calculateTotalPoints()
        }
      })
    },
    goBack() {
      //this.$router.back()
      this.$router.push('/exam/' + this.instance.key + '/evaluation')
    },
    cancelEditor() {
      if (this.dirtyPages.size > 0) {
        this.$buefy.dialog.confirm({
          message: 'Tem a certeza que quer sair sem guardar as alterações?',
          onConfirm: () => {
            this.$emit('cancel')
          }
        })
      } else {
        this.$emit('cancel')
      }
    }
  }
}
</script>

<style>
.form-editor .textarea,
.form-editor .input,
.form-editor .taginput .taginput-container.is-focusable {
  background: white !important;
}
.placeholder-input {
  @apply relative bg-white ring-1 whitespace-nowrap overflow-hidden text-gray-300 ring-gray-800/10 rounded shadow-sm px-4 py-2.5 cursor-not-allowed text-base;
}
.multiplechoice_option_editor.editable .multiplechoice_letter {
  @apply text-gray-500;
}
.multiplechoice_option_editor.editable:hover .multiplechoice_letter {
  color: rgba(3, 164, 121, 0.7);
}
.multiplechoice_option_editor.editable:focus .multiplechoice_letter {
  color: rgba(3, 164, 121, 0.7);
}
.multiplechoice_option_editor.editable:hover {
  border-color: rgba(3, 164, 121, 0.7);
}

.multiplechoice_option_editor.selected_correct .multiplechoice_letter {
  background-color: #03b78e;
  @apply text-gray-100;
}
.multiplechoice_option_editor.selected_correct {
  border-color: rgba(3, 164, 121, 0.7);
  background-color: #e3f1ed;
  @apply font-semibold;
}

.multiplechoice_option_editor.missed_selection .multiplechoice_letter {
  background-color: #d2b44fff;
  @apply text-gray-100;
}
.multiplechoice_option_editor.missed_selection {
  border-color: rgba(248, 215, 25, 0.7);
  background-color: #fffdef;
  @apply font-semibold;
}

.multiplechoice_option_editor.selected_wrong .multiplechoice_letter {
  background-color: #b72403;
  @apply text-gray-100;
}
.multiplechoice_option_editor.selected_wrong {
  border-color: rgba(164, 33, 3, 0.7);
  background-color: #ecded9;
  @apply font-semibold;
}

.bt_nav_lateral {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.image_direct {
  min-height: 50px;
}
.view-builder .modal input {
  @apply leading-4 bg-gray-100 py-0;
  min-height: auto;
}
.add-content-box .add-content-label {
  @apply transition-all whitespace-nowrap mx-1 text-sm font-semibold;
}

.add-content-box .add-content-arrow {
  @apply transition-all overflow-hidden whitespace-nowrap;
  max-width: 27px;
}
.add-content-box .add-content-arrow.open {
  max-width: 500px !important;
}

.add-content-arrow {
  @apply rounded px-1 py-0.5 bg-primary text-white text-opacity-80 flex items-center;
}

.dndrop-container.vertical > .dndrop-draggable-wrapper {
  overflow: visible !important;
  display: block;
}

.add-content-arrow:after {
  content: '';
  position: absolute;
  right: -0.5em;
  width: 1.2em;
  height: 1.2em;
  border-radius: 0 0 0.2em 0;
  margin-top: 0px;
  transform: rotate(-45deg);
  background: linear-gradient(-45deg, #30a898 50%, transparent 50%);
}
.page-section .section-toolbar {
  @apply opacity-0 transition-all;
}
.page-section:hover .section-toolbar {
  @apply opacity-100;
}

.page-separator {
  @apply relative mt-12 mb-8 bg-gray-200 bg-opacity-40;
  height: 2px;
  box-shadow: inset 0px 1px rgba(55, 55, 55, 0.07);
  border-radius: 1px;
}

.page-separator .title {
  @apply absolute bg-gray-100 font-semibold uppercase text-xs text-gray-400 text-opacity-60 text-center -top-1.5;
  left: 50%;
  margin-left: -40px;
  width: 80px;
}

.form-editor input {
  background: white !important;
  outline: none !important;
  border: none !important;
}
.form-editor input:focus {
  background: white !important;
  outline: none !important;
  border: none !important;
}

.real-white {
  background-color: #fdfdfd;
}

.slighty-gray-color {
  background-color: #fcfcfc;
}
.real-shadow-1 {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
}
.real-shadow-2 {
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12) !important;
}
.real-shadow-3 {
  box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.2), 0 7px 10px 1px rgba(0, 0, 0, 0.14), 0 2px 16px 1px rgba(0, 0, 0, 0.12) !important;
}
.real-shadow-4 {
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 5px 22px 4px rgba(0, 0, 0, 0.12) !important;
}
.bt-scafold {
  @apply rounded-md border font-bold border-gray-100 py-2.5 text-gray-700 text-center mx-auto cursor-pointer text-gray-100;
  min-width: 80px;
}

.multiplechoice_option_editor .multiplechoice_letter {
  @apply text-gray-500;
}
.title-anim {
  @apply transition-all z-20;
}
.title-anim .edit-button {
  @apply opacity-100;
}

.title-anim.title-header {
  @apply top-3 left-20 absolute text-2xl cursor-default;
}
.title-anim.title-header .edit-button {
  @apply opacity-0;
}

.box-question .drag-handle {
  @apply transition-all opacity-0;
}
.box-question:hover .drag-handle {
  @apply transition-all opacity-100;
}

.box-question .add-content-box {
  @apply transition-all opacity-0;
}
.box-question:hover .add-content-box {
  @apply transition-all opacity-100;
}

.drag-handle {
  @apply h-6 w-5 shadow-sm bg-white rounded border border-gray-200 items-center flex justify-center text-center absolute -left-3 top-3;
  cursor: grab;
}

.view-builder .question-points:focus,
.view-builder .question-points:active {
  @apply ring-2 ring-offset-2 ring-teal-500 outline-none ring-opacity-90;
}
.exam-cover input {
  @apply outline-none;
}

input.question-points {
  @apply text-base px-2 py-1 rounded-lg bg-gray-100 w-16 text-xl text-right;
}

input.question-points-2 {
  @apply text-base px-2 py-1.5 rounded-lg bg-gray-100 text-xl text-left;
}

.dropdown.w-full .dropdown-trigger {
  width: 100% !important;
}
.dropdown .dropdown-content {
  @apply border-gray-200;
}
.multiplechoice_option_editor input,
.multiplechoice_option_editor input:active,
.multiplechoice_option_editor input:focus {
  @apply py-2 rounded-md outline-none;
}
.fw-multiplechoice .multiplechoice_option_editor.active {
  @apply ring-2 ring-offset-2 ring-teal-500 ring-opacity-90;
}

.question-container {
  min-height: 50px;
  @apply relative flex gap-5;
}

.loading-bar {
  width: 12%;
  animation-name: loading-bar-anim;
  animation-duration: 3s;
  animation-delay: 500ms;
}

@keyframes loading-bar-anim {
  from {
    width: 12%;
  }
  to {
    width: 80%;
  }
}

/* Chrome, Safari, Edge, Opera */
input.points_input::-webkit-outer-spin-button,
input.points_input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.points_input[type='number'] {
  -moz-appearance: textfield;
}
</style>

<i18n>
  {
    "pt": {
      "multiple_choice": "Escolha múltipla",
      "text_area": "Pergunta aberta",
      "upload_file": "Upload de ficheiros",
      "text_content": "Conteúdo de texto"
    },
    "en": {
      "multiple_choice": "Multiple choice",
      "text_area": "Open question",
      "upload_file": "Upload files",
      "text_content": "Text content"
    }
  }
</i18n>
