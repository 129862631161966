var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fw-layout',{attrs:{"full":"","main-sidebar":true,"header-session-nav":true,"back-to":"emit"},on:{"go-back-to":_vm.backTo},scopedSlots:_vm._u([{key:"main-sidebar",fn:function(){return [_c('SidebarClass',{attrs:{"edition":_vm.edition,"view":_vm.view,"profile":"teacher","teachers":_vm.teachers},on:{"go-to-view":_vm.goToView}})]},proxy:true},{key:"main-content",fn:function(){return [(_vm.view == 'liveclasses' && _vm.edition)?_c('PanelLiveClasses',{staticClass:"flex",attrs:{"edition":_vm.edition,"is-teacher":true,"view-list":_vm.viewList},on:{"load-session":function($event){_vm.viewList = !_vm.viewList},"go-to-user-chat":_vm.goToUserChat}}):_vm._e(),(_vm.view == 'teachers')?_c('PanelTeachers',{attrs:{"teachers":_vm.teachers},on:{"go-to-user-chat":_vm.goToUserChat}}):_vm._e(),(_vm.view == 'students')?_c('PanelStudents',{attrs:{"students":_vm.students},on:{"go-to-user-chat":_vm.goToUserChat}}):_vm._e(),(_vm.view == 'new-bucket')?_c('PanelBucketAdd',{staticClass:"mx-auto max-w-prose",attrs:{"application-context":{ application: 'academic', item_type: 'class_edition', item_key: _vm.edition.key }},on:{"go-back":function($event){_vm.view = 'buckets'}}}):_vm._e(),(_vm.view == 'buckets')?_c('fw-panel',{staticClass:"p-5 xl:max-w-screen-xl mx-auto",attrs:{"title":"Materiais de apoio","featured":""},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('fw-button',{attrs:{"type":"regular"},nativeOn:{"click":function($event){_vm.view = 'new-bucket'}}},[_vm._v("Novo repositório")])]},proxy:true}],null,false,1034105272)},[_c('PanelBucketList',{attrs:{"create-allowed":_vm.edition && _vm.edition.bucket_permissions.create,"paddingless":"","application-context":{
          application: 'academic',
          item_type: 'class_edition', //, 'unit_edition'],
          include_unit_edition_buckets: _vm.edition !== null ? _vm.edition.unit.key : '',
          include_unit_buckets: _vm.edition !== null ? _vm.edition.unit.unit_key_for_bucket : '',
          item_key: _vm.edition.key
        }},on:{"go-to-new-bucket":function($event){return _vm.goToView('new-bucket')}}})],1):_vm._e(),(_vm.inChatView)?_c('PanelChats',{ref:"chat",attrs:{"chats":_vm.chats,"chat-users":_vm.chatUsers,"all-chat-users":_vm.allChatUsers,"chat-active":_vm.chatActive,"view-chats-list":_vm.viewChatsList,"chat-locked":_vm.chatLocked,"load-old-messages-core":_vm.loadOldMessagesCore,"load-more-chats-core":_vm.loadMoreChatsCore},on:{"update-active-chat":_vm.updateActiveChat,"load-chat":_vm.loadChatAndSetNav,"load-main-chat":_vm.loadMainChat,"unload-chat":_vm.unloadChatAndSetNav,"set-chat-messages":_vm.setChatMessages,"delete-chat-message":_vm.deleteChatMessage}}):_vm._e(),(_vm.view == 'calendar' && _vm.edition)?_c('PanelCalendar',{attrs:{"edition":_vm.edition}}):_vm._e(),(_vm.view == 'settings' && _vm.edition)?_c('PanelClassSettings',{attrs:{"edition":_vm.edition},on:{"update-edition":_vm.localUpdateEdition}}):_vm._e()]},proxy:true},{key:"tapbar",fn:function(){return [_c('TapbarClass',{attrs:{"view":_vm.view,"is-teacher":_vm.isTeacher},on:{"go-to-view":_vm.goToView}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }