<template>
  <div
    class="flex rounded-full cursor-pointer text-sm font-medium bg-gray-500 items-center select-none text-white hover:bg-opacity-80 px-2.5 py-1"
    :class="{ 'pr-2': showCloseButton }"
    @click="$emit('close')"
  >
    <div>{{ text }}</div>
    <div v-if="showCloseButton">
      <FwIconClose class="text-white w-5 h-5" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterTag',
  props: {
    text: {
      type: String,
      required: true
    },
    showCloseButton: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    }
  }
}
</script>
