<template>
  <fw-panel :title="title ? `Editar módulo` : 'Novo módulo'">
    <form @submit.prevent="next()">
      <div class="flex flex-col">
        <div>
          <fw-label>Nome do módulo</fw-label>
          <TextInput
            v-model="moduleTitle"
            :minlength="2"
            :maxlength="200"
            :class="{
              error: $v.moduleTitle.$error
            }"
            autofocus
          >
          </TextInput>

          <fw-tip v-if="$v.moduleTitle.$error" error>
            <span v-if="!$v.moduleTitle.min">Insira no mínimo 2 caracteres</span>
            <span v-if="!$v.moduleTitle.max">Insira no máximo 200 caracteres</span>
            <span v-else>Insira o nome do módulo</span>
          </fw-tip>
        </div>
      </div>
      <div class="flex flex-shrink-0 pt-5 gap-3">
        <div class="flex-1"></div>
        <fw-button type="link-muted" @click.native="cancel()">
          Cancelar
        </fw-button>
        <fw-button type="primary" class="w-32" role="submit">
          Guardar
        </fw-button>
      </div>
    </form>
  </fw-panel>
</template>

<script>
import TextInput from '@/fw-modules/fw-core-vue/ui/components/form/TextInput'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
  components: {
    TextInput
  },

  props: {
    title: String
  },

  data() {
    return {
      moduleTitle: ''
    }
  },

  mounted() {
    this.moduleTitle = this.title
  },

  validations: {
    moduleTitle: {
      required,
      min: minLength(2),
      max: maxLength(200)
    }
  },

  methods: {
    next() {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.$emit('save', {
        title: this.moduleTitle
      })
    },

    cancel() {
      this.$emit('cancel')
    }
  }
}
</script>
