<template>
  <SidebarBase :paddingless="isBreadCrumb">
    <template #main>
      <ButtonSidebar
        label="Preview"
        icon="eye"
        bordered
        :to="{ name: 'course-edition', params: { courseKey: courseKey, editionKey: editionKey } }"
      />
      <ButtonSidebar
        label="Dashboard"
        icon="dashboard"
        :active="view == 'dashboard'"
        :to="resolve('course-manage-edition', 'dashboard')"
      />
      <ButtonSidebar
        label="Metadados"
        :active="view == 'metadata'"
        icon="journal"
        :to="resolve('course-manage-edition', 'metadata')"
      />
      <ButtonSidebar label="Módulos" :active="view == 'modules'" icon="book-mark-solid" :to="goToModules()" />
      <ButtonSidebar
        :active="isClassesView"
        icon="cube"
        label="Turmas"
        :to="
          classes?.length
            ? {
                name: 'course-manage-edition-class',
                params: { id: courseKey, editionId: editionKey, classKey: classes?.[0]?.key }
              }
            : {
                name: 'course-manage-edition',
                params: { id: courseKey, editionId: editionKey, view: 'classes' }
              }
        "
      >
        <template v-if="!isBreadCrumb" #suffix>
          <fw-icon-chevron-up v-if="isClassesView" class="w-4 h-4" />
          <fw-icon-chevron-down v-else class="w-4 h-4" />
        </template>
      </ButtonSidebar>

      <VerticalSteps
        v-if="isClassesView && !isBreadCrumb"
        :steps="classesSteps"
        :current-step="currentClass"
        class="mb-2"
        :truncate-text="true"
        @clicked="goToClass"
      />

      <ButtonSidebar
        :active="view == 'discussions'"
        icon="chat-thread"
        label="Fóruns"
        :to="{
          name: 'course-manage-edition-post-collections',
          params: { id: courseKey, editionId: editionKey, view: 'discussions' }
        }"
      >
        <template v-if="!isBreadCrumb" #suffix>
          <fw-icon-chevron-up v-if="view == 'discussions'" class="w-4 h-4" />
          <fw-icon-chevron-down v-else class="w-4 h-4" />
        </template>
      </ButtonSidebar>

      <VerticalSteps
        v-if="view == 'discussions'"
        :steps="forunsSteps"
        :current-step="activeCollectionKey"
        class="mb-2"
        :truncate-text="true"
        @clicked="goToCollection"
      />

      <ButtonSidebar
        label="Pessoas"
        icon="people"
        :active="view == 'people'"
        :to="resolve('course-manage-edition', 'people')"
      />
      <ButtonSidebar
        label="Canais"
        :active="view == 'chats'"
        icon="chats"
        :to="resolve('course-manage-edition', 'chats')"
      />
      <ButtonSidebar
        v-if="edition && edition.bucket_key"
        label="Ficheiros"
        icon="box-open"
        :active="view == 'bucket'"
        :to="resolve('course-manage-edition', 'bucket')"
      />
      <ButtonSidebar
        v-if="validations.edition.can_edit"
        :to="resolve('course-manage-edition', 'activity')"
        icon="activity"
        label="Atividade"
        class="rounded-md"
        :active="view == 'activity'"
      />
      <ButtonSidebar
        v-if="validations.edition.can_edit"
        :to="resolve('course-manage-edition', 'notifications')"
        label="Notificações"
        icon="message-sent"
        class="rounded-md"
        :active="view == 'notifications'"
      />
      <ButtonSidebar
        :active="isSettingsView"
        icon="settings"
        label="Configurações"
        :to="resolve('course-manage-edition', 'settings-edition')"
      />
    </template>
  </SidebarBase>
</template>

<script>
import SidebarBase from '@/fw-modules/fw-core-vue/ui/components/sidebars/SidebarBase'
import ButtonSidebar from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonSidebar'
import VerticalSteps from '@/fw-modules/fw-core-vue/ui/components/buttons/VerticalSteps'

export default {
  name: 'SidebarManageCourse',
  components: {
    ButtonSidebar,
    SidebarBase,
    VerticalSteps
  },

  props: {
    course: {
      type: Object,
      default: () => {}
    },
    courseKey: {
      type: String,
      default: null
    },
    edition: {
      type: Object,
      default: () => {}
    },
    editionKey: {
      type: String,
      default: null
    },
    validations: {
      type: Object,
      default: () => {
        return {
          edition: {
            can_edit: false
          },
          course: {
            can_edit: false
          }
        }
      }
    },
    activeUnit: {
      type: String,
      default: null
    },
    collections: {
      type: Array,
      default: () => []
    },
    activeCollectionKey: {
      type: String,
      default: null
    },
    classes: {
      type: Array,
      default: () => []
    },
    units: {
      type: Array,
      default: () => []
    },
    isBreadCrumb: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    view() {
      return this.$route.meta.view || this.$route.params.view || 'dashboard'
    },

    isClassesView() {
      return this.view == 'classes'
    },

    isModulesView() {
      return this.view == 'modules'
    },

    currentClass() {
      if (this.isClassesView) return this.$route.params.classKey
      return null
    },

    currentModule() {
      if (this.isModulesView) return this.$route.params.moduleId
      return null
    },

    classesSteps() {
      return this.classes.map(el => {
        return {
          text: el.name,
          value: el.key,
          status: 'icon',
          icon: 'class-unit'
        }
      })
    },

    isSettingsView() {
      return ['settings-edition', 'settings-space'].includes(this.view)
    },

    settingsSteps() {
      return [
        {
          text: 'Edição',
          status: 'next',
          value: 'settings-edition'
        },
        {
          text: 'Espaço',
          status: 'next',
          value: 'settings-space'
        }
      ]
    },

    forunsSteps() {
      if (!this.collections?.length) return []

      const steps = []
      for (const el of this.collections) {
        if (el.class_keys?.length) {
          steps.push({
            text: el.title,
            status: 'icon',
            icon: 'hashtag',
            value: el.key
          })
        }
      }

      return steps
    },

    modulesSteps() {
      return this.units.map(el => {
        return {
          text: el.title,
          value: el.key,
          status: 'icon',
          icon: 'book-mark-solid'
        }
      })
    }
  },

  methods: {
    goToCollection(key) {
      this.$router.push({
        name: 'course-manage-edition-post-collection',
        params: { id: this.courseKey, editionId: this.editionKey, collectionId: key, view: 'discussions' }
      })
    },

    goToClass(key) {
      console.log('goToClass :>> ', key)
      this.$router.push({
        name: 'course-manage-edition-class',
        params: {
          id: this.courseKey,
          editionId: this.editionKey,
          view: 'classes',
          classKey: key
        }
      })
    },

    goToModules() {
      let key = this.units?.[0]?.key
      console.log('goToModules :>> ', key)
      return this.$router.resolve({
        name: 'course-manage-edition-module',
        params: {
          id: this.courseKey,
          editionId: this.editionKey,
          view: 'modules',
          moduleId: key
        }
      }).href
    },

    resolve(routeName, view) {
      return this.$router.resolve({
        name: routeName,
        params: { id: this.courseKey, editionId: this.editionKey, view: view }
      }).href
    },

    goToSettings(step) {
      console.log('goToSettings', step)
      return this.$router.push({
        name: 'course-manage-edition',
        params: { id: this.courseKey, editionId: this.editionKey, view: step }
      })
    }
  }
}
</script>
