<template>
  <fw-panel title="Novo espaço">
    <div class="flex flex-col">
      <b-field label="Indique o nome do espaço a criar">
        <b-input ref="input" v-model="courseName" maxlength="150"></b-input>
      </b-field>
      <div class="text-xs text-gray-500">
        Este nome (que deverá conter pelo menos 5 caracteres) poderá ser alterado mais tarde nas definições do espaço.
      </div>
    </div>
    <div class="flex flex-shrink-0 pt-5 gap-3">
      <div class="flex-1"></div>
      <fw-button type="link-muted" @click.native="cancel()">
        Cancelar
      </fw-button>
      <fw-button :type="!allowNext ? 'disabled' : 'primary'" :disable="!allowNext" class="w-32" @click.native="next()">
        Criar
      </fw-button>
    </div>
  </fw-panel>
</template>

<script>
export default {
  name: 'ModalNewCourse',
  data() {
    return {
      courseName: ''
    }
  },
  computed: {
    allowNext() {
      return this.courseName.length > 4
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.input.focus()
    })
  },
  methods: {
    next() {
      // Verify name
      if (this.courseName.length == 0) {
        return
      }

      this.$emit('new', {
        name: this.courseName
      })
    },
    cancel() {
      this.$emit('cancel')
    }
  }
}
</script>
