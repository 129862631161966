<template>
  <div class="w-full h-full flex justify-between">
    <!-- Recordings list -->
    <div class="w-1/6 h-full flex flex-col">
      <div class="px-5 py-4 flex items-center justify-between">
        <fw-heading size="h3">
          Gravações
          <fw-badge type="simple">{{ recordings.length }}</fw-badge>
        </fw-heading>
      </div>
      <div class="flex flex-1 flex-col gap-2 overflow-y-auto">
        <RecycleScroller
          v-if="recordings.length"
          v-slot="{ item }"
          :items="recordings"
          :item-size="55"
          :buffer="50"
          key-field="key"
          class="scroller px-4"
        >
          <button
            class="flex p-2 h-14 gap-2 relative items-center w-full text-left group rounded-lg my-1"
            :class="{
              'text-primary bg-white shadow-md': active && item.key == active.key,
              'text-gray-600 bg-gray-200 shadow-sm bg-opacity-30 hover:text-gray-800': !active || item.key != active.key
            }"
            @click="loadRecording(item.key)"
          >
            <div>
              <fw-icon-rec
                class="w-5 h-5"
                :class="{
                  'text-primary': item.status == 'done' && !(active && item.key == active.key)
                }"
              />
              <span class="flex h-2 w-2 absolute top-2 right-2">
                <span
                  v-if="item.status == 'creating'"
                  class="absolute inline-flex h-full w-full rounded-full opacity-75 animate-ping bg-primary"
                ></span>
                <span
                  class="relative inline-flex rounded-full h-2 w-2"
                  :class="{
                    'bg-primary': item.status == 'done' || item.status == 'creating',
                    'bg-gray-400': item.status != 'done' && item.status != 'creating'
                  }"
                ></span>
              </span>
            </div>
            <div class="flex-1">
              <div class="font-medium text-xs">
                {{ item.start_date | formatDateTime }}
              </div>
              <v-clamp autoresize :max-lines="1">{{ item.title }}</v-clamp>
            </div>
          </button>
        </RecycleScroller>
      </div>
    </div>
    <!-- Recordings main window (must be redesigned to use only tailwind) -->
    <div class="flex-1 h-full bg-white overflow-y-auto">
      <div v-if="active" class="is-container-max-medium">
        <div class="has-padding-medium">
          <div v-if="active.file" class="has-margin-bottom">
            <video-player
              :ref="'v-1'"
              :poster="recordingsPosterURL(active)"
              :src="getFileViewUrl(active.file)"
              :type="active.file.mimetype"
            />
          </div>
          <div
            v-else
            class="has-padding-large is-flex is-flex-direction-column is-flex-align-center is-flex-justify-center has-background-light is-rounded has-margin-bottom"
            style="min-height: 25vh"
          >
            <div class="is-size-2 has-text-centered" :class="{ 'animate-flicker slow': active.status == 'creating' }">
              {{ getStatusTitle(active.status, active.build) }}
            </div>
            <div v-if="active.status != 'done'" class="has-text-centered has-text-muted">
              <div v-if="active.status == 'error'" class="has-text-small has-margin-top">
                Vamos tentar corrigir o mais rápido possível
              </div>
              <div
                v-else-if="active.status == 'creating' && active.build"
                class="has-text-small has-margin-top has-text-centered"
              >
                <div>O vídeo está a ser compilado e poderá demorar algum tempo a terminar.</div>
                <div class="has-text-muted">
                  Passo: {{ stepTitle(active.build.step) }} / Percentagem: {{ active.build.percentage }}% / Tempo
                  estimado: {{ secondsToTime(active.build.expected) }}
                </div>
              </div>
              <div
                v-else-if="active.status == 'pending'"
                class="has-text-small has-margin-top is-width-small has-text-centered"
              >
                <div>
                  A gravação foi terminada mas o vídeo ainda não está pronto.<br />A plataforma precisará compilar um
                  vídeo final, com todos os elementos.
                </div>
                <div class="has-text-tiny has-margin-top">
                  Antes de iniciar a compilação do vídeo, edite os metadados como entender necessário.
                </div>
                <div v-if="!withErrors && active.permissions.can_edit" class="has-margin-top">
                  <b-button type="is-primary is-medium" :loading="buildLoading" @click="startRecordingBuild">
                    Compilar vídeo
                  </b-button>
                </div>
              </div>
              <div
                v-else-if="active.status == 'waiting'"
                class="has-text-small has-margin-top is-width-tiny has-text-centered"
              >
                <div>
                  A compilação do vídeo final será iniciada após terminar esta sessão (fechar a sala para todos).
                </div>
              </div>
              <div v-else-if="active.status == 'running'" class="has-text-small has-margin-top">
                A gravação está em curso.<br />Quando parar, poderá editar os metados do vídeo.
              </div>
            </div>
          </div>
          <div class="columns has-margin-top">
            <div class="column">
              <div v-if="!active.permissions.can_edit">
                <h1 class="is-size-2">{{ active.title }}</h1>
                <div v-if="active.description" class="is-size-3">{{ active.description }}</div>
                <div v-if="active.authors" class="has-text-muted">{{ active.authors }}</div>
              </div>
              <div v-else>
                <h3 class="has-margin-bottom">Editar metadados do vídeo</h3>
                <b-field
                  label="Título"
                  :type="{ 'is-danger': formErrors.title }"
                  :message="formErrors.title"
                  class="is-marginless"
                >
                  <b-input v-model="formData.title" maxlength="50" expanded></b-input>
                </b-field>
                <b-field
                  label="Subtítulo"
                  :type="{ 'is-danger': formErrors.description }"
                  :message="formErrors.description"
                  class="is-marginless"
                >
                  <b-input v-model="formData.description" maxlength="50" expanded></b-input>
                </b-field>
                <b-field
                  label="Autores / Créditos"
                  :type="{ 'is-danger': formErrors.authors }"
                  :message="formErrors.authors"
                  class="is-marginless"
                >
                  <b-input v-model="formData.authors" maxlength="50" expanded></b-input>
                </b-field>
                <b-field class="is-marginless">
                  <b-button type="is-primary" :loading="formData.loading" @click="saveData"> Guardar </b-button>
                </b-field>
              </div>
            </div>
            <div v-if="active.permissions.can_delete" class="column is-narrow">
              <b-dropdown
                :triggers="['click']"
                aria-role="list"
                position="is-bottom-left"
                :mobile-modal="false"
                animation="none"
              >
                <button slot="trigger" class="button">
                  <b-icon icon="ellipsis-h"></b-icon>
                </button>
                <b-dropdown-item v-if="active && active.file">
                  <a target="_blank" :href="getFileViewUrl(active.file, true)" class="has-text-weight-bold">
                    <faicon style="width: 20px" class="has-margin-right-small" icon="cloud-download-alt"></faicon>
                    Download
                  </a>
                </b-dropdown-item>
                <b-dropdown-item aria-role="listitem" @click="deleteRecording(active.key)">
                  <faicon style="width: 20px" class="has-margin-right-small" icon="trash-alt"></faicon>Eliminar vídeo
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
          <div
            v-if="!active.permissions.can_edit && active.status == 'done'"
            class="is-flex has-text-small has-text-muted"
          >
            <div class="has-margin-right">
              <faicon icon="eye" class="has-margin-right-small"></faicon>
              <span>{{ active.stats.views }} visualizações</span>
            </div>
            <div v-if="active.metadata" class="is-flex">
              <div class="has-margin-right">
                <faicon icon="clock" class="has-margin-right-small"></faicon>
                <span>{{ active.metadata.duration | prettySeconds }}</span>
              </div>
              <div class="has-margin-right">
                <faicon icon="box-open" class="has-margin-right-small"></faicon>
                <span>{{ active.metadata.size | prettyBytes }}</span>
              </div>
              <div class="has-margin-right">
                <faicon icon="video" class="has-margin-right-small"></faicon>
                <span>{{ active.metadata.resolution | prettyResolution }}</span>
              </div>
            </div>
          </div>
          <hr />
          <div v-if="active.status == 'done' && active.url">
            <div class="has-margin-top">
              <div class="label">Permissões</div>
              <div class="is-flex is-flex-align-center">
                <b-select
                  v-model="permission"
                  :loading="permissionLoading"
                  placeholder="Escolha"
                  :icon="permission == 'public' ? 'eye' : 'lock'"
                >
                  <option value="private">Privado</option>
                  <option value="public">Público</option>
                </b-select>
                <div class="has-text-small has-text-muted has-margin-left">
                  <span v-if="permission == 'public'"
                    >O vídeo fica acessível a qualquer pessoa com quem partilhe o link.</span
                  >
                </div>
              </div>
            </div>
            <div v-if="permission == 'public'" class="has-margin-top-medium">
              <div class="label is-marginless">Link do vídeo</div>
              <div class="is-flex is-flex-align-center">
                <a target="_blank" :href="active.url" class="has-margin-right-small">
                  <v-clamp autoresize :max-lines="1">{{ active.url }}</v-clamp>
                </a>
                <b-button type="is-light is-small" @click="toClipboardVideoUrl(active.url)">Copiar link</b-button>
              </div>
            </div>
          </div>
          <div v-if="active.periods.length > 1" class="has-margin-top-medium">
            <h3 class="has-margin-bottom">Capítulos do vídeo</h3>
            <div v-if="!active.permissions.can_edit || (active.permissions.can_edit && active.editMode)">
              <div
                v-for="period in active.periods"
                :key="period.number"
                class="has-background-light is-rounded has-padding has-margin-bottom-small"
              >
                <div class="has-margin-right is-flex is-flex-align-center is-flex-space-between">
                  <div class="has-text-weight-bold">
                    <span>{{ period.startSecond | secondsToTime }}</span>
                    <span class="has-margin-small">&rarr;</span>
                    <span>{{ period.endSecond | secondsToTime }}</span>
                  </div>
                  <div v-if="period.duration !== null" class="has-margin-left has-text-small">
                    <span class="has-text-muted">Duração:</span> {{ period.duration | prettySeconds }}
                  </div>
                </div>
                <div v-if="period.number" class="has-margin-top-small">
                  <div v-if="period.title">{{ period.title }}</div>
                  <div v-else class="has-text-muted">Sem título</div>
                  <div v-if="period.description" class="has-text-small">
                    {{ period.description }}
                  </div>
                </div>
                <div v-else class="has-text-muted">
                  <div v-if="active.title">{{ active.title }}</div>
                  <div v-if="active.description" class="has-text-small">{{ active.description }}</div>
                </div>
              </div>
            </div>
            <div v-else>
              <div
                v-for="period in formPeriods"
                :key="period.number"
                class="has-background-light is-rounded has-padding has-margin-bottom"
              >
                <div class="has-margin-right is-flex is-flex-align-center is-flex-space-between">
                  <div class="has-text-weight-bold is-size-4">
                    <span>{{ period.startSecond | secondsToTime }}</span>
                    <span class="has-margin-small">&rarr;</span>
                    <span>{{ period.endSecond | secondsToTime }}</span>
                  </div>
                  <div v-if="period.duration !== null" class="has-margin-left has-text-small">
                    <span class="has-text-muted">Duração:</span> {{ period.duration | prettySeconds }}
                  </div>
                </div>
                <div v-if="period.number && period.editing" class="has-margin-top-medium">
                  <div class="is-flex-grow has-margin-right">
                    <b-field
                      label="Título"
                      :type="{ 'is-danger': formPeriodError(period, 'title') }"
                      :message="formPeriodError(period, 'title')"
                    >
                      <b-input v-model="period.title" expanded></b-input>
                    </b-field>
                    <b-field
                      label="Subtítulo"
                      :type="{ 'is-danger': formPeriodError(period, 'description') }"
                      :message="formPeriodError(period, 'description')"
                    >
                      <b-input v-model="period.description" expanded></b-input>
                    </b-field>
                  </div>
                  <div class="has-margin-top-medium is-flex is-flex-align-center is-flex-space-between">
                    <div>
                      <b-button
                        class="has-margin-right"
                        type="is-dark is-small"
                        outlined
                        :loading="period.loading"
                        @click="savePeriodData(period, true)"
                      >
                        Guardar e fechar
                      </b-button>
                      <b-button
                        type="is-text is-small"
                        outlined
                        :loading="period.loading"
                        @click="savePeriodData(period)"
                      >
                        Guardar
                      </b-button>
                    </div>
                    <div>
                      <b-button
                        type="is-text is-small"
                        outlined
                        :loading="period.loading"
                        @click="period.editing = false"
                      >
                        Fechar
                      </b-button>
                    </div>
                  </div>
                </div>
                <div v-else class="has-margin-top-small">
                  <div v-if="period.title">{{ period.title }}</div>
                  <div v-if="period.description" class="has-text-small">
                    {{ period.description }}
                  </div>
                </div>
                <div v-if="!period.number" class="has-text-muted">
                  <div v-if="active.title">{{ active.title }}</div>
                  <div v-if="active.description" class="has-text-small">{{ active.description }}</div>
                </div>
                <div v-if="period.number && !period.editing" class="has-margin-top-small has-text-small">
                  <a href="" @click.prevent="period.editing = !period.editing">Editar título / subtítulo</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VideoPlayer from '@/fw-modules/fw-core-vue/ui/components/video/VideoPlayer'
import { RecycleScroller } from 'vue-virtual-scroller'
import ServiceMeetings from '@/fw-modules/fw-meetings-vue/services/ServiceMeetings'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    RecycleScroller,
    VideoPlayer
  },

  data() {
    this.getRecordings()
    return {
      recordings: [],
      active: null,
      withErrors: false,
      formData: {},
      formErrors: {},
      formPeriods: [],
      buildLoading: false,
      permission: 'private',
      permissionLoading: false
    }
  },

  watch: {
    permission(value) {
      if (this.active && value !== this.getPermissionsKey(this.active)) {
        this.setRecordingPermissions(this.active, value)
      }
    }
  },

  methods: {
    getFileViewUrl(file, download = false) {
      return ServiceStorage.getFileViewUrl(file, download)
    },
    recordingsPosterURL(recording) {
      return utils.recordingsPosterURL(recording)
    },

    secondsToTime(seconds) {
      return Dates.secondsToTime(seconds)
    },

    stepTitle(step) {
      if (step == 'prepare') {
        return 'A preparar vídeo'
      } else if (step == 'create') {
        return 'A criar vídeo'
      } else {
        return 'A finalizar'
      }
    },
    getStatusTitle(status, buildData) {
      if (status == 'done') {
        return 'Disponível'
      } else if (status == 'running') {
        return 'Pendente'
      } else if (status == 'creating') {
        if (typeof buildData !== 'undefined' && !buildData) {
          return 'Aguarda um servidor disponível para iniciar a compilação'
        } else {
          return 'A compilar...'
        }
      } else if (status == 'error') {
        return 'Ocorreu um erro'
      } else if (status == 'pending') {
        return 'Por compilar'
      } else if (status == 'waiting') {
        return 'A aguardar'
      } else {
        return 'A decorrer'
      }
    },

    getPermissionsKey(recording) {
      return recording.is_public ? 'public' : 'private'
    },
    async setRecordingPermissions(recording, value) {
      const data = { is_public: value == 'public' }

      this.permissionLoading = true
      try {
        const response = await ServiceMeetings.updateRecordings(recording.key, data)
        Object.assign(recording, response)
      } finally {
        this.permissionLoading = false
      }
    },

    async saveRecordingData(data) {
      this.active = await ServiceMeetings.updateRecordings(this.active.key, data)
      this.syncRecordingData(this.active)

      const newErrors = utils.errors(this.active, true).extract()
      Object.assign(this.formErrors, newErrors)

      this.withErrors = Object.keys(this.formErrors).length !== 0
      return Object.keys(newErrors).length !== 0
    },
    async saveData() {
      const periodFieldStart = this.getPeriodFieldStart()
      for (let key of Object.keys(this.formErrors)) {
        if (!key.startsWith(periodFieldStart)) {
          delete this.formErrors[key]
        }
      }

      const data = {
        title: this.formData.title,
        description: this.formData.description,
        authors: this.formData.authors
      }

      this.formData.loading = true
      try {
        await this.saveRecordingData(data)
      } finally {
        this.formData.loading = false
      }
    },
    getPeriodFieldStart(period = null) {
      if (period) {
        return `periods.${period.number}.`
      } else {
        return 'periods.'
      }
    },
    formPeriodError(period, key) {
      return this.formErrors[this.getPeriodFieldStart(period) + key]
    },
    async savePeriodData(period, closeOnSuccess = false) {
      const periodFieldStart = this.getPeriodFieldStart(period)
      for (let key of Object.keys(this.formErrors)) {
        if (key.startsWith(periodFieldStart)) {
          delete this.formErrors[key]
        }
      }

      const data = { periods: {} }
      data.periods[period.number] = {
        title: period.title,
        description: period.description
      }

      period.loading = true
      try {
        const withErrors = await this.saveRecordingData(data)
        if (closeOnSuccess && !withErrors) {
          period.editing = false
        }
      } finally {
        period.loading = false
      }
    },
    startRecordingBuild() {
      this.$buefy.dialog.confirm({
        type: 'is-primary',
        title: 'Tem a certeza que deseja compilar esta gravação?',
        message: `<p class="is-size-3"></p><p>Após iniciar este processo, não poderá voltar a editar os metadados da gravação.</p>`,
        indefinite: true,
        queue: false,
        cancelText: 'Cancelar',
        confirmText: 'Compilar',
        canCancel: true,
        onConfirm: async () => {
          this.buildLoading = true
          try {
            await this.saveRecordingData({ build: true })
          } finally {
            this.buildLoading = false
          }
        }
      })
    },

    async getRecordings() {
      this.recordings = await ServiceMeetings.getRecordings()
      if (this.recordings.length) {
        let hash = this.$route.hash
        if (hash && hash.length > 2) {
          hash = hash.substr(1)
          for (let recording of this.recordings) {
            if (recording.key == hash) {
              this.loadRecording(recording.key)
              return
            }
          }
        }

        this.loadRecording(this.recordings[0].key)
      }
    },
    async loadRecording(key) {
      const recording = await ServiceMeetings.getRecording(key)
      this.syncRecordingData(recording)

      this.formData = {
        loading: false,
        title: recording.title,
        description: recording.description,
        authors: recording.authors
      }
      this.formPeriods = []
      for (let period of recording.periods) {
        this.formPeriods.push(Object.assign({ loading: false, editing: false }, period))
      }

      this.permission = this.getPermissionsKey(recording)
      this.active = recording
    },
    syncRecordingData(recording) {
      for (let listRecording of this.recordings) {
        if (listRecording.key == recording.key) {
          listRecording.title = recording.title
          listRecording.status = recording.status
          break
        }
      }

      let duration = 0
      for (let period of recording.periods) {
        period.startSecond = duration
        if (period.duration) duration += period.duration
        period.endSecond = period.startSecond + duration
      }
    },

    deleteRecording(key) {
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        title: 'Tem a certeza que deseja eliminar esta gravação?',
        message: `Esta operação é irreversível. Todos os elementos da gravação serão eliminados de forma definitiva.`,
        indefinite: true,
        queue: false,
        cancelText: 'Cancelar',
        confirmText: 'Eliminar',
        canCancel: true,
        onConfirm: async () => {
          await ServiceMeetings.deleteRecording(key)

          // Remove from list
          for (let idx = 0; idx < this.recordings.length; idx++) {
            if (this.recordings[idx].key == key) {
              this.recordings.splice(idx, 1)
              break
            }
          }

          if (this.active && this.active.key == key) {
            this.active = null
            if (this.recordings.length) {
              this.loadRecording(this.recordings[0].key)
            }
          }
        }
      })
    },

    toClipboardVideoUrl(videoUrl) {
      this.$copyText(videoUrl).then(() => {
        this.$buefy.toast.open({
          duration: 3000,
          message: `Endereço copiado`,
          position: 'is-top'
        })
      })
    },

    // Video player
    onPlayerPlay(player) {
      console.debug('player play!', player)
    },
    onPlayerPause(player) {
      console.debug('player pause!', player)
    },
    playerStateChanged(playerCurrentState) {
      console.debug('player current update state', playerCurrentState)
    },
    playerReadied(player) {
      console.debug('the player is readied', player)
    }
  }
}
</script>
