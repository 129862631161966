<template>
  <div>
    <TextHeadingDual :main-text="'Editor'" :secondary-text="'Versões'"></TextHeadingDual>
    <div class="flex flex-wrap pt-5  gap-5">
      <div
        v-for="version in versions"
        :key="version.value"
        class="bg-white rounded-xl hover:shadow-xl shadow-lg p-5 w-72 h-52 flex flex-col gap-6 cursor-pointer"
        @click="selectVersion(version.value)"
      >
        <div class="flex items-center gap-2">
          <div class="rounded-lg bg-gray-300 text-center px-3 py-1 text-gray-600 text-lg font-semibold">
            {{ version.letter }}
          </div>
          <div class="flex-1"></div>
          <!--<div
            v-for="(language, l) in version.availableLanguages"
            :key="'lang_' + l"
            class="border border-gray-400 text-gray-500 uppercase rounded-md px-1.5 py-0.5 text-xs font-bold"
          >
            {{ language }}
          </div>-->
        </div>

        <div class="flex items-center justify-center gap-5 flex-1">
          <div class="flex flex-col text-center">
            <div class=" font-bold text-gray-700 text-lg">{{ version.questions }}</div>
            <div class=" text-xs text-gray-500">Questões</div>
          </div>
          <div class="flex flex-col text-center">
            <div class=" font-bold text-gray-700 text-lg">{{ version.sections }}</div>
            <div class=" text-xs text-gray-500">Secções</div>
          </div>
        </div>
        <div v-if="false" class="flex items-center justify-end gap-5">
          <fw-button
            v-if="editable || canIgnoreQuestions || canUpdateOptions"
            type="primary"
            class="py-2 px-4 rounded-lg"
            @click.stop.native="editVersion(version.value)"
          >
            Editar
          </fw-button>
        </div>
      </div>

      <div
        v-if="editable"
        class="rounded-xl p-5 w-72 h-52 flex flex-col gap-6 justify-center border border-dashed text-sm font-bold border-gray-300 hover:border-primary cursor-pointer text-center text-primary"
        @click="newVersion"
      >
        Adicionar nova versão
      </div>
    </div>
  </div>
</template>
<script>
import TextHeadingDual from '@/fw-modules/fw-core-vue/exams/components/text/TextHeadingDual.vue'
export default {
  name: 'PanelVersionSelection',
  components: { TextHeadingDual },
  props: {
    versions: {
      type: Array,
      default: () => []
    },
    editable: {
      type: Boolean,
      default: false
    },
    canIgnoreQuestions: {
      type: Boolean,
      default: false
    },
    canUpdateOptions: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    selectVersion(version) {
      this.$emit('select-version', version)
    },
    editVersion(version) {
      this.$emit('edit-version', version)
    },
    newVersion() {
      this.$emit('new-version')
    }
  }
}
</script>
