<template>
  <fw-layout
    :wide="true"
    paddingless
    full
    :notfound="!initialLoading && !form"
    :loading="initialLoading"
    loading-title="Formulário"
    loading-icon="survey"
    back-to="/forms"
  >
    <template #header-nav>
      <div class="flex gap-5 items-center">
        <div>
          <div>
            <v-clamp
              v-if="formTitle && formTitle.length > 0"
              autoresize
              :max-lines="1"
              class="font-semibold text-md leading-5 max-w-prose"
            >
              {{ formTitle }}
            </v-clamp>
            <span v-else class="text-gray-200">Formulário sem título</span>
          </div>
          <div
            class="font-semibold leading-5 text-sm"
            :class="{ 'text-gray-500': form.status !== 'running', 'text-primary': form.status === 'running' }"
          >
            {{ $t(`examStatus.${form.status}`) }}
          </div>
        </div>
      </div>
    </template>

    <template #main-sidebar>
      <SidebarManage :form="form" />
    </template>

    <template #main-content>
      <fw-message v-if="form && form['__errors__']" type="error" class="mb-2">
        {{ form['__errors__'] }}
      </fw-message>
      <LoadingPage v-if="initialLoading" icon="exam" title="Formulário" class="my-28"></LoadingPage>
      <PanelFormEditor v-else-if="form != null" :form="form" :permissions="permissions" :answersid="answersid" />
      <div v-else>NOT FOUND</div>
    </template>

    <template #modals>
      <fw-modal :active.sync="showOptions" :can-cancel="true" size="min" @close="closeOptionsModal">
        <div
          v-if="currentModal === 'permissions'"
          class="rounded text-sm p-5 font-semibold flex flex-col gap-3 mx-auto"
        >
          <fw-heading size="h2">Dar permissões</fw-heading>

          <input
            v-model="permissions_email"
            class="w-full border rounded-md bg-white border-gray-200 p-5 pr-14 h-12 text-base outline-none"
            type="text"
            placeholder="Insira e-mail do utilizador"
          />

          <fw-button
            v-for="[key, value] in Object.entries(permissionsSettings)"
            :key="'option_' + key + '_' + value"
            type="dropdown"
            :check-box="true"
            :expanded="true"
            :checked="value"
            :custom-class="'py-1'"
            @click.native="changeBool('permissions', key)"
          >
            {{ permissionsMapping(key) }}
          </fw-button>
          <fw-button
            :type="saving || !isPermissionEmailValid ? 'disabled' : 'primary'"
            size="xl"
            class="mt-2"
            :class="{ 'cursor-not-allowed': saving || !isPermissionEmailValid }"
            @click.native="save('permissions')"
          >
            <InnerLoader v-if="saving" class="mx-auto my-1 w-16" />
            <span v-else>Guardar</span>
          </fw-button>

          <div v-if="usersWithPermissions.length" class="mt-4 overflow-y-scroll" style="max-height: 300px">
            <fw-label class="mb-4">Utilizadores com permissões associadas</fw-label>
            <Person
              v-for="(user, u) in usersWithPermissions"
              :key="user.key"
              :person="user"
              :selectable="false"
              :clickable="false"
            >
              <template #options>
                <b-tooltip label="Remover permissões" position="is-right" type="is-light">
                  <fw-button type="dropdown" :custom-class="'py-1'" @click.native="removeAllPermissions(user.key, u)">
                    <svg
                      class="text-gray-500 h-6 w-6 fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M20 7v13a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V7H2V5h20v2h-2zM6 7v13h12V7H6zm5 2h2v2h-2V9zm0 3h2v2h-2v-2zm0 3h2v2h-2v-2zM7 2h10v2H7V2z"
                      />
                    </svg>
                  </fw-button>
                </b-tooltip>
              </template>
            </Person>
          </div>
        </div>
        <div
          v-else-if="currentModal === 'share'"
          class="rounded text-sm font-semibold flex flex-col gap-3 mx-auto text-gray-600 w-96"
        >
          <fw-heading size="h2">Link para responder ao exame</fw-heading>
          <div class="relative">
            <b-tooltip class="absolute top-4 right-3" label="Copiar link do exame" type="is-light">
              <fw-button
                :type="copied ? 'light-primary' : 'link'"
                size="xs"
                class="ml-2"
                @click.native="toClipboardExamLink"
              >
                <fw-icon-check v-if="copied"></fw-icon-check>
                <fw-icon-copy v-else></fw-icon-copy>
              </fw-button>
            </b-tooltip>
          </div>
          <input
            class="w-full border rounded-md bg-gray-50 border-gray-200 p-5 pr-14 h-12 text-base outline-none"
            type="text"
            disabled
            :value="examLink"
          />
        </div>
      </fw-modal>
    </template>
  </fw-layout>
</template>

<script>
import SidebarManage from '@/fw-modules/fw-core-vue/forms/components/sidebars/SidebarManage'
import PanelFormEditor from '../components/panels/PanelFormEditor.vue'
import utils from '@/fw-modules/fw-core-vue/utilities/utils.js'
import Vue from 'vue'
import InnerLoader from '@/fw-modules/fw-core-vue/ui/components/animation/InnerLoader'
import FormServices from '@/fw-modules/fw-core-vue/forms/services/ServiceForms'
import FwMessage from '@/fw-modules/fw-core-vue/ui/components/text/FwMessage'

export default {
  name: 'ViewFormEditor',
  components: {
    SidebarManage,
    PanelFormEditor,
    InnerLoader,
    FwMessage
  },
  data() {
    return {
      connectedUsers: [],
      canCloseAllForms: false,
      permissions_email: '',
      users: {},
      permissions: {},
      permissionsSettings: {
        canSeeAnswers: true,
        canStartStopInstance: true
      },
      currentModal: 'options',
      showOptions: false,
      allowPublish: false,
      publishOptions: {
        allow: 'now',
        //min: '00',
        //hour: '00',
        duration: 60,
        location: '',
        startDate: null
      },
      form: null, //data first form
      formOptions: {},
      formTitle: '',
      //Exam url
      clipboard_timer: null,
      copied: false,
      publishing: false,
      saving: false,
      modal: null,
      settingsSavingSuccess: false,
      language: 'pt',
      initialLoading: true,
      wideViews: ['evaluation', 'activity'],
      // User modal
      userModalData: null,
      userModalAnswer: null,
      isAnswer: false,
      activity: [], // activity to be used in the user modal
      answers: [], // answers to be used in the user modal
      versionMap: {}, //map from form id to version index
      realtimeDebouncer: null, //for debouncing realtime updates
      remainingSeconds: 0,
      timerInterval: null
    }
  },
  computed: {
    userActivity() {
      return this.userModalData != null
        ? this.activity.filter(activity => activity.by_user_key == this.userModalData.key)
        : []
    },
    isWebSocketReady() {
      return !!(this.$store.state.socket && this.$store.state.socket.connectionId)
    },
    examWSMessages() {
      return this.$store.state.session.unreadExamWsMessages
    },
    socketId() {
      return this.$store.state.socket.connectionId
    },
    settingsSubsection() {
      return this.$route.params.subsettings
    },
    missingInfoTo() {
      if (this.form.status === 'draft' && this.isCreator) {
        return 'publish'
      } else if (this.form.status === 'ended' && this.isCreator) {
        return 'lock'
      } else {
        return null
      }
    },
    missingInfo() {
      if (this.form.status === 'draft') {
        return {
          title: this.form.title['pt'] === '',
          location: this.form.location === null || this.form.location === '',
          start: this.instformance.startDate === null || this.form.startDate === '',
          duration: this.form.duration === 0,
          versions:
            !this.permissions.publish &&
            this.form.duration > 0 &&
            this.form.title['pt'] !== '' &&
            !(this.form.location === null || this.form.location === '') &&
            !(this.form.startDate === null || this.form.startDate === '') //if user can't publish, then there must be at least one version
        }
      } else if (this.form.status === 'ended') {
        return {
          correction: !this.permissions.lockAnswers
        }
      } else {
        return {}
      }
    },
    // For the user modal
    showUserModal() {
      return this.userModalData !== null
    },

    // Make sure exam is not allowed in small devices
    isMobileOrSmallTablet() {
      return window.innerWidth < 1024
    },
    showWideLayout() {
      return this.wideViews.includes(this.view)
    },
    loggedUser() {
      return this.$store.getters.getUser
    },
    formServices() {
      return FormServices
    },
    editing() {
      return this.$route.query.edit ?? false
    },
    answersid() {
      return this.$route.query.answers ?? null
    },
    examLink() {
      return process.env.VUE_APP_IS_PRODUCTION
        ? `https://ucstudent.uc.pt/form/${this.form.key}`
        : `https://ucstudent.dev.ucframework.pt/form/${this.form.key}`
    },
    isPermissionEmailValid() {
      return utils.isValidEmail(this.permissions_email)
    },
    formKey() {
      return this.$route.params.key ? this.$route.params.key : null
    },
    versionID() {
      return this.$route.params.versionid ? this.$route.params.versionid : null
    },
    view() {
      return this.$route.meta.view ?? 'dashboard'
    },
    isCreator() {
      return this.instance != null && this.loggedUser != null && this.loggedUser.key == this.instance.user_key
    },
    isManager() {
      return (
        this.instance != null &&
        this.loggedUser != null &&
        Object.keys(this.instance.managers).includes(this.loggedUser.key)
      )
    },
    publishingMin() {
      return this.publishOptions.min
    },
    publishingHour() {
      return this.publishOptions.hour
    },
    //Permissions check
    isEditable() {
      return this.permissions != null && this.permissions.edit
    },
    isPublishable() {
      return this.permissions != null && this.permissions.publish
    },
    isUnpublishable() {
      return this.permissions != null && this.permissions.unpublish
    },
    isDeletable() {
      return this.permissions != null && this.permissions.delete
    },
    isStartable() {
      return this.permissions != null && this.permissions.start
    },
    isStopable() {
      return this.permissions != null && this.permissions.stop
    },
    isEndable() {
      return this.permissions != null && this.permissions.end
    },
    canIgnoreQuestions() {
      return this.permissions != null && this.permissions.canIgnoreQuestions
    },
    canUpdateOptions() {
      return this.permissions != null && this.permissions.canUpdateOptions
    },
    baseUrl() {
      return '/exam/' + this.instance.key
    }
  },
  watch: {
    examWSMessages(newMessages) {
      if (this.realtimeDebouncer == null && newMessages.length > 0) {
        this.realtimeDebouncer = setTimeout(() => {
          console.log('examWSMessages changed', newMessages)
          //change user state or number of answers
          //if not found, load answers again
          let userfound = true
          for (let index = 0; index < newMessages.length; index++) {
            const message = newMessages[index]
            console.log('newMessage', message)
            if (message.type == 'instanceOnlineManagers' && this.instance.key === message.instance_key) {
              this.connectedUsers = message.users
            } else if (message.type == 'instanceStatus' || message.type == 'instanceUpdate') {
              if (message.remaining_time) {
                console.log('Update remaining time from websocket', message.remaining_time)
                this.remainingSeconds = message.remaining_time
              }
              if (message.type == 'instanceUpdate' && message.status == 'start') {
                console.log('WS exam starting')
                this.instance.status = 'running'
              } else if (message.type == 'instanceUpdate' && message.status == 'stop') {
                console.log('WS exam ending')
                this.instance.status = 'ended'
                this.stopTime()
              }
              //make it run?
              // Make sure we start timer after user starts to work
              if (this.timerInterval == null && this.instance.status == 'running') {
                console.log('Start time tick after loading ws messages')
                this.startTime()
              }
            }
          }
          this.$store.commit('removeFromExamMessageQueue', newMessages.length)
          if (!userfound) {
            //this.loadAnswers()
          }
          this.realtimeDebouncer = null
        }, 1000)
      }
    }
  },
  created() {
    this.loadForm()
  },
  methods: {
    async loadActivity() {
      let result = await FormServices.getActivityLogs({
        instanceKey: this.instance.key,
        sort: 'created_date',
        direction: 'desc'
      })
      this.activity = result.logs
    },
    async loadAnswers() {
      console.log('loading answers')
      let result = await FormServices.getAnswers(this.instance.key, true, null, 'asc', null, [])
      this.answers = result.answers
    },
    async loadForm() {
      this.initialLoading = true
      let key = this.$route.params.key
      let response = await FormServices.getForm(key)
      console.log('loadForm response', response)
      this.form = response
      this.initialLoading = false
    },
    cancelExamEditor() {
      this.$router.push({
        path: `/exam/${this.instance.key}/editor/${this.versionID}`
      })
    },
    async addPermissions() {
      this.showOptions = true
      this.currentModal = 'permissions'
      this.usersWithPermissions = []
      this.usersWithPermissions = await FormServices.getInstancePermissions(this.instance.key)
    },
    async removeAllPermissions(userKey, u) {
      try {
        await FormServices.deleteInstancePermissions(this.instance.key, userKey)
        this.usersWithPermissions.splice(u, 1)
        this.$buefy.toast.open({
          duration: 2000,
          message: `Permissões removidas`,
          position: 'is-top'
        })
      } catch (e) {
        console.error(e)
        this.showError('Ocorreu um erro ao remover permissões')
      }
    },
    async save(attr) {
      if (attr === 'permissions') {
        //verify e-mail
        if (this.isPermissionEmailValid) {
          this.saving = true
          try {
            let result = await FormServices.addInstancePermissions(
              this.instance.key,
              this.permissions_email,
              this.permissions
            )
            console.log('permission result', result)
            this.usersWithPermissions = result.users
            this.showOptions = false
            this.currentModal = 'options'
          } catch (e) {
            this.saving = false
            console.error(e)
            alert(e.response.data['__errors__'][0]['detail'])
          }
          this.saving = false
          //
        }
      }
    },
    changeBool(attr, key) {
      this[attr][key] = !this[attr][key]
    },
    permissionsMapping(key) {
      const map = {
        canSeeAnswers: 'Ver respostas do exame',
        canStartStopInstance: 'Iniciar e terminar o exame'
      }
      return map[key] ? map[key] : ''
    },
    setPublishOption(id, value) {
      console.log('PublishOption', id, value)
      /*if (id === 'allow') {
        value = value.value
      }*/
      if (id === 'time') {
        let hour = value.split(':')[0]
        let min = value.split(':')[1]
        Vue.set(this.publishOptions, 'min', min)
        Vue.set(this.publishOptions, 'hour', hour)
      } else {
        Vue.set(this.publishOptions, id, value)
      }
      this.allowPublish = this.verifyPublishOptions(false)
    },
    verifyPublishOptions(showErrorMessage = true) {
      if (isNaN(parseInt(this.publishOptions.duration))) {
        if (showErrorMessage) {
          this.showError('A duração do exame não é válida')
        }
        return false
      }

      if (this.publishOptions.duration < 15) {
        if (showErrorMessage) {
          this.showError('A duração do exame deve ser maior que 15 minutos')
        }
        return false
      }

      if (this.publishOptions.startDate == null) {
        if (showErrorMessage) {
          this.showError('A data de início do exame não é válida')
        }
        return false
      }
      return true
    },
    getShareLink() {
      this.showOptions = true
      this.currentModal = 'share'
    },
    async unpublish() {
      try {
        let publish_result = await FormServices.publishInstance(this.instance.key, 'unpublish')
        console.log('unpublish result', publish_result)
        //this.instance.status = publish_result.status
        Vue.set(this.instance, 'status', publish_result.status)
        this.permissions = publish_result.permissions
        //reopen forms - USES BACKEND NOW
        /*let formPromises = []
        this.instance.forms.forEach(form => {
          formPromises.push(FormServices.updateFormStatus(form.key, 'reopen'))
        })
        Promise.all(formPromises)
          .then(result => {
            console.log('form reopen result', result)
            //this.instance.status = 'draft'
            //this.form.status = 'draft'
          })
          .catch(e => {
            console.error(e)
            //this.showError('Ocorreu um erro ao reabrir a(a) versão(ões) do exame para editar')
          })*/
      } catch (e) {
        console.error(e)
        this.showError('Ocorreu um erro ao despublicar exame')
      }
    },
    showError(message = 'Ocorreu um erro. Tente de novo mais tarde ou contacte o suporte.') {
      this.$buefy.dialog.alert({
        message: message,
        type: 'is-danger',
        hasIcon: false,
        icon: 'times-circle',
        iconPack: 'fa',
        ariaRole: 'alertdialog',
        ariaModal: true
      })
    },
    async lockExam(lock) {
      try {
        this.publishing = true
        let status = lock ? 'lock_answers' : 'unlock_answers'
        let response = await FormServices.publishInstance(this.instance.key, status)
        console.log(response)
        this.instance.answersLocked = this.instance.answersLocked //lock ? true : false
        this.instance.status = response.status
        this.permissions = response.permissions
        //this.$emit('update-permissions', response.permissions)
        this.publishing = false
      } catch (e) {
        console.error(e)
        this.showError('Ocorreu um erro ao ' + (lock ? 'lacrar o exame' : 'deslacrar o exame'))
      }
    },
    async publishForm(forcePublish = false) {
      console.log('publishForm')
      //ask for start date if its null
      let options = this.publishOptions
      if (((this.instance && this.instance.startDate === null) || this.instance === null) && forcePublish === false) {
        //pass data to options:

        options.allow = this.instance !== null && this.instance.startDate !== null ? 'later' : 'now'
        options.startDate =
          this.instance !== null && this.instance.startDate !== null ? this.instance.startDate : new Date()
        //options.hour =
        //  this.instance !== null && this.instance.startDate !== null ? this.instance.startDate.getHours() : ''
        //options.min =
        //  this.instance !== null && this.instance.startDate !== null ? this.instance.startDate.getMinutes() : ''
        options.duration = this.instance !== null && this.instance.duration ? this.instance.duration : 60
        options.location = this.instance !== null && this.instance.location !== null ? this.instance.location : ''
        this.publishOptions = options
        this.allowPublish = this.verifyPublishOptions(false)
        this.showOptions = true
        this.currentModal = 'publish'
        /*} else if (this.instance === null) {
        //this.showOptions = false

        this.publishing = true
        //ask for a name
        //and start date?

        //change form status and save
        //this.form.status = 'active'
        //this.dirtyPages.add('form')
        //await this.saveForm(false)

        //TODO: USAR duration!!!
        let options = this.publishOptions
        let instanceOptions = this.form.options
        //use permissions from the form

        let startDate = options.allow === 'later' ? options.date : null
        if (startDate !== null) {
          startDate.setHours(options.hour, options.min, 0)
          startDate = startDate.toISOString()
        }

        let formKeys = [this.formid]
        let local_instance = await FormServices.createInstance(
          formKeys,
          this.form.title,
          instanceOptions,
          startDate,
          options.duration
        )
        this.instance = local_instance

        let formPromises = []
        let loadformPromises = []
        local_instance.forms.forEach(form => {
          loadformPromises.push(FormServices.getForm(form.key))
        })

        let resultForms = await Promise.all(loadformPromises)
        //close all forms
        resultForms.forEach(form => {
          if (form.status !== 'closed') formPromises.push(this.closeForm(form.key))
        })

        await Promise.all(formPromises)
          .then(async result => {
            console.log('closing forms', result)
            //Publish instance
            try {
              let publish_result = await FormServices.publishInstance(local_instance.key, 'publish')
              console.log('publish_result', publish_result)
              this.$router.push({ path: `/builder/${this.type}/${local_instance.key}/${this.formid}` })
              this.currentModal = 'share'
              this.instance.status = 'waiting'
              this.form.status = 'closed'
              //this.instance.permissions = publish_result.permissions
              this.$emit('update-permissions', publish_result.permissions)
            } catch (e) {
              console.error(e)
              this.showError('Ocorreu um erro ao publicar o exame. Tente de novo mais tarde.')
            }
            this.publishing = false
          })
          .catch(e => {
            console.error(e)
            this.showError(
              'Verifique que todas as versões do exame contém título, instruções e conteúdo. Tente de novo mais tarde.'
            )
            this.publishing = false
          })
     */
      } else if (this.instance !== null) {
        if (this.allowPublish) {
          //override default values by the option ones
          this.instance.startDate = options.startDate.toISOString()
          this.instance.duration = options.duration
          this.instance.location = options.location

          let duration = parseInt(options.duration)
          if (isNaN(duration) || duration < 15) {
            this.$buefy.dialog.alert({
              message: 'Por favor preencha a duração do exame. A duração mínima é de 15 minutos.',
              type: 'is-danger',
              hasIcon: false,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            return
          }

          if (this.instance.location === '') {
            this.$buefy.dialog.alert({
              message: 'Por favor preencha o local do exame',
              type: 'is-danger',
              hasIcon: false,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            return
          }
        }
        this.saveInstance(true)
        this.showOptions = false
        this.currentModal = 'options'
      }
    },
    async publishExam() {
      this.saveInstance(true)
    },
    async closeForm(formData) {
      //let response = await FormServices.updateFormStatus(formId, 'close')
      formData['status'] = 'close'
      let response = await FormServices.saveForm(formData) //updateFormStatus(formId, 'close')
      console.log('closeForm', response)
    },
    toClipboardExamLink() {
      this.$copyText(this.examLink).then(() => {
        this.copied = true

        if (this.clipboard_timer !== null) {
          clearTimeout(this.clipboard_timer)
        } else {
          this.$buefy.toast.open({
            duration: 3000,
            message: `Link copiado para a área de transferência.`,
            position: 'is-top'
          })
        }

        this.clipboard_timer = setTimeout(() => {
          this.copied = false
          this.clipboard_timer = null
        }, 3000)
      })
    },
    async saveAllFormSettings() {
      try {
        this.saving = true
        this.settingsSavingSuccess = false
        //TODO: update all forms
        let formLoadingPromises = []
        let formSavingPromises = []
        this.instance.forms.forEach(form => {
          formLoadingPromises.push(FormServices.getForm(form.key))
        })

        let results = await Promise.all(formLoadingPromises)
        results.forEach((formData, f) => {
          let newFormData = {}
          newFormData.title = this.instance.title
          newFormData.key = this.instance.forms[f].key
          newFormData.instructions = formData.instructions
          //add new options to each form
          newFormData.options = this.formOptions
          console.log('FORM DATA', newFormData)

          formSavingPromises.push(FormServices.saveForm(newFormData))
        })
        await Promise.all(formSavingPromises)
        this.settingsSavingSuccess = true
        console.log('FORM SAVING SUCCESSFUL')
      } catch (e) {
        console.error(e)
        this.showError('Ocorreu um erro ao guardar as configurações do exame. Tente de novo mais tarde.')
      } finally {
        this.saving = false
      }
    },
    async saveInstance(publish = false) {
      console.log('saving instance')
      //save instance and formOptions
      //update instance and publish instance
      this.publishing = publish
      this.saving = true
      this.settingsSavingSuccess = false

      //TODO: USAR duration!!!
      //let options = this.publishOptions

      //TODO: use permissions from the form
      let instanceOptions = this.instance.options
      /*let formOptions = this.formOptions //update all forms with the same settings

      //use permissions from the form
      let startDate = options.allow === 'later' ? this.options.date : null
      if (startDate !== null) {
        startDate.setHours(options.hour, options.min, 0)
        startDate = startDate.toISOString()
      }*/

      console.log('instanceDate', this.instance.startDate, typeof this.instance.startDate)
      console.log('instanceOptions', instanceOptions)
      let startDate = this.instance.startDate != null ? this.instance.startDate : null
      let formKeys = this.instance.forms.map(form => form.key)
      //let canCloseForms = true

      let local_instance = await FormServices.updateInstance(
        this.instance.key,
        formKeys,
        this.instance.title,
        instanceOptions,
        startDate,
        this.instance.duration,
        this.instance.mode,
        this.instance.location,
        this.instance.organic_unit_key
      )

      console.log('Remote instance', local_instance)
      this.instance = local_instance

      //let formPromises = []
      /*let loadformPromises = []
      local_instance.forms.forEach(form => {
        loadformPromises.push(FormServices.getForm(form.key))
      })

      let resultForms = await Promise.all(loadformPromises)*/

      /*for (let index = 0; index < resultForms.length; index++) {
        const canClose = resultForms[index].status == 'closed' || resultForms[index].permissions.close
        if (!canClose) {
          canCloseForms = false
        }
      }*/
      //console.log(publish, canCloseForms)
      /*if (publish && canCloseForms) {
        //close all forms
        resultForms.forEach(form => {
          //form['title'] = local_instance.title
          if (form.status !== 'closed') formPromises.push(this.closeForm(form))
        })
      } else if (publish && !canCloseForms) {
        this.showError(
          'Não é possível publicar o exame. Verifique que todas as versões têm conteúdo em todos os idiomas antes de publicar.'
        )
        return
      }*/

      /*Promise.all(formPromises)
        .then(async result => {
          if (publish) {
            console.log('closing forms', result)
            //Publish instance
            try {
              let publish_result = await FormServices.publishInstance(local_instance.key, 'publish')
              console.log('publish_result', publish_result)
              //this.currentModal = 'share'
              this.instance.status = publish_result.status //'waiting'
              this.permissions = publish_result.permissions
              //this.$router.push({ path: `/exam/${local_instance.key}/evaluation` })
              //this.form.status = 'closed'
            } catch (e) {
              console.error(e)
              this.showError('Ocorreu um erro ao publicar o exame. Tente de novo mais tarde.')
            }
          }
          this.publishing = false
          this.saving = false
          this.settingsSavingSuccess = true
          //verify if we can close the forms
          this.checkFormClosureAbility()
        })
        .catch(e => {
          console.error(e)
          this.showError('Ocorreu um erro ao guardar as configurações do exame. Tente de novo mais tarde.')
          this.publishing = false
          this.saving = false
        })*/

      if (publish) {
        try {
          let publish_result = await FormServices.publishInstance(local_instance.key, 'publish')
          console.log('publish_result', publish_result)
          //this.currentModal = 'share'
          this.instance.status = publish_result.status //'waiting'
          this.permissions = publish_result.permissions
          //this.$router.push({ path: `/exam/${local_instance.key}/evaluation` })
          //this.form.status = 'closed'
        } catch (e) {
          console.error(e)
          this.showError('Ocorreu um erro ao publicar o exame. Tente de novo mais tarde.')
        } finally {
          this.publishing = false
          this.saving = false
          this.settingsSavingSuccess = true
        }
      } else {
        this.publishing = false
        this.saving = false
        this.settingsSavingSuccess = true
        this.checkFormClosureAbility()
      }

      //verify if we can close the forms
      //
    },
    async saveInstanceSettings(value) {
      console.log('SAVING', value)

      //verify if language was changed
      let instanceLanguages = this.instance.languages
      let languagesChanged = false
      if (instanceLanguages.length !== value.languages.length) {
        languagesChanged = true
      } else {
        value.languages.forEach(lang => {
          if (!instanceLanguages.includes(lang)) languagesChanged = true
        })
      }

      if (languagesChanged) {
        //TODO: WARNING, race condition here againts intance languages... MAKE IT BETTER IN THE FUTURE
        //Change forms languages first
        let formPromises = []
        this.instance.forms.forEach(form => {
          let newFormData = {}
          newFormData.options = this.formOptions
          newFormData.key = form.key
          newFormData.options['availableLanguages'] = value.languages
          formPromises.push(FormServices.saveForm(newFormData))
        })

        await Promise.all(formPromises)
      }

      this.instance = value
      this.saveInstance()
    },
    saveFormSettings(value) {
      //TODO: WARNING, race condition here againts intance languages... MAKE IT BETTER IN THE FUTURE
      //get languages from instance and update form
      let instanceLanguages = this.instance.languages
      value['availableLanguages'] = Array.from(instanceLanguages)
      this.formOptions = value
      this.form.options = value
      this.saveAllFormSettings()
    },
    changeInstanceOptions(value, save = false) {
      console.log('changeInstanceOptions', value)
      Vue.set(this.instance.options, value[0], value[1])
      if (save) this.saveInstance()
    },
    changeInstance(value, save = false) {
      console.log('changeInstance', value)
      Vue.set(this.instance, value[0], value[1])
      if (save) this.saveInstance()
    },
    toogleInstanceOption(option, save = false) {
      console.log('toogleInstanceOption', option)
      Vue.set(this.instance.options, option, !this.instance.options[option])
      if (save) this.saveInstance()
    },
    toogleFormOption(option, save = false) {
      //TODO: UPDATE REMOTE OPTIONS
      console.log('toogleFormOption', option)
      Vue.set(this.formOptions, option, !this.formOptions[option])
      if (save) this.saveInstance()
      //this.form.options[option] =
    },
    changeFormOption(value) {
      console.log('changeFormOption', value)
      Vue.set(this.formOptions, value[0], value[1])
    },
    closeOptionsModal() {
      this.showOptions = false
    },
    async getUsersData(users = []) {
      let usersData = await FormServices.getUsersInfo(users)
      this.users = Object.assign(this.users, usersData)
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "examStatus": {
      "draft": "Rascunho",
      "published": "Publicado",
      "running": "A decorrer",
      "closed": "Fechado",
      "ended": "Terminado",
      "scheduled": "Agendado"
    }
  },
  "en": {
    "examStatus": {
      "draft": "Rascunho",
      "published": "Publicado",
      "running": "A decorrer",
      "closed": "Fechado",
      "ended": "Terminado",
      "scheduled": "Agendado"
    }
  }
}
</i18n>
