<template>
  <div class="flex-1 rounded-3xl bg-panel p-5 flex gap-2 flex-col flex-shrink-0 select-none">
    <div class="flex items-center">
      <div class="text-sm text-title font-semibold flex-shrink-0 flex-1">{{ $t('pin_accesses') }}</div>
      <fw-button type="black" class="mr-4" @click.native="createPasswordlessLogin()">
        {{ $t('pinaccess') }}
      </fw-button>
      <fw-button type="black" :loading="loading" rounded="left" size="sm" icon="restart" @click.native="refresh()">
        <template #icon>
          <fw-icon-refresh class="w-4 h-4 text-white" />
        </template>
        <template #default>
          Atualizar
        </template>
      </fw-button>
      <b-dropdown aria-role="list" position="is-bottom-left">
        <template #trigger="{ active }">
          <fw-button type="black" rounded="right" class="opacity-75 hover:opacity-100 flex items-center gap-2">
            {{ autoRefresh || 'Off' }} <fw-icon-chevron-down class="h-4 w-4"></fw-icon-chevron-down>
          </fw-button>
        </template>
        <b-dropdown-item paddingless aria-role="listitem">
          <fw-button type="basic-action" size="sm" expanded left label="Off" @click.native="activateAutoRefresh('off')">
            Off
          </fw-button>
        </b-dropdown-item>
        <b-dropdown-item v-for="(option, o) in refreshOptions" :key="o" paddingless aria-role="listitem">
          <fw-button
            type="basic-action"
            size="sm"
            expanded
            left
            :label="option.label"
            @click.native="activateAutoRefresh(option)"
          >
            {{ option.label }}
          </fw-button>
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="flex flex-1 overflow-hidden">
      <LoadingPlaceholder v-if="loading" :number="5" />
      <div v-else-if="results.length > 0" class="flex-1 relative">
        <div
          ref="answerslist"
          class="h-full overflow-y-scroll flex flex-col gap-3 justify-start rounded-lg hide-scrollbar"
        >
          <AccessCard
            v-for="access in results"
            :key="access.key"
            :pin="access.code"
            :from="users[access.user_key]"
            :to="users[access.for_user_key]"
          />
        </div>
        <div v-if="usersOveflow" class="absolute bottom-0 left-0 right-0 h-10 dashboard-overflow-gradient"></div>
      </div>
      <div v-else class="flex-1 flex flex-col justify-center items-center">
        <fw-icon-rest class="color-icon-panel h-12 w-12 mb-2"></fw-icon-rest>
        <div class="text-title text-sm">
          {{ $t('noresults') }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AccessCard from '../cards/PinCard.vue'
import FormServices from '@/fw-modules/fw-core-vue/exams/services/ServiceExams'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import ServiceExams from '../../services/ServiceExams'
export default {
  name: 'PanelDashboardAccesses',
  components: {
    AccessCard,
    LoadingPlaceholder
  },
  filters: {
    split3digits(value) {
      if (!value) return ''
      //create a code XXX-XXX
      return value.replace(/(\d{3})(\d{3})/, '$1-$2')
    }
  },
  props: {
    instance: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      usersFilter: 'all',
      usersOveflow: false,
      results: [],
      users: {},
      loading: false,
      refreshOptions: [
        {
          label: '15s',
          value: 15000
        },
        {
          label: '30s',
          value: 30000
        },
        {
          label: '1m',
          value: 60000
        },
        {
          label: '5m',
          value: 300000
        },
        {
          label: '10m',
          value: 600000
        },
        {
          label: '15m',
          value: 900000
        },
        {
          label: '30m',
          value: 1800000
        }
      ],
      autoRefreshTimer: null,
      autoRefresh: ''
    }
  },
  mounted() {
    this.loadAccesses()
  },
  methods: {
    async createPasswordlessLogin() {
      this.$buefy.dialog.prompt({
        title: 'Criar acesso por PIN',
        message: 'Insira o número de estudante para criar um acesso por PIN.',
        inputAttrs: {
          type: 'text',
          placeholder: 'Número de estudante'
        },
        confirmText: 'Criar',
        cancelText: 'Cancelar',
        onConfirm: async value => {
          console.log('createPinAccess', value)
          if (value) {
            let result = await ServiceExams.createPinAccess(this.instance.key, value)
            let pin = result.code.replace(/(\d{3})(\d{3})/, '$1-$2')
            this.$buefy.dialog.alert({
              title: 'Acesso por PIN criado',
              message: 'Utilize o seguinte PIN para iniciar sessão no tablet:<br /><b class="text-xl">' + pin + '</b>',
              type: 'is-success'
            })
            if (result) {
              this.loadAccesses()
            }
          }
        }
      })
    },
    activateAutoRefresh(option) {
      if (option === 'off') {
        this.autoRefresh = ''
        clearInterval(this.autoRefreshTimer)
        return
      }

      this.autoRefresh = option.label
      if (this.autoRefreshTimer != null) {
        clearInterval(this.autoRefreshTimer)
      }
      this.autoRefreshTimer = setInterval(() => {
        this.loadAccesses()
      }, option.value)
    },
    refresh() {
      this.loadAccesses()
    },
    verifyUsersOverflow() {
      let container = this.$refs.answerslist
      if (container) {
        this.usersOveflow = container.scrollHeight > container.clientHeight
        if (this.usersOveflow) {
          container.addEventListener('scroll', () => {
            if (container.offsetHeight + container.scrollTop >= container.scrollHeight) {
              this.usersOveflow = false
            } else {
              this.usersOveflow = true
            }
          })
        }
      } else {
        this.usersOveflow = false
      }
    },
    async loadAccesses() {
      this.loading = true
      let results = await FormServices.getPasswordlessAccesses(this.instance.key)
      this.results = results.items
      this.users = { ...this.users, ...results.users }
      this.loading = false
      this.verifyUsersOverflow()
    }
  }
}
</script>
<i18n>
  {
    "pt": {
      "pinaccess": "Criar acesso por PIN",
      "devices": "Dispositivos",
      "pin_accesses": "Acessos por PIN",
      "results": "Resultados",
      "all": "Todos",
      "running": "Em curso",
      "submited": "Entregues",
      "giveup": "Desistências",
      "noanswer": "Sem resposta",
      "noanswers": "Sem avaliações para mostrar.",
      "noanswers_answering": "Sem avaliações a decorrer.",
      "noanswers_closed": "Sem respostas entregues.",
      "noanswers_withdraw": "Sem desistências.",
      "noanswers_noanswer": "Sem avaliações sem resposta.",
      "noresults": "Sem acessos por pin definidos."
    },
    "en": {
      "pinaccess": "Create access by PIN",
      "devices": "Devices",
      "pin_accesses": "PIN accesses",
      "results": "Results",
      "all": "All",
      "running": "Running",
      "submited": "Submited",
      "giveup": "Withdrawn",
      "noanswer": "No answer",
      "noanswers": "Without any answers to show.",
      "noanswers_answering": "Without any running evaluations.",
      "noanswers_closed": "Without any submited answers.",
      "noanswers_withdraw": "Without any withdraws.",
      "noanswers_noanswer": "Without any evaluations without answers.",
      "noresults": "No PIN accesses defined."
    }
  }
</i18n>
