<template>
  <fw-layout>
    <template #main-content>
      <fw-panel-info type="orange" boxed icon class="mb-5">
        <div>
          A nova versão da funcionalidade de Exames está em
          <span class="font-bold">fase de testes</span>. Caso se depare com algum erro ou identifique aspetos passíveis
          de melhoria, por favor, contacte-nos através do nosso canal de suporte
          <a class="underline" href="mailto:support@ucframework.pt">support@ucframework.pt</a>.
        </div>
      </fw-panel-info>
      <fw-panel :title="$t('exams')" featured>
        <template v-if="canCreateExams" #toolbar>
          <fw-button v-if="debug" type="link" @click.native="openQuestionBuckets()"
            >Repositórios de perguntas</fw-button
          >
          <fw-button type="link" @click.native="newExam()">Criar novo exame</fw-button>
        </template>
        <ContextualSearch
          v-if="isSearching || hasInstances"
          :loading="loading"
          :filter-options="filters"
          :applied-filters="appliedFilters"
          :start-value="searchInput"
          @search="search"
        >
          <template #tags>
            <FilterTag
              v-for="(filter, f) in appliedFilters"
              :key="'filter_' + f"
              :text="getFilterText(filter)"
              :show-close-button="true"
              @close="deleteFilter(f)"
            ></FilterTag>
          </template>
        </ContextualSearch>

        <PanelStats
          v-if="!isSearching && hasInstances && !loadingStats"
          class="pt-2"
          title="Números"
          :stats="stats"
          :loading="loadingStats"
        />
      </fw-panel>

      <div v-if="isSearching">
        <fw-panel :title="'Resultados'" :counter="searchResults.length" class="my-5">
          <div v-if="searchResults.length > 0" class="bg-white rounded-md">
            <ExamRecord
              v-for="(instance, i) in searchResults"
              :key="'exam_published_' + i"
              :users="users"
              :instance="instance"
              @click.native="goToExam(instance)"
            ></ExamRecord>
          </div>
          <div v-else-if="!loading" class="bg-white rounded-md py-16 text-center text-gray-400">
            Sem resultados
          </div>
        </fw-panel>
      </div>

      <!-- Homepage (no search) -->
      <div v-else class="my-5">
        <div v-if="!loading && (next.length > 0 || running.length > 0)" :counter="next.length" class="my-5">
          <fw-panel v-if="running.length > 0" :title="'Em curso'" featured>
            <template #toolbar>
              <fw-button type="link" @click.native="seeAll('running')">Ver todos</fw-button>
            </template>
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
              <ExamCard
                v-for="(instance, i) in running"
                :key="'exam_running_' + i"
                :users="users"
                :instance="instance"
                @click.native="goToExam(instance)"
              ></ExamCard>
            </div>
          </fw-panel>
          <fw-panel v-if="next.length > 0" :title="'Próximos'">
            <div class="flex gap-5  w-full pb-5 flex-wrap">
              <ExamCard
                v-for="(instance, i) in next"
                :key="'exam_next_' + i"
                :users="users"
                :instance="instance"
                @click.native="goToExam(instance)"
              ></ExamCard>
            </div>
          </fw-panel>
        </div>
        <fw-panel v-if="!loading && draft.length > 0" :title="'Em edição'" :counter="draft.length" class="my-5">
          <template #toolbar>
            <fw-button type="link" @click.native="seeAll('draft')">Ver todos</fw-button>
          </template>
          <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5">
            <ExamDraftCard
              v-for="(instance, i) in draft"
              :key="'exam_draft_' + i"
              :users="users"
              :instance="instance"
              @click.native="goToExam(instance)"
            ></ExamDraftCard>
          </div>
        </fw-panel>
        <fw-panel
          v-if="!loading && published.length > 0"
          :title="'Publicados'"
          :counter="published.length"
          class="mt-10 pb-5"
          boxed
          custom-class="bg-white"
        >
          <template v-if="published.length" #toolbar>
            <fw-button type="link" @click.native="seeAll('published')">Ver todos</fw-button>
          </template>
          <div v-if="!loading && published.length > 0">
            <ExamRecord
              v-for="(instance, i) in published"
              :key="'exam_published_' + i"
              :users="users"
              :instance="instance"
              @click.native="goToExam(instance)"
            ></ExamRecord>
          </div>
          <fw-panel-info v-else-if="!loading && published.length == 0" empt centered>
            Sem exames publicados.
          </fw-panel-info>
          <LoadingPlaceholder v-else :list="true"></LoadingPlaceholder>
        </fw-panel>
        <div v-else-if="!loading && archivedCounter" class="text-sm text-gray-500 border-t pt-3 mt-10">
          <p>Tem exames terminados que estão arquivados e não visíveis nesta página.</p>
          <p>
            <a class="text-primary" @click.prevent="seeArchived">Ver exames arquivados ({{ archivedCounter }})</a>.
          </p>
        </div>
        <fw-panel-info v-else-if="!loading && !hasInstances" centered clean>
          <div class="flex flex-col gap-5 items-center justify-center p-10">
            <div class="text-center">
              <div class="rounded-full p-5 bg-gray-300 text-white">
                <fw-icon-survey class="w-12 h-12" />
              </div>
            </div>
            <div>Ainda não tem exames criados.</div>
            <div class="flex gap-5 items-center">
              <fw-button type="link" @click.native="newExam()">Criar exame</fw-button>
            </div>
          </div>
        </fw-panel-info>
      </div>
    </template>

    <template #modals>
      <fw-modal v-if="showNewExamModal" :active.sync="showNewExamModal" :can-cancel="true" size="min" @close="close">
        <template #default>
          <ModalNewVersion
            v-if="showTemplateModal === false"
            :is-exam="true"
            @new-from-blank="createNewExam($event)"
            @new-from-template="createNewExam($event)"
            @close="close"
          ></ModalNewVersion>
          <ModalVersionFromTemplate v-else @close="close"></ModalVersionFromTemplate>
        </template>
      </fw-modal>
    </template>
  </fw-layout>
</template>

<script>
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import PanelStats from '@/fw-modules/fw-core-vue/ui/components/panels/PanelStats'
import ModalNewVersion from '@/fw-modules/fw-core-vue/exams/components/modals/ModalNewVersion'
import ModalVersionFromTemplate from '@/fw-modules/fw-core-vue/exams/components/modals/ModalVersionFromTemplate'
import ContextualSearch from '@/fw-modules/fw-core-vue/ui/components/search/ContextualSearch'
import FilterTag from '@/fw-modules/fw-core-vue/ui/components/text/FilterTag'
import FormServices from '@/fw-modules/fw-core-vue/exams/services/ServiceExams'
import ExamCard from '@/fw-modules/fw-core-vue/exams/components/cards/ExamCard'
import ExamDraftCard from '@/fw-modules/fw-core-vue/exams/components/cards/ExamDraftCard'
import ExamRecord from '@/fw-modules/fw-core-vue/exams/components/cards/ExamRecord'
import ServiceExams from '../services/ServiceExams'

export default {
  name: 'ViewExams',
  components: {
    ModalNewVersion,
    ModalVersionFromTemplate,
    ContextualSearch,
    FilterTag,
    PanelStats,
    ExamCard,
    ExamDraftCard,
    ExamRecord,
    LoadingPlaceholder
  },
  data: function() {
    return {
      showNewExamModal: false,
      showTemplateModal: false,
      stats: [],
      rawStats: null,
      hideStatsOnSearching: false,
      loadingStats: true,
      loading: false,
      searchInput: '',
      selectedUser: null,
      appliedFilters: [],
      filters: [
        {
          key: 'state',
          label: 'Estado',
          options: [
            {
              key: 'reset',
              label: 'Todos'
            },
            {
              key: 'draft',
              label: 'Em edição'
            },
            {
              key: 'running',
              label: 'Em curso'
            },
            {
              key: 'waiting',
              label: 'Publicados'
            },
            {
              key: 'scheduled',
              label: 'Agendados'
            },
            {
              key: 'ended',
              label: 'Terminados'
            }
          ]
        }
      ],
      sortBy: null,
      sortDirection: 'asc',
      exams: [],
      searchResults: [],
      users: {}
    }
  },
  computed: {
    debug() {
      return Boolean(localStorage.getItem('fw-debug'))
    },
    appName() {
      return this.$store.state.appName || 'ucstudent'
    },
    language() {
      return this.$store.state.language || this.$i18n.locale || 'pt'
    },
    hasInstances() {
      return !!(!this.loading && this.exams.length)
    },
    running() {
      return this.exams.filter(exam => exam.status === 'running')
    },
    next() {
      return this.exams.filter(exam => exam.status === 'waiting')
    },
    published() {
      return this.exams.filter(exam => exam.status !== 'running' && exam.status !== 'draft')
    },
    archivedCounter() {
      if (!this.rawStats) return 0
      return this.rawStats.ended + this.rawStats.deleted + this.rawStats.archived
    },
    draft() {
      return this.exams.filter(exam => exam.status === 'draft')
    },

    // Checks
    isSearching() {
      return this.searchInput.length > 0 || this.appliedFilters.length > 0
    },

    // Permissions
    canCreateExams() {
      // TODO: We must have roles or permissions assigned to the user, not using app name to allow them to do things...
      return this.appName === 'ucteacher'
    },

    showStats() {
      return true
    }
  },
  created() {
    this.loadInstances()
  },
  methods: {
    seeAll(type) {
      this.search({
        filters: type === 'published' ? ['state:waiting', 'state:scheduled', 'state:ended'] : ['state:' + type]
      })
    },
    seeArchived() {
      this.search({
        filters: ['state:ended']
      })
    },
    async loadStats() {
      this.loadingStats = true
      // let data
      // try {
      //   data = await this.api.getProceduresStats()
      // } catch (error) {
      //   console.error('Error loadStats: ', error)
      // }
      let stats = []
      for (const [key, value] of Object.entries(this.rawStats)) {
        stats.push({
          label: '',
          value: value,
          valueSuffix: null,
          description: ServiceExams.dashboardStatisticsMessages(this.language)[key]
        })
      }
      console.log('STATS', stats)
      this.stats = stats
      this.loadingStats = false
    },
    getFilterText(key) {
      var split = key.split(':')
      for (var i = 0; i < this.filters.length; i++) {
        if (this.filters[i].key == split[0]) {
          for (var j = 0; j < this.filters[i].options.length; j++) {
            if (this.filters[i].options[j].key == split[1]) {
              return this.filters[i].options[j].label
            }
          }
        }
      }
      return ''
    },
    deleteFilter(index) {
      this.appliedFilters.splice(index, 1)
      this.search()
    },
    async search(searchCriteria) {
      if (searchCriteria.term) {
        this.searchInput = searchCriteria.term
      } else {
        this.searchInput = ''
      }
      /*
       term: this.searchTerm,
        filters: this.selectedFilters,
        orderBy: this.orderItemsBy != null ? this.orderItemsBy.key : null,
        orderDirection: this.orderItemsBy != null ? this.orderDirection : null,
        user: this.user,
        dates: this.dates,
      */

      console.log('Searching...')
      console.log(searchCriteria)

      this.loading = true
      this.appliedFilters = typeof searchCriteria != 'undefined' ? searchCriteria.filters : []
      try {
        let status = null
        if (typeof searchCriteria != 'undefined') {
          for (let index = 0; index < searchCriteria.filters.length; index++) {
            const filterParts = searchCriteria.filters[index].split(':')
            if (filterParts[0] == 'state' && filterParts[1] != 'reset') {
              status = filterParts[1]
            }
          }
        }

        let result = await FormServices.getAllInstances({
          filterStatus: status,
          term: searchCriteria.term
        })
        this.searchResults = result.instances
        /*let result = await FormServices.getAnswers(
          this.instance.key,
          true,
          this.sortBy,
          this.sortDirection,
          searchCriteria.term.length > 0 ? searchCriteria.term : null,
          searchCriteria.filters
        )
        console.log(result)
        .answers*/
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    openQuestionBuckets() {
      this.$router.push({ name: 'exams-manage-question-buckets' })
    },
    goToExam(instance) {
      if (this.appName != 'ucstudent') {
        this.$router.push({ name: 'exams-manage-dashboard', params: { id: instance.key } })
      } else {
        this.$router.push({ name: 'exams-examination', params: { key: instance.key } })
      }
    },
    async loadInstances() {
      this.loading = true
      try {
        let data = await FormServices.getAllInstances({ dashboard: true })
        this.exams = data.instances
        this.rawStats = data.stats
        this.users =
          data.users /*.reduce(function(map, obj) {
          map[obj.key] = obj
          return map
        }, {})*/
        this.loadStats()
      } finally {
        this.loading = false
      }
    },
    close() {
      this.showNewExamModal = false
      this.showTemplateModal = false
    },
    newExam() {
      this.showNewExamModal = true
    },
    /*createExam(type, data) {
      if (type === 'new') {
        //1. Create form
        //2. Add it to instance
        this.createNewExam(data)
      } else {
        //this.$router.push({ name: 'exam', params: { id: 'template' } })
        this.createNewExam(data)
      }
    },*/
    async loadFormPage(pageKey) {
      console.log('loadFormPage', pageKey)
      let pageResponse = await FormServices.getPage(pageKey)
      if (typeof pageResponse.title[this.language] === 'undefined') {
        pageResponse.title[this.language] = ''
      }

      for (let s = 0; s < pageResponse.schema.length; s++) {
        if (typeof pageResponse.schema[s].title[this.language] === 'undefined') {
          pageResponse.schema[s].title[this.language] = ''
        }

        for (let i = 0; i < pageResponse.schema[s].content.length; i++) {
          //score
          if (typeof pageResponse.schema[s].content[i].score === 'undefined') {
            pageResponse.schema[s].content[i].score = null
          }
          //instructions
          if (
            typeof pageResponse.schema[s].content[i].instructions === 'undefined' ||
            typeof pageResponse.schema[s].content[i].instructions[this.language] === 'undefined'
          ) {
            if (typeof pageResponse.schema[s].content[i].instructions === 'undefined') {
              pageResponse.schema[s].content[i].instructions = {}
            }
            pageResponse.schema[s].content[i].instructions[this.language] = ''
          }
          //label
          if (
            typeof pageResponse.schema[s].content[i].label === 'undefined' ||
            typeof pageResponse.schema[s].content[i].label[this.language] === 'undefined'
          ) {
            if (typeof pageResponse.schema[s].content[i].label === 'undefined') {
              pageResponse.schema[s].content[i].label = {}
            }
            pageResponse.schema[s].content[i].label[this.language] = ''
          }
          //message
          if (
            pageResponse.schema[s].content[i].message &&
            typeof pageResponse.schema[s].content[i].message[this.language] === 'undefined'
          ) {
            if (typeof pageResponse.schema[s].content[i].message === 'undefined') {
              pageResponse.schema[s].content[i].message = {}
            }
            pageResponse.schema[s].content[i].message[this.language] = ''
          }
          //text
          if (
            pageResponse.schema[s].content[i].text &&
            typeof pageResponse.schema[s].content[i].text[this.language] === 'undefined'
          ) {
            if (typeof pageResponse.schema[s].content[i].text === 'undefined') {
              pageResponse.schema[s].content[i].text = {}
            }
            pageResponse.schema[s].content[i].text[this.language] = ''
          }
          //placeholder
          if (
            typeof pageResponse.schema[s].content[i].placeholder === 'undefined' ||
            typeof pageResponse.schema[s].content[i].placeholder[this.language] === 'undefined'
          ) {
            if (typeof pageResponse.schema[s].content[i].placeholder === 'undefined') {
              pageResponse.schema[s].content[i].placeholder = {}
            }

            if (pageResponse.schema[s].content[i].type === 'text_area') {
              pageResponse.schema[s].content[i]['placeholder'][this.language] =
                this.language === 'pt' ? 'Escreva aqui a sua resposta' : 'Write here your answer'
            }

            pageResponse.schema[s].content[i].placeholder[this.language] = ''
          }

          //check options
          if (pageResponse.schema[s].content[i].options && pageResponse.schema[s].content[i].options.length > 0) {
            for (let o = 0; o < pageResponse.schema[s].content[i].options.length; o++) {
              let optiontext = pageResponse.schema[s].content[i].options[o].text
              /*let optionscore = 0
              if (
                this.scores &&
                this.scores[pageResponse.key + '_' + pageResponse.schema[s].content[i].key] &&
                this.scores[pageResponse.key + '_' + pageResponse.schema[s].content[i].key].correct &&
                this.scores[pageResponse.key + '_' + pageResponse.schema[s].content[i].key].correct[o] &&
                this.scores[pageResponse.key + '_' + pageResponse.schema[s].content[i].key].correct[o].score
              ) {
                optionscore = Number(
                  this.scores[pageResponse.key + '_' + pageResponse.schema[s].content[i].key].correct[o].score
                )
              }*/

              //? this.scores[pageResponse.key + '_' + pageResponse.schema[s].content[i].key].score
              //: 0

              /*try {
                optiontext = JSON.parse(optiontext)
                console.log('é JSON', optiontext)
              } catch (e) {
                //no json detected
                console.log('NAO é JSON', optiontext)
              }*/

              if (typeof optiontext !== 'string' && typeof optiontext[this.language] === 'undefined') {
                optiontext[this.language] = ''
              }
              pageResponse.schema[s].content[i].options[o].text = optiontext
              //pageResponse.schema[s].content[i].options[o].score = optionscore
            }
          }
        }
      }

      return pageResponse
    },
    async createNewExam(data) {
      let language = data.language || 'pt'

      try {
        let formData = {
          instructions: {},
          title: {},
          options: {
            disableRightClick: true,
            availableLanguages: [],
            anonymous: false,
            //new
            pointsCorrectOption: 1,
            pointsWrongOption: 0,
            //new new
            scale: 20,
            pointsCorrectInput: 1,
            randomMultiplechoiceOptions: false,
            randomSections: false,
            negativePoints: false,
            limitNegativePoints: true,
            logInteractions: false
          }
        }

        //Add language exam
        formData['instructions'][language] = data.instructions || ''
        formData['title'][language] = data.name || ''
        formData['options']['availableLanguages'].push(language)

        let pagesData = []

        if (data.key != null) {
          //load data from form
          let templateForm = await FormServices.getForm(data.key)
          let pagesPromises = []
          //response.pages.forEach(page => promises.push(FormServices.getPage(page.key)))
          templateForm.pages.forEach(page => pagesPromises.push(this.loadFormPage(page.key)))

          this.pages = []
          pagesData = await Promise.all(pagesPromises)
        }

        formData['type'] = 'exam'
        let responseForm = await FormServices.saveForm(formData)

        //verify if we have current language
        if (typeof responseForm.title[language] === 'undefined') {
          responseForm.title[language] = ''
        }

        let title = {}

        title[language] = language === 'pt' ? 'Página 1' : 'Page 1'

        let pagesPromises = []

        //add empty page to form
        if (pagesData.length == 0) {
          await FormServices.addFormPage(
            responseForm.key,
            title,
            [
              {
                boxed: true,
                required: false,
                title: '',
                key: '1',
                content: []
              }
            ],
            {},
            null
          )
        } else {
          for (let index = 0; index < pagesData.length; index++) {
            pagesPromises.push(
              FormServices.addFormPage(
                responseForm.key,
                pagesData[index]['title'],
                pagesData[index]['schema'],
                pagesData[index]['scores'],
                null
              )
            )
          }
          await Promise.all(pagesPromises)
        }

        let responseInstance = await FormServices.createInstance(
          [responseForm.key],
          formData.title,
          formData.options,
          null
        )
        console.log(responseForm, responseInstance)
        this.$router.push({
          name: 'exams-manage-editor-version',
          params: { id: responseInstance.key, versionid: responseForm.key },
          query: { edit: true }
        })
      } catch (e) {
        console.error(e)
        this.$buefy.dialog.alert({
          message: 'Ocorreu um erro ao criar o exame',
          type: 'is-danger',
          hasIcon: false,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
    }
  }
}
</script>

<i18n>
  {
    "en": {
      "exams": "Exams"
    },
    "pt": {
      "exams": "Exames"
    }
  }
</i18n>
