import Vue from 'vue'
import VueRouter from 'vue-router'

import BaseRoutes from './routes'

import FormManageRoutes from '@/fw-modules/fw-core-vue/exams/routes-manage'
import FormStudentRoutes from '@/fw-modules/fw-core-vue/exams/routes-student'
import NonDegreeCoursesRoutes from '@/fw-modules/fw-core-vue/nondegree-courses/routes'
import ManageCoursesRoutes from '@/fw-modules/fw-core-vue/courses/routes-manage'
import CoursesRoutes from '@/fw-modules/fw-core-vue/courses/routes'
//import CoursesManageRoutes from '@/fw-modules/fw-core-vue/courses/routes-manage'
import PagesEditorRoutes from '@/fw-modules/fw-core-vue/pages/routes'
import CoreRoutes from '@/fw-modules/fw-core-vue/routes'
import CoreRouter from '@/fw-modules/fw-core-vue/router'
import AnalyticsRoutes from '@/fw-modules/fw-core-vue/analytics/routes'

//new forms
import FormsRoutes from '@/fw-modules/fw-core-vue/forms/routes-manage'

Vue.use(VueRouter)

const routes = [].concat(
  BaseRoutes,
  FormsRoutes,
  AnalyticsRoutes,
  FormManageRoutes,
  FormStudentRoutes,
  NonDegreeCoursesRoutes,
  ManageCoursesRoutes,
  CoursesRoutes,
  //CoursesManageRoutes,
  PagesEditorRoutes,
  CoreRoutes
)

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_ROUTER_BASE || '/',
  routes
})

router.beforeResolve((to, from, next) => {
  return CoreRouter.beforeResolve(to, from, next)
})
router.afterEach((to, from, next) => {
  return CoreRouter.afterEach(to, from, next)
})
router.beforeEach((to, from, next) => {
  return CoreRouter.beforeEach(to, from, next)
})

export default router
