<template>
  <LayoutDefault
    class="cursor-default select-none"
    :header-session-nav="true"
    :full="true"
    :header-toolbar="true"
    :main-footer="false"
    :main-sidebar="false"
  >
    <template #header-nav>
      <div class="flex gap-5 items-center">
        <div class="flex flex-col">
          <fw-heading size="md" marginless class="line-clamp-1">{{ name }}</fw-heading>
          <fw-label class="hidden md:flex" marginless>{{ id }}</fw-label>
        </div>
      </div>
    </template>
    <template #header-toolbar>
      <div class="flex gap-3 mr-3">
        <b-dropdown :scrollable="true" :max-height="200" aria-role="list" position="is-bottom-left">
          <template #trigger="{ active }">
            <div class="h-10 flex-shrink-0">
              <fw-button type="simple" class="text-gray-600 shadow bg-white h-7 uppercase">{{ language }}</fw-button>
            </div>
          </template>
          <div class="px-4 hidden"><fw-label>Mudar de idioma</fw-label></div>
          <b-dropdown-item aria-role="listitem" @click="changeLanguage('pt')">
            <div class="flex justify-start items-center gap-3 font-semibold text-gray-600">
              <div
                class="relative border border-gray-200 text-gray-500 bg-gray-100 leading-3 text-xs px-1.5 py-1.5 inline-flex flex items-center font-bold transition-colors duration-150 rounded-md hover:opacity-75 focus:outline-none"
              >
                PT
              </div>
              Português
            </div>
          </b-dropdown-item>
          <div class="px-4 hidden"><fw-label>Adicionar novo idioma</fw-label></div>
          <b-dropdown-item
            v-if="editable || (form != null && availableLanguages.includes('en'))"
            class="group"
            aria-role="listitem"
            @click="changeLanguage('en')"
          >
            <div class="flex justify-start items-center gap-3 font-semibold text-gray-600">
              <div
                class="relative border border-gray-200 text-gray-500 bg-gray-100 leading-3 text-xs px-1.5 py-1.5 inline-flex flex items-center font-bold transition-colors duration-150 rounded-md hover:opacity-75 focus:outline-none"
              >
                {{ form != null && availableLanguages.includes('en') ? 'EN' : '+ EN' }}
              </div>
              Inglês
            </div>
          </b-dropdown-item>
        </b-dropdown>
        <fw-button :type="saving ? 'disabled' : 'primary'" @click.native="saveForm">Guardar</fw-button>
      </div>
    </template>

    <!-- <LayoutEditor
    :text-header-class="'mr-56'"
    :header-title="form !== null ? form.title : {}"
    :header-language="language"
    :show-header-title="animateTitle"
    :header-toolbar="false"
    :header-avatar="true"
    :header-session-nav="true"
    :full-height="true"
    class="view-builder"
    @click-background="clickBackground"
  >
    <template #header-title-tags>
      <div
        v-if="form !== null && form.status === 'draft'"
        class="font-semibold text-xs flex flex-inline items-center bg-yellow-300 bg-opacity-70 rounded text-opacity-10 px-2 py-0 leading-4"
      >
        Rascunho
      </div>
      <div
        v-else-if="instance !== null && instance.status === 'waiting'"
        class="font-semibold text-xs flex  flex-inline items-center text-white bg-teal-500 text-opacity-80 rounded px-2  leading-4"
      >
        Publicado
      </div>
      <SavedIndicator
        v-if="form !== null"
        :shorter="true"
        :dirty-data="dirtyData"
        :saving-data="savingData"
        :bold="true"
        class="text-xs leading-4 bg-opacity-10"
      />
    </template>-->

    <template #main-content>
      <div v-if="bucket !== null && !loading" class="pb-32" @click.self="clickBackground">
        <div class="mx-auto max-w-screen-md">
          <div class="relative">
            <SavedIndicator
              v-if="editable"
              :shorter="true"
              :dirty-data="dirtyData"
              :saving-data="saving"
              :bold="true"
              class="text-xs leading-4 bg-opacity-10 rounded"
            />
          </div>

          <div class="page-section relative">
            <div class="real-white rounded-2xl mt-3 shadow text-gray-800 mb-6">
              <div v-if="questions.length === 0" class="p-5">
                <div class="text-gray-300 text-lg text-center py-5">Repositório sem perguntas</div>
                <div v-if="editable" class="mb-3 text-sm text-gray-600">Comece por criar uma pergunta:</div>
                <div v-if="editable" class="flex gap-3 text-gray-700">
                  <button
                    class="border w-full text-left items-center flex flex-col sm:flex-row border-gray-200 gap-3 px-3 py-3 hover:shadow-lg hover:text-gray-800 hover:border-primary rounded-lg text-sm font-bold"
                    @click="addInput('multiple_choice', null)"
                  >
                    <div class="w-12 h-12 pt-1 rounded-lg bg-white shadow flex flex-col gap-1 flex-shrink-0">
                      <div
                        class="rounded-l pl-0.5 leading-4 border border-gray-100 ml-2 flex items-center text-gray-300 border-r-transparent"
                        style=""
                      >
                        A
                      </div>
                      <div
                        class="rounded-l pl-0.5 leading-4 border border-gray-100 ml-2 flex items-center text-gray-300 border-r-transparent"
                        style=""
                      >
                        B
                      </div>
                    </div>
                    <span>Pergunta de escolha múltipla</span>
                  </button>
                  <button
                    class="border w-full text-left items-center  flex flex-col sm:flex-row gap-3 border-gray-200 px-3 py-3 hover:shadow-lg hover:text-gray-800 hover:border-primary rounded-lg text-sm font-bold"
                    @click="addInput('text_area', null)"
                  >
                    <div class="w-12 h-12 rounded-lg bg-white shadow flex flex-col justify-center gap-1  flex-shrink-0">
                      <div
                        class="rounded-l flex flex-col py-1 gap-1 pl-1 leading-4 border border-gray-200 ml-2 text-gray-300 border-r-0"
                      >
                        <div class="bg-gray-100 rounded-l-lg h-1.5 w-full"></div>
                        <div class="bg-gray-100 rounded-l-lg h-1.5 w-full"></div>
                        <div class="bg-gray-100 rounded-lg h-1.5 w-6"></div>
                      </div>
                    </div>
                    <span>Pergunta de resposta aberta</span>
                  </button>
                  <button
                    class="border w-full text-left items-center flex flex-col sm:flex-row border-gray-200 gap-3 px-3 py-3 hover:shadow-lg hover:text-gray-800 hover:border-primary rounded-lg text-sm font-bold"
                    @click="addInput('upload_file', null)"
                  >
                    <div class="w-12 h-12 rounded-lg bg-white shadow flex flex-col justify-center gap-1  flex-shrink-0">
                      <div
                        class="rounded-l flex flex-col py-1 gap-1 pl-1 leading-4 border border-gray-200 ml-2 text-gray-300 border-r-0"
                        style=""
                      >
                        <svg
                          class="fill-current h-5 w-5"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path
                            d="M1 14.5a6.496 6.496 0 0 1 3.064-5.519 8.001 8.001 0 0 1 15.872 0 6.5 6.5 0 0 1-2.936 12L7 21c-3.356-.274-6-3.078-6-6.5zm15.848 4.487a4.5 4.5 0 0 0 2.03-8.309l-.807-.503-.12-.942a6.001 6.001 0 0 0-11.903 0l-.12.942-.805.503a4.5 4.5 0 0 0 2.029 8.309l.173.013h9.35l.173-.013zM13 13v4h-2v-4H8l4-5 4 5h-3z"
                          />
                        </svg>
                      </div>
                    </div>
                    <span class="">Zona de envio de ficheiros</span>
                  </button>
                </div>
              </div>
              <!--
              :data-index="index"
              group-name="column"
              :get-child-payload="(itemIndex) => getChildPayload(index, itemIndex)"
              :should-accept-drop="
              (src, payload) => getShouldAcceptDrop(index, src, payload)
              :get-child-payload="getQuestionPayload(p, s)"
              " -->
              <Container
                v-else
                group-name="questions"
                drop-class="card-ghost-drop"
                :drop-placeholder="dropPlaceholderOptions"
                :should-animate-drop="() => true"
                :get-child-payload="index => getChildPayload(index)"
                class="flex flex-col gap-3 pb-5"
                @drop="onDropSection($event)"
              >
                <div
                  v-for="(input, i) in questions"
                  :key="'input_' + input.key"
                  class="real-white rounded-xl p-5 pb-0 pt-3 relative box-question"
                >
                  <div class="question-container">
                    <div
                      class="flex w-full items-start gap-2"
                      :class="{ 'absolute pr-2': input.type === 'image_direct' }"
                    >
                      <div class="flex-1 flex flex-col items-center h-full">
                        <input
                          v-if="input.type !== 'image_direct' && input.type !== 'text_content'"
                          v-model="input.label[language]"
                          :disabled="!editable"
                          class="text-sm outline-none w-full font-semibold bg-transparent mt-1"
                          :placeholder="'Etiqueta'"
                        />

                        <FormattedText
                          v-if="input.instructions && input.type !== 'image_direct'"
                          :id="input.key"
                          v-model="input.instructions[language]"
                          class="mb-1.5 w-full"
                          :disabled="!editable"
                          :placeholder="
                            input.type === 'multiple_choice'
                              ? 'Escreva aqui a pergunta'
                              : input.type === 'files'
                              ? 'Escreva aqui as instruções de envio dos ficheiros'
                              : input.type === 'text_content'
                              ? 'Escreva aqui qualquer coisa'
                              : 'Escreva aqui a pergunta'
                          "
                          @input="dataChanged()"
                        ></FormattedText>
                      </div>
                      <b-dropdown
                        class="mt-1"
                        :scrollable="true"
                        :max-height="200"
                        aria-role="list"
                        position="is-bottom-left"
                      >
                        <template #trigger="{ active }">
                          <fw-button
                            type="simple"
                            class="text-gray-600 h-8 mt-1 border-white border-opacity-70 bg-white bg-opacity-60"
                            :class="{ 'h-9': input.type === 'image_direct' }"
                            ><fw-icon-more
                          /></fw-button>
                        </template>

                        <b-dropdown-item
                          v-if="
                            editable &&
                              input.score === null &&
                              input.type !== 'text_content' &&
                              input.type !== 'image_direct' &&
                              input.type !== 'image'
                          "
                          aria-role="listitem"
                        >
                          <div
                            class="flex justify-start items-center gap-3 font-semibold text-gray-600"
                            @click="editInputScore(input.key)"
                          >
                            Editar pontuação
                          </div>
                        </b-dropdown-item>

                        <b-dropdown-item v-if="editable" aria-role="listitem">
                          <div
                            class="flex justify-start items-center gap-3 font-semibold text-gray-600"
                            @click="removeInput(i)"
                          >
                            {{
                              input.type === 'text_content' || input.type === 'image_direct' || input.type === 'image'
                                ? 'Eliminar conteúdo'
                                : 'Eliminar pergunta'
                            }}
                          </div>
                        </b-dropdown-item>

                        <div
                          class="flex justify-start items-center font-semibold text-gray-400 bg-gray-100 mx-2 p-2 rounded-lg text-sm mt-2"
                        >
                          #{{ input.key }}
                        </div>
                      </b-dropdown>
                      <div
                        v-if="
                          input.type !== 'text_content' &&
                            input.type !== 'image_direct' &&
                            input.type !== 'image' &&
                            (pointsCorrectInput > 0 || (input.score !== null && input.score > 0))
                        "
                        class="text-right mt-1.5"
                      >
                        <div v-if="input.score !== null">
                          <input
                            v-model="input.score"
                            :disabled="!editable"
                            class="points_input question-points"
                            type="number"
                            inputmode="numeric"
                            @input="dataChanged()"
                          />
                        </div>

                        <!--  @blur="saveScores(input.score)" -->
                        <div v-else-if="editing_score === input.key" class="points_input question-points">
                          <input
                            v-model="editing_score_value"
                            class="points_input question-points"
                            type="number"
                            inputmode="numeric"
                            @blur="saveInputScore(i)"
                          />
                        </div>
                        <div v-else class="points_input question-points">
                          {{ pointsCorrectInput }}
                        </div>
                        <div class="text-gray-500 text-sm text-xs mb-2 mt-0.5">valores</div>
                      </div>
                    </div>
                    <div
                      v-if="input.file == null && user_answers == null && editable"
                      class="bg-gray-50 rounded-xl w-full mb-2"
                    >
                      <Uploader
                        :is-docked="true"
                        :layout="'simple'"
                        :label="'Adicione uma imagem ou video à pergunta'"
                        :reference-id="'upload_' + i + '_' + input.key"
                        :is-custom="true"
                        :limit="1"
                        allowed="any"
                        :clear-after="true"
                        bucket-code="files"
                        bucket-type="file"
                        input-id="upload"
                        @upload="uploadFileToQuestion(i, $event)"
                      />
                    </div>
                    <div
                      v-else-if="input.file != null"
                      class="overflow-hidden rounded-xl mb-2 bg-gray-200 image_direct"
                    >
                      <img
                        v-if="input.file.token && input.file.type == 'image'"
                        :src="ServiceStorage.getImageViewUrl(input.file, null, null)"
                        class="w-full"
                      />
                      <!--
                       :src="ServiceStorage.getImageViewUrl(input.file, '100', myStorageToken)"
                      :src="ServiceStorage.getFileUrl(input.file, input.file.token)"
                        :src="ServiceStorage.getFileUrl(input.file, input.file.token)"
                      {{ input.file }} -->
                      <RecordFileEntry
                        v-if="editable"
                        :key="input.file.key"
                        :can-edit="true"
                        :allow-classified="false"
                        :file="input.file"
                        :can-download="false"
                        @remove="removeFile(i, $event)"
                        @download="downloadFile(input.file)"
                      />
                    </div>

                    <div
                      v-if="input.type === 'text_area'"
                      class="relative z-10 bg-white ring-1 ring-gray-900/5 rounded px-4 py-4 cursor-not-allowed"
                    >
                      <div
                        class="absolute inset-x-0 inset-y-4 border-t border-b border-gray-100 pointer-events-none"
                      ></div>
                      <div
                        class="absolute inset-x-4 inset-y-0 border-l border-r border-gray-100 pointer-events-none"
                      ></div>
                      <div
                        class="slighty-gray-color p-3 pb-10 text-gray-400 flex items-center group gap-1 items-center w-full"
                      >
                        {{ input.placeholder[language] }}
                        <div
                          v-if="false"
                          class="inline-block relative ml-0.5 flex items-center gap-1 group-hover:bg-opacity-0 opacity-30 group-hover:opacity-70 text-sm bg-gray-200 bg-opacity-80 text-gray-500 font-bold flex items-center justify-center text-sm rounded focus:outline-none "
                          style="height: 20px; width: 20px; padding: 2px;"
                        >
                          <svg
                            class="fill-current"
                            style="height: 16px; width: 16px;"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path
                              d="M12.9 6.858l4.242 4.243L7.242 21H3v-4.243l9.9-9.9zm1.414-1.414l2.121-2.122a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414l-2.122 2.121-4.242-4.242z"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div
                      v-else-if="input.type === 'upload_file'"
                      class="bg-white cursor-not-allowed flex gap-2 rounded-md items-center flex-col p-4 border border-dashed border-gray-300 text-gray-600"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        class="fill-current w-8 h-8 opacity-50"
                      >
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path
                          d="M12 12.586l4.243 4.242-1.415 1.415L13 16.415V22h-2v-5.587l-1.828 1.83-1.415-1.415L12 12.586zM12 2a7.001 7.001 0 0 1 6.954 6.194 5.5 5.5 0 0 1-.953 10.784v-2.014a3.5 3.5 0 1 0-1.112-6.91 5 5 0 1 0-9.777 0 3.5 3.5 0 0 0-1.292 6.88l.18.03v2.014a5.5 5.5 0 0 1-.954-10.784A7 7 0 0 1 12 2z"
                        ></path>
                      </svg>
                      <span class="text-sm opacity-50">Carregar ficheiros</span
                      ><span class="text-xs opacity-50">ou arraste para esta zona</span>
                    </div>
                    <div
                      v-else-if="input.type === 'files'"
                      class="bg-white cursor-not-allowed flex gap-2 rounded-md items-center flex-col p-4 border border-dashed border-gray-300 text-gray-600"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        class="fill-current w-8 h-8 opacity-50"
                      >
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path
                          d="M12 12.586l4.243 4.242-1.415 1.415L13 16.415V22h-2v-5.587l-1.828 1.83-1.415-1.415L12 12.586zM12 2a7.001 7.001 0 0 1 6.954 6.194 5.5 5.5 0 0 1-.953 10.784v-2.014a3.5 3.5 0 1 0-1.112-6.91 5 5 0 1 0-9.777 0 3.5 3.5 0 0 0-1.292 6.88l.18.03v2.014a5.5 5.5 0 0 1-.954-10.784A7 7 0 0 1 12 2z"
                        ></path></svg
                      ><span class="text-sm opacity-50">Carregar ficheiros</span
                      ><span class="text-xs opacity-50">ou arraste para esta zona</span>
                    </div>

                    <div v-else-if="input.type === 'multiple_choice'" class="fw-multiplechoice flex flex-col gap-2">
                      <div
                        v-for="(option, o) in input.options"
                        :key="'option_' + o"
                        class="multiplechoice_option_editor relative border border-gray-100 rounded-md text-base font-semibold pl-14 text-gray-800 bg-white block w-full text-left flex justify-start group"
                        :class="{
                          editable: editable,
                          'cursor-pointer hover:bg-gray-100':
                            editing_option !== input.key + '_' + option.key && editable,
                          ' py-2.5 pr-3': editing_option !== input.key + '_' + option.key,
                          active: editing_option === input.key + '_' + option.key && editable,
                          'py-0.5 pr-1': editing_option === input.key + '_' + option.key
                        }"
                      >
                        <div
                          class="multiplechoice_letter absolute text-center py-1.5 bg-white uppercase rounded shadow leading-4 text-lg font-semibold top-2 left-2"
                          style="width: 1.85rem;"
                        >
                          {{ String.fromCharCode(97 + o) }}
                        </div>
                        <div
                          v-if="editing_option === input.key + '_' + option.key"
                          class="flex-1 flex gap-2 items-center"
                        >
                          <input
                            :ref="input.key + '_' + option.key"
                            v-model="option.text[language]"
                            class="flex-1 w-full  text-base font-semibold"
                            type="text"
                            @input="dataChanged(p)"
                          />
                          <fw-button
                            type="dropdown"
                            :check-box="true"
                            :checked="
                              !!(
                                scores[input.key] &&
                                scores[input.key].options &&
                                scores[input.key].options.includes(option.key)
                              )
                            "
                            :custom-class="'py-1'"
                            @click.native="setCorrectOption(i, o)"
                          >
                            Opção certa
                          </fw-button>

                          <fw-button
                            v-if="input.options.length > 2"
                            type="dropdown"
                            paddingless
                            @click.native="deleteMultiplechoiceOption(i, o)"
                          >
                            <svg
                              class="text-red-600 fill-current h-5 w-5 flex-shrink-0"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="24"
                              height="24"
                            >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path
                                d="M17 6h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3zm1 2H6v12h12V8zm-9 3h2v6H9v-6zm4 0h2v6h-2v-6zM9 4v2h6V4H9z"
                              />
                            </svg>
                          </fw-button>
                        </div>
                        <div
                          v-else
                          class="flex-1 flex items-center gap-2"
                          @click="editMultiplechoiceOption(input.key + '_' + option.key)"
                        >
                          <div class="flex-1">
                            {{ option.text[language] }}
                          </div>

                          <div
                            v-if="editable"
                            class="inline-block relative ml-2 flex items-center gap-1 group-hover:bg-opacity-0 opacity-30 group-hover:opacity-70 text-sm bg-gray-200 bg-opacity-80 text-gray-500 font-bold flex items-center justify-center text-sm rounded focus:outline-none "
                            style="height: 20px; width: 20px; padding: 2px;"
                          >
                            <svg
                              class="fill-current"
                              style="height: 16px; width: 16px;"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="24"
                              height="24"
                            >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path
                                d="M12.9 6.858l4.242 4.243L7.242 21H3v-4.243l9.9-9.9zm1.414-1.414l2.121-2.122a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414l-2.122 2.121-4.242-4.242z"
                              />
                            </svg>
                          </div>
                          <fw-icon-checkbox-solid
                            v-if="
                              !!(
                                scores[input.key] &&
                                scores[input.key].options &&
                                scores[input.key].options.includes(option.key)
                              )
                            "
                            class="w-5 h-5 bg-white rounded-full text-primary flex-shrink-0"
                          />
                        </div>
                      </div>
                      <button
                        v-if="input.options.length < 10 && editable"
                        class="flex bg-gray-50 border border-gray-100 flex items-center justify-center group cursor-pointer text-center hover:shadow rounded-md text-gray-400 font-semibold px-2 py-2 hover:text-gray-700"
                        @click="addNewMultiplechoiceOption(i)"
                      >
                        <div
                          class="multiplechoice_letter text-center pl-1 flex-inline items-center justify-center py-1 bg-white uppercase rounded shadow leading-4 text-lg"
                          style="width: 1.85rem; height: 1.85rem;"
                        >
                          <svg
                            class="h-5 w-5 mr-3 text-gray-400 group-hover:text-primary fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" />
                          </svg>
                        </div>
                        <div class="flex-1 text-left pl-5">
                          Adicionar nova opção
                        </div>
                      </button>
                    </div>

                    <div
                      v-else-if="input.type === 'image_direct'"
                      class="overflow-hidden rounded-2xl mt-2.5 bg-gray-200 image_direct"
                    >
                      <img :src="input.directUrl" class="w-full" />
                    </div>

                    <input
                      v-if="input.type === 'image_direct' && input.message && input.message[language]"
                      v-model="input.message[language]"
                      class="text-sm outline-none w-full text-gray-400 italic bg-transparent mt-1"
                      :placeholder="'Legenda da imagem'"
                    />
                  </div>
                  <div v-if="editable" class="add-content-box pt-5 absolute left-0 right-5 -bottom-6 z-50">
                    <div class=" relative border-primary border-t h-4 border-dashed border-opacity-50 rounded-md ml-2">
                      <div class="absolute bottom-0.5 -left-4">
                        <b-dropdown
                          :scrollable="true"
                          :max-height="isMobile ? 600 : 500"
                          aria-role="list"
                          class="w-full"
                          position="is-top-right"
                        >
                          <template #trigger="{ active }">
                            <div class="add-content-arrow shadow hover:shadow-lg" :class="{ open: active }">
                              <svg
                                class="fill-current h-6 w-6 scale-90 transition-all flex-shrink-0"
                                :class="{ 'rotate-45': active }"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                              >
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path
                                  d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                                />
                              </svg>
                              <div class="add-content-label">
                                Adicionar conteúdo
                              </div>
                            </div>
                          </template>

                          <b-dropdown-item
                            v-for="(menu, index) in menu_add_question"
                            :key="index"
                            aria-role="listitem"
                            @click="addInput(menu.type, i + 1)"
                          >
                            <div class="flex justify-start items-center gap-3">
                              <div v-html="menu.icon"></div>
                              <div>
                                <div class="font-bold">{{ menu.text[language] }}</div>
                                <div class="text-gray-600">{{ menu.description[language] }}</div>
                              </div>
                            </div>
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </template>
  </LayoutDefault>
</template>

<script>
import LayoutDefault from '../../ui/components/layouts/LayoutDefault'
import FormServices from '../services/ServiceExams'
import utils from '../../utilities/utils.js'
import FormattedText from '../../ui/components/form/FormattedText'
import { Container } from 'vue-dndrop' //Draggable
import Vue from 'vue'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import RecordFileEntry from '@/fw-modules/fw-core-vue/ui/components/form/RecordFileEntry'
import Uploader from '@/fw-modules/fw-core-vue/storage/components/Uploader'
import SavedIndicator from '@/fw-modules/fw-core-vue/ui/components/form/SavedIndicator'
export default {
  name: 'QuestionsBucket',
  components: {
    Container,
    //Draggable,
    FormattedText,
    LayoutDefault,
    RecordFileEntry,
    Uploader,
    SavedIndicator
  },
  data: function() {
    return {
      permissions: {
        canSeeAnswers: true,
        canStartStopInstance: true
      },
      permissions_email: '',
      usersWithPermissions: [],
      clipboard_timer: null,
      copied: false,
      publishOptions: {
        allow: 'now',
        min: '00',
        hour: '00',
        duration: 60
      },
      currentModal: 'options',
      publishing: false,
      loading: true,
      saving: false,
      editing_option: '',
      editing_score: '',
      editing_score_value: 1,
      dirtyPages: new Set(),
      allPagesCompleted: false,
      showOptions: false,
      rightMargin: 200, //used in header animation (for the right toolbar)
      animateTitle: false,
      animateToolbar: false,
      isMobile: false,
      menu_add_question: [
        {
          icon:
            '<div class="w-12 h-12 pt-1 rounded-lg bg-white shadow flex flex-col gap-1">' +
            '<div class="rounded-l pl-0.5 leading-4 border border-gray-100 ml-2 flex items-center text-gray-300 border-r-transparent">A</div>' +
            '<div class="rounded-l pl-0.5 leading-4 border border-gray-100 ml-2 flex items-center text-gray-300 border-r-transparent">B</div>' +
            '</div>',
          text: {
            pt: 'Pergunta de escolha múltipla',
            en: 'Multiple choice question'
          },
          description: {
            pt: 'Permite escolher uma das opções definidas',
            en: 'Allow choose one of the options defined'
          },
          type: 'multiple_choice'
        },

        {
          type: 'text_area',
          icon:
            '<div class="w-12 h-12 rounded-lg bg-white shadow flex flex-col justify-center gap-1">' +
            '<div class="rounded-l flex flex-col py-1 gap-1 pl-1 leading-4 border border-gray-200 ml-2 text-gray-300 border-r-0">' +
            '<div class="bg-gray-100 rounded-l-lg h-1.5 w-full"></div>' +
            '<div class="bg-gray-100 rounded-l-lg h-1.5 w-full"></div>' +
            '<div class="bg-gray-100 rounded-lg h-1.5 w-6"></div>' +
            '</div>' +
            '</div>',
          text: {
            pt: 'Pergunta de resposta aberta',
            en: 'Open answer question'
          },
          description: {
            pt: 'Permite responder em texto livre',
            en: 'Allow to answer in plain text'
          }
        },

        {
          type: 'text_content',
          icon:
            "<div class='w-12 h-12 rounded-lg bg-white shadow flex flex-col justify-center gap-1'>" +
            "<div class='rounded-l flex flex-col py-1 gap-1 pl-1 leading-4 border border-gray-200 ml-2 text-gray-300 border-r-0'>" +
            'Tx' +
            '</div>' +
            '</div>',
          text: {
            pt: 'Conteúdo de texto',
            en: 'Text content'
          },
          description: {
            pt: 'Mostre texto adicional',
            en: 'Show additional text'
          }
        },
        {
          type: 'image_direct',
          icon:
            "<div class='w-12 h-12 rounded-lg bg-white shadow flex flex-col justify-center gap-1'>" +
            "<div class='rounded-l flex flex-col py-1 gap-1 pl-1.5 leading-4 border justify-center text-center border-gray-200 ml-4 text-gray-300 border-r-0 relative'>" +
            '<svg class="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M11.27 12.216L15 6l8 15H2L9 8l2.27 4.216zm1.12 2.022L14.987 19h4.68l-4.77-8.942-2.507 4.18zM5.348 19h7.304L9 12.219 5.348 19zM5.5 8a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5z"/></svg>' +
            "<div class='rounded-full bg-white text-gray-300 border border-gray-200 absolute -bottom-2 -left-3'>" +
            '<svg class="fill-current h-4 w-4 my-0.5 mx-0.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M17.657 14.828l-1.414-1.414L17.657 12A4 4 0 1 0 12 6.343l-1.414 1.414-1.414-1.414 1.414-1.414a6 6 0 0 1 8.485 8.485l-1.414 1.414zm-2.829 2.829l-1.414 1.414a6 6 0 1 1-8.485-8.485l1.414-1.414 1.414 1.414L6.343 12A4 4 0 1 0 12 17.657l1.414-1.414 1.414 1.414zm0-9.9l1.415 1.415-7.071 7.07-1.415-1.414 7.071-7.07z"/></svg>' +
            '</div>' +
            '</div>' +
            '</div>',
          text: {
            pt: 'Imagem de link',
            en: 'Image from link'
          },
          description: {
            pt: 'Mostre uma imagem usando o seu url',
            en: 'Show an image using its url'
          }
        },
        {
          type: 'upload_file',
          icon:
            "<div class='w-12 h-12 rounded-lg bg-white shadow flex flex-col justify-center gap-1'>" +
            "<div class='rounded-l flex flex-col py-1 gap-1 pl-1 leading-4 border border-gray-200 ml-2 text-gray-300 border-r-0'>" +
            "<svg class='fill-current h-5 w-5' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'>" +
            "<path fill='none' d='M0 0h24v24H0z' /> <path d='M1 14.5a6.496 6.496 0 0 1 3.064-5.519 8.001 8.001 0 0 1 15.872 0 6.5 6.5 0 0 1-2.936 12L7 21c-3.356-.274-6-3.078-6-6.5zm15.848 4.487a4.5 4.5 0 0 0 2.03-8.309l-.807-.503-.12-.942a6.001 6.001 0 0 0-11.903 0l-.12.942-.805.503a4.5 4.5 0 0 0 2.029 8.309l.173.013h9.35l.173-.013zM13 13v4h-2v-4H8l4-5 4 5h-3z'  />" +
            '</svg>' +
            '</div>' +
            '</div>',
          text: {
            pt: 'Zona para envio de ficheiros',
            en: 'Place to upload files'
          },
          description: {
            pt: 'Permitir anexar ficheiros',
            en: 'Allow to attach files'
          }
        }
      ],
      dropPlaceholderOptions: {
        className: 'drop-preview',
        animationDuration: '150',
        showOnTop: true
      },
      cover: null,
      startAt: null,
      endsAt: null,
      questions: [],
      editors: {},
      scrollY: 0,
      language: 'pt', //currently editing
      total_score: 0,
      instance: null,
      form: null,
      user_answers: null,
      override_formid: null, //used for user answers
      user: null,
      debouce_timer: null,
      bucket: null,
      scores: {},
      pointsCorrectInput: 1,
      availableLanguages: ['pt']
    }
  },

  computed: {
    myStorageToken() {
      return this.$store.getters.getUser.token
    },
    ServiceStorage() {
      return ServiceStorage
    },
    me() {
      return this.$store.getters.getUser
    },
    name() {
      return this.bucket && this.bucket.titles[this.language] ? this.bucket.titles[this.language] : ''
    },
    isPermissionEmailValid() {
      return utils.isValidEmail(this.permissions_email)
    },
    editable() {
      return this.bucket != null && this.me != null && this.bucket.user_key == this.me.key
    },
    publishingMin() {
      return this.publishOptions.min
    },
    publishingHour() {
      return this.publishOptions.hour
    },
    dirtyData() {
      return this.$store.state.dirtyData
    },
    savingData() {
      return this.saving
    },
    id() {
      return this.$route.params.id ? this.$route.params.id : null
    }
  },
  async created() {
    await this.loadFormData()
  },
  mounted() {
    let self = this
    document.querySelector('main').addEventListener('scroll', function() {
      let top = document.querySelector('main').scrollTop
      if (top > 88 && self.animateTitle === false) {
        self.animateTitle = true
        Vue.nextTick(function() {
          self.animateToolbar = true
        })
      } else if (top <= 88 && self.animateTitle === true) {
        self.animateToolbar = false
        Vue.nextTick(function() {
          self.animateTitle = false
        })
      }
    })

    window.addEventListener('resize', function() {
      if (!this.loading) {
        self.calculateRightMargin()
      }
    })
  },
  beforeDestroy() {
    let self = this
    window.removeEventListener('resize', function() {
      self.calculateRightMargin()
    })
    document.querySelector('main').removeEventListener('scroll', function() {
      let top = document.querySelector('main').scrollTop
      if (top > 88 && self.animateTitle === false) {
        self.animateTitle = true
        Vue.nextTick(function() {
          self.animateToolbar = true
        })
      } else if (top <= 88 && self.animateTitle === true) {
        self.animateTitle = false
        Vue.nextTick(function() {
          self.animateToolbar = false
        })
      }
    })
  },
  methods: {
    changeMaxChar(p, s, i) {
      let currentValue = this.pages[p].schema[s].content[i].maxChars
      this.$buefy.dialog.prompt({
        message: `Qual o comprimento máximo para a repsosta a esta pergunta?`,
        inputAttrs: {
          type: 'number',
          placeholder: 'Insira o número máximo de caracteres',
          value: currentValue,
          min: 0
        },
        trapFocus: true,
        onConfirm: value => {
          console.log(value)
          Vue.set(this.pages[p].schema[s].content[i], 'maxChars', value)
          this.dataChanged(p)
        }
      })
    },
    uploadFileToQuestion(i, event) {
      console.log('UPLOAD RESULT', i)
      console.log(event)
      if (event.length > 0) {
        let serverFile = event[0].response.data.file
        Vue.set(this.questions[i], 'file', serverFile)
        this.dataChanged()
      }
    },
    removeFile(i) {
      this.$buefy.dialog.confirm({
        message: 'Tem a certeza que quer remover a imagem/video?',
        onConfirm: () => {
          Vue.set(this.questions[i], 'file', null)
          this.dataChanged()
        }
      })
    },
    async addPermissions() {
      this.showOptions = true
      this.currentModal = 'permissions'
      this.usersWithPermissions = []
      this.usersWithPermissions = await FormServices.getInstancePermissions(this.instance.key)
    },
    async removeAllPermissions(userKey, u) {
      try {
        await FormServices.deleteInstancePermissions(this.instance.key, userKey)
        this.usersWithPermissions.splice(u, 1)
        this.$buefy.toast.open({
          duration: 2000,
          message: `Permissões removidas`,
          position: 'is-top'
        })
      } catch (e) {
        console.error(e)
        this.showError('Ocorreu um erro ao remover permissões')
      }
    },
    async save(attr) {
      if (attr === 'permissions') {
        //verify e-mail
        if (this.isPermissionEmailValid) {
          this.saving = true
          try {
            let result = await FormServices.addInstancePermissions(
              this.instance.key,
              this.permissions_email,
              this.permissions
            )
            console.log('permission result', result)
            this.usersWithPermissions = result.users
            this.showOptions = false
            this.currentModal = 'options'
          } catch (e) {
            this.saving = false
            console.error(e)
            alert(e.response.data['__errors__'][0]['detail'])
          }
          this.saving = false
          //
        }
      }
    },
    changeBool(attr, key) {
      this[attr][key] = !this[attr][key]
    },
    permissionsMapping(key) {
      const map = {
        canSeeAnswers: 'Ver respostas do exame',
        startStopInstance: 'Iniciar e terminar o exame'
      }
      return map[key] ? map[key] : ''
    },
    editInputScore(id) {
      if (this.editable) {
        this.editing_score = id
        this.editing_score_value = this.pointsCorrectInput
      }
    },
    saveInputScore(i) {
      Vue.set(this.questions[i], 'score', this.editing_score_value)
      this.editing_score = ''
      this.dataChanged()
    },
    removeInputScore(i) {
      Vue.set(this.questions[i], 'score', null)
      this.editing_score = ''
      this.dataChanged()
    },
    /*async saveScores(newscore = 0) {
      let total_score = 0
      let scoreData = {}

      for (let p = 0; p < this.pages.length; p++) {
        for (let s = 0; s < this.pages[p].schema.length; s++) {
          for (let i = 0; i < this.pages[p].schema[s].content.length; i++) {
            let inputData = this.pages[p].schema[s].content[i]
            let inputScore = this.pages[p].schema[s].content[i].score ? this.pages[p].schema[s].content[i].score : 0
            if (!staticContentBlocks.includes(inputData.type) && inputData.score === 0 && newscore > 0) {
              Vue.set(this.pages[p].schema[s].content[i], 'score', Number(newscore))
              inputScore = Number(newscore)
            }
            scoreData[this.pages[p].key + '_' + inputData.key] = {}
            scoreData[this.pages[p].key + '_' + inputData.key]['score'] = inputScore
            scoreData[this.pages[p].key + '_' + inputData.key]['correct'] = inputData.options
            total_score += Number(inputScore)
          }
        }
      }
      this.total_score = total_score
      let json_data = JSON.stringify(scoreData)
      //if (this.instance !== null) {
      //  window.localStorage.setItem('correction_instance_' + this.instance.key, json_data)
      //  await ServiceSettings.setSetting('correction_instance_' + this.instance.key, json_data)
      //} else {
      window.localStorage.setItem('correction_form_' + this.form.key, json_data)
      await ServiceSettings.setSetting('correction_form_' + this.form.key, json_data)
      //}

      console.log('scoreData', scoreData)
    },*/
    /*checkPagesCompleteness() {
      for (let i = 0; i < this.pages.length; i++) {
        if (this.pages[i].complete !== true) {
          this.allPagesCompleted = false
          return
        }
      }
      this.allPagesCompleted = true
    },
    getShareLink() {
      this.showOptions = true
      this.currentModal = 'share'
    },*/

    goToSection(key) {
      document.getElementsByTagName('main')[0].scrollTo({
        top:
          this.$refs['section_' + key][0].getBoundingClientRect().top +
          document.getElementsByTagName('main')[0].scrollTop -
          55,
        behavior: 'smooth'
      })
    },
    renderNav() {
      let all = document.getElementsByClassName('empty_nav_bt')
      for (let i = 0; i < all.length; i++) {
        //if (all[i].classList.contains('empty_nav_bt')) {
        all[i].innerHTML = (this.language === 'pt' ? 'Secção ' : 'Section ') + (i + 1)
        //}
      }
    },
    changeLanguage(language) {
      this.language = language
    },
    async loadFormData() {
      this.loading = true
      try {
        let bucket = await FormServices.getBucket(this.id)
        console.log('result', bucket)
        this.bucket = bucket
        if (bucket.schema != null && bucket.schema.length > 0) {
          this.questions = bucket.schema
          this.scores = Array.isArray(bucket.scores) ? {} : bucket.scores
        }
        this.loading = false
      } catch (e) {
        console.error(e)
        this.showError('Ocorreu um erro ao carregar o documento. Tente de novo mais tarde ou contacte o suporte.')
      }
    },
    showError(message = 'Ocorreu um erro. Tente de novo mais tarde ou contacte o suporte.') {
      this.$buefy.dialog.alert({
        message: message,
        type: 'is-danger',
        hasIcon: false,
        icon: 'times-circle',
        iconPack: 'fa',
        ariaRole: 'alertdialog',
        ariaModal: true
      })
    },
    setCorrectOption(i, o) {
      let correctOptionID = this.questions[i].options[o].key
      let inputId = this.questions[i].key

      if (this.scores[inputId] && this.scores[inputId].type === 'choices') {
        //opções já definidas
        let selectedOptions = this.scores[inputId] && this.scores[inputId].options
        if (Array.isArray(selectedOptions)) {
          let index = selectedOptions.indexOf(correctOptionID)
          if (index >= 0) {
            selectedOptions.splice(index, 1)
          } else {
            selectedOptions.push(correctOptionID)
          }
          if (selectedOptions.length === 0) {
            Vue.delete(this.scores, inputId)
          } else {
            Vue.set(this.scores[inputId], 'options', selectedOptions)
          }
        } else {
          Vue.set(this.scores[inputId], 'options', [correctOptionID])
        }
      } else {
        let data = {
          type: 'choices',
          options: [correctOptionID]
        }
        if (typeof this.scores === 'undefined') {
          let tmp = {}
          tmp[inputId] = data
          this.scores = tmp
          //Vue.set(this.pages[p], 'scores', tmp)
        } else {
          Vue.set(this.scores, inputId, data)
        }
      }
      this.dataChanged()
    },
    clickBackground() {
      this.editing_option = ''
    },

    addNewMultiplechoiceOption(i) {
      let options = this.questions[i].options

      let letter = String.fromCharCode(97 + options.length).toUpperCase()
      //let input_score = this.pages[p].schema[s].content[i].score

      let newOptionData = {
        key: Date.now().toString(), //this.makeid(8),
        text: {}
      }
      newOptionData['text'][this.language] = this.language === 'pt' ? 'Opção ' + letter : 'Option ' + letter

      options.push(newOptionData)

      /*if (input_score > 0) {
        let partial_score = input_score / options.length
        //redistribuir score pelas opções
        options.map(opt => {
          opt['score'] = partial_score
          return opt
        })
      }*/

      Vue.set(this.questions[i], 'options', options)
      this.dataChanged()
    },
    deleteMultiplechoiceOption(i, o) {
      this.questions[i].options.splice(o, 1)
      this.dataChanged()
    },

    editMultiplechoiceOption(val) {
      if (this.editable) {
        this.editing_option = val
        let self = this
        Vue.nextTick(function() {
          //console.log(val, self.$refs[val], self.$refs)
          self.$refs[val][0].focus()
        })
      }
    },
    async saveForm() {
      console.log('saveForm activated!')
      if (!this.saving) {
        this.saving = true
        console.log('saving')
        let data = {}
        data['questions'] = {
          questions: this.questions,
          scores: this.scores
        }
        try {
          let reply = await FormServices.updateBucket(this.id, data)

          //update files
          for (let i = 0; i < reply.schema.length; i++) {
            let file = reply.schema[i].file
            if (file != null) {
              let question = this.questions.find(q => q.key === reply.schema[i].key)
              Vue.set(question, 'file', file)
            }
          }

          this.$store.commit('setDirtyData', false)
        } catch (e) {
          console.error(e)
        } finally {
          this.saving = false
        }
      }
    },
    dataChanged(page) {
      console.log('dataChanged ' + page + ' activated!')
      this.dirtyPages.add(page)
      //debounce, save
      let self = this
      if (this.debouce_timer !== null) {
        clearTimeout(this.debouce_timer)
      }
      this.debouce_timer = setTimeout(() => {
        this.$store.commit('setDirtyData', true)
        self.debouce_timer = null
        console.log('debounced ' + page + ' activated!')
        self.saveForm()
      }, 500)
    },
    closeOptionsModal() {
      this.showOptions = false
    },
    changeOptions(option) {
      console.log('changing', option)
      Vue.set(this.form.options, option, !this.form.options[option])
      //this.form.options[option] =
    },
    calculateRightMargin() {
      //console.log(this.$refs['form_main_body'])
      //console.log(window.innerWidth, this.$refs['form_main_body'].getBoundingClientRect().right)
      this.isMobile = window.innerWidth < 640

      if (this.animateTitle) {
        this.rightMargin = 15
      } else if (this.form !== null && !this.loading && this.$refs['form_main_body']) {
        this.rightMargin = window.innerWidth - this.$refs['form_main_body'].getBoundingClientRect().right
      }
    },
    getChildPayload(index) {
      return this.questions[index]
    },
    toogleCover() {
      if (this.cover === null) {
        this.cover = true
      } else {
        this.cover = null
      }
    },
    async publishForm() {
      console.log('publishForm')

      if (this.bucket !== null) {
        //update instance and publish instance
        this.publishing = true

        //let formKeys = this.instance.forms.map(form => form.key)
        let data = {}
        let local_bucket = await FormServices.updateBucket(
          this.id,
          data
          //formKeys,
          //this.form.title,
          //instanceOptions,
          //startDate
        )
        this.bucket = local_bucket

        //let formPromises = []
        //let loadformPromises = []
        /*local_instance.forms.forEach(form => {
          loadformPromises.push(FormServices.getForm(form.key))
        })*/

        //let resultForms = await Promise.all(loadformPromises)
        //close all forms
        /*resultForms.forEach(form => {
          if (form.status !== 'closed') formPromises.push(this.closeForm(form.key))
        })*/

        /* Promise.all(formPromises)
          .then(async result => {
            console.log('closing forms', result)
            //Publish instance
            try {
              let publish_result = await FormServices.publishInstance(local_instance.key, 'publish')
              console.log('publish_result', publish_result)
              this.$router.push({ path: `/builder/${this.type}/${local_instance.key}/${this.formid}` })
              this.currentModal = 'share'
              this.bucket.status = 'waiting'
              this.form.status = 'closed'
            } catch (e) {
              console.error(e)
              this.showError('Ocorreu um erro ao publicar o exame. Tente de novo mais tarde.')
            }
            this.publishing = false
          })
          .catch(e => {
            console.error(e)
            this.showError(
              'Verifique que todas as versões do exame contém título, instruções e conteúdo. Tente de novo mais tarde.'
            )
            this.publishing = false
          }) */
      }
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr

      const result = [...arr]
      let itemToAdd = payload

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }

      return result
    },
    onDropSection(dropResult) {
      if (this.editable) {
        let result = this.applyDrag(this.questions, dropResult)
        console.log('applyDrag', result)
        Vue.set(this.questions, 'content', result)
        this.dataChanged()
      }
    },
    makeid(length) {
      var result = ''
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      var charactersLength = characters.length
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
      }
      return result
    },
    addInput(type, i = null) {
      let addItem = true
      let image_url

      if (type === 'files') {
        //IGNORE FILES TYPE FOR NOW
        return
      }

      if (type === 'image_direct') {
        image_url = window.prompt(
          'Indique o link directo da imagem (apenas https://):',
          'https://www.example.com/imagelink.jpeg'
        )
        if (image_url == null || image_url == '') {
          console.log('link da imagem vazio')
          addItem = false
        } else if (image_url.startsWith('https://')) {
          //link válido
        } else {
          addItem = false
        }
      }

      let inputData = {
        placeholder: {},
        //TODO: generate uid
        key: Date.now().toString(), //this.makeid(8),
        type: type,
        instructions: {},
        label: {},
        score: null,
        min: type === 'multiple_choice' ? 0 : 1,
        max: type === 'multiple_choice' ? 1 : type === 'text_area' ? 1000 : 250,
        required: false
      }

      inputData['placeholder'][this.language] = ''
      inputData['instructions'][this.language] = ''
      inputData['label'][this.language] = ''

      if (type === 'multiple_choice') {
        inputData['random'] = false
        inputData['options'] = [
          {
            key: Date.now().toString() + '1',
            text: {}
          },
          {
            key: Date.now().toString() + '2',
            text: {}
          }
        ]
        inputData['options'][0]['text'][this.language] = this.language === 'pt' ? 'Opção A' : 'Option A'
        inputData['options'][1]['text'][this.language] = this.language === 'pt' ? 'Opção B' : 'Option B'
      } else if (type === 'text_area') {
        inputData['min'] = 0
        //inputData['message'] = { pt: '', en: '' }
        inputData['message'] = {}
        inputData['message'][this.language] = ''
        inputData['placeholder'] = {}
        inputData['placeholder'][this.language] =
          this.language === 'pt' ? 'Escreva aqui a sua resposta' : 'Write here your answer'
      } else if (type === 'files') {
        inputData['allowed'] = 'any'
      } else if (type === 'image_direct') {
        inputData = {
          type: type,
          key: Date.now().toString(),
          directUrl: image_url
        }
      }

      if (addItem) {
        console.log('ADDING', inputData)
        if (i === null) {
          this.questions.push(inputData)
        } else {
          this.questions.splice(i, 0, inputData)
        }
        this.dataChanged()
      }
    },
    removeInput(i) {
      let inputId = this.questions[i].key
      this.questions.splice(i, 1)
      Vue.delete(this.scores, inputId)
      this.dataChanged()
    },
    goBack() {
      this.$router.back()
    }
  }
}
</script>

<style>
.multiplechoice_option_editor.editable .multiplechoice_letter {
  @apply text-gray-500;
}
.multiplechoice_option_editor.editable:hover .multiplechoice_letter {
  color: rgba(3, 164, 121, 0.7);
}
.multiplechoice_option_editor.editable:focus .multiplechoice_letter {
  color: rgba(3, 164, 121, 0.7);
}
.multiplechoice_option_editor.editable:hover {
  border-color: rgba(3, 164, 121, 0.7);
}

.multiplechoice_option_editor.selected_correct .multiplechoice_letter {
  background-color: #03b78e;
  @apply text-gray-100;
}
.multiplechoice_option_editor.selected_correct {
  border-color: rgba(3, 164, 121, 0.7);
  background-color: #e3f1ed;
  @apply font-semibold;
}

.multiplechoice_option_editor.missed_selection .multiplechoice_letter {
  background-color: #d2b44fff;
  @apply text-gray-100;
}
.multiplechoice_option_editor.missed_selection {
  border-color: rgba(248, 215, 25, 0.7);
  background-color: #fffdef;
  @apply font-semibold;
}

.multiplechoice_option_editor.selected_wrong .multiplechoice_letter {
  background-color: #b72403;
  @apply text-gray-100;
}
.multiplechoice_option_editor.selected_wrong {
  border-color: rgba(164, 33, 3, 0.7);
  background-color: #ecded9;
  @apply font-semibold;
}

.bt_nav_lateral {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.image_direct {
  min-height: 50px;
}
.view-builder .modal input {
  @apply leading-4 bg-gray-100 py-0;
  min-height: auto;
}
.add-content-box .add-content-label {
  @apply transition-all whitespace-nowrap mx-1 text-sm font-semibold;
}

.add-content-box .add-content-arrow {
  @apply transition-all overflow-hidden whitespace-nowrap;
  max-width: 27px;
}
.add-content-box .add-content-arrow.open {
  max-width: 500px !important;
}

.add-content-arrow {
  @apply rounded px-1 py-0.5 bg-primary text-white text-opacity-80 flex items-center;
}

.dndrop-container.vertical > .dndrop-draggable-wrapper {
  overflow: visible !important;
  display: block;
}

.add-content-arrow:after {
  content: '';
  position: absolute;
  right: -0.5em;
  width: 1.2em;
  height: 1.2em;
  border-radius: 0 0 0.2em 0;
  margin-top: 0px;
  transform: rotate(-45deg);
  background: linear-gradient(-45deg, #30a898 50%, transparent 50%);
}
.page-section .section-toolbar {
  @apply opacity-0 transition-all;
}
.page-section:hover .section-toolbar {
  @apply opacity-100;
}

.page-separator {
  @apply relative mt-12 mb-8 bg-gray-200 bg-opacity-40;
  height: 2px;
  box-shadow: inset 0px 1px rgba(55, 55, 55, 0.07);
  border-radius: 1px;
}

.page-separator .title {
  @apply absolute bg-gray-100 font-semibold uppercase text-xs text-gray-400 text-opacity-60 text-center -top-1.5;
  left: 50%;
  margin-left: -40px;
  width: 80px;
}

.real-white {
  background-color: #fdfdfd;
}

.slighty-gray-color {
  background-color: #fcfcfc;
}
.real-shadow-1 {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
}
.real-shadow-2 {
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12) !important;
}
.real-shadow-3 {
  box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.2), 0 7px 10px 1px rgba(0, 0, 0, 0.14), 0 2px 16px 1px rgba(0, 0, 0, 0.12) !important;
}
.real-shadow-4 {
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 5px 22px 4px rgba(0, 0, 0, 0.12) !important;
}
.bt-scafold {
  @apply rounded-md border font-bold border-gray-100 py-2.5 text-gray-700 text-center mx-auto cursor-pointer text-gray-100;
  min-width: 80px;
}

.multiplechoice_option_editor .multiplechoice_letter {
  @apply text-gray-500;
}
.title-anim {
  @apply transition-all z-20;
}
.title-anim .edit-button {
  @apply opacity-100;
}

.title-anim.title-header {
  @apply top-3 left-20 absolute text-2xl cursor-default;
}
.title-anim.title-header .edit-button {
  @apply opacity-0;
}

.box-question .drag-handle {
  @apply transition-all opacity-0;
}
.box-question:hover .drag-handle {
  @apply transition-all opacity-100;
}

.box-question .add-content-box {
  @apply transition-all opacity-0;
}
.box-question:hover .add-content-box {
  @apply transition-all opacity-100;
}

.drag-handle {
  @apply h-6 w-5 shadow-sm bg-white rounded border border-gray-200 items-center flex justify-center text-center absolute -left-2;
  cursor: grab;
}

.view-builder .question-points:focus,
.view-builder .question-points:active {
  @apply ring-2 ring-offset-2 ring-teal-500 outline-none ring-opacity-90;
}
.exam-cover input {
  @apply outline-none;
}

input.question-points {
  @apply text-base px-2 py-1 rounded-lg bg-gray-100 w-16 text-xl text-right;
}

.dropdown.w-full .dropdown-trigger {
  width: 100% !important;
}
.dropdown .dropdown-content {
  @apply border-gray-200;
}
.multiplechoice_option_editor input,
.multiplechoice_option_editor input:active,
.multiplechoice_option_editor input:focus {
  @apply py-2 rounded-md outline-none;
}
.fw-multiplechoice .multiplechoice_option_editor.active {
  @apply ring-2 ring-offset-2 ring-teal-500 ring-opacity-90;
}

.question-container {
  min-height: 85px;
  @apply relative;
}

.loading-bar {
  width: 12%;
  animation-name: loading-bar-anim;
  animation-duration: 3s;
  animation-delay: 500ms;
}

@keyframes loading-bar-anim {
  from {
    width: 12%;
  }
  to {
    width: 80%;
  }
}

/* Chrome, Safari, Edge, Opera */
input.points_input::-webkit-outer-spin-button,
input.points_input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.points_input[type='number'] {
  -moz-appearance: textfield;
}
</style>
