<template>
  <ExpandList
    disabled
    empty
    show-add-items
    :empty-title="'Sem entradas'"
    :add-title="'Adicionar nova entrada'"
  ></ExpandList>
</template>

<script>
import ExpandList from '@/fw-modules/fw-core-vue/ui/components/lists/ExpandList'
export default {
  name: 'PlaceholderCustomObject',
  components: {
    ExpandList
  },
  props: {
    placeholder: {
      type: Object,
      default: () => {
        return {
          pt: 'Insira uma data',
          en: 'Enter a date'
        }
      }
    },
    schema: {
      type: Array,
      default: () => {
        return []
      }
    },
    language: {
      type: String,
      default: 'pt'
    }
  }
}
</script>
